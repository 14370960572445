import React, { useEffect, useState } from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import {
  deleteParticipant,
  getEditProfileParticipantByIdAndStatus,
  updateParticipantProfileLock,
} from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import {
  IEditProfileParticipantByIdAndStatus,
  IEditProfileParticipantByIdAndStatusBody,
} from '../../interface'
import ProfileParticipantName from './profileParticipantName'
import ProfileParticipantRole from './profileParticipantRole'
import ProfileParticipantInvite from './profileParticipantInvite'
import ProfileParticipantForm from './profileParticipantForm'
import ProfileParticipantProfile from './profileParticipantProfile'
import ProfileParticipantAccount from './profileParticipantAccount'
import { DeleteModal } from '../../../../../components/modals/deleteModal'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { addToast, setSpinner } from '@app/containers/actions'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { AnyAction } from 'redux'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { ToggleCheckbox } from '@app/components/formComponents/toggleCheckbox'
import { saveParticipantFieldValue } from '@app/containers/participantPages/actions'
import { ISaveParticipantFieldValueBody } from '@app/containers/profileList/addProfile/roleSettings/roleSettingsWithEditOption'
import { ProfileId } from '@app/containers/reducer'

interface IEditProfileParticipantModalProps {
  languageText: ITranslationObject
  profileId: ProfileId
  roleDisabled: boolean
  languageCode: string
  languages: IDropdownList[]
  messageTranslation: ITranslationObject
  status: number
  showDeleteButton?: boolean
  handleCloseClick: () => void
  refetchParticipants: () => void
  refetchOngoingParticipants?: () => void
  refetchCompletedParticipants?: () => void
  handleUnPublishProfile?: () => void
  handlePublishProfile?: (profileId: ProfileId) => void
  handleTransferProfile?: () => void
}

const initialProfileParticipantState: IEditProfileParticipantByIdAndStatus = {
  name: '',
  surname: '',
  roleId: 0,
  roleText: '',
  noOfRespondents: 0,
  emailAddress: '',
  emailStatus: 0,
  emailSentLog: [],
  latestEmailSentLog: '',
  phoneNumber: '',
  smsStatus: 0,
  smsSentLog: [],
  emailOwnMessage: '',
  smsOwnMessage: '',
  latestSmsSentLog: '',
  respondentMsg1: '',
  respondentMsg2: '',
  respondentsAnswered: 0,
  respondentsInvited: 0,
  pdfProfileDownloaded: false,
  isLocked: false,
  enableSelfDelivery: false,
}

const EditProfileParticipantModal = (props: IEditProfileParticipantModalProps): JSX.Element => {
  const {
    languageText,
    profileId,
    roleDisabled,
    languageCode,
    languages,
    status,
    messageTranslation,
    showDeleteButton,
    handleCloseClick,
    refetchParticipants,
    refetchOngoingParticipants,
    refetchCompletedParticipants,
    handlePublishProfile,
    handleUnPublishProfile,
    handleTransferProfile,
  } = props

  const dispatch = useDispatch()
  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)

  const [profileParticipantData, setProfileParticipantData] =
    useState<IEditProfileParticipantByIdAndStatus>(initialProfileParticipantState)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [refetchParticipantData, setRefetchParticipantData] = useState<boolean>(false)
  const [isDownload, setIsDownload] = useState<boolean>(false)
  const isAdmin = loggedInUserRole === 'Admin'

  useEffect(() => {
    const body: IEditProfileParticipantByIdAndStatusBody = {
      profileId,
      status,
      languageCode,
    }
    getEditProfileParticipantByIdAndStatus(body, dispatch).then((response) => {
      if (response) {
        setProfileParticipantData(response)
      }
    })
  }, [refetchParticipantData])

  const onDeleteParticipantClick = (): void => setOpenDeleteModal(true)

  const closeDeleteModal = (): void => setOpenDeleteModal(false)

  const handleDeleteParticipant = (): void => {
    deleteParticipant(profileId, dispatch).then((response) => {
      if (response?.success) {
        dispatch(addToast('Participant Deleted') as AnyAction)
        refetchParticipants()
        handleCloseClick()
      }
    })
  }

  const handleProfileLock = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSpinner(true))
    const { checked } = e.target

    updateParticipantProfileLock(profileId, checked, dispatch)
      .then(() => {
        setProfileParticipantData({
          ...profileParticipantData,
          isLocked: checked,
        })
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const updatedownloadedStatus = () => {
    setIsDownload(true)
  }

  const handleMessageSentSuccess = (): void => {
    setRefetchParticipantData(!refetchParticipantData)
    refetchParticipants()
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): Promise<unknown> {
    const name = event.target.name
    setProfileParticipantData((prev) => {
      return {
        ...prev,
        [name]: event.target.checked,
      }
    })

    return saveFieldValue(name, String(event.target.checked))
  }

  /** FIXME: this function name is way too similar to the imported 'saveParticipantFieldValue'.  */
  function saveFieldValue(name: string, value: string): Promise<unknown> {
    const body: ISaveParticipantFieldValueBody = {
      id: profileId,
      fieldName: name,
      fieldValue: value,
    }
    dispatch(setSpinner(true))
    return saveParticipantFieldValue(body, dispatch).finally(() => {
      dispatch(setSpinner(false))
    })
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Edit Participant')}
        submitButtonText={
          isLoggedInRoleAdmin(loggedInUserRole) || showDeleteButton
            ? getLanguageValue(languageText, 'Delete participant')
            : undefined
        }
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleSubmitClick={onDeleteParticipantClick}
        handleCancelClick={handleCloseClick}
        isSubmitDangerButton={true}
        width='lg'
      >
        {/* Participant Name & Surname */}
        <ProfileParticipantName
          languageText={languageText}
          profileId={profileId}
          name={profileParticipantData.name}
          surname={profileParticipantData.surname}
          refetchParticipants={refetchParticipants}
        />

        {/* Participant Role */}
        {!roleDisabled && (
          <ProfileParticipantRole
            languageText={languageText}
            profileId={profileId}
            status={status}
            roleId={profileParticipantData.roleId}
            roleText={profileParticipantData.roleText}
            noOfRespondents={profileParticipantData.noOfRespondents}
            respondentsAnswered={profileParticipantData.respondentsAnswered}
            refetchParticipants={refetchParticipants}
            refetchOngoingParticipants={refetchOngoingParticipants}
            refetchCompletedParticipants={refetchCompletedParticipants}
          />
        )}

        {/* Participant Invite */}
        <ProfileParticipantInvite
          languageText={languageText}
          languages={languages}
          languageCode={languageCode}
          name={profileParticipantData.name}
          profileId={profileId}
          emailAddress={profileParticipantData.emailAddress}
          emailSentLog={profileParticipantData.emailSentLog}
          latestEmailSentLog={profileParticipantData.latestEmailSentLog}
          phoneNumber={profileParticipantData.phoneNumber}
          smsSentLog={profileParticipantData.smsSentLog}
          emailOwnMessage={profileParticipantData.emailOwnMessage}
          smsOwnMessage={profileParticipantData.smsOwnMessage}
          latestSmsSentLog={profileParticipantData.latestSmsSentLog}
          messageTranslation={messageTranslation}
          emailStatus={profileParticipantData.emailStatus}
          smsStatus={profileParticipantData.smsStatus}
          handleMessageSentSuccess={handleMessageSentSuccess}
        />

        {/* Participant Form */}
        <ProfileParticipantForm
          languageText={languageText}
          status={status}
          respondentMsg1={profileParticipantData.respondentMsg1}
          respondentMsg2={profileParticipantData.respondentMsg2}
          respondentsAnswered={profileParticipantData.respondentsAnswered}
          respondentsInvited={profileParticipantData.respondentsInvited}
        />

        {/* Participant Profile */}
        <ProfileParticipantProfile
          languageText={languageText}
          status={status}
          profileId={profileId}
          profileParticipantData={profileParticipantData}
          isDownloaded={isDownload}
          pdfProfileDownloaded={profileParticipantData.pdfProfileDownloaded}
          handleProfileLock={handleProfileLock}
          updatedownloadedStatus={updatedownloadedStatus}
        />

        {/* Participant Account */}
        <ProfileParticipantAccount
          languageText={languageText}
          status={status}
          pdfProfileDownloaded={profileParticipantData.pdfProfileDownloaded}
          profileId={profileId}
          isDownloaded={isDownload}
          closeModal={handleCloseClick}
          handlePublishProfile={handlePublishProfile}
          handleUnPublishProfile={handleUnPublishProfile}
          handleTransferProfile={handleTransferProfile}
        />

        {isAdmin && (
          <>
            {/** If we add stuff below here, remember to bring this border out of the condition */}
            <div className='border-bottom my-4' />
            <ToggleCheckbox
              label={getLanguageValue(languageText, 'Enable self delivery')}
              name='enableSelfDelivery'
              handleCheckboxChange={handleCheckboxChange}
              value={profileParticipantData.enableSelfDelivery}
            />
          </>
        )}

        {/* Delete Participant Modal */}
        {openDeleteModal && (
          <DeleteModal
            languageText={languageText}
            headerText={getLanguageValue(languageText, 'participant')}
            bodyText={getLanguageValue(languageText, 'Self and Profile form data')}
            handleDeleteClick={handleDeleteParticipant}
            closeDeleteModal={closeDeleteModal}
          />
        )}
      </ModalComponent>
    </>
  )
}

export default EditProfileParticipantModal
