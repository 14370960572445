import React from 'react'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'

interface IDeliveryStatusPendingProps {
  languageText: ITranslationObject
  coursePresentationDate: string
  facilitatorName: string
}

const DeliveryStatusPending = (props: IDeliveryStatusPendingProps): JSX.Element => {
  const { languageText, coursePresentationDate, facilitatorName } = props

  return (
    <div className='load'>
      <div className='title-m'>{getLanguageValue(languageText, 'IDI Profile Delivery')}</div>
      <br />
      <div className='pres-info'>
        {getLanguageValue(languageText, 'Presentation Date')}:
        <br /> <strong> {coursePresentationDate}</strong> <br />
        <br />
        {getLanguageValue(languageText, 'Presenter')}:
        <br /> <strong>{decodeURI(facilitatorName)}</strong>
      </div>
      <br />
      {getLanguageValue(languageText, 'The presentation has not been started by the presenter yet')}
      ...
    </div>
  )
}

export default DeliveryStatusPending
