import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import CreateNewProfile from './createNewProfileModal'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IProfileStatus } from '../interface'
import { CoursePresentationStatus, ProfileStatus } from '../../../profileList/profileEnums'
import { routePath } from '../../../routePaths'
import { RootState } from '@app/store/configureStore'
import { clearParticipantProfileDetails } from '../../actions'
import { ucQueryKey } from '../../useParticipantProfileAuth'

interface IStatusProps {
  languageText: ITranslationObject
  profileStatus: IProfileStatus
  readOnly?: boolean
}

const Status = (props: IStatusProps): JSX.Element => {
  const { languageText, profileStatus, readOnly = false } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userDetails = useSelector((state: RootState) => state.loginReducer.userDetails)
  const isRoledisabled = profileStatus.activityId ? false : true
  const isRoleFilled = profileStatus.isRoleFilled
  const isSelfFormDisabled = !profileStatus.isRoleFilled
  const isSelfFormFilled = profileStatus.isSelfFormFilled
  const isRespondentsdisabled = !profileStatus.isSelfFormFilled
  const isRespondentsInvited = profileStatus.isRespondentsInvited
  const isPresentationCreated = profileStatus.isPresentationCreated

  // Profile navigation
  const handleProfileNavigation = (): void => {
    dispatch(clearParticipantProfileDetails())

    // nice copy pasta, bro.
    if (profileStatus.isPresentationCreated) {
      // Profile Presentation detail page
      navigate(
        `${routePath.participantProfilePresentation}?${ucQueryKey}=${profileStatus.uniqueCode}`
      )
    } else if (profileStatus.isRespondentsInvited || profileStatus.isSelfFormFilled) {
      navigate(
        `${routePath.participantInviteRespondents}?${ucQueryKey}=${profileStatus.uniqueCode}`
      )
    } else if (!profileStatus.isDemographicsFilled) {
      navigate(`${routePath.participantWelcomePage}?${ucQueryKey}=${profileStatus.uniqueCode}`)
    } else if (profileStatus.isRoleFilled) {
      navigate(`${routePath.participantSelfForm}?${ucQueryKey}=${profileStatus.uniqueCode}`)
    } else if (profileStatus.isDemographicsFilled) {
      navigate(`${routePath.participantRole}?${ucQueryKey}=${profileStatus.uniqueCode}`)
    } else {
      navigate(`${routePath.participantWelcomePage}?${ucQueryKey}=${profileStatus.uniqueCode}`)
    }
  }

  // Profile report
  const handleProfileReport = (): void => {
    if (readOnly) return

    navigate(
      routePath.participantProfileReport.replace(':profileId', profileStatus.profileId.toString())
    )
  }

  return (
    <div className='card h-100 shadow-sm bg-white rounded border-0'>
      <div className='card-header border-0 p-3 fs-5 text-body-tertiary'>
        {getLanguageValue(languageText, 'Status')}
      </div>
      <div className='card-body p-4'>
        {/* No profile - Create new one */}
        {isRoledisabled && (
          <div className='fs-5 fw-bold mb-3 d-flex justify-content-between align-items-center'>
            {getLanguageValue(languageText, 'Get started')} -{' '}
            {getLanguageValue(languageText, 'create your IDI Profile')}
            {userDetails.enableParticipantCreateProfile && (
              <CreateNewProfile languageText={languageText} readOnly={readOnly} />
            )}
          </div>
        )}

        {/* Ongoing profiles  */}
        {!isRoledisabled && !isRespondentsInvited && (
          <div className='d-flex justify-content-between mb-3'>
            <div className='fs-5 fw-bold'>
              {getLanguageValue(languageText, 'Your profile is in progress')}.
            </div>
            <div className='d-none float-end'>
              <span className='badge bg-light color-999 p-2'>
                {profileStatus.profileName} - {profileStatus.profileDate.slice(0, 10)}
              </span>
            </div>
            <button
              className='btn btn-sm btn-success'
              disabled={readOnly}
              onClick={handleProfileNavigation}
            >
              {getLanguageValue(languageText, 'Go to profile')}
              <i className='bi bi-arrow-right ms-2'></i>
            </button>
          </div>
        )}

        {/* Completed Profiles */}
        {!isRoledisabled &&
          isRespondentsInvited &&
          profileStatus.status !== ProfileStatus.Delivery && (
            <>
              <h5>{getLanguageValue(languageText, 'Great, your IDI profile is completed')}!</h5>
              {!isPresentationCreated ? (
                <>
                  <div>
                    {getLanguageValue(languageText, 'Profile presentation is not planned yet')}.
                  </div>
                  <button
                    className='btn btn-sm btn-success'
                    disabled={readOnly}
                    onClick={handleProfileNavigation}
                  >
                    {getLanguageValue(languageText, 'Go to profile')}
                  </button>
                </>
              ) : (
                <div>
                  {profileStatus.presentationStatus === CoursePresentationStatus.Ongoing ? (
                    <div className='my-2'>
                      {getLanguageValue(languageText, 'Profile presentation is ongoing')}
                    </div>
                  ) : (
                    <div className='my-2'>
                      {getLanguageValue(languageText, 'Presentation is planned at')}:{' '}
                      {profileStatus.presentationDate.slice(0, 10)}{' '}
                      {getLanguageValue(languageText, 'at')}{' '}
                      {profileStatus.presentationDate.slice(11, 16)}
                    </div>
                  )}
                  <button
                    className='btn btn-sm btn-success mb-3'
                    disabled={readOnly}
                    onClick={handleProfileNavigation}
                  >
                    {getLanguageValue(languageText, 'Profile presentation')}
                  </button>
                </div>
              )}
            </>
          )}

        {/* Delivered Profiles */}
        {profileStatus.status === ProfileStatus.Delivery && (
          <div className='mb-4'>
            <div className='float-end mt-2'>
              <button className='btn btn-success' onClick={handleProfileReport} disabled={readOnly}>
                {getLanguageValue(languageText, 'Show profile')}{' '}
                <i className='bi bi-arrow-right fs-6 ms-2'></i>
              </button>
              {userDetails.enableParticipantCreateProfile && (
                <CreateNewProfile languageText={languageText} readOnly={readOnly} />
              )}
            </div>
            <div className='fs-4 fw-bold'>
              {getLanguageValue(languageText, 'Your Profile is delivered')}!
            </div>
            {getLanguageValue(languageText, 'Continue with further studies in IDI Academy')}.
          </div>
        )}

        {/* Profile status */}
        <div className='row mx-0'>
          {/* Role */}
          <div
            className={clsx(
              'col-md-3 justify-content-between align-items-center bg-gradient border p-2 d-flex rounded',
              isRoleFilled ? 'bg-success bg-opacity-25 border-white' : 'bg-success text-white'
            )}
            role={!readOnly && !isRoledisabled && !isRoleFilled ? 'button' : ''}
            onClick={
              !readOnly && !isRoledisabled && !isRoleFilled ? handleProfileNavigation : () => {}
            }
          >
            <div className='small'>1. {getLanguageValue(languageText, 'Role')}</div>

            {isRoleFilled ? (
              <i className='bi bi-check-lg fs-5 text-success'></i>
            ) : (
              <i className='bi bi-arrow-right fs-5 text-grey'></i>
            )}
          </div>

          {/* Self Assessment */}
          <div
            className={clsx(
              'col-md-3 justify-content-between align-items-center bg-gradient border p-2 d-flex rounded',
              isSelfFormDisabled
                ? ''
                : isSelfFormFilled
                  ? 'bg-success bg-opacity-25 border-white'
                  : 'bg-success text-white'
            )}
            role={!readOnly && !isSelfFormDisabled && !isSelfFormFilled ? 'button' : ''}
            onClick={
              !readOnly && !isSelfFormDisabled && !isSelfFormFilled
                ? handleProfileNavigation
                : () => {}
            }
          >
            <div className='small'>2. {getLanguageValue(languageText, 'Self assessment')}</div>

            {!isSelfFormDisabled &&
              (isSelfFormFilled ? (
                <i className='bi bi-check-lg fs-5 text-success'></i>
              ) : (
                <i className='bi bi-arrow-right fs-5'></i>
              ))}
          </div>

          {/* Respondents */}
          <div
            className={clsx(
              'col-md-3 justify-content-between align-items-center bg-gradient border p-2 d-flex rounded',
              isRespondentsdisabled
                ? ''
                : isRespondentsInvited
                  ? 'bg-success bg-opacity-25 border-white'
                  : 'bg-success text-white'
            )}
            role={!readOnly && !isRespondentsdisabled && !isRespondentsInvited ? 'button' : ''}
            onClick={
              !readOnly && !isRespondentsdisabled && !isRespondentsInvited
                ? handleProfileNavigation
                : () => {}
            }
          >
            <div className='small'>3. {getLanguageValue(languageText, 'Respondents')}</div>

            {!isRespondentsdisabled &&
              (isRespondentsInvited ? (
                <i className='bi bi-check-lg fs-5 text-success'></i>
              ) : (
                <i className='bi bi-arrow-right fs-5'></i>
              ))}
          </div>

          {/* Profile */}
          <div
            className={clsx(
              'col-md-3 justify-content-between align-items-center bg-gradient border p-2 d-flex rounded',
              !isPresentationCreated
                ? ''
                : isPresentationCreated &&
                    (profileStatus.presentationStatus === CoursePresentationStatus.Completed ||
                      profileStatus.status === ProfileStatus.Delivery)
                  ? 'bg-success bg-opacity-25 border-white'
                  : 'bg-success text-white'
            )}
            role={
              !readOnly &&
              isPresentationCreated &&
              (profileStatus.presentationStatus === CoursePresentationStatus.Planned ||
                profileStatus.presentationStatus === CoursePresentationStatus.Ongoing)
                ? 'button'
                : ''
            }
            onClick={
              !readOnly &&
              isPresentationCreated &&
              (profileStatus.presentationStatus === CoursePresentationStatus.Planned ||
                profileStatus.presentationStatus === CoursePresentationStatus.Ongoing)
                ? handleProfileNavigation
                : () => {}
            }
          >
            <div className='small'>4. {getLanguageValue(languageText, 'Profile')}</div>

            {isPresentationCreated &&
              (profileStatus.presentationStatus === CoursePresentationStatus.Completed ||
              profileStatus.status === ProfileStatus.Delivery ? (
                <i className='bi bi-check-lg fs-5 text-success'></i>
              ) : (
                <i className='bi bi-arrow-right fs-5'></i>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Status
