import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { RootState } from '@app/store/configureStore'
import { GuidePages } from '@app/containers/commonEnums'
import { IRespondentData } from '@app/containers/respondentPage/reducer'
import { IntroJs } from './introJs'

export interface IInstructionSteps {
  element: string
  intro: string
}
interface IInstructionProps {
  showHelpButton?: boolean
  targetElement: string // Element(Id) to check if it's rendered in the DOM for auto-guide trigger
  guidePage: GuidePages
  manualTrigger?: boolean
  instructionSteps: IInstructionSteps[]
  stepsNotToSkip?: Array<number> // Indices of steps not to be skipped
  stepsToSkip?: Array<number> // Indices of steps to be skipped
  isRespondent?: boolean // Respondents doesn't have userId, guide api is unauthorized
  skipPostGuideRequest?: boolean // If true, post request should be managed in the parent component
  handleInstructionHelpClick?: (guidePage: GuidePages) => void
  handleExitGuide?: (guidePage: GuidePages, isSkipped: boolean) => void
}

const Instruction = (props: IInstructionProps) => {
  // the library that we're using for our guides is totally broken on
  // safari. the tooltip element is not visible but the overlay still
  // blocks the screen, so the user can't do anything.
  //
  // TODO: replace the garbage-ass introjs library with something else.
  //
  //   https://github.com/usablica/intro.js/issues/109
  //
  //   -johan, 2024-09-30
  return null

  const {
    showHelpButton = true,
    targetElement,
    guidePage,
    manualTrigger,
    instructionSteps,
    stepsNotToSkip,
    stepsToSkip,
    isRespondent,
    skipPostGuideRequest,
    handleInstructionHelpClick,
    handleExitGuide,
  } = props

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const guideData = useSelector((state: RootState) => state.mainReducer.guideData)
  const respondentData: IRespondentData = useSelector(
    (state: RootState) => state.respondentReducer.respondentData
  )

  const [initializeGuide, setInitializeGuide] = useState<boolean>(false)
  const [autoTriggerSteps, setAutoTriggerSteps] = useState<boolean>(false)
  const [instructionStepsEnabled, setInstructionStepsEnabled] = useState<boolean>(false)

  // This useEffect is to check if the targetted element is rendered in the DOM, then start the guide
  useEffect(() => {
    const element = document.getElementById(targetElement)
    if (element) {
      setInitializeGuide(true)
      return
    } else {
      const observer = new MutationObserver(() => {
        // Check if the element is rendered in the DOM
        const element = document.getElementById(targetElement)
        if (element) {
          // Start the guide if the element is found
          setInitializeGuide(true)
          observer.disconnect() // Stop observing after initialization
        }
      })

      // Start observing the DOM
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      })

      // Cleanup observer on component unmount
      return () => observer.disconnect()
    }
  }, [targetElement])

  useEffect(() => {
    if (!initializeGuide) return
    if (guideData !== undefined && !isRespondent) {
      const guide = guideData.find((guide) => guide.guideName === guidePage)
      if (!guide?.isCompleted || manualTrigger) {
        setAutoTriggerSteps(true)
      } else {
        handleExitGuide && handleExitGuide(guidePage, false)
      }
    }
  }, [initializeGuide])

  // useEffect only for respondent
  useEffect(() => {
    if (
      initializeGuide &&
      isRespondent &&
      respondentData.profileRespondentId &&
      !respondentData.isFormFilledData
    ) {
      setAutoTriggerSteps(true)
    }
  }, [initializeGuide, respondentData.profileRespondentId])

  const onHelpClick = (): void => {
    setInstructionStepsEnabled(true)
    handleInstructionHelpClick && handleInstructionHelpClick(guidePage)
  }

  const handleExit = (guidePage: GuidePages, isSkipped: boolean): void => {
    setAutoTriggerSteps(false)
    setInstructionStepsEnabled(false)
    handleExitGuide && handleExitGuide(guidePage, isSkipped)
  }

  return (
    <>
      {showHelpButton && (
        <div
          id='instructionBtn'
          className='d-flex align-items-center text-success'
          onClick={onHelpClick}
          role='button'
        >
          <i className='bi bi-question-square-fill me-1'></i>
          <span>{getLanguageValue(languageText, 'Help')}</span>
        </div>
      )}
      {(autoTriggerSteps || instructionStepsEnabled) && Object.keys(languageText).length > 0 && (
        <IntroJs
          guidePage={guidePage}
          instructionSteps={instructionSteps}
          stepsNotToSkip={stepsNotToSkip}
          stepsToSkip={stepsToSkip || []}
          isRespondent={isRespondent || false}
          skipPostGuideRequest={skipPostGuideRequest || false}
          handleExit={handleExit}
        />
      )}
    </>
  )
}

export default Instruction
