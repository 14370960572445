import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import { getLanguageValue, ITranslationObject } from '../../../commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import ToggleButtonGroup, {
  IButtonGroupList,
} from '../../../components/formComponents/toggleButtonGroup'
import { InvitationCategory, IndividualGroupBtnsEnum } from '../profileEnums'
import PersonalizedInvitationModal from './emailSMSInvitation/personalizedInvitationModal'
import GroupInvitation from './emailSMSInvitation/groupInvitation'
import { routePath } from '../../routePaths'
import { ICreateProfileResponse } from './hooks'
import { getLanguageTextByName } from '@app/containers/actions'
import { ActivityId, ProfileId, UserId } from '@app/containers/reducer'

export interface ISendParticipantsInvitationBody {
  id: number
  message: string
  ownMessage: string
  savedMessageId: number
  isEmailOrSms: boolean
  languageId: number
  isEmailOrSmsReminder: boolean
  phoneNumber?: string
  userId?: UserId
}

export interface ISelectedProfilesList {
  id: ProfileId
  name: string
  email?: string
  activityId?: number
  phoneNumber?: string
  emailSent: boolean
  smsSent: boolean
  emailStatus?: number
  emailOwnMessage?: string
  smsStatus?: number
  smsOwnMessage?: string
}

interface IActivityInviteParticipantsStepProps {
  languageText: ITranslationObject
  createdActivityId: ActivityId
  languages: IDropdownList[]
  cultureValue: string
  selectedIndividualGroupBtn: string
  selectedParticipants: ICreateProfileResponse[]
  onSaveClick: () => void
}

const ActivityInviteParticipantsStep = (
  props: IActivityInviteParticipantsStepProps
): JSX.Element => {
  const {
    languageText,
    createdActivityId,
    languages,
    cultureValue,
    selectedIndividualGroupBtn,
    selectedParticipants,
    onSaveClick,
  } = props

  const initialParticipantState: ISelectedProfilesList = {
    id: 0 as ProfileId,
    name: '',
    email: '',
    activityId: 0,
    emailSent: false,
    smsSent: false,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedInvitationCategoryBtn, setSelectedInvitationCategoryBtn] = useState<string>('')
  const [selectedParticipantsList, setSelectedParticipantsList] = useState<ISelectedProfilesList[]>(
    []
  )
  const [openGroupInvitationModal, setOpenGroupInvitationModal] = useState<boolean>(false)
  const [personalizedInvitationParticipant, setPersonalizedInvitationParticipant] =
    useState<ISelectedProfilesList>(initialParticipantState)
  const [messageTranslation, setMessageTranslation] = useState<ITranslationObject>(
    {} as ITranslationObject
  )

  const [emailInvitationSent, setEmailInvitationSent] = useState<boolean>(false)
  const [smsInvitationSent, setSMSInvitationSent] = useState<boolean>(false)

  const invitationCategoryBtns = useMemo(
    () =>
      [
        {
          id: 1,
          name: InvitationCategory.same,
          title: getLanguageValue(languageText, 'Send same invitation to everyone'),
        },
        {
          id: 2,
          name: InvitationCategory.personalized,
          title: getLanguageValue(languageText, 'Send personalized invitations'),
        },
      ] as IButtonGroupList[],
    [languageText]
  )

  const getMessageTranslations = (): void => {
    const languageCode: string =
      languages.find((language) => language.value === cultureValue)?.value ?? ''

    getLanguageTextByName(languageCode, dispatch).then((lang) => {
      setMessageTranslation(lang)
    })
  }

  useEffect(() => {
    if (languages.length > 0) {
      getMessageTranslations()
    }
  }, [languages])

  useEffect(() => {
    const selectedParticipantsList: ISelectedProfilesList[] = selectedParticipants.map(
      (participant) => ({
        id: participant.id,
        name: participant.name,
        email: participant.email,
        userId: participant.userId,
        activityId: participant.activityId,
        phoneNumber: participant.phoneNumber ?? '',
        emailSent: false,
        smsSent: false,
      })
    )
    setSelectedParticipantsList(selectedParticipantsList)
  }, [])

  const handleEditPencilClick = (): void => setSelectedInvitationCategoryBtn('')
  const isGroup = selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group

  const handleInvitationCategoryBtnClick = (name: string): void => {
    setSelectedInvitationCategoryBtn(name)
    if (name === InvitationCategory.same) {
      setOpenGroupInvitationModal(true)
    }
  }

  const handleCloseGroupInvitation = (): void => {
    setOpenGroupInvitationModal(false)
  }

  // Same Invitation fn
  const handleSameMessageSentSuccess = (isEmail: boolean): void => {
    const updatedList: ISelectedProfilesList[] = selectedParticipantsList.map((item) => ({
      ...item,
      emailSent: isEmail ? true : item.emailSent,
      smsSent: !isEmail ? true : item.smsSent,
    }))
    setSelectedParticipantsList(updatedList)
    if (isEmail) {
      setEmailInvitationSent(true)
    } else {
      setSMSInvitationSent(true)
    }
    navigate(routePath.editActivity.replace(':id', createdActivityId.toString()))
  }

  // Personalized Invitation fn's
  const handlePersonalizedInvitation = (participant: ISelectedProfilesList): void => {
    setPersonalizedInvitationParticipant(participant)
  }

  const closePersonalizedInvitationModal = (): void => {
    setPersonalizedInvitationParticipant(initialParticipantState)
  }

  const handlePersonalizedMessageSentSuccess = (
    isEmail: boolean,
    participant: ISelectedProfilesList,
    emailOrSMSStatus: number
  ): void => {
    const updatedList: ISelectedProfilesList[] = selectedParticipantsList.map((item) => {
      if (item.id === participant.id) {
        return {
          ...item,
          emailSent: isEmail ? true : item.emailSent,
          smsSent: !isEmail ? true : item.smsSent,
          emailStatus: isEmail ? emailOrSMSStatus : item.emailStatus,
          smsStatus: !isEmail ? emailOrSMSStatus : item.smsStatus,
        }
      } else return item
    })

    setSelectedParticipantsList(updatedList)

    if (selectedInvitationCategoryBtn === InvitationCategory.personalized || !isGroup) {
      const updatedParticipant: ISelectedProfilesList =
        updatedList.find((item) => item.id === participant.id) ?? initialParticipantState
      setPersonalizedInvitationParticipant(updatedParticipant)
    }
  }

  return (
    <>
      <div id='activityInvitationStep' className='p-4 mb-4 shadow-sm rounded bg-white'>
        <div className='fs-5 fw-bold mb-3'>
          {getLanguageValue(languageText, 'Invite Participants')}
        </div>

        {isGroup &&
          (!selectedInvitationCategoryBtn ? (
            <ToggleButtonGroup
              buttonList={invitationCategoryBtns}
              selectedBtn={selectedInvitationCategoryBtn}
              className=''
              handleButtonClick={handleInvitationCategoryBtnClick}
            />
          ) : (
            <div className='d-flex align-items-center mb-4'>
              <div className='btn me-3 border'>
                {
                  invitationCategoryBtns.filter(
                    (btn) => btn.name === selectedInvitationCategoryBtn
                  )[0].title
                }
              </div>
              <button className='btn btn-light' onClick={handleEditPencilClick}>
                <i className='bi bi-pencil'></i>
              </button>
            </div>
          ))}

        {/* -- Same Invitation -- start */}
        {openGroupInvitationModal && (
          <GroupInvitation
            languageText={languageText}
            messageTranslation={messageTranslation}
            languages={languages}
            cultureValue={cultureValue}
            participants={selectedParticipantsList}
            emailInvitationSent={emailInvitationSent}
            smsInvitationSent={smsInvitationSent}
            handleMessageSentSuccess={handleSameMessageSentSuccess}
            handleCloseClick={handleCloseGroupInvitation}
          />
        )}

        {/* -- Personalized Invitation -- start */}
        {(selectedInvitationCategoryBtn === InvitationCategory.personalized || !isGroup) && (
          <div className='p-3 py-2 bg-secondary rounded bg-opacity-10'>
            {selectedParticipantsList.map((participant, index) => {
              const inviteSent: boolean = participant.emailSent || participant.smsSent
              return (
                <div
                  key={participant.id}
                  className={clsx(
                    'd-flex justify-content-between align-items-center flex-wrap py-2',
                    index !== 0 && 'border-top border-secondary-subtle'
                  )}
                >
                  <span className='fs-5 ms-2 fw-bold'>{participant.name}</span>
                  <button
                    className={clsx(
                      'btn border fw-bold',
                      inviteSent ? 'btn-success text-white' : 'bg-white'
                    )}
                    onClick={() => handlePersonalizedInvitation(participant)}
                  >
                    {getLanguageValue(
                      languageText,
                      inviteSent ? 'Invitation sent' : 'Create invitation'
                    )}
                  </button>
                </div>
              )
            })}
          </div>
        )}

        {personalizedInvitationParticipant.id > 0 && (
          <PersonalizedInvitationModal
            languageText={languageText}
            messageTranslation={messageTranslation}
            languages={languages}
            cultureValue={cultureValue}
            profile={personalizedInvitationParticipant}
            phoneNumber={personalizedInvitationParticipant.phoneNumber}
            handleCloseClick={closePersonalizedInvitationModal}
            handleMessageSentSuccess={handlePersonalizedMessageSentSuccess}
          />
        )}
        {/* -- Personalized Invitation -- end */}

        <div className='mt-4'>
          <span
            className='fs-5 fw-bold text-decoration-underline'
            onClick={onSaveClick}
            role='button'
          >
            {getLanguageValue(languageText, 'Save and finish')}
          </span>{' '}
          ({getLanguageValue(languageText, 'You can send the invitations later')})
        </div>
      </div>
    </>
  )
}

export default ActivityInviteParticipantsStep
