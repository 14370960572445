import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { routePath } from '../../../routePaths'
import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { RootState } from '@app/store/configureStore'
import { getProfileParticipantRole, setParticipantProfileDetails } from '../../actions'
import { setSpinner } from '../../../actions'
import { IProfileDetails } from '../../reducer'
import useParticipantProfileAuth, { ucQueryKey } from '../../useParticipantProfileAuth'
import { participantRoleSelectionInstructionSteps } from '@app/components/instruction/instructionSteps'

export interface IParticipantStepperData {
  id: number
  title: string
  page: JSX.Element
}

export interface IParticipantRoleData {
  roleId: number
  roleText: string
  noOfRespondents: number
  id: number
}

export const useRolePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { uniqueCode } = useParticipantProfileAuth()

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const profileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )
  const instructionSteps = useMemo(
    () => participantRoleSelectionInstructionSteps(languageText),
    [languageText]
  )

  const [roleCanBeChangedByParticipant, setRoleCanBeChangedByParticipant] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const fetchProfileParticipantRole = async () => {
    dispatch(setSpinner(true))
    const response = await getProfileParticipantRole(profileDetails.profileId, dispatch).finally(
      () => dispatch(setSpinner(false))
    )

    if (response) {
      // Set role settings
      const {
        roleId,
        roleText,
        noOfRespondents,
        roleCanBeChangedByParticipant,
        isActivityRoleEnabled,
      } = response

      if (!isActivityRoleEnabled) {
        // If Role settings is disabled, assign noOfRespondents = 6
        const updatedParticipantProfileDetails: IProfileDetails = {
          ...profileDetails,
          noOfRespondents: 6,
          isActivityRoleEnabled: false,
        }
        dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))
        navigate(`${routePath.participantSelfForm}?${ucQueryKey}=${uniqueCode}`)
        return
      }
      const updatedParticipantProfileDetails: IProfileDetails = {
        ...profileDetails,
        roleId: roleId ? roleId : 0,
        roleText: roleText ? roleText : '',
        noOfRespondents: noOfRespondents ? noOfRespondents : 0,
      }
      dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))
      setRoleCanBeChangedByParticipant(roleCanBeChangedByParticipant)
    }
  }

  useEffect(() => {
    if (profileDetails.profileId) {
      fetchProfileParticipantRole()
    }
  }, [profileDetails.profileId])

  const handleTypeOfRoleSave = (typeOfRole: string | number): void => {
    let updatedParticipantProfileDetails: IProfileDetails = {
      ...profileDetails,
    }

    if (typeof typeOfRole === 'string') {
      updatedParticipantProfileDetails = {
        ...updatedParticipantProfileDetails,
        roleId: 0,
        roleText: typeOfRole,
      }
    } else {
      updatedParticipantProfileDetails = {
        ...updatedParticipantProfileDetails,
        roleId: typeOfRole,
        roleText: '',
      }
    }
    dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))
    if (errorMessage) setErrorMessage('')
  }

  const handleNoOfRespondentsSave = (noOfRespondents: number): void => {
    const updatedParticipantProfileDetails: IProfileDetails = {
      ...profileDetails,
      noOfRespondents: noOfRespondents,
    }
    dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))
    if (errorMessage) setErrorMessage('')
  }

  const goToPrevious = (): void => {
    navigate(`${routePath.participantCreate}?${ucQueryKey}=${uniqueCode}`)
  }

  const onSubmitClick = (): void => {
    if ((!profileDetails.roleId && !profileDetails.roleText) || !profileDetails.noOfRespondents) {
      setErrorMessage(getLanguageValue(languageText, 'Select role settings'))
      return
    }

    let updatedParticipantProfileDetails: IProfileDetails = {
      ...profileDetails,
    }
    if (profileDetails.roleText) {
      updatedParticipantProfileDetails = {
        ...updatedParticipantProfileDetails,
        roleText: profileDetails.roleText,
        noOfRespondents: profileDetails.noOfRespondents,
      }
    } else if (profileDetails.roleId) {
      updatedParticipantProfileDetails = {
        ...updatedParticipantProfileDetails,
        roleId: profileDetails.roleId,
        noOfRespondents: profileDetails.noOfRespondents,
      }
    }
    dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))
    navigate(`${routePath.participantSelfForm}?${ucQueryKey}=${uniqueCode}`)
  }

  return {
    languageText,
    instructionSteps,
    errorMessage,
    roleCanBeChangedByParticipant,
    participantProfileDetails: profileDetails,
    handleTypeOfRoleSave,
    handleNoOfRespondentsSave,
    goToPrevious,
    onSubmitClick,
  }
}
