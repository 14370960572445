import React from 'react'

import { IProductData } from '../invoiceInterface'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'

interface IProductDetails {
  languageText: ITranslationObject
  invoiceId: number
  profileInvoiceRows: IProductData[]
  handleEditProduct: (id: number) => void
  handleDeleteProduct: (id: number) => void
}

const ProductDetails = (props: IProductDetails) => {
  const { languageText, invoiceId, profileInvoiceRows, handleEditProduct, handleDeleteProduct } =
    props

  const totalSum = profileInvoiceRows.reduce((acc, item) => acc + item.sum, 0)
  const tax = (totalSum * 0.25).toFixed(2)
  const formattedTax = parseFloat(tax).toLocaleString('sv-SE')

  const netTotal = (Number(totalSum) + Number(tax)).toFixed(2)

  return (
    <>
      {profileInvoiceRows.length > 0 && (
        <div className='table-responsive'>
          <table className='table'>
            <thead className='fw-semibold fs-6 border'>
              <tr>
                <th className='col-lg-3 col-md-6'>{getLanguageValue(languageText, 'Product')}</th>
                <th className='col-lg-3 col-md-6 '>{getLanguageValue(languageText, 'Details')}</th>
                <th className='col-lg-1 col-md-6 text-end'>
                  {getLanguageValue(languageText, 'Quantity')}
                </th>
                <th className='col-lg-2 col-md-6 text-end'>
                  {getLanguageValue(languageText, 'Price')}
                </th>
                <th className='col-lg-1 col-md-6 text-end'>
                  {getLanguageValue(languageText, 'Discount')}
                </th>
                <th className='col-lg-1 col-md-6 text-end'>
                  {getLanguageValue(languageText, 'Sum')}
                </th>
                <th className='col-lg-1 col-md-6'></th>
              </tr>
            </thead>
            <tbody>
              {profileInvoiceRows?.map((invoiceRow, index) => {
                return (
                  <tr key={index} className='fs-5 fw-bold border'>
                    <td>{invoiceRow.articleNumber ?? 'IDI Profile'}</td>
                    <td>{invoiceRow.description}</td>
                    <td className='text-end'>
                      {invoiceRow.quantity ? invoiceRow.quantity.toLocaleString('sv-SE') : 0}
                    </td>
                    <td className='text-end'>
                      {invoiceRow.price ? invoiceRow.price.toLocaleString('sv-SE') : 0}
                    </td>
                    <td className='text-end'>
                      {invoiceRow.discount ? invoiceRow.discount.toLocaleString('sv-SE') : 0}
                    </td>
                    <td className='text-end'>
                      {invoiceRow.sum ? invoiceRow.sum.toLocaleString('sv-SE') : 0}
                    </td>
                    {invoiceId > 0 && (
                      <td className='d-flex gap-1 border-0 '>
                        <div
                          className='btn btn-light'
                          role='button'
                          onClick={() => handleEditProduct(invoiceRow.temporaryId!)}
                        >
                          <i className='bi bi-pencil-fill text-secondary' />
                        </div>
                        <div
                          className='btn btn-light'
                          role='button'
                          onClick={() => handleDeleteProduct(invoiceRow.temporaryId!)}
                        >
                          <i className='bi bi-trash3 text-secondary' />
                        </div>
                      </td>
                    )}
                  </tr>
                )
              })}
              <tr className='border'>
                <td colSpan={5}>
                  <span className='d-flex fs-5 fw-bold justify-content-end'>
                    {getLanguageValue(languageText, 'Subtotal')}
                  </span>
                </td>
                <td className='pe-3 fs-5 fw-bold text-end'>{totalSum.toLocaleString('sv-SE')}</td>
              </tr>
              <tr className='border'>
                <td colSpan={5}>
                  <span className='d-flex fs-5 fw-bold justify-content-end'>
                    {getLanguageValue(languageText, 'Tax')} 25%
                  </span>
                </td>
                <td className='pe-3 fs-5 fw-bold text-end'>{formattedTax}</td>
              </tr>
              <tr className='border'>
                <td colSpan={5}>
                  <span className='d-flex fs-5 fw-bold justify-content-end'>
                    {getLanguageValue(languageText, 'Total')}
                  </span>
                </td>
                <td className='pe-3 fs-5 fw-bold text-end'>
                  {parseFloat(netTotal).toLocaleString('sv-SE')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ProductDetails
