import React from 'react'
import clsx from 'clsx'

export interface IButtonGroupList {
  id: number
  name: string
  title: string
}

interface IButtonGroupProps {
  buttonList: IButtonGroupList[]
  className?: string
  selectedBtn: string
  unselectEnabled?: boolean // To Unselect already selected button/option
  disabled?: boolean
  handleButtonClick: (name: string) => void
}

const ToggleButtonGroup = (props: IButtonGroupProps): JSX.Element => {
  const { buttonList, className, selectedBtn, unselectEnabled, disabled, handleButtonClick } = props

  const onButtonClick = (e: React.MouseEvent) => {
    const name = e.target['name']
    if (name === selectedBtn && !unselectEnabled) return
    handleButtonClick(name)
  }

  return (
    <>
      {buttonList.map((list) => {
        const { id, name, title } = list
        return (
          <button
            key={id}
            id={String(id)}
            name={name}
            className={clsx(
              'btn me-2',
              selectedBtn === name ? 'btn-success' : 'btn-light border',
              className && className
            )}
            onClick={onButtonClick}
            disabled={disabled}
          >
            {title}
          </button>
        )
      })}
    </>
  )
}

export default ToggleButtonGroup
