import React from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../../commonUtils/languageFunctionsHelper'
import DeviationValue from './deviationValues/lowDeviationValue'

interface IAffiliationProps {
  languageText: ITranslationObject
  profileMeasure: number
  affiliationMessage: string
  affiliationSpreadText: string
}

const Affiliation = (props: IAffiliationProps): JSX.Element => {
  const { languageText, profileMeasure, affiliationMessage, affiliationSpreadText } = props

  return (
    <>
      <h5 className='fw-bold mb-2'>{getLanguageValue(languageText, 'Affiliation')}</h5>
      <DeviationValue
        profileMeasure={profileMeasure}
        deviationMessage={affiliationMessage}
        deviationSpreadText={affiliationSpreadText}
      />
    </>
  )
}

export default Affiliation
