import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import Input, { IFocusError } from '../../../../../components/formComponents/input'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { updateProfileParticipantName } from '../../actions'
import { addToast } from '@app/containers/actions'
import { AnyAction } from 'redux'
import Instruction from '@app/components/instruction/instruction'
import { GuidePages } from '@app/containers/commonEnums'
import { editProfileParticipantInstructionSteps } from '@app/components/instruction/instructionSteps'
import { ProfileId } from '@app/containers/reducer'

enum ParticipantName {
  name = 'name',
  surname = 'surname',
}

export interface IUpdateProfileParticipantNameBody {
  profileId: ProfileId
  name: string
  surName: string
}

interface IParticipantInfo {
  name: string
  surname: string
}

interface IFocusInput {
  name: IFocusError
  surname: IFocusError
}

interface IProfileParticipantNameProps {
  languageText: ITranslationObject
  profileId: ProfileId
  name: string
  surname: string
  refetchParticipants: () => void
}

const ProfileParticipantName = (props: IProfileParticipantNameProps): JSX.Element => {
  const { languageText, profileId, name, surname, refetchParticipants } = props

  const dispatch = useDispatch()
  const instructionSteps = useMemo(
    () => editProfileParticipantInstructionSteps(languageText),
    [languageText]
  )

  const [participantInfo, setParticipantInfo] = useState<IParticipantInfo>({
    name: '',
    surname: '',
  })
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    name: {
      touched: false,
      errorMessage: '',
    },
    surname: {
      touched: false,
      errorMessage: '',
    },
  })
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)

  useEffect(() => {
    setParticipantInfo({
      name: name,
      surname: surname,
    })
  }, [name, surname])

  const handleFormErrors = (name: string, value: string, onFocus = false): void => {
    let errorMessage: string = ''

    switch (name) {
      case ParticipantName.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Name is required')
        }
        break
      case ParticipantName.surname:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Surname is required')
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))

    if (onFocus && !errorMessage) {
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setParticipantInfo({
      ...participantInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }

    if (
      (name === ParticipantName.name && value !== participantInfo.name) ||
      (name === ParticipantName.surname && value !== participantInfo.surname)
    ) {
      setShowSaveButton(true)
    }
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value, true)
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !participantInfo.name ||
      focusInput.name.errorMessage ||
      !participantInfo.surname ||
      focusInput.surname.errorMessage
    ) {
      for (const item in ParticipantName) {
        handleFormErrors(item, participantInfo[item])
      }
      return false
    }
    return true
  }

  const handleSaveClick = (): void => {
    if (!handleValidationOnSubmit()) return

    const body: IUpdateProfileParticipantNameBody = {
      profileId: profileId,
      name: participantInfo.name,
      surName: participantInfo.surname,
    }
    updateProfileParticipantName(body, dispatch).then((response) => {
      if (response?.success) {
        setShowSaveButton(false)
        dispatch(addToast('Participant name updated') as AnyAction)
        refetchParticipants()
      }
    })
  }

  return (
    <div id='editParticipantName'>
      <div className='d-flex justify-content-between'>
        <div className='fs-5 fw-bold mb-3'>
          {getLanguageValue(languageText, 'Participant Name')}
        </div>

        <Instruction
          targetElement='editParticipantPublish'
          guidePage={GuidePages.Edit_Profile_Participant}
          instructionSteps={instructionSteps}
        />
      </div>
      <form className='row'>
        <div className='col-6'>
          <Input
            name={ParticipantName.name}
            label={getLanguageValue(languageText, 'Name')}
            placeholder={getLanguageValue(languageText, 'Name')}
            value={participantInfo.name}
            focusInput={focusInput.name}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>
        <div className='col-6'>
          <Input
            name={ParticipantName.surname}
            label={getLanguageValue(languageText, 'Surname')}
            placeholder={getLanguageValue(languageText, 'Surname')}
            value={participantInfo.surname}
            focusInput={focusInput.surname}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>
      </form>
      {showSaveButton &&
        participantInfo.name &&
        !focusInput.name.errorMessage &&
        participantInfo.surname &&
        !focusInput.surname.errorMessage && (
          <div className='d-flex justify-content-end'>
            <button onClick={handleSaveClick} className='btn btn-success me-3 mb-4'>
              {getLanguageValue(languageText, 'Save')}
            </button>
          </div>
        )}
      <div className='border-bottom my-4' />
    </div>
  )
}

export default ProfileParticipantName
