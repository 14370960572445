import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

export interface IRadioButtonList {
  id: number
  label: string
  name: string
  value: boolean
}

interface IRadioButtonProps {
  options: IRadioButtonList[]
  wrapperClass?: string
  radioButtonChangeCallback: (updatedOptions: IRadioButtonList[], name: string) => void
}

const RadioButton = (props: IRadioButtonProps): JSX.Element => {
  const { options, wrapperClass, radioButtonChangeCallback } = props

  const [radioOptions, setRadioOptions] = useState<IRadioButtonList[]>([])
  const [selectedOption, setSelectedOption] = useState<string>('')

  useEffect(() => {
    const selectedOption = options.find((item) => item.value === true)?.name
    setSelectedOption(selectedOption!)
    setRadioOptions(options)
  }, [options])

  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target
    const updatedOptions = [...radioOptions]

    // Find index of previous selected option
    const prevIndex = updatedOptions.findIndex((obj) => obj.name == selectedOption)

    // Find index of new selected option
    const newIndex = updatedOptions.findIndex((obj) => obj.name == name)

    // Update Values
    updatedOptions[prevIndex].value = false
    updatedOptions[newIndex].value = true
    setSelectedOption(updatedOptions[newIndex].name)
    setRadioOptions(updatedOptions)
    radioButtonChangeCallback(updatedOptions, name)
  }

  return (
    <div className={clsx('mb-7', wrapperClass && wrapperClass)}>
      {radioOptions.map((item: IRadioButtonList) => (
        <div
          key={item.id}
          className='form-check form-check-success form-check-custom form-check-solid'
        >
          <input
            className='form-check-input'
            name={item.name}
            type='radio'
            id={String(item.id)}
            checked={item.value}
            onChange={handleRadioButtonChange}
          />
          <label className='form-check-label fw-bold fs-6' htmlFor={String(item.id)}>
            {item.label}
          </label>
        </div>
      ))}
    </div>
  )
}

export default RadioButton
