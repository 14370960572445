import React, { useState } from 'react'

import { EditParticipantModal } from './editParticipantModal/editParticipantModal'
import Input from '../../../components/formComponents/input'
import ValidationBox from '../../../components/newPasswordInputBox/validationBox'
import TermsFooter from '../../../components/termsFooter/termsFooter'
import PopoverTooltip from '../../../components/tooltip/popoverTooltip'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { PasswordInput, useChangePasswordPage } from './hooks'
import { AuthLayout } from '@app/containers/auth/signUp/layout'

const ChangePasswordPage = (): JSX.Element => {
  const {
    languageText,
    focusInput,
    userPassword,
    userData,
    participantProfileDetails,
    onSubmitClick,
    handleInputChange,
    handleBlurEvent,
    onContinueClick,
  } = useChangePasswordPage()

  const [open, setOpen] = useState<boolean>(false)

  const handleEditUserDataModal = (): void => {
    setOpen(true)
  }

  const handleCancelClick = (): void => {
    setOpen(false)
  }

  return (
    <>
      <AuthLayout page='login' language={languageText} hideLanguageSelection={true}>
        <h3 className='mb-4'>{getLanguageValue(languageText, 'IDI Account')}</h3>
        <p className='fs-5 mb-4'>
          {getLanguageValue(languageText, 'Monitor the status and access your profiles')}.
        </p>

        <div className='border rounded p-3 mb-4'>
          <div className='d-flex flex-row justify-content-between'>
            <div>
              {getLanguageValue(languageText, 'Name')}:<h6>{userData.name}</h6>
            </div>
            <i
              role='button'
              className='bi bi-pencil fs-4 ms-1'
              onClick={() => handleEditUserDataModal()}
            />
          </div>
          <div className='my-3'>
            {getLanguageValue(languageText, 'Email')}:<h6>{userData.emailAddress}</h6>
          </div>
          <div>
            {getLanguageValue(languageText, 'Phone')}:<h6>{userData.phoneNumber}</h6>
          </div>
        </div>

        {!participantProfileDetails.isPasswordSet ? (
          <>
            <div className='d-flex align-items-center mb-2'>
              <h4>{getLanguageValue(languageText, 'Set password')}</h4>
              <PopoverTooltip
                tooltipText={getLanguageValue(
                  languageText,
                  'This will only change your IDI-password'
                )}
              />
            </div>
            <form onSubmit={onSubmitClick}>
              <Input
                name={PasswordInput.password}
                handleInputChange={handleInputChange}
                handleBlurEvent={handleBlurEvent}
                value={userPassword.password}
                placeholder={getLanguageValue(languageText, 'Password')}
                focusInput={focusInput.password}
                label={getLanguageValue(languageText, 'Password')}
                tooltipText={getLanguageValue(
                  languageText,
                  'Password must be at least 8 characters, include at least one non alphanumeric character, at least one digit and at least one uppercase symbol'
                )}
                type='new-password'
              />
              <ValidationBox value={userPassword.password} languageText={languageText} />
              <Input
                name={PasswordInput.confirmPassword}
                handleInputChange={handleInputChange}
                handleBlurEvent={handleBlurEvent}
                value={userPassword.confirmPassword}
                placeholder={getLanguageValue(languageText, 'Confirm password')}
                focusInput={focusInput.confirmPassword}
                label={getLanguageValue(languageText, 'Confirm password')}
                type='new-password'
              />
              <button type='submit' className='btn btn-success w-100'>
                {getLanguageValue(languageText, 'Set password and continue')}
              </button>
            </form>
          </>
        ) : (
          <button type='submit' className='btn btn-success w-150px' onClick={onContinueClick}>
            {getLanguageValue(languageText, 'Continue')}
          </button>
        )}
      </AuthLayout>
      <TermsFooter languageText={languageText} />

      {open && <EditParticipantModal handleCancelClick={handleCancelClick} />}
    </>
  )
}

export default ChangePasswordPage
