import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { participantSelfFormInstructionSteps } from '../../../../components/instruction/instructionSteps'
import useParticipantProfileAuth from '../../useParticipantProfileAuth'

export const useSelfForm = () => {
  const { uniqueCode } = useParticipantProfileAuth()

  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)

  const instructionSteps = useMemo(
    () => participantSelfFormInstructionSteps(languageText),
    [languageText]
  )

  return {
    languageText,
    instructionSteps,
    uniqueCode,
    userLanguage,
    participantProfileDetails,
  }
}
