import React from 'react'

import { DropdownSelect } from '@app/components/formComponents/dropdownSelect'
import Input from '../../../components/formComponents/input'
import { ToggleCheckbox } from '@app/components/formComponents/toggleCheckbox'
import { IAddEditWordModalProps, useAddEditWordModal, WordInputs } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '@app/components/modals/modalComponent'

const AddEditWordModal = (props: IAddEditWordModalProps): JSX.Element => {
  const {
    isEditClicked,
    languageText,
    wordInfo,
    focusInput,
    isSubmitting,
    countriesList,
    cultures,
    bhaveOptions,
    onSubmitClick,
    handleCheckboxChange,
    handleDropdownSelect,
    handleBlurEvent,
    handleInputChange,
    closeModal,
  } = useAddEditWordModal(props)

  return (
    <>
      <ModalComponent
        headerText={
          isEditClicked
            ? getLanguageValue(languageText, 'Edit Word')
            : getLanguageValue(languageText, 'Add Word')
        }
        submitButtonText={getLanguageValue(languageText, 'Submit')}
        cancelButtonText={getLanguageValue(languageText, 'Discard')}
        handleSubmitClick={onSubmitClick}
        handleCancelClick={closeModal}
        submitButtonDisabled={isSubmitting}
        cancelButtonDisabled={isSubmitting}
      >
        <form>
          <Input
            name={WordInputs.wordNo}
            label={getLanguageValue(languageText, 'Word No.')}
            placeholder={getLanguageValue(languageText, 'Word No.')}
            value={wordInfo.wordNo}
            focusInput={focusInput.wordNo}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
          <Input
            name={WordInputs.leftWord}
            label={getLanguageValue(languageText, 'Left word')}
            placeholder={getLanguageValue(languageText, 'Left word')}
            value={wordInfo.leftWord}
            focusInput={focusInput.leftWord}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
          <Input
            name={WordInputs.rightWord}
            label={getLanguageValue(languageText, 'Right word')}
            placeholder={getLanguageValue(languageText, 'Right word')}
            value={wordInfo.rightWord}
            focusInput={focusInput.rightWord}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
          <DropdownSelect
            label={getLanguageValue(languageText, 'Behave')}
            name={WordInputs.behave}
            list={bhaveOptions}
            defaultLabel={getLanguageValue(languageText, 'Select Behave')}
            value={wordInfo.behave}
            focusInput={focusInput.behave}
            handleDropdownSelect={handleDropdownSelect}
            required
          />
          <DropdownSelect
            dropdownHeight='mh-500px'
            name={WordInputs.countryCode}
            label={getLanguageValue(languageText, 'Country')}
            defaultLabel={getLanguageValue(languageText, 'Select Country')}
            list={countriesList}
            value={wordInfo.countryCode}
            focusInput={focusInput.countryCode}
            handleDropdownSelect={handleDropdownSelect}
            searchOption
            required
          />
          <DropdownSelect
            dropdownHeight='mh-500px'
            name={WordInputs.cultureId}
            label={getLanguageValue(languageText, 'Culture')}
            defaultLabel={getLanguageValue(languageText, 'Select Culture')}
            list={cultures}
            value={wordInfo.cultureId}
            focusInput={focusInput.cultureId}
            handleDropdownSelect={handleDropdownSelect}
            searchOption
            required
          />
          <ToggleCheckbox
            label={getLanguageValue(languageText, 'Display')}
            name={WordInputs.display}
            value={wordInfo.display}
            handleCheckboxChange={handleCheckboxChange}
          />
          <ToggleCheckbox
            label={getLanguageValue(languageText, 'Calculate')}
            name={WordInputs.calculate}
            value={wordInfo.calculate}
            handleCheckboxChange={handleCheckboxChange}
          />
          <ToggleCheckbox
            label={getLanguageValue(languageText, 'Is scored left-to-right')}
            name={WordInputs.isScoredLeftToRight}
            value={wordInfo.isScoredLeftToRight}
            handleCheckboxChange={handleCheckboxChange}
          />
        </form>
      </ModalComponent>
    </>
  )
}

export default AddEditWordModal
