import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import ReactTooltip from '../tooltip/reactTooltip'
import { IReorderData } from './reorderModal'

export const ReorderItem = (props: IReorderData): JSX.Element => {
  const { id, orderNumber, title } = props

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  return (
    <div
      className='d-flex align-items-center rounded mb-2 p-2 bg-secondary-subtle fw-bold gap-2'
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <span className='fs-5'>{orderNumber}.</span>
      <ReactTooltip id={String(orderNumber)} tooltipText={title}>
        {title}
      </ReactTooltip>
    </div>
  )
}
