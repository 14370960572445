import React from 'react'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { ProfileStatus } from '../../../profileEnums'

interface IProfileParticipantFormProps {
  languageText: ITranslationObject
  status: number
  respondentMsg1: string
  respondentMsg2: string
  respondentsAnswered: number
  respondentsInvited: number
}

const ProfileParticipantForm = (props: IProfileParticipantFormProps): JSX.Element => {
  const {
    languageText,
    status,
    respondentMsg1,
    respondentMsg2,
    respondentsAnswered,
    respondentsInvited,
  } = props

  return (
    <div id='editParticipantFormstatus'>
      <div className='fs-5 fw-bold mb-2'>{getLanguageValue(languageText, 'Form')}</div>

      <div className='row'>
        {/* Self form info */}
        <div className='col-lg-4'>
          <p className='my-2'>{getLanguageValue(languageText, 'Self form')}</p>
          <div className='d-flex gap-2 align-items-center'>
            {status === ProfileStatus.New ? (
              <>
                <div className='border btn disabled'>
                  <i className='bi bi-check-lg opacity-0'></i>
                </div>
                {getLanguageValue(languageText, 'Self form is not filled')}.
              </>
            ) : (
              <>
                <div className='btn btn-outline-success disabled'>
                  <i className='bi bi-check-lg'></i>
                </div>
                {getLanguageValue(languageText, 'Self form is filled')}.
              </>
            )}
          </div>
        </div>
        <div className='mt-8 d-lg-none d-xl-none d-md-none'></div>
        {/* Respondents info */}
        <div className='col-lg-8'>
          <p className='my-md-2 mt-4'>{getLanguageValue(languageText, 'Respondents')}</p>
          <div className='d-flex gap-2 align-items-center'>
            {status === ProfileStatus.New ? (
              <div className='border btn disabled'>
                <i className='bi bi-check-lg opacity-0'></i>
              </div>
            ) : (
              <>
                {status === ProfileStatus.Active ? (
                  <div className='border btn disabled'>
                    <i className='bi bi-check-lg opacity-0'></i>
                  </div>
                ) : (
                  <div className='btn btn-outline-success disabled'>
                    <i className='bi bi-check-lg'></i>
                  </div>
                )}
                <div className='btn border text-nowrap'>
                  {respondentsAnswered ?? 0} / {respondentsInvited ?? 0} (6*
                  {
                    //AM: Add number of respondents in Role settings
                  }
                  )
                </div>
              </>
            )}

            <div>
              {respondentMsg1 && <>{respondentMsg1}</>} {respondentMsg2 && respondentMsg2}
            </div>
          </div>
        </div>
      </div>
      <div className='border-bottom my-4'></div>
    </div>
  )
}

export default ProfileParticipantForm
