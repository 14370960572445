import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import {
  ILanguageObject,
  ITranslationObject,
  getLanguageValue,
} from '@app/commonUtils/languageFunctionsHelper'
import {
  ProfileStatus,
  ProfileOutputPage,
  ProfileOutputPages,
} from '@app/containers/profileList/profileEnums'
import { IEditProfileParticipantByIdAndStatus } from '../../interface'
//If we are giving @app path, it is throwing "File name differs from already included file name only in casing" error
import {
  downloadParticipantProfilePdfReport,
  updateProfileParticipantPdfDownload,
} from '../../actions'
import { addToast, setSpinner } from '@app/containers/actions'
import LanguageModal from '@app/components/modals/languageModal'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { getParticipantLanguages } from '@app/containers/languageList/languageTexts/actions'
import { ToggleCheckbox } from '@app/components/formComponents/toggleCheckbox'
import { downloadFileFromBlob } from '@app/commonUtils/downloadHepler'
import { AnyAction } from 'redux'
import { RootState } from '@app/store/configureStore'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { ActivityId, ProfileId } from '@app/containers/reducer'

interface IProfileParticipantProfileProps {
  languageText: ITranslationObject
  status: number
  profileId: ProfileId
  isDownloaded?: boolean
  pdfProfileDownloaded?: boolean
  profileParticipantData: IEditProfileParticipantByIdAndStatus
  handleProfileLock: (e: React.ChangeEvent<HTMLInputElement>) => void
  updatedownloadedStatus: () => void
}

const ProfileParticipantProfile = (props: IProfileParticipantProfileProps): JSX.Element => {
  const {
    languageText,
    status,
    profileId,
    profileParticipantData,
    isDownloaded,
    pdfProfileDownloaded,
    handleProfileLock,
    updatedownloadedStatus,
  } = props

  const dispatch = useDispatch()
  const activityId = (Number(useParams().id) || 0) as ActivityId

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)

  const reportName = `IDIProfile_${profileParticipantData?.name}_${profileId}`

  const [languages, setLanguages] = useState<ILanguageObject[]>([])
  const [reportReady, setReportReady] = useState<boolean>(false)
  const [languageSelectionModal, setLanguageSelectionModal] = useState<boolean>(false)
  const [page, setPage] = useState<ProfileOutputPage>(ProfileOutputPages.All)
  const [file1Clicked, setFile1Clicked] = useState<boolean>(false)
  const [file2Clicked, setFile2Clicked] = useState<boolean>(false)
  const [selectedLanguageForReport, setSelectedLanguageForReport] = useState<string>('')

  const handleCloseClick = (): void => {
    setLanguageSelectionModal(false)
    setPage(ProfileOutputPages.All)
    setReportReady(false)
  }

  useEffect(() => {
    if (file1Clicked && file2Clicked) {
      updateDownloadStatus()
    }
  }, [file1Clicked, file2Clicked])

  const onLanguageClick = async (language: ILanguageObject) => {
    const languageCode = language.name
    setSelectedLanguageForReport(languageCode)
    setReportReady(true)
  }

  const fetchLanguages = async () => {
    dispatch(setSpinner(true))
    const languagesResponse = await getParticipantLanguages(dispatch)
    let languages: ILanguageObject[] = []
    if (languagesResponse && languagesResponse?.length > 0) {
      languages = languagesResponse.map((item) => {
        const displayName = item.displayName.split(' ')[0]
        return {
          key: item.id,
          id: item.id,
          icon: item.icon,
          value: String(item.id),
          name: item.name,
          displayName: displayName,
        }
      })
    }
    setLanguages(languages)
    dispatch(setSpinner(false))
  }

  const updateDownloadStatus = (): void => {
    if (!isDownloaded && !pdfProfileDownloaded) {
      updateProfileParticipantPdfDownload(activityId, [profileId], dispatch).then((response) => {
        if (response?.success) {
          updatedownloadedStatus()
          dispatch(addToast('Downloaded successfully') as AnyAction)
        }
      })
    }
  }

  const onDownloadClick = async (): Promise<void> => {
    dispatch(setSpinner(true))
    const fileNameSuffix =
      page == ProfileOutputPages.Page1 ? 'p1' : page == ProfileOutputPages.Page2 ? 'p2' : ''
    try {
      const blobData = await downloadParticipantProfilePdfReport(
        [profileId],
        selectedLanguageForReport,
        page,
        dispatch
      )

      downloadFileFromBlob(blobData, `${reportName + fileNameSuffix}.pdf`)

      if (page === ProfileOutputPages.All) {
        updateDownloadStatus()
      }
      if (page === ProfileOutputPages.Page1) {
        setFile1Clicked(true)
      }
      if (page === ProfileOutputPages.Page2) {
        setFile2Clicked(true)
      }
    } catch (e: any) {
      //Error is suppressed as the error is aleady handled in the downloadParticipantProfilePdfReport function
    }
    dispatch(setSpinner(false))
  }

  return (
    <div id='editParticipantProfile'>
      <div className='fs-5 fw-bold mb-3'>{getLanguageValue(languageText, 'IDI-profile')}</div>

      {status === ProfileStatus.New || status === ProfileStatus.Active ? (
        getLanguageValue(languageText, 'Not ready')
      ) : (
        <>
          <div className='d-flex flex-wrap justify-content-between'>
            <div>
              <div className='fw-bold'>{getLanguageValue(languageText, 'Single file')}</div>
              <div
                className='btn btn-success mt-1'
                onClick={() => {
                  setLanguageSelectionModal(true)
                  fetchLanguages()
                  setPage(ProfileOutputPages.All)
                }}
              >
                {getLanguageValue(languageText, 'Download the complete IDI-Profile')}.
              </div>
            </div>
            <div>
              <div className='fw-bold mt-3 mt-md-0'>
                {getLanguageValue(languageText, 'Two files')}
              </div>
              <div
                className='btn btn-success mt-1 me-2'
                onClick={() => {
                  setLanguageSelectionModal(true)
                  fetchLanguages()
                  setPage(ProfileOutputPages.Page1)
                }}
              >
                {getLanguageValue(languageText, 'File 1 contains grid & substyle')}.
              </div>
              <div
                className='btn btn-success mt-1'
                onClick={() => {
                  setLanguageSelectionModal(true)
                  fetchLanguages()
                  setPage(ProfileOutputPages.Page2)
                }}
              >
                {getLanguageValue(languageText, 'File 2 contains grid & adaptability')}.
              </div>
            </div>
          </div>

          {isLoggedInRoleAdmin(loggedInUserRole) && (
            <div className='mt-4'>
              <ToggleCheckbox
                label={getLanguageValue(languageText, 'Profile locked')}
                value={profileParticipantData.isLocked}
                handleCheckboxChange={handleProfileLock}
              />
            </div>
          )}
        </>
      )}
      <div className='border-bottom my-4'></div>

      {languageSelectionModal && (
        <>
          <LanguageModal
            languageText={languageText}
            languages={languages}
            headerText={`${getLanguageValue(languageText, 'Profile')} - ${
              profileParticipantData.name
            } ${profileParticipantData.surname}`}
            selectedProfileLanguageId={0}
            handleChangeLanguage={onLanguageClick}
            closeLanguageModal={handleCloseClick}
          />
        </>
      )}

      {reportReady && (
        <ModalComponent
          headerText={`${getLanguageValue(languageText, 'Profile')} - ${
            profileParticipantData?.name
          } ${profileParticipantData.surname}`}
          cancelButtonText={getLanguageValue(languageText, 'Close')}
          handleCancelClick={handleCloseClick}
        >
          <div className='d-flex flex-column gap-10'>
            <div>
              <div className='fs-7 fw-bold text-gray-600 pb-2'>
                {getLanguageValue(languageText, 'Download File')}
              </div>
              <button onClick={onDownloadClick} className='btn btn-success'>
                {getLanguageValue(languageText, 'Download')}
              </button>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  )
}

export default ProfileParticipantProfile
