import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnDef } from '@tanstack/react-table'

import BooleanCell from '../../components/reactTable/booleanCell'
import { RootState } from '@app/store/configureStore'
import { SubscriptionType } from '../clientList/addEditModal/clientEnums'
import { CustomActionsCell } from '../../components/reactTable/customActionsCell'
import { deleteSubscription, getAllSubscriptions } from './actions'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { setSpinner } from '../actions'

interface ISubscriptionTable {
  id: number
  name: string
  type: string
  enabled: boolean
  actions: JSX.Element
}

export const useSubscriptionsList = () => {
  const dispatch = useDispatch()
  const isMounted = useRef(false)

  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [data, setData] = useState<any[]>([])
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [editRowId, setEditRowId] = useState<number>(0)
  const [deleteRowId, setDeleteRowId] = useState<number>(0)
  const [selectedActionRowName, setSelectedActionRowName] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10) // items_per_page
  const [pageCount, setPageCount] = useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0) // page_number_selected
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(false)

  const fetchSubscriptions = (skipCount: number): void => {
    dispatch(setSpinner(true))
    getAllSubscriptions(filter, sorting, pageLimit, skipCount, dispatch)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit)
          setData(response.items)
          setTotalCount(response.totalCount)
          setPageCount(pageSize)
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  useEffect(() => {
    fetchSubscriptions(0)
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected
      fetchSubscriptions(skipCount)
    }
  }, [pageSelected, sorting])

  useEffect(() => {
    if (isMounted.current) {
      fetchSubscriptions(0)
    }
    if (!isMounted.current) isMounted.current = true
  }, [filter, pageLimit])

  const handleEditClick = (id: number): void => {
    setEditRowId(id)
    setIsAddEditModalOpen(true)
  }

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id)
    setSelectedActionRowName(name)
    setIsAddEditModalOpen(true)
  }

  const handleDeleteSubscription = (): void => {
    dispatch(setSpinner(true))
    deleteSubscription(deleteRowId, dispatch)
      .then(() => {
        setDeleteRowId(0)
        setSelectedActionRowName('')
        fetchSubscriptions(0)
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const handleAddSubscription = (): void => {
    setEditRowId(0)
    setIsAddEditModalOpen(true)
  }

  const closeDeleteModal = (): void => {
    setDeleteRowId(0)
  }

  const closeAddEditModal = (): void => {
    setIsAddEditModalOpen(false)
    setEditRowId(0)
  }

  const handleRefetchSubscriptions = (): void => fetchSubscriptions(0)

  const tableHeader = useMemo<ColumnDef<ISubscriptionTable, any>[]>(
    () => [
      {
        header: '',
        accessorKey: 'actions',
        disableSortBy: true,
        cell: ({ ...props }) => (
          <CustomActionsCell
            editOption
            deleteOption
            languageText={languageText}
            id={props.row.original.id}
            name={props.row.original.name}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, 'ID'),
        accessorKey: 'id',
      },
      {
        header: getLanguageValue(languageText, 'Name'),
        accessorKey: 'name',
      },
      {
        header: getLanguageValue(languageText, 'Type'),
        accessorKey: 'type',
        cell: ({ ...props }) => (
          <span>{SubscriptionType[props.row.original.type as keyof typeof SubscriptionType]}</span>
        ),
      },
      {
        header: getLanguageValue(languageText, 'Enabled'),
        accessorKey: 'enabled',
        cell: ({ ...props }) => (
          <BooleanCell languageText={languageText} boolValue={props.row.original.enabled} />
        ),
      },
    ],
    [languageText]
  )

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    deleteRowId,
    editRowId,
    isAddEditModalOpen,
    selectedActionRowName,
    filter,
    pageLimit,
    pageSelected,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    closeDeleteModal,
    closeAddEditModal,
    handleAddSubscription,
    handleDeleteSubscription,
    handleRefetchSubscriptions,
  }
}
