import React, { useState } from 'react'
import clsx from 'clsx'

import ReactTooltip from '@app/components/tooltip/reactTooltip'
import { PresentationsModal } from '../modals/presentationsModal/presentationsModal'
import { IEditActivity, IEditActivityPlannedPresentation } from '../interface'
import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { CoursePresentationStatus, getCoursePresentationStatus } from '../../profileEnums'
import { routePath } from '@app/containers/routePaths'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { TIMEZONE } from '@app/constants'
import { CustomModal } from '@app/components/modals/customModal'
import { classNames } from '@app/containers/utils'
import { pIdQueryKey } from '@app/consts'
import { useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'

interface IEditProfilePresentationProps {
  languageText: ITranslationObject
  profileData: IEditActivity
  cultures: IDropdownList[]
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
  refetchPlannedPresentations: () => void
  refetchCompletedParticipants: () => void
  refetchDeliveredParticipants: () => void
}

export const EditProfilePresentation: React.FC<IEditProfilePresentationProps> = (props) => {
  const {
    languageText,
    profileData,
    isActivityCancelled,
    isActivityInvoiced,
    refetchPlannedPresentations,
    refetchCompletedParticipants,
    refetchDeliveredParticipants,
  } = props

  const userDetails = useSelector((state: RootState) => state.loginReducer.userDetails)

  const initialPresentationDataState: IEditActivityPlannedPresentation = {
    presentationId: 0,
    presentationDateTime: '',
    presentationStatus: CoursePresentationStatus.Unknown,
    presentationTemplateName: '',
    presentationTemplateId: 0,
    currentSlideId: 0,
    presentationLanguageFlag: '',
    presentationPublicId: '',
  }
  const [presentationData, setPresentationData] = useState<IEditActivityPlannedPresentation>(
    initialPresentationDataState
  )
  const [refreshMessagePopup, setRefreshMessagePopup] = useState<boolean>(false)

  const openEditPresentationModal = (presentationData: IEditActivityPlannedPresentation): void => {
    setPresentationData(presentationData)
  }

  const handleCloseEditPresentationModal = (): void => {
    setPresentationData(initialPresentationDataState)
  }

  const handlePlayPresentation = (
    presentationStatus: number,
    presentationPublicId: string
  ): void => {
    if (
      presentationStatus === CoursePresentationStatus.Completed ||
      presentationStatus === CoursePresentationStatus.Cancelled ||
      isActivityCancelled ||
      isActivityInvoiced
    ) {
      return
    }
    setRefreshMessagePopup(true)
    setPresentationData(initialPresentationDataState)
    const redirectURL = `${process.env.PUBLIC_URL}${routePath.facilitatorDelivery}`
    window.open(`${redirectURL}?${pIdQueryKey}=${presentationPublicId}`, '_blank')
  }

  const getRefreshMessage = (): JSX.Element => {
    return (
      <>
        {getLanguageValue(languageText, 'Click on the')} "
        <span className='fw-bold'>{getLanguageValue(languageText, 'Refresh')}</span>"{' '}
        {getLanguageValue(
          languageText,
          'below to update status of participants after the presentation is finished'
        )}
        .
      </>
    )
  }

  const closeRefreshPopup = (): void => {
    setRefreshMessagePopup(false)
  }

  const handleRefreshClick = (): void => {
    refetchCompletedParticipants()
    refetchPlannedPresentations()
    refetchDeliveredParticipants()
    closeRefreshPopup()
  }

  let plannedAndSortedPresentations: IEditActivityPlannedPresentation[] = []
  if (profileData.plannedPresentations?.length > 0) {
    plannedAndSortedPresentations = profileData.plannedPresentations.slice()
    const collator = new Intl.Collator('en', {
      numeric: true,
    })

    plannedAndSortedPresentations.sort((a, b) => {
      return collator.compare(a.presentationDateTime, b.presentationDateTime)
    })
  }

  return (
    <>
      {plannedAndSortedPresentations?.length > 0 && (
        <>
          <div className='rounded border mb-3 mt-n2 bg-white'>
            <div className='fs-6 p-3 fw-bold text-secondary'>
              {getLanguageValue(languageText, 'Presentations')}
            </div>

            {plannedAndSortedPresentations.map((presentation, index) => {
              const isPlannedOrOngoing =
                presentation.presentationStatus === CoursePresentationStatus.Planned ||
                presentation.presentationStatus === CoursePresentationStatus.Ongoing

              type Named = { name: string }

              const completedParticipants: ReadonlyArray<Named> =
                profileData.completedProfiles.filter(
                  (p) => p.presentationId == presentation.presentationId
                )

              const deliveredParticipants: ReadonlyArray<Named> =
                profileData.deliveredProfiles.filter(
                  (p) => p.presentationId == presentation.presentationId
                )

              const participants = completedParticipants.concat(deliveredParticipants)
              const rowClazz = classNames({
                'row mx-auto p-2 border-top align-items-center': true,
                'bg-white': !isPlannedOrOngoing,
                'bg-warning bg-opacity-10': isPlannedOrOngoing,
                'text-black-50':
                  presentation.presentationStatus === CoursePresentationStatus.Completed,
              })

              return (
                <div className={rowClazz} key={index}>
                  {/* Presentation Name */}
                  <div
                    className={clsx(
                      'col-md-5 col-12 py-2 fs-5 fw-bold',
                      !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                    )}
                    role={!isActivityCancelled ? 'button' : ''}
                    onClick={() =>
                      !isActivityCancelled &&
                      !isActivityInvoiced &&
                      openEditPresentationModal(presentation)
                    }
                  >
                    {/* Presentation Date */}
                    <div className='fs-5 fw-bold'>
                      {presentation.presentationDateTime.slice(0, 10)}&nbsp;
                      <span className='fw-bold me-1'>
                        {presentation.presentationDateTime.slice(11, -3)}
                      </span>
                      {TIMEZONE}
                    </div>

                    {/* Template Name */}
                    <div className='fs-6 fw-normal'>
                      <ReactTooltip
                        id={presentation.presentationTemplateId.toString()}
                        tooltipText={presentation.presentationTemplateName}
                      >
                        {presentation.presentationTemplateName}
                      </ReactTooltip>
                    </div>
                  </div>

                  {/* No of Participants */}
                  <div className='col-md-4 col-6 py-2'>
                    {participants.map((p, index) => {
                      return (
                        <span
                          className='badge bg-dark bg-opacity-10 text-body fs-6 ms-2'
                          key={index}
                        >
                          {p.name}
                        </span>
                      )
                    })}
                  </div>

                  {/* Presentation Status */}
                  <div className='col-md-2 col-3 text-end'>
                    <span className='fw-bold fs-6'>
                      {getLanguageValue(
                        languageText,
                        getCoursePresentationStatus(presentation.presentationStatus)
                      )}
                    </span>
                  </div>

                  {/* Play Icon */}
                  <div
                    className={clsx(
                      'col-md-1 col-3 pt-1 text-end',
                      isPlannedOrOngoing && !isActivityCancelled && 'cursor-pointer'
                    )}
                  >
                    <button
                      className={clsx(
                        isPlannedOrOngoing ? 'btn-success' : 'disabled border-0',
                        'btn py-0'
                      )}
                      disabled={userDetails.isManager}
                      onClick={() =>
                        !isActivityCancelled &&
                        !isActivityInvoiced &&
                        handlePlayPresentation(
                          presentation.presentationStatus,
                          presentation.presentationPublicId
                        )
                      }
                    >
                      <i
                        className={
                          isPlannedOrOngoing ? 'bi bi-play-fill fs-4' : 'bi bi-check-lg fs-3'
                        }
                      ></i>
                    </button>
                  </div>
                </div>
              )
            })}
          </div>

          {presentationData.presentationId > 0 && (
            <PresentationsModal
              isEdit={true}
              presentationData={presentationData}
              activityId={profileData.id}
              handleCloseClick={handleCloseEditPresentationModal}
              refetchPlannedPresentations={refetchPlannedPresentations}
              refetchParticipants={refetchCompletedParticipants}
              handlePlayPresentation={handlePlayPresentation}
            />
          )}
        </>
      )}

      {refreshMessagePopup && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Refresh Message')}
          bodyText={getRefreshMessage()}
          submitButtonText={getLanguageValue(languageText, 'Refresh')}
          handleSubmitClick={handleRefreshClick}
          handleCancelClick={closeRefreshPopup}
        />
      )}
    </>
  )
}
