import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnDef } from '@tanstack/react-table'

import ReactTable from '@app/components/reactTable/reactTable'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { RootState } from '@app/store/configureStore'
import { setSpinner } from '../actions'
import { FortnoxInvoiceStatus, IDIInvoice } from '@app/components/invoice/invoiceInterface'
import { getInvoices } from './actions'
import { ITableCommonParams } from '../commonInterfaces'
import { SubscriptionType } from '../clientList/addEditModal/clientEnums'

const InvoiceList = (): JSX.Element => {
  const dispatch = useDispatch()
  const isMounted = useRef(false)
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [data, setdata] = useState<IDIInvoice[]>([])
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10) // items_per_page
  const [pageCount, setPageCount] = useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0) // page_number_selected

  const fetchInvoices = (skipCount: number): void => {
    dispatch(setSpinner(true))

    const params: ITableCommonParams = {
      filter: filter,
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: skipCount,
    }
    getInvoices(params, dispatch)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit)
          setdata([...response.items])
          setTotalCount(response.totalCount)
          setPageCount(pageSize)
          if (!isMounted.current) isMounted.current = true
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected
      fetchInvoices(skipCount)
    }
  }, [pageSelected, sorting])

  useEffect(() => {
    fetchInvoices(0)
  }, [filter, pageLimit])

  const invoiceTableHeader = useMemo<ColumnDef<IDIInvoice, keyof IDIInvoice>[]>(
    () => [
      {
        header: getLanguageValue(languageText, 'Account'),
        accessorKey: 'name',
        enableSorting: false,
        cell: ({ ...props }) => props.row.original.client?.name,
      },
      {
        header: getLanguageValue(languageText, 'Activity'),
        accessorKey: 'activityName',
        enableSorting: false,
        cell: ({ ...props }) => props.row.original.activity?.name,
      },
      {
        header: getLanguageValue(languageText, 'Invoice Number'),
        accessorKey: 'fortnoxInvoiceNumber',
      },
      {
        header: getLanguageValue(languageText, 'Facilitator'),
        accessorKey: 'facilitator',
        enableSorting: false,
        cell: ({ ...props }) => props.row.original.activity?.facilitator,
      },
      {
        header: getLanguageValue(languageText, 'Date'),
        accessorKey: 'date',
        cell: ({ ...props }) => props.row.original.date?.slice(0, 10),
      },
      {
        header: getLanguageValue(languageText, 'Amount'),
        accessorKey: 'total',
        cell: ({ ...props }) => (
          <>
            {props.row.original.total} {props.row.original.currency}
          </>
        ),
      },
      {
        header: getLanguageValue(languageText, 'Payment type'),
        accessorKey: 'paymentType',
        enableSorting: false,
        cell: ({ ...props }) => (
          <>
            {props.row.original.clientSubscription?.subscription?.type
              ? SubscriptionType[props.row.original.clientSubscription.subscription.type]
              : null}
          </>
        ),
      },
      {
        header: getLanguageValue(languageText, 'Status'),
        accessorKey: 'fortnoxInvoiceStatus',
        cell: ({ ...props }) => (
          <>
            {props.row.original.fortnoxInvoiceStatus
              ? FortnoxInvoiceStatus[props.row.original.fortnoxInvoiceStatus]
              : null}
          </>
        ),
      },
    ],
    [languageText]
  )

  return (
    <>
      <ReactTable
        wrapperClass='p-2 pt-4 p-md-4'
        headerText={getLanguageValue(languageText, 'Invoices')}
        searchPlaceholder={getLanguageValue(languageText, 'Search Invoice')}
        tableHeader={invoiceTableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        sorting={sorting}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />
    </>
  )
}

export default InvoiceList
