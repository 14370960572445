import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { IRespondentData } from './reducer'
import TermsFooter from '../../components/termsFooter/termsFooter'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import RespondentLanguageNavbar from './respondentLanguageNavbar/respondentLanguageNavbar'
import { Link } from 'react-router-dom'
import { routePath } from '../routePaths'
import { ProfileStatus } from '../profileList/profileEnums'

const RespondentThankYouPage = (): JSX.Element => {
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const respondentData: IRespondentData = useSelector(
    (state: RootState) => state.respondentReducer.respondentData
  )

  let title: string

  // technically several of these cases work totally by accident,
  // and depend on us _not_ updating the redux state before we get here.
  //
  // most notably a profile could become completed after a
  // respondent fills the form, but we won't detect that because
  // we never updated the redux state.
  //
  // the last block (the default case) only triggers because
  // we don't update our local copy of 'isFormFilled' after
  // the form is filled... which is kind of a bug in itself.
  //   -johan, 2024-10-11
  if (respondentData.profileStatus !== ProfileStatus.Active) {
    title = 'Profile is delivered, so the link is expired'
  } else if (respondentData.isFormFilled) {
    title = 'The form is already filled. Thank you'
  } else {
    title = 'Thank you for your contribution'
  }

  return (
    <div className='container my-md-5'>
      <div className='row p-3'>
        <div className='col-lg-10 rounded shadow mx-auto p-md-5 p-4 bg-white mb-3'>
          <RespondentLanguageNavbar languageText={languageText} hideLanguageSelection={true} />
          <div className='my-5'>
            <h1 className='fs-4 fw-bold'>
              {/* Why is there a wild exclamation mark here? Is that useful? */}
              {getLanguageValue(languageText, title)}!
            </h1>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-7 order-2 order-md-1'>
                <h2 className='fw-bold mt-3 mt-md-0 d-none d-md-block'>
                  {getLanguageValue(languageText, 'About Us')}
                </h2>
                <div className='fs-5 mt-3'>
                  {getLanguageValue(
                    languageText,
                    /* NOTE: this is a long text block with a shortened key. */
                    'Thank you page text'
                  )}
                  .
                </div>
                <div className='fs-5 mt-3'>
                  {getLanguageValue(languageText, `Want to dive deeper? Head over to our website!`)}
                </div>
                <a
                  className='btn btn-success my-4 w-50'
                  href='https://www.idi.se'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {getLanguageValue(languageText, 'Visit our website!')}
                </a>
              </div>

              <h2 className='fw-bold mb-4 d-md-none'>
                {getLanguageValue(languageText, 'About Us')}
              </h2>

              <div className='col-12 col-md-5 order-1 order-md-2'>
                <img src='/DeliveryImages/Functional-role-5.webp' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>

        <TermsFooter languageText={languageText} />
      </div>
    </div>
  )
}

export default RespondentThankYouPage
