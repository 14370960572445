import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'

import { ParticipantAccountDetailsModal } from '../modals/participantAccountDetailsModal/participantAccountDetailsModal'
import ReactTooltip from '../../../../components/tooltip/reactTooltip'
import PersonalizedInvitationModal from '../../addProfile/emailSMSInvitation/personalizedInvitationModal'
import { RoleSettingsModalWithEditOption } from '../../addProfile/roleSettings/roleSettingsModalWithEditOption'
import EditProfileParticipantModal from '../modals/editProfileParticipantModal/editProfileParticipantModal'
import Checkbox from '../../../../components/formComponents/checkbox'
import { UploadEmployeeModal } from '../../../employeeList/uploadEmployeeModal/uploadEmployeeModal'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { GetTypeOfRoleName, ProfileStatus, ProfileRoleStatus } from '../../profileEnums'
import { ISelectedProfilesList } from '../../addProfile/activityInviteParticipantsStep'
import {
  EmailStatus,
  IEditActivity,
  IEditActivityCompletedProfile,
  IEditActivityDeliveredProfile,
  IEditActivityNewProfile,
  IEditActivityOngoingProfile,
  getEmailStatus,
} from '../interface'
import { IRoleData } from '../../addProfile/roleSettings/roleSettingsModal'
import { CustomModal } from '../../../../components/modals/customModal'
import GroupInvitation from '../../addProfile/emailSMSInvitation/groupInvitation'
import Instruction from '@app/components/instruction/instruction'
import { GuidePages } from '@app/containers/commonEnums'
import { editProfileNewStatusInstructionSteps } from '@app/components/instruction/instructionSteps'
import { ActivityId, ProfileId } from '@app/containers/reducer'

interface IEditProfileUnstartedParticipantProps {
  id: ActivityId
  languageText: ITranslationObject
  profileData: IEditActivity
  isInstructionHelpClicked: boolean
  editProfileGuideDone: boolean
  languages: IDropdownList[]
  cultureValue: string
  messageTranslation: ITranslationObject
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void
  refetchNewParticipants: () => void
  handleTransferProfile: () => void
}

const EditProfileUnstartedParticipant = (
  props: IEditProfileUnstartedParticipantProps
): JSX.Element => {
  const {
    id,
    languageText,
    profileData,
    isInstructionHelpClicked,
    editProfileGuideDone,
    languages,
    cultureValue,
    messageTranslation,
    isActivityCancelled,
    isActivityInvoiced,
    handleExitGuide,
    refetchNewParticipants,
    handleTransferProfile,
  } = props

  const instructionSteps = useMemo(
    () => editProfileNewStatusInstructionSteps(languageText),
    [languageText]
  )

  const initialRoleState: IRoleData<ProfileId> = {
    id: 0 as ProfileId,
    roleId: 0,
    roleText: '',
    noOfRespondents: 0,
  }
  const initialInviteState: ISelectedProfilesList[] = [
    {
      id: 0 as ProfileId,
      name: '',
      email: '',
      phoneNumber: '',
      emailSent: false,
      smsSent: false,
    },
  ]
  const [addParticipantModal, setAddParticipantModal] = useState<boolean>(false)
  const [existingEmails, setExistingEmails] = useState<string[]>([])
  const [editProfileId, setEditProfileId] = useState<ProfileId>(0 as ProfileId)
  const [selectedRoleSettings, setSelectedRoleSettings] =
    useState<IRoleData<ProfileId>>(initialRoleState)
  const [profileAccountDetailsId, setProfileAccountDetailsId] = useState<ProfileId>(0 as ProfileId)
  // Email feature States
  const [selectedParticipants, setSelectedParticipants] = useState<Array<number>>([]) // Checkbox selected participant
  const [invitedParticipants, setInvitedParticipants] = useState<Array<number>>([]) // Email sent once more for these participants
  const [participantsList, setParticipantsList] = useState<ISelectedProfilesList[]>([]) // List of participants that can be invited
  const [selectedParticipantsForInvite, setSelectedParticipantsForInvite] =
    useState<ISelectedProfilesList[]>(initialInviteState) // To send invite once more
  const [emailInvitationSent, setEmailInvitationSent] = useState<boolean>(false)
  const [smsInvitationSent, setSMSInvitationSent] = useState<boolean>(false)
  const [selectRoleMessage, setSelectRoleMessage] = useState<string>('')

  useEffect(() => {
    if (profileData.newProfileCount > 0) {
      const participantsList: ISelectedProfilesList[] = profileData.newProfiles.map(
        (participant) => ({
          id: participant.id,
          name: participant.name,
          email: participant.emailAddress,
          phoneNumber: participant.phoneNumber ?? '',
          emailSent: false,
          smsSent: false,
          emailOwnMessage: participant.emailOwnMessage,
          smsOwnMessage: participant.smsOwnMessage,
          emailStatus: participant.emailStatus,
          smsStatus: participant.smsStatus,
        })
      )
      setParticipantsList(participantsList)
    }
  }, [profileData.newProfiles])

  //  Add new participant fn's
  const onAddParticipantClick = (): void => {
    const updatedExistingEmails: string[] = []

    // Extracting all participant emails present in current profile
    Object.values(profileData).forEach((value) => {
      if (Array.isArray(value)) {
        value.forEach(
          (
            participant:
              | IEditActivityNewProfile
              | IEditActivityOngoingProfile
              | IEditActivityCompletedProfile
              | IEditActivityDeliveredProfile
          ) => {
            if (participant.emailAddress) {
              updatedExistingEmails.push(participant.emailAddress)
            }
          }
        )
      }
    })

    setExistingEmails(updatedExistingEmails)
    setAddParticipantModal(true)
  }

  const handleCancelClick = (): void => {
    setSelectRoleMessage('')
  }

  const closeAddParticipantModal = (): void => {
    setAddParticipantModal(false)
  }

  const handleNewlyAddedParticipant = (): void => refetchNewParticipants()

  // Edit Settings fn's
  const onSettingsClick = (id: ProfileId): void => {
    setEditProfileId(id)
  }

  const closeSettingsModal = (): void => {
    setEditProfileId(0 as ProfileId)
  }

  // Role settings modal fn's
  const onRoleClick = (profile: IEditActivityNewProfile): void => {
    setSelectedRoleSettings({
      id: profile.id,
      roleId: profile.roleId,
      roleText: profile.roleText,
      noOfRespondents: profile.noOfRespondents,
    })
  }

  const closeRoleSettingsModal = (): void => {
    setSelectedRoleSettings(initialRoleState)
  }

  // Participant account details fn's
  const onProfileClick = (id: ProfileId): void => {
    setProfileAccountDetailsId(id)
  }

  const closeAccountDetailsModal = (): void => {
    setProfileAccountDetailsId(0 as ProfileId)
  }

  // Checkbox fn's
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      // Select all participants
      const filteredParticipants = profileData.newProfiles.filter(
        (participant) =>
          participant.emailStatus !== EmailStatus.InvitationSentWaitingForResult &&
          participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult
      )
      if (filteredParticipants.length > 0) {
        const allParticipants = filteredParticipants.map((p) => p.id)
        setSelectedParticipants(allParticipants)
      } else return
    } else {
      // Unselect all participants
      setSelectedParticipants([])
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: number): void => {
    setSelectedParticipants((prevSelectedParticipant) => {
      if (e.target.checked) {
        return [...prevSelectedParticipant, id]
      } else {
        return prevSelectedParticipant.filter((item) => item !== id)
      }
    })
  }

  const closeInvitationModal = (): void => {
    setSelectedParticipantsForInvite(initialInviteState)
    setSelectedParticipants([])
  }

  // Individual Invitation fn
  const onInvitationClick = (id: number): void => {
    const isRoleSelected = profileData.newProfiles.find((participant) => participant.id === id)
    if (
      (!isRoleSelected?.roleId || !isRoleSelected.roleText) &&
      !isRoleSelected?.noOfRespondents &&
      !profileData.roleCanBeChangedByParticipant &&
      profileData.roleSetBy !== Number(ProfileRoleStatus.DisableRole)
    ) {
      setSelectRoleMessage('Please select participant role before sending the invitation')
    } else {
      const selectedParticipant = participantsList.find((participant) => participant.id === id)
      initialInviteState
      setSelectedParticipantsForInvite(
        selectedParticipant ? [selectedParticipant] : initialInviteState
      )
    }
  }

  const handleIndividualMessageSentSuccess = (
    isEmail: boolean,
    profile: ISelectedProfilesList,
    emailOrSMSStatus: number
  ): void => {
    const updatedList: ISelectedProfilesList[] = participantsList.map((item) => {
      if (item.id === profile.id) {
        return {
          ...item,
          emailStatus: isEmail ? emailOrSMSStatus : item.emailStatus,
          smsStatus: !isEmail ? emailOrSMSStatus : item.smsStatus,
          emailSent: isEmail ? true : item.emailSent,
          smsSent: !isEmail ? true : item.smsSent,
        }
      } else return item
    })

    setParticipantsList(updatedList)

    const updatedParticipant = updatedList.find((item) => item.id === profile.id)
    setSelectedParticipantsForInvite(updatedParticipant ? [updatedParticipant] : initialInviteState)

    // Email sent once more for these participants
    setInvitedParticipants((prev) => [...prev, profile.id])

    refetchNewParticipants()
  }

  // Group Invitation fn
  const inviteSelectedParticipants = (): void => {
    if (!selectedParticipants.length) return
    let isRoleSelectedForAll = true
    if (!profileData.roleCanBeChangedByParticipant) {
      isRoleSelectedForAll = profileData.newProfiles
        .filter((participant) => selectedParticipants.includes(participant.id))
        .every(
          (participant) =>
            ((participant.roleId || participant.roleText) && participant.noOfRespondents) ||
            profileData.roleSetBy === Number(ProfileRoleStatus.DisableRole)
        )
    }

    if (!isRoleSelectedForAll) {
      setSelectRoleMessage(
        'Few participants are missing roles. Please select roles for all participants before sending the invitations'
      )
    } else {
      const selectedParticipantsToInvite = participantsList.filter((item) =>
        selectedParticipants.includes(item.id)
      )
      setSelectedParticipantsForInvite(selectedParticipantsToInvite)
    }
  }

  const handleSameMessageSentSuccess = (isEmail: boolean): void => {
    const updatedList: ISelectedProfilesList[] = participantsList.map((item) => ({
      ...item,
      emailSent: isEmail ? true : item.emailSent,
      smsSent: !isEmail ? true : item.smsSent,
    }))
    setParticipantsList(updatedList)

    const updatedParticipant =
      updatedList.filter((item) =>
        selectedParticipantsForInvite.filter((participant) => participant.id === item.id)
      ) ?? initialInviteState
    setSelectedParticipantsForInvite(updatedParticipant)

    // Email sent once more for these participants
    selectedParticipantsForInvite.map((participant) =>
      setInvitedParticipants((prev) => [...prev, participant.id])
    )

    if (isEmail) {
      setEmailInvitationSent(true)
    } else setSMSInvitationSent(true)

    refetchNewParticipants()
  }

  let disableCheckbox = false
  if (profileData.newProfileCount > 0) {
    disableCheckbox = profileData.newProfiles.every(
      (participant) =>
        participant.emailStatus === EmailStatus.InvitationSentWaitingForResult ||
        participant.emailStatus === EmailStatus.ReminderSentWaitingForResult
    )
  }

  let allParticipantsSelected = false
  if (profileData.newProfileCount > 0 && !disableCheckbox) {
    allParticipantsSelected =
      selectedParticipants.length ===
      profileData.newProfiles.filter(
        // Shouldn't send email for participants waiting for email result
        (participant) =>
          participant.emailStatus !== EmailStatus.InvitationSentWaitingForResult &&
          participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult
      ).length
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mt-5 mb-4'>
        <span className='fs-4 fw-bold'>
          <span className='badge fs-5 text-bg-dark pt-2 me-1'>{profileData.profileCount}</span>{' '}
          {getLanguageValue(languageText, 'Participants')}
        </span>
        {/* Add new participant */}
        <button
          id='editProfileAddParticipants'
          className='btn btn-success ms-8'
          disabled={isActivityCancelled || isActivityInvoiced}
          onClick={onAddParticipantClick}
        >
          <i className='bi bi-plus-lg'></i> {getLanguageValue(languageText, 'Add Participant')}
        </button>
      </div>

      <div className='rounded border mb-3 bg-white'>
        <div
          id='editProfileNewStatusHeader'
          className='p-3 pe-4 bg-secondary-subtle d-flex justify-content-between align-items-center flex-wrap'
        >
          <div className='fs-5 fw-bold text-secondary'>
            <span className='badge fs-5 bg-dark pt-2 me-2 bg-opacity-25'>
              {profileData.newProfileCount}
            </span>{' '}
            {getLanguageValue(languageText, 'Unstarted')}
          </div>
          {profileData.newProfileCount > 0 && (
            <>
              <div id='newMultiEmailSMS' className='d-flex align-items-center'>
                <button
                  className={clsx(
                    'btn border me-4',
                    selectedParticipants.length > 0 && 'btn-success'
                  )}
                  disabled={isActivityCancelled || disableCheckbox || isActivityInvoiced}
                  onClick={inviteSelectedParticipants}
                >
                  <i className='bi bi-send-fill fs-5'></i>
                </button>
                <Checkbox
                  className='fs-3'
                  inputClass={clsx('border border-secondary-subtle')}
                  value={allParticipantsSelected}
                  disable={isActivityCancelled}
                  handleCheckboxChange={handleSelectAll}
                />
              </div>

              {/* Instruction steps */}
              {editProfileGuideDone ? (
                <Instruction
                  showHelpButton={false}
                  targetElement='newIndividualCheckbox'
                  guidePage={GuidePages.Edit_Profile_New_Status}
                  instructionSteps={instructionSteps}
                  skipPostGuideRequest={true}
                  manualTrigger={isInstructionHelpClicked}
                  handleExitGuide={handleExitGuide}
                />
              ) : null}
            </>
          )}
        </div>

        {profileData.newProfileCount > 0 && (
          <div>
            {profileData.newProfiles.map((participant) => {
              const isSelected = selectedParticipants.includes(participant.id)
              return (
                <div key={participant.id} className='d-flex flex-wrap border-top p-4'>
                  <div id='newParticipantDetails' className='col-lg-6 d-flex col-12 p-0'>
                    {/* Participant name */}
                    <div
                      className={clsx(
                        'w-100 m-auto fs-5 fw-bold',
                        !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                      )}
                      role='button'
                      onClick={() =>
                        !isActivityCancelled &&
                        !isActivityInvoiced &&
                        onSettingsClick(participant.id)
                      }
                    >
                      <ReactTooltip id={participant.id.toString()} tooltipText={participant.name}>
                        {participant.name}
                      </ReactTooltip>
                      {(participant.noOfRespondents ||
                        participant.roleId ||
                        participant.roleText) && (
                        <div className='fs-6 fw-normal'>
                          {participant.noOfRespondents ? participant.noOfRespondents : ''}{' '}
                          {participant.roleId
                            ? getLanguageValue(languageText, GetTypeOfRoleName[participant.roleId])
                            : participant.roleText
                              ? participant.roleText
                              : getLanguageValue(languageText, 'Respondents')}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-lg-6 d-flex col-12 p-0 d-flex justify-content-between'>
                    <div className='d-flex py-1'>
                      {/* Role settings icon */}
                      <button
                        id='roleIcon'
                        className={clsx(
                          'btn me-3',
                          !isActivityCancelled && '',
                          participant.isRoleSet ? 'bg-success-subtle' : 'bg-dark bg-opacity-10',
                          profileData.roleSetBy === Number(ProfileRoleStatus.DisableRole) &&
                            'invisible'
                        )}
                        onClick={() => !isActivityCancelled && onRoleClick(participant)}
                      >
                        <i className='bi bi-ui-radios-grid fs-5'></i>
                      </button>

                      {/* Participant Link */}
                      <button
                        id='participantLink'
                        className={clsx(
                          'btn bg-dark bg-opacity-10',
                          !isActivityCancelled && ' cursor-pointer'
                        )}
                        onClick={() => !isActivityCancelled && onProfileClick(participant.id)}
                      >
                        <i className='bi bi-person fs-5'></i>
                      </button>
                    </div>

                    <div className='d-flex py-1 justify-content-end align-items-center'>
                      {/* Invitation icon */}
                      <button
                        id='newInvitation'
                        className={clsx(
                          'btn',
                          !isActivityCancelled &&
                            participant.emailStatus !==
                              EmailStatus.InvitationSentWaitingForResult &&
                            participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult &&
                            ' cursor-pointer',
                          getEmailStatus(participant.emailStatus)
                        )}
                        onClick={() =>
                          !isActivityCancelled &&
                          participant.emailStatus !== EmailStatus.InvitationSentWaitingForResult &&
                          participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult &&
                          onInvitationClick(participant.id)
                        }
                      >
                        <i className='bi bi-send-fill fs-5'></i>
                      </button>

                      {/* Checkbox icon */}
                      <div className='mt-1'>
                        <Checkbox
                          id='newIndividualCheckbox'
                          className='ms-4 fs-3'
                          inputClass={clsx('border border-secondary-subtle')}
                          value={isSelected}
                          disable={
                            isActivityCancelled ||
                            participant.emailStatus ===
                              EmailStatus.InvitationSentWaitingForResult ||
                            participant.emailStatus === EmailStatus.ReminderSentWaitingForResult
                          }
                          handleCheckboxChange={(e) => handleCheckboxChange(e, participant.id)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      {/* Edit Settings Modal */}
      {editProfileId > 0 && (
        <EditProfileParticipantModal
          languageText={languageText}
          profileId={editProfileId}
          roleDisabled={profileData.roleSetBy === Number(ProfileRoleStatus.DisableRole)}
          languageCode={cultureValue}
          languages={languages}
          showDeleteButton={true}
          messageTranslation={messageTranslation}
          status={ProfileStatus.New}
          handleCloseClick={closeSettingsModal}
          refetchParticipants={refetchNewParticipants}
          handleTransferProfile={handleTransferProfile}
        />
      )}

      {/* Role Settings Modal */}
      {selectedRoleSettings.id > 0 && (
        <RoleSettingsModalWithEditOption
          languageText={languageText}
          profileId={selectedRoleSettings.id}
          status={ProfileStatus.New}
          roleId={selectedRoleSettings.roleId}
          roleText={selectedRoleSettings.roleText}
          noOfRespondents={selectedRoleSettings.noOfRespondents}
          unselectRoleEnabled={
            profileData.roleCanBeChangedByParticipant ||
            profileData.roleSetBy === Number(ProfileRoleStatus.RoleSetByParticipant)
          }
          refetchParticipants={refetchNewParticipants}
          handleCloseClick={closeRoleSettingsModal}
        />
      )}

      {/* Participant account details Modal */}
      {profileAccountDetailsId > 0 && (
        <ParticipantAccountDetailsModal
          languageText={languageText}
          languages={languages}
          status={ProfileStatus.New}
          handleCloseClick={closeAccountDetailsModal}
          profileId={profileAccountDetailsId}
        />
      )}

      {/* Individual Invitation Modal */}
      {selectedParticipantsForInvite.length === 1 && selectedParticipantsForInvite[0].id > 0 && (
        <PersonalizedInvitationModal
          languageText={languageText}
          messageTranslation={messageTranslation}
          languages={languages}
          cultureValue={cultureValue}
          profile={selectedParticipantsForInvite[0]}
          phoneNumber={selectedParticipantsForInvite[0].phoneNumber}
          handleCloseClick={closeInvitationModal}
          handleMessageSentSuccess={handleIndividualMessageSentSuccess}
        />
      )}

      {/* Group Invitation Modal */}
      {selectedParticipantsForInvite.length > 1 && (
        <GroupInvitation
          languageText={languageText}
          messageTranslation={messageTranslation}
          languages={languages}
          cultureValue={cultureValue}
          participants={selectedParticipantsForInvite}
          emailInvitationSent={emailInvitationSent}
          smsInvitationSent={smsInvitationSent}
          handleMessageSentSuccess={handleSameMessageSentSuccess}
          handleCloseClick={closeInvitationModal}
        />
      )}

      {/* Add Participant Modal */}
      {addParticipantModal && (
        <UploadEmployeeModal
          languageText={languageText}
          uploadFromActivityPage={true}
          clientIdForEdit={profileData.clientId}
          activityIdFromEditActivityPage={id}
          existingEmails={existingEmails}
          isRolesRequired={
            profileData.roleSetBy === Number(ProfileRoleStatus.RoleSetByFacilitator) &&
            !profileData.roleSameForAll &&
            !profileData.roleCanBeChangedByParticipant
          }
          refetchNewParticipants={refetchNewParticipants}
          setNewlyAddedEmployees={handleNewlyAddedParticipant}
          closeUploadEmployeeModal={closeAddParticipantModal}
        />
      )}

      {selectRoleMessage !== '' && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Select Participant Role')}
          bodyText={getLanguageValue(languageText, selectRoleMessage)}
          submitButtonText={getLanguageValue(languageText, 'Ok')}
          handleCancelClick={handleCancelClick}
          handleSubmitClick={handleCancelClick}
        />
      )}
    </>
  )
}

export default EditProfileUnstartedParticipant
