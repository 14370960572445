import React, { useEffect, useMemo, useState } from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import Input from '../../../../components/formComponents/input'
import ToggleButtonGroup from '../../../../components/formComponents/toggleButtonGroup'
import {
  CustomModal as RespondentsModal,
  CustomModal as ProfileLockWarningMsgModal,
} from '../../../../components/modals/customModal'
import { useDispatch, useSelector } from 'react-redux'
import { NoOfRespondents, ProfileStatus } from '../../profileEnums'
import { RootState } from '@app/store/configureStore'
import {
  getNoOfRespondentsButtonList,
  getTypeOfRoleButtonList,
} from '../../../../commonUtils/roleSettingsFunctionsHelper'
import {
  saveParticipantFieldValue,
  setParticipantProfileDetails,
} from '../../../participantPages/actions'
import { addToast, setSpinner } from '@app/containers/actions'
import { AnyAction } from 'redux'
import { IProfileDetails } from '@app/containers/participantPages/reducer'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { ProfileId } from '@app/containers/reducer'

export interface ISaveParticipantFieldValueBody {
  id: number
  fieldName: string
  fieldValue: string
}

interface IRoleSettingsWithEditOptionProps {
  languageText: ITranslationObject
  profileId: ProfileId
  status?: number
  roleId: number
  roleText: string
  noOfRespondents: number
  respondentsAnswered?: number
  isEditable?: boolean
  isParticipant?: boolean
  errorMessage?: string
  unselectRoleEnabled?: boolean
  refetchParticipants?: () => void
  refetchOngoingParticipants?: () => void
  refetchCompletedParticipants?: () => void
  handleTypeOfRoleSave?: (typeOfROle: string | number) => void
  handleNoOfRespondentsSave?: (noOfRespondents: number) => void
}

const RoleSettingsWithEditOption = (props: IRoleSettingsWithEditOptionProps): JSX.Element => {
  const {
    languageText,
    profileId,
    status,
    roleId,
    roleText,
    noOfRespondents,
    respondentsAnswered,
    isEditable,
    isParticipant,
    errorMessage,
    unselectRoleEnabled,
    refetchParticipants,
    refetchOngoingParticipants,
    refetchCompletedParticipants,
    handleTypeOfRoleSave,
    handleNoOfRespondentsSave,
  } = props

  const dispatch = useDispatch()

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )

  const [selectedTypeOfRoleBtn, setSelectedTypeOfRoleBtn] = useState<string>('') // TYPE OF ROLE STATES BEGIN
  const [typeOfRoleInput, setTypeOfRoleInput] = useState<string>('')
  const [customTypeOfRole, setCustomTypeOfRole] = useState<string>('')
  const [roleInputSelected, setRoleInputSelected] = useState<boolean>(false)
  const [typeOfRoleText, setTypeOfRoleText] = useState<string>('')
  const [typeOfRoleEditOption, setTypeOfRoleEditOption] = useState<boolean>(false)
  const [selectedNoOfRespondentsBtn, setSelectedNoOfRespondentsBtn] = useState<string>('') // NUMBER OF RESPONDENTS STATES BEGIN
  const [noOfRespondentsInput, setNoOfRespondentsInput] = useState<string>('')
  const [customNoOfRespondents, setCustomNoOfRespondents] = useState<string>('')
  const [respondentsInputSelected, setRespondentsInputSelected] = useState<boolean>(false)
  const [respondentsConfirmModal, setRespondentsConfirmModal] = useState<boolean>(false)
  const [noOfRespondentsText, setNoOfRespondentsText] = useState<string>('')
  const [noOfRespondentsEditOption, setNoOfRespondentsEditOption] = useState<boolean>(false)
  const [profileLockWarningMsgValue, setProfileLockWarningMsgValue] = useState<string>('')
  const [isNoOfRespondentsButtonClick, setIsNoOfRespondentsButtonClick] = useState<boolean>(true) // To know whether noOfRespondents btn is clicked or input field is filled
  const [errorMsg, setErrorMsg] = useState<string>('')

  const typeOfRoleButtonList = useMemo(() => getTypeOfRoleButtonList(languageText), [languageText])
  const noOfRespondentsButtonList = useMemo(() => getNoOfRespondentsButtonList(), [languageText])

  const typeOfRoleCanBeEdited =
    (status &&
      (status === ProfileStatus.New ||
        (isLoggedInRoleAdmin(loggedInUserRole) && status !== ProfileStatus.Delivery))) ||
    (!status && isEditable)
  const noOfRespondentsCanBeEdited =
    (status &&
      (status === ProfileStatus.New ||
        status === ProfileStatus.Active ||
        (isLoggedInRoleAdmin(loggedInUserRole) && status !== ProfileStatus.Delivery))) ||
    (!status && isEditable)

  useEffect(() => {
    if (roleId) {
      const selectedRoleType = typeOfRoleButtonList.find((role) => role.id === roleId)?.name ?? ''
      const selectedRoleName = typeOfRoleButtonList.find((role) => role.id === roleId)?.title ?? ''
      setSelectedTypeOfRoleBtn(selectedRoleType)
      setTypeOfRoleText(selectedRoleName)
      setTypeOfRoleEditOption(true)
    } else if (roleText) {
      setTypeOfRoleInput(roleText)
      setCustomTypeOfRole(roleText)
      setRoleInputSelected(true)
      setTypeOfRoleText(roleText)
      setTypeOfRoleEditOption(true)
    }
    if (noOfRespondents) {
      if (noOfRespondents >= 3 && noOfRespondents <= 6)
        setSelectedNoOfRespondentsBtn(String(noOfRespondents))
      else {
        setNoOfRespondentsInput(String(noOfRespondents))
        setCustomNoOfRespondents(String(noOfRespondents))
        setRespondentsInputSelected(true)
      }
      setNoOfRespondentsText(String(noOfRespondents))
      setNoOfRespondentsEditOption(true)
    }
  }, [roleId, roleText, noOfRespondents, typeOfRoleButtonList])

  useEffect(() => {
    setErrorMsg(errorMessage!)
  }, [errorMessage])

  // TYPE OF ROLE FUNCTIONS
  const saveTypeOfRole = (roleId: string, roleText: string, typeOfRoleUnselected = false): void => {
    const body: ISaveParticipantFieldValueBody = {
      id: profileId,
      fieldName: roleId ? 'RoleId' : 'RoleText',
      fieldValue: typeOfRoleUnselected ? '' : roleId ? roleId : roleText,
    }

    saveParticipantFieldValue(body, dispatch).then((response) => {
      if (response) {
        dispatch(addToast('Role type updated') as AnyAction)
        if (roleId) {
          const selectedRoleName =
            typeOfRoleButtonList.find((role) => role.name === roleId)?.title ?? ''
          setTypeOfRoleText(selectedRoleName)
        } else setTypeOfRoleText(roleText)
        if (!typeOfRoleUnselected) setTypeOfRoleEditOption(true)
        refetchParticipants && refetchParticipants()
        handleTypeOfRoleSave && handleTypeOfRoleSave(roleId ? Number(roleId) : roleText)
      }
    })
  }

  const onTypeOfRoleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!typeOfRoleCanBeEdited) return
    setTypeOfRoleInput(e.target.value)
  }

  const onTypeOfRoleBtnClick = (name: string): void => {
    if (!typeOfRoleCanBeEdited) return

    if (roleInputSelected) setRoleInputSelected(false)

    // Should be able to unselect individual participant role settings(TypeOfRole) when unselectRoleEnabled
    const typeOfRoleUnselected = unselectRoleEnabled && selectedTypeOfRoleBtn === name
    if (typeOfRoleUnselected) {
      setSelectedTypeOfRoleBtn('')
    } else {
      setSelectedTypeOfRoleBtn(name)
    }
    if (customTypeOfRole) {
      setCustomTypeOfRole('')
      setTypeOfRoleInput('')
    }
    saveTypeOfRole(name, '', typeOfRoleUnselected)
  }

  const handleTypeOfRoleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (!typeOfRoleCanBeEdited) return

    const { value } = e.target
    if (!value) return
    if (value) {
      setRoleInputSelected(true)
    } else {
      if (roleInputSelected) setRoleInputSelected(false)
    }
    setCustomTypeOfRole(value)
    if (value && selectedTypeOfRoleBtn) setSelectedTypeOfRoleBtn('')
    if (value !== customTypeOfRole) {
      saveTypeOfRole('', value)
    }
  }

  const onEditTypeOfRoleClick = (): void => setTypeOfRoleEditOption(false)

  // NUMBER OF RESPONDENTS FUNCTIONS
  const saveNoOfRespondents = (
    updatedNoOfRespondents: string,
    noOfRespondentsUnselected = false
  ): void => {
    dispatch(setSpinner(true))
    const body: ISaveParticipantFieldValueBody = {
      id: profileId,
      fieldName: 'NoOfRespondents',
      fieldValue: noOfRespondentsUnselected ? '' : updatedNoOfRespondents,
    }

    saveParticipantFieldValue(body, dispatch)
      .then((response) => {
        if (response) {
          dispatch(addToast('No. of respondents updated') as AnyAction)
          setNoOfRespondentsText(updatedNoOfRespondents)
          if (!noOfRespondentsUnselected) setNoOfRespondentsEditOption(true)
          refetchParticipants && refetchParticipants()

          // Ongoing -> if No of respondents is decreased, move participant to completed status
          if (status === ProfileStatus.Active && noOfRespondents > Number(updatedNoOfRespondents)) {
            refetchCompletedParticipants && refetchCompletedParticipants()
          }

          // Completed & Planned -> if No of respondents is increased, move participant to Ongoing status
          if (
            (status === ProfileStatus.Completed || status === ProfileStatus.DeliveryPlanned) &&
            noOfRespondents < Number(updatedNoOfRespondents)
          ) {
            refetchOngoingParticipants && refetchOngoingParticipants()
          }
          if (isParticipant) {
            const updatedParticipantProfileDetails: IProfileDetails = {
              ...participantProfileDetails,
              noOfRespondents: Number(updatedNoOfRespondents),
            }
            dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))
          }
          handleNoOfRespondentsSave && handleNoOfRespondentsSave(Number(updatedNoOfRespondents))
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const onNoOfRespondentsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!noOfRespondentsCanBeEdited) return
    setNoOfRespondentsInput(e.target.value)
    if (errorMsg) setErrorMsg('')
  }

  const handleNoOfRespondentsClick = (value: string): void => {
    if (respondentsInputSelected) setRespondentsInputSelected(false)

    // Should be able to unselect individual participant role settings(NoOfRespondents) when unselectRoleEnabled
    const noOfRespondentsUnselected = unselectRoleEnabled && selectedNoOfRespondentsBtn === value
    if (noOfRespondentsUnselected) {
      setSelectedNoOfRespondentsBtn('')
    } else {
      setSelectedNoOfRespondentsBtn(value)
    }

    if (customNoOfRespondents) {
      setCustomNoOfRespondents('')
      setNoOfRespondentsInput('')
    }
    saveNoOfRespondents(value, noOfRespondentsUnselected)
  }

  const onNoOfRespondentsClick = (value: string): void => {
    if (!noOfRespondentsCanBeEdited) {
      return
    }
    if (errorMsg) setErrorMsg('')

    // If the facilitator reduces the number of respondents in profile,
    // show a prompt warning that the profile will be locked for both facilitator and participant
    if (
      status === ProfileStatus.Active &&
      respondentsAnswered &&
      Number(value) <= respondentsAnswered
    ) {
      setProfileLockWarningMsgValue(value)
      setIsNoOfRespondentsButtonClick(true)
      return
    }
    handleNoOfRespondentsClick(value)
  }

  const handleCustomNoOfRespondents = (value: string): void => {
    setCustomNoOfRespondents(value)
    if (value && selectedNoOfRespondentsBtn) setSelectedNoOfRespondentsBtn('')
  }

  const handleNoOfRespondentsFocus = (value: string): void => {
    if (
      Number(value) > 8 &&
      value !== customNoOfRespondents &&
      (isLoggedInRoleAdmin(loggedInUserRole) ||
        (!isLoggedInRoleAdmin(loggedInUserRole) && Number(value) <= 20))
    ) {
      setRespondentsConfirmModal(true)
      return
    } else if (
      value === NoOfRespondents.N3 ||
      value === NoOfRespondents.N4 ||
      value === NoOfRespondents.N5 ||
      value === NoOfRespondents.N6
    ) {
      onNoOfRespondentsClick(value)
      setNoOfRespondentsInput('')
      if (value !== customNoOfRespondents) {
        saveNoOfRespondents(value)
      }
      return
    } else if (!isLoggedInRoleAdmin(loggedInUserRole) && Number(value) < 3) {
      setErrorMsg(getLanguageValue(languageText, 'Minimum 3 respondents should be selected'))
      handleNoOfRespondentsSave && handleNoOfRespondentsSave(0)
    } else if (!isLoggedInRoleAdmin(loggedInUserRole) && Number(value) > 20) {
      setErrorMsg(getLanguageValue(languageText, 'Maximum 20 respondents can be selected'))
    } else {
      setRespondentsInputSelected(true)
      if (value !== customNoOfRespondents) {
        saveNoOfRespondents(value)
      }
    }
    handleCustomNoOfRespondents(value)
  }

  const onNoOfRespondentsFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (!noOfRespondentsCanBeEdited) return

    const { value } = e.target
    if (!value) return
    if (value) {
      // If the facilitator reduces the number of respondents in profile,
      // show a prompt warning that the profile will be locked for both facilitator and participant
      if (
        status === ProfileStatus.Active &&
        respondentsAnswered &&
        Number(value) <= respondentsAnswered
      ) {
        setProfileLockWarningMsgValue(value)
        setIsNoOfRespondentsButtonClick(false)
        return
      }
      handleNoOfRespondentsFocus(value)
    }
  }

  const handleSubmitProfileLock = (): void => {
    if (isNoOfRespondentsButtonClick) {
      handleNoOfRespondentsClick(profileLockWarningMsgValue)
    } else {
      handleNoOfRespondentsFocus(profileLockWarningMsgValue)
    }
    setProfileLockWarningMsgValue('')
    setIsNoOfRespondentsButtonClick(true)
  }

  const cancelProfileLockWarningMsgModal = (): void => {
    setProfileLockWarningMsgValue('')
    setIsNoOfRespondentsButtonClick(true)
  }

  const cancelRespondentsConfirmModal = (): void => {
    setRespondentsConfirmModal(false)
    setNoOfRespondentsInput('')
    setRespondentsInputSelected(false)
  }

  const submitRespondentsConfirmModal = (): void => {
    setRespondentsConfirmModal(false)
    setRespondentsInputSelected(true)
    handleCustomNoOfRespondents(noOfRespondentsInput)
    saveNoOfRespondents(noOfRespondentsInput)
  }

  const onEditNoOfRespondentsClick = (): void => setNoOfRespondentsEditOption(false)

  return (
    <>
      {/* TYPE OF ROLE BUTTONS */}
      <div className='fs-6 mb-3'>
        {getLanguageValue(languageText, 'I want to know how Im perceived by certain')}:
      </div>
      {typeOfRoleEditOption ? (
        <div className='mb-4'>
          <button className='btn btn-success'>{typeOfRoleText}</button>
          {typeOfRoleCanBeEdited && (
            <button className='btn btn-light border ms-2' onClick={onEditTypeOfRoleClick}>
              <i className='bi bi-pencil'></i>
            </button>
          )}
        </div>
      ) : (
        <div id='roleInstruction2' className='d-flex gap-1 flex-wrap'>
          <div>
            <ToggleButtonGroup
              buttonList={typeOfRoleButtonList}
              selectedBtn={selectedTypeOfRoleBtn}
              unselectEnabled={unselectRoleEnabled}
              handleButtonClick={onTypeOfRoleBtnClick}
              className='me-2 mb-2'
            />
          </div>
          <Input
            placeholder={getLanguageValue(languageText, 'Other')}
            value={typeOfRoleInput}
            handleInputChange={onTypeOfRoleChange}
            handleBlurEvent={handleTypeOfRoleFocus}
          />
        </div>
      )}

      {/* NUMBER OF RESPONDENTS BUTTONS */}
      <div className='my-3'>
        {getLanguageValue(languageText, 'Choose how many respondents you want from')}:
      </div>

      {noOfRespondentsEditOption ? (
        <>
          <button className='btn btn-success'>{noOfRespondentsText}</button>
          {noOfRespondentsCanBeEdited && (
            <button className='btn btn-light border ms-2' onClick={onEditNoOfRespondentsClick}>
              <i className='bi bi-pencil'></i>
            </button>
          )}
        </>
      ) : (
        <div id='roleInstruction3' className='d-flex gap-1 flex-wrap mb-2'>
          <div>
            <ToggleButtonGroup
              buttonList={noOfRespondentsButtonList}
              selectedBtn={selectedNoOfRespondentsBtn}
              unselectEnabled={unselectRoleEnabled}
              handleButtonClick={onNoOfRespondentsClick}
              className='me-2'
            />
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input
                type='number'
                placeholder=''
                value={noOfRespondentsInput}
                handleInputChange={onNoOfRespondentsChange}
                handleBlurEvent={onNoOfRespondentsFocus}
              />
            </div>
          </div>
        </div>
      )}

      {errorMsg && <div className='invalid-feedback d-inline-block'>{errorMsg}</div>}

      {/* NUMBER OF RESPONDENTS CONFIRM MODAL */}
      {respondentsConfirmModal && (
        <RespondentsModal
          bodyText={`${getLanguageValue(
            languageText,
            `We do not recommend more than 8 respondents, are you sure you want to continue`
          )} ?`}
          cancelButtonText={getLanguageValue(languageText, 'No')}
          submitButtonText={getLanguageValue(languageText, 'Yes')}
          handleSubmitClick={submitRespondentsConfirmModal}
          handleCancelClick={cancelRespondentsConfirmModal}
        />
      )}

      {/* Profile lock warning message modal */}
      {profileLockWarningMsgValue && (
        <ProfileLockWarningMsgModal
          bodyText={`${getLanguageValue(
            languageText,
            `The profile will be locked for both facilitator and participant. Do you want to continue`
          )} ?`}
          cancelButtonText={getLanguageValue(languageText, 'No')}
          submitButtonText={getLanguageValue(languageText, 'Yes')}
          handleSubmitClick={handleSubmitProfileLock}
          handleCancelClick={cancelProfileLockWarningMsgModal}
        />
      )}
    </>
  )
}

export default RoleSettingsWithEditOption
