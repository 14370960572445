import React from 'react'

import Input from '../../../components/formComponents/input'
import { DropdownSelect } from '@app/components/formComponents/dropdownSelect'
import { ToggleCheckbox } from '@app/components/formComponents/toggleCheckbox'
import { useAddEditPresentation } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '../../../components/modals/modalComponent'

export interface IAddEditPresentationModalProps {
  editId: number
  refreshPresentationList: () => void
  closePresentationModal: () => void
}

export const AddEditPresentationModal = (props: IAddEditPresentationModalProps) => {
  const { closePresentationModal } = props

  const {
    languageText,
    isEditClicked,
    PresentationInputs,
    presentationInfo,
    focusInput,
    languages,
    handleInputChange,
    handleBlurEvent,
    handleDropdownSelect,
    handleCheckboxChange,
    handleSaveClick,
  } = useAddEditPresentation(props)

  return (
    <>
      <ModalComponent
        width='lg'
        headerText={getLanguageValue(
          languageText,
          isEditClicked ? 'Edit Template' : 'Create Template'
        )}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        submitButtonText={getLanguageValue(languageText, 'Save')}
        handleCancelClick={closePresentationModal}
        handleSubmitClick={handleSaveClick}
      >
        <Input
          name={PresentationInputs.description}
          label={getLanguageValue(languageText, 'Description')}
          placeholder={getLanguageValue(languageText, 'Description')}
          value={presentationInfo.description}
          focusInput={focusInput.description}
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          required
        />
        <DropdownSelect
          dropdownHeight='mh-450px'
          name={PresentationInputs.language}
          label={getLanguageValue(languageText, 'Language')}
          defaultLabel={getLanguageValue(languageText, 'Select Language')}
          list={languages}
          /* The dropdown list cannot operate on numeric values :) */
          value={presentationInfo.language ? String(presentationInfo.language) : ''}
          focusInput={focusInput.language}
          handleDropdownSelect={handleDropdownSelect}
          searchOption
          required
        />
        <ToggleCheckbox
          name={PresentationInputs.isActive}
          label={getLanguageValue(languageText, 'Active')}
          value={presentationInfo.isActive}
          handleCheckboxChange={handleCheckboxChange}
        />
        <ToggleCheckbox
          name={PresentationInputs.enableAccessControl}
          label={getLanguageValue(languageText, 'Access control')}
          value={presentationInfo.enableAccessControl}
          handleCheckboxChange={handleCheckboxChange}
        />
        <ToggleCheckbox
          name={PresentationInputs.isSelfDeliveryTemplate}
          label={getLanguageValue(languageText, 'Self delivery')}
          value={presentationInfo.isSelfDeliveryTemplate}
          handleCheckboxChange={handleCheckboxChange}
        />
        <ToggleCheckbox
          name={PresentationInputs.isSelfDeliveryDefault}
          label={getLanguageValue(languageText, 'Self delivery default')}
          value={presentationInfo.isSelfDeliveryDefault}
          handleCheckboxChange={handleCheckboxChange}
          disable={!presentationInfo.isSelfDeliveryTemplate}
        />
      </ModalComponent>
    </>
  )
}
