import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoginSocialGoogle, IResolveParams, LoginSocialMicrosoft } from 'reactjs-social-login'

import Input from '../../../components/formComponents/input'
import LoginTermsFooter from './loginTermsFooter'
import { ExternalUserLogin } from './externalUserLogin'
import { setNotification } from '../../actions'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { UserInputs } from './hooks'
import { routePath } from '@app/containers/routePaths'

interface ILoginScreenProps {
  languageText: any
  email: string
  password: string
  formError: any
  setForgotPasswordPage: () => void
  handleSubmit: (e: { preventDefault: () => void }) => void
  handleEmailChange: (e: React.FocusEvent<HTMLInputElement>) => void
  handlePasswordChange: (e: React.FocusEvent<HTMLInputElement>) => void
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void
}

export const LoginScreen: React.FC<ILoginScreenProps> = (props) => {
  const {
    languageText,
    email,
    password,
    formError,
    setForgotPasswordPage,
    handleSubmit,
    handleEmailChange,
    handlePasswordChange,
    handleBlurEvent,
  } = props

  const dispatch = useDispatch()

  const [provider, setProvider] = useState<string>('')

  /** FIXME: any? what? */
  const [profile, setProfile] = useState<any>()
  const usernameRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    usernameRef.current?.focus()
  }, [])

  return (
    <>
      <div className='d-flex flex-column justify-content-between'>
        <div className='p-0'>
          <a
            href='https://app.idi.se'
            className='btn border border-secondary-subtle bg-success bg-opacity-10 mb-3 w-100 fw-bold'
          >
            {getLanguageValue(languageText, 'Log in to the old platform')}
          </a>
          <Link
            className='btn border border-secondary-subtle mb-4 w-100 fw-bold'
            to={routePath.register}
          >
            {getLanguageValue(languageText, 'Dont have an account yet? Get started now!')}
          </Link>
          <div className='mb-4 fs-4 fw-bolder'>{getLanguageValue(languageText, 'Sign in')}</div>
          <form onSubmit={handleSubmit} className='form w-100 mt-2'>
            <Input
              type='text'
              handleInputChange={handleEmailChange}
              value={email || ''}
              focusInput={formError.email}
              handleBlurEvent={handleBlurEvent}
              placeholder={getLanguageValue(languageText, 'Email')}
              name={UserInputs.email}
              required={true}
              checkMarkNotRequired={true}
              inputRef={usernameRef}
            />
            <Input
              handleInputChange={handlePasswordChange}
              value={password || ''}
              focusInput={formError.password}
              handleBlurEvent={handleBlurEvent}
              placeholder={getLanguageValue(languageText, 'Password')}
              name={UserInputs.password}
              id='password'
              required={true}
              checkMarkNotRequired={true}
              type='password'
            />
            <button type='submit' className='btn btn-success w-100 mb-3'>
              {getLanguageValue(languageText, 'Sign in')}
            </button>
            <a className='fw-bold' onClick={setForgotPasswordPage} id='forget-password' href='#'>
              {getLanguageValue(languageText, 'Forgot password')}?
            </a>
          </form>
          {provider && profile && <ExternalUserLogin provider={provider} profile={profile} />}

          <div className='border-top border-secondary-light mt-4'></div>
          <div className='mt-4 fw-bold'>{getLanguageValue(languageText, 'Sign in with')}</div>
          <div className='row mt-3'>
            <div className='col-6'>
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GG_APP_ID || ''}
                redirect_uri={process.env.REDIRECT_URI}
                scope='openid profile email'
                onResolve={({ provider, data }: IResolveParams) => {
                  if (!data || (!data.access_token && !data.code)) {
                    dispatch(setNotification('User cancelled'))
                  } else {
                    setProvider(provider)
                    setProfile(data)
                  }
                }}
                onReject={(error: any) => {
                  dispatch(setNotification('User cancelled'))
                }}
              >
                <button className='btn border border-secondary-subtle p-2 w-100'>
                  <i className='bi bi-google me-2'></i>
                  <span className='fw-bold'>Google</span>
                </button>
              </LoginSocialGoogle>
            </div>

            <div className='col-6'>
              <LoginSocialMicrosoft
                client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ''}
                redirect_uri={process.env.REDIRECT_URI || ''}
                isOnlyGetToken={true}
                scope='profile openid email user.read'
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider)
                  setProfile(data)
                }}
                onReject={(error: any) => {
                  dispatch(setNotification(error))
                  throw error
                }}
              >
                <button className='btn border border-secondary-subtle p-2 w-100'>
                  <i className='bi bi-microsoft me-2'></i>
                  <span className='fw-bold '>Microsoft</span>
                </button>
              </LoginSocialMicrosoft>
            </div>

            {/* <div className='social-content'>
              <LoginSocialLinkedin
                isOnlyGetToken
                client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                redirect_uri={process.env.REDIRECT_URI || ''}
                onResolve={({provider, data}) => {
                  setProvider(provider)
                  setProfile(data)
                }}
                onReject={(error: any) => {
                  dispatch(setNotification(error));
                  throw error;
                }}
              >
                <button className='btn btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light social-btn'>
                  <img className='h-15px mx-1' src={LinkedInLogo} />
                  <span className='social-title fw-semibold'>LinkedIn</span>
                </button>
              </LoginSocialLinkedin>
            </div> */}
            <LoginTermsFooter languageText={languageText} />
          </div>
        </div>
        <></>
      </div>
    </>
  )
}
