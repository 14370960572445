import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../../api'
import { setNotification } from '../../../actions'
import { IProfileInvoiceDetails } from '../../../../components/invoice/invoiceInterface'
import { ActivityId } from '@app/containers/reducer'

export const getProfileInvoiceAddresses = async (
  activityId: ActivityId,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getProfileInvoiceAddresses, {
      params: { activityId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getProfileInvoiceDetails = async (
  activityId: ActivityId,
  dispatch: Dispatch
): Promise<IProfileInvoiceDetails> => {
  try {
    const response = await api.get(apiEndPoints.getProfileInvoiceDetails, {
      params: { activityId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getInvoiceArticlesByProfileOrClientSubscriptionId = async (
  id: number, // ProfileId or ClientSubscriptionId (wtf? why can it be either?)
  isActivity: boolean,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getInvoiceArticlesByProfileOrClientSubscriptionId, {
      params: { id, isActivity },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getFortnoxCustomers = async (dispatch: Dispatch): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getFortnoxCustomers)
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getFortnoxInvoicesByCustomerNumber = async (
  customerNumber: number,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getFortnoxInvoicesByCustomerNumber, {
      params: { customerNumber },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createOrUpdateProfileInvoice = async (
  body: IProfileInvoiceDetails,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createOrUpdateProfileInvoice, JSON.stringify(body))
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const sendActivityInvoiceToFortnox = async (
  activityId: ActivityId,
  idiClientId: number,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.sendProfileInvoiceToFortnox,
      {},
      {
        params: { activityId, idiClientId },
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateFortnoxInvoiceStatus = async (
  idiInvoiceId: number,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.put(
      apiEndPoints.updateFortnoxInvoiceStatus,
      {},
      {
        params: { idiInvoiceId },
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
