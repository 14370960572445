import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PhoneInput from 'react-phone-number-input'

import { CountryCode, E164Number } from 'libphonenumber-js/types'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { getCountryCode, ICountryLocateResponse } from '@app/commonUtils/countryHelper'
import '@app/css/components/custom-phone-input.scss'

interface IPhoneNumberInputProps {
  value: string
  label?: string
  name?: string
  languageText: ITranslationObject
  errorFieldClassName?: string
  placeholder?: string
  phoneNumberError?: boolean
  phoneNumberErrorMsg?: string
  onChange: (event: E164Number) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = (props) => {
  const {
    value,
    label,
    name,
    languageText,
    phoneNumberError,
    phoneNumberErrorMsg,
    errorFieldClassName,
    placeholder,
    onChange,
    onBlur,
  } = props

  const dispatch = useDispatch()
  const [countryCode, setCountryCode] = useState<string>('')

  useEffect(() => {
    getCountryCode(dispatch).then((response: ICountryLocateResponse) => {
      setCountryCode(response ? response.isoCode : '')
    })
  }, [])

  return (
    <div className='mb-3'>
      {label && <label className='mb-1'>{label}</label>}
      <PhoneInput
        defaultCountry={(countryCode as CountryCode) || 'GB'}
        name={name ?? ''}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
        className='form-control'
      />
      {(phoneNumberError || phoneNumberErrorMsg) && (
        <p className={clsx('text-danger', errorFieldClassName)}>
          {phoneNumberErrorMsg
            ? phoneNumberErrorMsg
            : getLanguageValue(languageText, 'Invalid phonenumber')}
        </p>
      )}
    </div>
  )
}
