import React from 'react'

import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { useRolePage } from './hooks'
import ParticipantHeaderComponent from '../participantHeaderComponent'
import RoleSettingsWithEditOption from '../../../profileList/addProfile/roleSettings/roleSettingsWithEditOption'
import Instruction from '@app/components/instruction/instruction'
import { GuidePages } from '@app/containers/commonEnums'

const RolePage: React.FC<unknown> = () => {
  const {
    languageText,
    instructionSteps,
    errorMessage,
    roleCanBeChangedByParticipant,
    participantProfileDetails,
    handleTypeOfRoleSave,
    handleNoOfRespondentsSave,
    goToPrevious,
    onSubmitClick,
  } = useRolePage()

  return (
    <>
      <div className='p-2 pt-4 mt-0 p-md-4'>
        <div className='d-flex justify-content-between pb-3'>
          <ParticipantHeaderComponent currentHeaderId={1} />
          <Instruction
            targetElement='instructionBtn'
            guidePage={GuidePages.Participant_Role}
            instructionSteps={instructionSteps}
            stepsNotToSkip={!roleCanBeChangedByParticipant ? [4] : []}
          />
        </div>
        <h4 className='fw-bold my-4'>{getLanguageValue(languageText, 'Role')}</h4>
        <p className='pb-3'>
          {getLanguageValue(
            languageText,
            'With IDI you get valuable information about how you act in a role that is important to you'
          )}
        </p>

        <div className='rounded shadow-sm bg-white'>
          <div className='p-4'>
            <RoleSettingsWithEditOption
              languageText={languageText}
              profileId={participantProfileDetails.profileId}
              roleId={participantProfileDetails.roleId}
              roleText={participantProfileDetails.roleText}
              noOfRespondents={participantProfileDetails.noOfRespondents}
              isEditable={roleCanBeChangedByParticipant}
              isParticipant={true}
              errorMessage={errorMessage}
              handleTypeOfRoleSave={handleTypeOfRoleSave}
              handleNoOfRespondentsSave={handleNoOfRespondentsSave}
            />
          </div>
          <div className='d-flex justify-content-between bg-success-subtle rounded-bottom p-4'>
            <button className='btn' onClick={goToPrevious}>
              <i className='bi bi-arrow-left me-2'></i>
              {getLanguageValue(languageText, 'Go back')}
            </button>
            <button type='submit' className='btn btn-success btn-lg' onClick={onSubmitClick}>
              {getLanguageValue(languageText, 'Continue')}
              <i className='bi bi-arrow-right ms-2'></i>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default RolePage
