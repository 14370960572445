import React from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IMyProfiles } from '../hooks'
import { GetTypeOfRoleName, ProfileStatus } from '../../../profileList/profileEnums'
import { getFormattedDateOnly } from '../../../../commonUtils/dateFunctionsHelper'
import { routePath } from '../../../routePaths'
import { ProfileId } from '@app/containers/reducer'

interface IMyProfilesProps {
  languageText: ITranslationObject
  profiles: IMyProfiles[]
}

export const MyProfiles = (props: IMyProfilesProps): JSX.Element => {
  const navigate = useNavigate()

  const handleProfileReport = (profileId: ProfileId): void => {
    navigate(routePath.participantProfileReport.replace(':profileId', profileId.toString()))
  }

  /** FIXME: It seems we have ongoing profiles in this array of profiles as well
   * Let's filter them out and sort them in descending order on calcdate (profiledate)
   */

  const sortedDeliveredProfiles = props.profiles
    .filter((profile) => profile.status === ProfileStatus.Delivery)
    .sort((a, b) => new Date(b.calculatedDate).getTime() - new Date(a.calculatedDate).getTime())

  return (
    <>
      {sortedDeliveredProfiles.length > 0 ? (
        <div>
          {sortedDeliveredProfiles.map((profile, index) => {
            const number = sortedDeliveredProfiles.length - index
            const data = getFormattedDateOnly(profile.calculatedDate)

            return (
              <div
                key={number}
                className={clsx('d-flex rounded mb-3 align-items-center', 'bg-light')}
              >
                <div className='py-4 px-4 border-end fs-4 text-secondary'>
                  <div className='py-2 my-2'>{number}</div>
                </div>
                <div className='w-100 p-3 ps-4'>
                  <div
                    className={clsx('fs-5  mb-2', 'fw-bold')}
                    role='button'
                    onClick={() => handleProfileReport(profile.profileId)}
                  >
                    {profile.title}
                  </div>
                  <div className='d-flex flex-wrap gap-2'>
                    <div className='p-1 px-2 rounded bg-dark bg-opacity-10 text-secondary opacity-75'>
                      {data}
                    </div>
                    <div className='p-1 px-2 rounded bg-dark bg-opacity-10 text-secondary opacity-75'>
                      {getLanguageValue(props.languageText, 'ID')} {profile.profileId}
                    </div>
                    <div className='p-1 px-2 rounded bg-success-subtle text-success fw-bold min-vw-50'>
                      {profile.noOfRespondents}{' '}
                      {profile.roleId
                        ? getLanguageValue(props.languageText, GetTypeOfRoleName[profile.roleId])
                        : profile.roleText
                          ? profile.roleText
                          : getLanguageValue(props.languageText, 'Respondents')}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className='color-666 fs-20px fw-bold p-7'>
          {getLanguageValue(props.languageText, 'Profile not yet created')}!
        </div>
      )}
    </>
  )
}
