import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { getMyIDIProfiles } from './actions'
import { IProfilesDeliveredReport } from '../participantDashboard/interface'
import { setSpinner } from '../../actions'
import { IGroupReportAdaptabilities } from '../../profileList/editProfile/interface'
import { ProfileStatus } from '@app/containers/profileList/profileEnums'
import { IProfileDetails } from '../reducer'
import { ActivityId, ProfileId } from '@app/containers/reducer'

/** @see IParticipantProfileDetails */
export interface IMyProfiles {
  activityId: ActivityId
  profileId: ProfileId
  title: string
  noOfRespondents: number
  roleId: number
  roleText: string
  profileDate: string
  calculatedDate: string
  status: ProfileStatus
  isDemographicsFilled: boolean
  isRoleFilled: boolean
  isSelfFormFilled: boolean
  isRespondentsInvited: boolean
  presentationId: number
  uniqueCode: string
}

export interface IMyIDIProfiles {
  profiles: IMyProfiles[]
  profilesDeliveredReport: IProfilesDeliveredReport[]
  profilesOtherFormsAdaptabilites: IGroupReportAdaptabilities[]
}

export const useMyIDIProfiles = () => {
  const dispatch = useDispatch()

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)

  const [myProfilesDetails, setMyProfilesDetails] = useState<IMyIDIProfiles>({
    profiles: [],
    profilesDeliveredReport: [],
    profilesOtherFormsAdaptabilites: [],
  })

  useEffect(() => {
    if (userLanguage.userLanguageCode) {
      dispatch(setSpinner(true))
      getMyIDIProfiles(userLanguage.userLanguageCode, dispatch)
        .then((response) => {
          if (response) {
            const updatedProfileDetails: IMyIDIProfiles = {
              profiles: response.profiles ?? [],
              profilesDeliveredReport: response.profilesDeliveredReport ?? [],
              profilesOtherFormsAdaptabilites: response.profilesOtherFormsAdaptabilites ?? [],
            }
            setMyProfilesDetails(updatedProfileDetails)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }, [userLanguage.userLanguageCode])

  return {
    languageText,
    myProfilesDetails,
  }
}
