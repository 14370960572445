import React, { useEffect, useState } from 'react'

import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import TextArea from '../formComponents/textArea'
import { ModalComponent } from './modalComponent'

interface ITextAreaModalProps {
  languageText: ITranslationObject
  headerText: string
  value: string
  handleSaveClick: (value: string) => void
  handleCloseModal: () => void
}

export const TextAreaModal = (props: ITextAreaModalProps) => {
  const { languageText, headerText, value, handleCloseModal, handleSaveClick } = props

  const [updatedValue, setUpdatedValue] = useState<string>('')

  useEffect(() => {
    setUpdatedValue(value)
  }, [value])

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setUpdatedValue(e.target.value)
  }

  const onSaveClick = (): void => {
    handleSaveClick(updatedValue)
    handleCloseModal()
  }

  return (
    <>
      <ModalComponent
        headerText={headerText}
        submitButtonText={getLanguageValue(languageText, 'Save')}
        cancelButtonText={getLanguageValue(languageText, 'Discard')}
        handleSubmitClick={onSaveClick}
        handleCancelClick={handleCloseModal}
      >
        <TextArea name='template' value={updatedValue} onChange={handleTextAreaChange} />
      </ModalComponent>
    </>
  )
}
