import React from 'react'

import { useWelcomePage } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'

const WelcomePage = (): JSX.Element => {
  const { languageText, participantProfileDetails, handleNextClick } = useWelcomePage()

  return (
    <>
      <div className='p-2 pt-4 p-md-4'>
        <h4 className='fw-bold'>
          {`${getLanguageValue(languageText, 'Hi')} ${participantProfileDetails.participantName}, 
            ${getLanguageValue(languageText, 'Welcome to IDI')}`}
        </h4>
        <div className='shadow-sm rounded bg-white mt-4'>
          <div className='p-4'>
            <div className='container'>
              <div className='d-flex flex-column flex-md-row'>
                <div className='col-12 col-md-6 order-2 order-md-1'>
                  <h3 className='fw-bold mt-2 d-none d-md-block'>
                    {getLanguageValue(languageText, 'A Specific Starting Point')}{' '}
                  </h3>
                  <div className='fs-5 mt-3 mt-xl-4'>
                    {getLanguageValue(
                      languageText,
                      /* NOTE: this is a long text block with a shortened key. */
                      'Developing soft skills'
                    )}
                  </div>
                  <div className='fs-5 mt-5'>
                    <p>{getLanguageValue(languageText, 'Step1')}.</p>
                    <p>{getLanguageValue(languageText, 'Step2')}.</p>
                    <p>{getLanguageValue(languageText, 'Step3')}.</p>
                    <p>{getLanguageValue(languageText, 'Start your journey')}</p>
                  </div>
                </div>
                <div className='col-12 col-md-6 mt-2 order-1 order-md-2 align-content-center text-center'>
                  <img src='/DeliveryImages/startpoint-1.webp' className='img-fluid w-75' />
                </div>
              </div>
            </div>
          </div>
          <div className='text-end bg-success-subtle rounded-bottom p-4'>
            <button type='submit' className='btn btn-success btn-lg' onClick={handleNextClick}>
              {getLanguageValue(languageText, 'Continue')}
              <i className='bi bi-arrow-right ms-2'></i>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomePage
