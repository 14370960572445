import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { getParticipantDashboardInfo } from './actions'
import { IDashboardDetails, IProfileStatus } from './interface'
import { setSpinner } from '../../actions'
import { ProfileStatus } from '../../profileList/profileEnums'
import { ActivityId, ProfileId } from '@app/containers/reducer'

export const useParticipantDashboard = () => {
  const dispatch = useDispatch()

  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initialProfileParticipantsStatusState: IProfileStatus = {
    activityId: 0 as ActivityId,
    profileId: 0 as ProfileId,
    profileName: '',
    profileDate: '',
    status: ProfileStatus.Unknown,
    isDemographicsFilled: false,
    isRoleFilled: false,
    isSelfFormFilled: false,
    isRespondentsInvited: false,
    isPresentationCreated: false,
    presentationId: 0,
    presentationName: '',
    facilitatorName: '',
    uniqueCode: '',
    presentationDate: '',
    presentationStatus: 0,
    presentationTemplateId: 0,
  }
  const initialDashboardDetailsState: IDashboardDetails = {
    profileStatus: initialProfileParticipantsStatusState,
    profileDeliveredReports: [],
    profileTimelines: [],
    profileCourseStudies: [],
  }
  const [dashboardDetails, setDashboardDetails] = useState<IDashboardDetails>(
    initialDashboardDetailsState
  )

  useEffect(() => {
    if (userId) {
      dispatch(setSpinner(true))
      getParticipantDashboardInfo(dispatch)
        .then((response: IDashboardDetails) => {
          if (response) {
            const updatedDashboardDetails: IDashboardDetails = {
              profileStatus: response.profileStatus ?? [],
              profileTimelines: response.profileTimelines ?? [],
              profileDeliveredReports: response.profileDeliveredReports ?? [],
              profileCourseStudies: response.profileCourseStudies ?? [],
            }
            if (
              updatedDashboardDetails.profileStatus.status === ProfileStatus.Completed ||
              updatedDashboardDetails.profileStatus.status === ProfileStatus.Delivery
            ) {
              updatedDashboardDetails.profileStatus.isRoleFilled = true
              updatedDashboardDetails.profileStatus.isSelfFormFilled = true
              updatedDashboardDetails.profileStatus.isRespondentsInvited = true
            }
            if (updatedDashboardDetails.profileStatus.status === ProfileStatus.Delivery) {
              updatedDashboardDetails.profileStatus.isPresentationCreated = true
            }
            setDashboardDetails(updatedDashboardDetails)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }, [userId])

  return {
    languageText,
    dashboardDetails,
  }
}
