import React from 'react'

import FacilitatorDashboardAccountSection from './facilitatorDashboardComponents/accountSection'
import FacilitatorDashboardProfileSection from './facilitatorDashboardComponents/profileSection'
import FacilitatorDashboardAcademySection from './facilitatorDashboardComponents/academySection'
import FacilitatorDashboardNewsSection from './facilitatorDashboardComponents/newsSection'
import FacilitatorDashboardOrganisationView from './facilitatorDashboardComponents/organisationView'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { useFacilitatorDashboard } from './hooks'

const FacilitatorDashboard = (): JSX.Element => {
  const { languageText, defaultClient, dashboardDetails } = useFacilitatorDashboard()

  return (
    <>
      <div className='mt-3 p-2 p-md-4'>
        <h3 className='fw-bold mb-4'>
          {getLanguageValue(languageText, 'Account Status')} - {defaultClient.defaultClientName}
        </h3>

        <div className='row'>
          <FacilitatorDashboardAccountSection
            languageText={languageText}
            account={dashboardDetails.account}
          />

          <FacilitatorDashboardProfileSection
            languageText={languageText}
            latestProfile={dashboardDetails.latestProfile}
          />

          <FacilitatorDashboardAcademySection
            languageText={languageText}
            academy={dashboardDetails.categoryCourses}
          />
        </div>

        <div className='row mt-4'>
          <FacilitatorDashboardNewsSection
            languageText={languageText}
            news={dashboardDetails.news}
          />

          <FacilitatorDashboardOrganisationView
            languageText={languageText}
            organizationView={dashboardDetails.organizationView}
          />
        </div>
      </div>
    </>
  )
}

export default FacilitatorDashboard
