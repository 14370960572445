import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../../../commonUtils/languageFunctionsHelper'
import { getParticipantProfileReport } from '../../../../actions'
import { IParticipantProfileReport } from '../../../../interface'
import IndividualPreview from './individualPreview'
import { setSpinner } from '../../../../../../actions'
import { emptyParticipantProfileReport } from '../../../../../../utils'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { ProfileId } from '@app/containers/reducer'

interface IPreviewModalProps {
  languageText: ITranslationObject
  profileId: ProfileId
  handleCloseClick: () => void
}

const IndividualPreviewModal = (props: IPreviewModalProps): JSX.Element => {
  const { languageText, profileId, handleCloseClick } = props

  const dispatch = useDispatch()

  const initialReportState: IParticipantProfileReport = emptyParticipantProfileReport()
  const [participantReport, setParticipantReport] =
    useState<IParticipantProfileReport>(initialReportState)

  useEffect(() => {
    dispatch(setSpinner(true))
    getParticipantProfileReport(profileId, dispatch)
      .then((response: IParticipantProfileReport) => {
        if (response) {
          setParticipantReport(response)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }, [])

  return (
    <>
      <ModalComponent
        headerText={`${getLanguageValue(languageText, 'Profile')} - ${participantReport.name}`}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={handleCloseClick}
        width='lg'
      >
        <IndividualPreview languageText={languageText} participantReport={participantReport} />
      </ModalComponent>
    </>
  )
}

export default IndividualPreviewModal
