import React, { useMemo } from 'react'
import clsx from 'clsx'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import Checkbox from '../../../components/formComponents/checkbox'
import { InviteRespondents } from '../profileEnums'

interface IInviteOptions {
  id: number
  name: string
  label: string
}

export interface IInviteRespondents {
  isInviteExternal: boolean
  isInviteColleagues: boolean
  isInviteOtherParticipant: boolean
}

interface IActivityRespondentsStepProps {
  languageText: ITranslationObject
  isGroup: boolean
  inviteRespondents: IInviteRespondents
  showRespondentsSaveBtn?: boolean
  respondentFromEditProfile?: boolean
  handleRespondentsCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSaveClick?: () => void
  respondentErrorMessage?: string
  formName?: string
}

const ActivityRespondentsStep = (props: IActivityRespondentsStepProps) => {
  const {
    languageText,
    isGroup,
    inviteRespondents,
    showRespondentsSaveBtn,
    respondentFromEditProfile,
    handleRespondentsCheckboxChange,
    onSaveClick,
    respondentErrorMessage,
  } = props

  const inviteRespondentsOptions = useMemo(() => {
    const options: IInviteOptions[] = [
      {
        id: 1,
        name: InviteRespondents.isInviteExternal,
        label: getLanguageValue(languageText, 'Participants can invite external respondents'),
      },
      {
        id: 2,
        name: InviteRespondents.isInviteColleagues,
        label: getLanguageValue(
          languageText,
          'Participants can choose other colleagues as respondents'
        ),
      },
      {
        id: 3,
        name: InviteRespondents.isInviteOtherParticipant,
        label: getLanguageValue(
          languageText,
          'Participants can choose other participants as respondents'
        ),
      },
    ]

    return options
  }, [languageText])

  return (
    <>
      <div id='activityRespondentStep'>
        {!respondentFromEditProfile && (
          <div className='fs-5 fw-bold mb-3'>{getLanguageValue(languageText, 'Respondents')}</div>
        )}

        {inviteRespondentsOptions.map((item) => {
          const checked = inviteRespondents[item.name]
          return (
            <div key={item.id}>
              <Checkbox
                className='mb-3'
                inputClass={clsx(!checked && 'bg-white border-secondary')}
                labelClass='ms-2'
                name={item.name}
                label={item.label}
                value={checked}
                handleCheckboxChange={handleRespondentsCheckboxChange}
              />
            </div>
          )
        })}

        {respondentErrorMessage && (
          <div className='invalid-feedback d-inline-block'>{respondentErrorMessage}</div>
        )}

        {showRespondentsSaveBtn && !respondentFromEditProfile && (
          <div className='mt-4'>
            <span
              className='fs-5 fw-bold text-decoration-underline'
              onClick={onSaveClick}
              role='button'
            >
              {getLanguageValue(languageText, 'Save and continue')}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default ActivityRespondentsStep
