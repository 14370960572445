import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import RoleSettings from './roleSettings'
import { getTypeOfRoleButtonList } from '../../../../commonUtils/roleSettingsFunctionsHelper'
import { RootState } from '@app/store/configureStore'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'

export interface IRoleData<TKey> {
  id: TKey
  roleId: number
  roleText: string
  noOfRespondents: number
  respondentsAnswered?: number
}

interface IRoleSettingsModalProps<TKey> {
  languageText: ITranslationObject
  selectedProfileForRole: IRoleData<TKey>
  required: boolean
  handleCancelClick: () => void
  handleSaveClick: (roleData: IRoleData<TKey>) => void
}

export function RoleSettingsModal<TKey extends number>(props: IRoleSettingsModalProps<TKey>) {
  const { languageText, selectedProfileForRole, required, handleCancelClick, handleSaveClick } =
    props

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)

  const [selectedTypeOfRoleBtn, setSelectedTypeOfRoleBtn] = useState<string>('') // TYPE OF ROLE STATES BEGIN
  const [typeOfRoleInput, setTypeOfRoleInput] = useState<string>('')
  const [customTypeOfRole, setCustomTypeOfRole] = useState<string>('')
  const [roleInputSelected, setRoleInputSelected] = useState<boolean>(false)
  const [selectedNoOfRespondentsBtn, setSelectedNoOfRespondentsBtn] = useState<string>('') // NUMBER OF RESPONDENTS STATES BEGIN
  const [noOfRespondentsInput, setNoOfRespondentsInput] = useState<string>('')
  const [customNoOfRespondents, setCustomNoOfRespondents] = useState<string>('')
  const [respondentsInputSelected, setRespondentsInputSelected] = useState<boolean>(false)
  const [respondentsConfirmModal, setRespondentsConfirmModal] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [showEditModeNoOfRespondent, setShowEditModeNoOfRespondent] = useState<boolean>(true)
  const [showEditModeRoleType, setShowEditModeRoleType] = useState<boolean>(true)

  const typeOfRoleButtonList = useMemo(() => getTypeOfRoleButtonList(languageText), [languageText])

  useEffect(() => {
    if (!selectedProfileForRole) return

    const { roleId, roleText, noOfRespondents } = selectedProfileForRole

    if (roleId) {
      const selectedRoleType = typeOfRoleButtonList.find((role) => role.id === roleId)?.name ?? ''
      setSelectedTypeOfRoleBtn(selectedRoleType)
      setShowEditModeRoleType(false)
    } else if (roleText) {
      setTypeOfRoleInput(roleText)
      setCustomTypeOfRole(roleText)
      setRoleInputSelected(true)
      setShowEditModeRoleType(false)
    }
    if (noOfRespondents) {
      if (noOfRespondents >= 3 && noOfRespondents <= 6) {
        setSelectedNoOfRespondentsBtn(String(noOfRespondents))
        setShowEditModeNoOfRespondent(false)
      } else {
        setNoOfRespondentsInput(String(noOfRespondents))
        setCustomNoOfRespondents(String(noOfRespondents))
        setRespondentsInputSelected(true)
        setShowEditModeNoOfRespondent(false)
      }
    }
  }, [])

  const clearError = (): void => setError('')

  // TYPE OF ROLE FUNCTIONS
  const onTypeOfRoleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTypeOfRoleInput(e.target.value)
  }

  const onTypeOfRoleBtnClick = (name: string): void => {
    if (roleInputSelected) setRoleInputSelected(false)
    setSelectedTypeOfRoleBtn(name)
    setShowEditModeRoleType(false)
    if (customTypeOfRole) setCustomTypeOfRole('')
    if (error) clearError()
  }

  const handleTypeOfRoleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target
    if (value) {
      setRoleInputSelected(true)
      setShowEditModeRoleType(false)
    } else {
      if (roleInputSelected) setRoleInputSelected(false)
      setShowEditModeRoleType(true)
    }
    setCustomTypeOfRole(value)
    if (value && selectedTypeOfRoleBtn) setSelectedTypeOfRoleBtn('')
    if (error) clearError()
  }

  // NUMBER OF RESPONDENTS FUNCTIONS
  const onNoOfRespondentsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNoOfRespondentsInput(e.target.value)
    if (error) clearError()
  }

  const onNoOfRespondentsClick = (name: string): void => {
    if (respondentsInputSelected) setRespondentsInputSelected(false)
    setSelectedNoOfRespondentsBtn(name)
    setShowEditModeNoOfRespondent(false)
    if (customNoOfRespondents) setCustomNoOfRespondents('')
    if (error) clearError()
  }

  const handleCustomNoOfRespondents = (name: string): void => {
    setCustomNoOfRespondents(name)
    if (name && selectedNoOfRespondentsBtn) setSelectedNoOfRespondentsBtn('')
  }

  const handleNoOfRespondentsFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target
    if (value) {
      if (
        Number(value) > 8 &&
        value !== customNoOfRespondents &&
        (isLoggedInRoleAdmin(loggedInUserRole) ||
          (!isLoggedInRoleAdmin(loggedInUserRole) && Number(value) <= 20))
      ) {
        setRespondentsConfirmModal(true)
        setShowEditModeNoOfRespondent(false)
        return
      } else if (value === '3' || value === '4' || value === '5' || value === '6') {
        onNoOfRespondentsClick(value)
        setNoOfRespondentsInput('')
        setShowEditModeNoOfRespondent(false)
        return
      } else if (value === '') {
        setShowEditModeNoOfRespondent(true)
      } else {
        setRespondentsInputSelected(true)
        setShowEditModeNoOfRespondent(false)
      }
    } else {
      if (respondentsInputSelected) setRespondentsInputSelected(false)
      setShowEditModeNoOfRespondent(true)
    }
    handleCustomNoOfRespondents(value)
    if (error) clearError()
  }

  const cancelRespondentsConfirmModal = (): void => {
    setRespondentsConfirmModal(false)
    setNoOfRespondentsInput('')
    setShowEditModeNoOfRespondent(true)
    setRespondentsInputSelected(false)
  }

  const SubmitRespondentsConfirmModal = (): void => {
    setRespondentsConfirmModal(false)
    setRespondentsInputSelected(true)
    handleCustomNoOfRespondents(noOfRespondentsInput)
  }

  const handleValidation = (): boolean => {
    let errorMsg: string = ''
    if (!selectedTypeOfRoleBtn && !customTypeOfRole) {
      errorMsg = 'Select role type'
    } else if (!selectedNoOfRespondentsBtn && !customNoOfRespondents) {
      errorMsg = 'Select no of respondents'
    }

    setError(getLanguageValue(languageText, errorMsg))
    return errorMsg ? false : true
  }

  const onSaveClick = (): void => {
    if (required) {
      if (!handleValidation()) return
    }
    if (
      !isLoggedInRoleAdmin(loggedInUserRole) &&
      customNoOfRespondents &&
      Number(customNoOfRespondents) < 3
    ) {
      setError(getLanguageValue(languageText, 'Minimum 3 respondents should be selected'))
      return
    }
    if (
      !isLoggedInRoleAdmin(loggedInUserRole) &&
      customNoOfRespondents &&
      Number(customNoOfRespondents) > 20
    ) {
      setError(getLanguageValue(languageText, 'Maximum 20 respondents can be selected'))
      return
    }

    const roleData: IRoleData<TKey> = {
      id: selectedProfileForRole.id,
      roleId: Number(selectedTypeOfRoleBtn),
      roleText: customTypeOfRole,
      noOfRespondents: customNoOfRespondents
        ? Number(customNoOfRespondents)
        : Number(selectedNoOfRespondentsBtn),
    }
    handleSaveClick(roleData)
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Role settings')}
        width='lg'
        submitButtonText={getLanguageValue(languageText, 'Save')}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        handleSubmitClick={onSaveClick}
        handleCancelClick={handleCancelClick}
      >
        <RoleSettings
          languageText={languageText}
          selectedTypeOfRoleBtn={selectedTypeOfRoleBtn}
          typeOfRoleButtonList={typeOfRoleButtonList}
          roleInputSelected={roleInputSelected}
          typeOfRoleInput={typeOfRoleInput}
          selectedNoOfRespondentsBtn={selectedNoOfRespondentsBtn}
          respondentsInputSelected={respondentsInputSelected}
          noOfRespondentsInput={noOfRespondentsInput}
          respondentsConfirmModal={respondentsConfirmModal}
          onTypeOfRoleBtnClick={onTypeOfRoleBtnClick}
          onTypeOfRoleChange={onTypeOfRoleChange}
          handleTypeOfRoleFocus={handleTypeOfRoleFocus}
          onNoOfRespondentsClick={onNoOfRespondentsClick}
          onNoOfRespondentsChange={onNoOfRespondentsChange}
          handleNoOfRespondentsFocus={handleNoOfRespondentsFocus}
          SubmitRespondentsConfirmModal={SubmitRespondentsConfirmModal}
          cancelRespondentsConfirmModal={cancelRespondentsConfirmModal}
          setShowEditModeNoOfRespondent={setShowEditModeNoOfRespondent}
          showEditModeNoOfRespondent={showEditModeNoOfRespondent}
          showEditModeRoleType={showEditModeRoleType}
          setShowEditModeRoleType={setShowEditModeRoleType}
        />
        {/* Error Message */}
        {error && <div className='invalid-feedback d-inline-block'>{error}</div>}
      </ModalComponent>
    </>
  )
}
