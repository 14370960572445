import clsx from 'clsx'
import React from 'react'
import { IFocusError } from './input'
import { IDropdownList } from './dropdownSelect'
import { classNames } from '@app/containers/utils'

interface IDropdownProps {
  label?: string
  value: string
  name?: string
  required?: boolean
  list: IDropdownList[]
  defaultLabel?: string
  focusInput?: IFocusError
  handleDropdownSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void

  /** Removes the 'mb-3' margin on the container. */
  narrow?: boolean
}

/**
 * TODO: rename this to &lt;Select /&gt;
 */
const Dropdown = (props: IDropdownProps) => {
  const { label, value, name, required, list, defaultLabel, focusInput, handleDropdownSelect } =
    props

  const clazz = classNames({
    'mb-3': !props.narrow,
  })

  return (
    <div className={clazz}>
      {label ? <label className={clsx('fs-6 mb-1', { required: required })}>{label}</label> : ''}
      <select
        className='form-select form-select-solid'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        value={value}
        name={name}
        onChange={handleDropdownSelect}
      >
        {defaultLabel && <option label={defaultLabel}></option>}

        {list.map((item, idx: number) => (
          <option key={idx} id={String(item.id)} value={item.value}>
            {item.displayName}
          </option>
        ))}
      </select>

      {focusInput?.errorMessage && (
        <div className='invalid-feedback d-inline-block'>{focusInput.errorMessage}</div>
      )}
    </div>
  )
}

export default Dropdown
