import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

import { getFormattedDateOnly } from '../../../commonUtils/dateFunctionsHelper'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import ReactDatePicker from '../../../components/datePicker/ReactDatePicker'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import Input, { IFocusError } from '../../../components/formComponents/input'
import { getAllCountries } from '../../clientList/addEditModal/action'
import { getFacilitatorCultures } from '../../languageList/actions'
import { DescriptionInputs, IndividualGroupBtnsEnum } from '../profileEnums'
import { updateProfileCulture } from './actions'
import { addToast, setSpinner } from '../../actions'
import { ICountry, ICulture } from '@app/containers/commonInterfaces'
import { ActivityId } from '@app/containers/reducer'

export interface ICreateProfileInfo {
  name: string
  completionDate: string
  clientId: number
  idiLanguageId: number
  countryId: number
  activityType: boolean
  notes: string
}

export interface IUpdateProfileInfo {
  id: number
  name: string
  completionDate: string
  idiLanguageId: number
  countryId: number
  notes: string
}

interface ICultureOrCountryInfo {
  id: number
  name: string
}

interface IDescriptionInfo {
  description: string
  completionDate: Date | null
  culture: ICultureOrCountryInfo
  country: ICultureOrCountryInfo
  notes: string
}

interface IDescriptionError {
  description: IFocusError
  completionDate: IFocusError
  culture: IFocusError
  country: IFocusError
}

interface IActivityDescriptionStepProps {
  languageText: ITranslationObject
  createdActivityId: ActivityId
  clientId: number
  selectedIndividualGroupBtn: string
  showDescriptionSaveBtn: boolean
  setShowDescriptionSaveBtn: React.Dispatch<React.SetStateAction<boolean>>
  onSaveClick: (
    createProfileInfo: ICreateProfileInfo | IUpdateProfileInfo,
    selectedCulture: string
  ) => void
}

const ActivityDescriptionStep = (props: IActivityDescriptionStepProps): JSX.Element => {
  const {
    languageText,
    createdActivityId,
    selectedIndividualGroupBtn,
    clientId,
    showDescriptionSaveBtn,
    setShowDescriptionSaveBtn,
    onSaveClick,
  } = props

  const dispatch = useDispatch()

  const [descriptionInfo, setDescriptionInfo] = useState<IDescriptionInfo>({
    description: '',
    completionDate: null,
    culture: {
      id: 0,
      name: '',
    },
    country: {
      id: 0,
      name: '',
    },
    notes: '',
  })
  const [descriptionError, setDescriptionError] = useState<IDescriptionError>({
    description: {
      touched: false,
      errorMessage: '',
    },
    completionDate: {
      touched: false,
      errorMessage: '',
    },
    culture: {
      touched: false,
      errorMessage: '',
    },
    country: {
      touched: false,
      errorMessage: '',
    },
  })
  const [countries, setCountries] = useState<IDropdownList[]>([])
  const [cultures, setCultures] = useState<IDropdownList[]>([])
  const [minDate, setMinDate] = useState<Date>(new Date())

  const getCountries = async () => {
    await getAllCountries(dispatch).then((response: ICountry[]) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: item.name,
        }))
        setCountries(countries)
      }
    })
  }

  const getCultures = async () => {
    await getFacilitatorCultures(dispatch).then((response: ICulture[]) => {
      if (response?.length > 0) {
        const cultures: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.displayName,
          value: item.name,
        }))
        setCultures(cultures)
      }
    })
  }

  useEffect(() => {
    getCountries()
    getCultures()

    // Completion date should not be possible to select upto 2 weeks from now
    const today = new Date()
    const futureDate = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000)
    setMinDate(futureDate)
  }, [])

  const showSaveButton = (): void => {
    if (!showDescriptionSaveBtn) {
      setShowDescriptionSaveBtn(true)
    }
  }

  const handleFormErrors = (name: string, value: string | ICultureOrCountryInfo): void => {
    let errorMessage: string = ''

    switch (name) {
      case DescriptionInputs.description:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Description is required')
        }
        break
      case DescriptionInputs.completionDate:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Completion Date is required')
        }
        break
      case DescriptionInputs.culture:
        if (!value['name']) {
          errorMessage = getLanguageValue(languageText, 'Culture is required')
        }
        break
      case DescriptionInputs.country:
        if (!value['name']) {
          errorMessage = getLanguageValue(languageText, 'Country is required')
        }
        break
      default:
        break
    }

    setDescriptionError((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setDescriptionInfo({
      ...descriptionInfo,
      [name]: value,
    })

    if (descriptionError[name]?.touched) {
      handleFormErrors(name, value)
    }
    showSaveButton()
  }

  const handleDateSelect = (name: string, date: Date): void => {
    setDescriptionInfo({
      ...descriptionInfo,
      [name]: date,
    })

    if (descriptionError[name]?.touched) {
      handleFormErrors(name, String(date))
    }
    showSaveButton()
  }

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { id, name, value } = selectedItem

    setDescriptionInfo({
      ...descriptionInfo,
      [name]: {
        id: Number(id),
        name: value,
      },
    })

    if (descriptionError[name]?.touched) {
      const updatedValue: ICultureOrCountryInfo = {
        id: Number(id),
        name: value,
      }
      handleFormErrors(name, updatedValue)
    }
    showSaveButton()
  }

  const handleValidationOnSecondStep = (): boolean => {
    if (
      !descriptionInfo.description ||
      !descriptionInfo.completionDate ||
      !descriptionInfo.culture.id ||
      !descriptionInfo.country.id
    ) {
      for (const item in DescriptionInputs) {
        handleFormErrors(item, descriptionInfo[item])
      }
      return false
    }
    return true
  }

  const handleSaveClick = async (): Promise<void> => {
    if (!handleValidationOnSecondStep()) return
    const completionDate = getFormattedDateOnly(descriptionInfo.completionDate!)

    if (!createdActivityId) {
      const createProfileInfo: ICreateProfileInfo = {
        name: descriptionInfo.description,
        completionDate: completionDate,
        clientId: clientId,
        idiLanguageId: descriptionInfo.culture.id,
        countryId: descriptionInfo.country.id,
        activityType: selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group ? true : false,
        notes: descriptionInfo.notes,
      }
      onSaveClick(createProfileInfo, descriptionInfo.culture.name)
    } else {
      // If already profile is created then update
      dispatch(setSpinner(true))
      const updatedProfileInfo: IUpdateProfileInfo = {
        id: createdActivityId,
        name: descriptionInfo.description,
        completionDate: completionDate,
        idiLanguageId: descriptionInfo.culture.id,
        countryId: descriptionInfo.country.id,
        notes: descriptionInfo.notes,
      }

      updateProfileCulture(updatedProfileInfo, dispatch)
        .then((response) => {
          if (response) {
            dispatch(addToast('Profile updated successfully') as AnyAction)
            onSaveClick(updatedProfileInfo, descriptionInfo.culture.name)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  return (
    <>
      <div className='rounded shadow-sm bg-white p-4 mb-4'>
        <div className='fs-5 fw-bold mb-3'>{getLanguageValue(languageText, 'Information')}</div>

        <div className='row'>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <Input
              name={DescriptionInputs.description}
              label={getLanguageValue(languageText, 'Description')}
              placeholder={getLanguageValue(languageText, 'Description')}
              value={descriptionInfo.description}
              focusInput={descriptionError.description}
              handleInputChange={handleInputChange}
              required
            />
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12'>
            <ReactDatePicker
              label={getLanguageValue(languageText, 'Completion date')}
              name={DescriptionInputs.completionDate}
              placeholder={getLanguageValue(languageText, 'Select Completion Date')}
              date={descriptionInfo.completionDate}
              handleDateSelect={handleDateSelect}
              focusInput={descriptionError.completionDate}
              minDate={minDate}
              required
            />
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <DropdownSelect
              name={DescriptionInputs.culture}
              label={getLanguageValue(languageText, 'Culture Norm')}
              defaultLabel={getLanguageValue(languageText, 'Select Culture')}
              list={cultures}
              value={descriptionInfo.culture.name}
              focusInput={descriptionError.culture}
              handleDropdownSelect={handleDropdownSelect}
              searchOption
              required
            />
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <DropdownSelect
              name={DescriptionInputs.country}
              label={getLanguageValue(languageText, 'Country')}
              defaultLabel={getLanguageValue(languageText, 'Select Country')}
              list={countries}
              value={descriptionInfo.country.name}
              focusInput={descriptionError.country}
              handleDropdownSelect={handleDropdownSelect}
              searchOption
              required
            />
          </div>
        </div>
        <div className='col-12 mt-2'>
          <Input
            name={DescriptionInputs.notes}
            label={getLanguageValue(languageText, 'Notes')}
            placeholder={getLanguageValue(languageText, 'Notes')}
            value={descriptionInfo.notes}
            handleInputChange={handleInputChange}
          />
        </div>
        {showDescriptionSaveBtn && (
          <div className='mt-4'>
            <span
              className='fs-5 fw-bold text-decoration-underline'
              onClick={handleSaveClick}
              role='button'
            >
              {getLanguageValue(languageText, 'Save and continue')}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default ActivityDescriptionStep
