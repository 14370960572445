import React from 'react'

import EditProfileDescriptionStep from './descriptionStep/editProfileDescriptionStep'
import EditProfileRoleStep from './roleStep/editProfileRoleStep'
import EditProfileRespondentStep from './respondentStep/editProfileRespondentStep'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { IRoleStepData } from '../../addProfile/activityRoleStep'
import { IEditActivity } from '../interface'
import { ITranslationObject } from '../../../../commonUtils/languageFunctionsHelper'
import { GuidePages } from '@app/containers/commonEnums'
import { ActivityId } from '@app/containers/reducer'

interface IEditProfileFirstStepProps {
  id: ActivityId
  languageText: ITranslationObject
  clientIdFromAccount: number
  profileData: IEditActivity
  cultures: IDropdownList[]
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
  handleProfileCancel: () => void
  onBillingButtonClick: () => void
  handleInstructionHelpClick: (guidePage: GuidePages) => void
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void
  handleClientSelect: (clientId: number, clientName: string, isFacilitatorRemoved: boolean) => void
  handleLanguageSelect: (languageId: number) => void
  handleRoleChanges: (roleSettings: IRoleStepData) => void
  refetchFullProfile: () => void
}

const EditProfileFirstStep = (props: IEditProfileFirstStepProps) => {
  const {
    id,
    languageText,
    clientIdFromAccount,
    profileData,
    cultures,
    isActivityCancelled,
    isActivityInvoiced,
    handleProfileCancel,
    onBillingButtonClick,
    handleInstructionHelpClick,
    handleExitGuide,
    handleClientSelect,
    handleLanguageSelect,
    handleRoleChanges,
    refetchFullProfile,
  } = props

  return (
    <>
      <EditProfileDescriptionStep
        id={id}
        languageText={languageText}
        clientIdFromAccount={clientIdFromAccount}
        profileData={profileData}
        cultures={cultures}
        isActivityCancelled={isActivityCancelled}
        isActivityInvoiced={isActivityInvoiced}
        handleProfileCancel={handleProfileCancel}
        onBillingButtonClick={onBillingButtonClick}
        handleInstructionHelpClick={handleInstructionHelpClick}
        handleExitGuide={handleExitGuide}
        handleClientSelect={handleClientSelect}
        handleLanguageSelect={handleLanguageSelect}
        refetchFullProfile={refetchFullProfile}
      />
      <EditProfileRoleStep
        id={id}
        languageText={languageText}
        profileData={profileData}
        isActivityCancelled={isActivityCancelled}
        isActivityInvoiced={isActivityInvoiced}
        handleRoleChanges={handleRoleChanges}
      />
      <EditProfileRespondentStep
        id={id}
        languageText={languageText}
        profileData={profileData}
        isActivityCancelled={isActivityCancelled}
        isActivityInvoiced={isActivityInvoiced}
      />
    </>
  )
}

export default EditProfileFirstStep
