import React from 'react'
import { Dropdown } from 'react-bootstrap'

import { useParticipantRespondentLanguageNavbar } from './hook'
import { CustomModal } from '../../../components/modals/customModal'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import famfamfam_flags from '../../../../public/DeliveryImages/famfamfam_flags.png'
import logo from '../../../../public/DeliveryImages/IDI_Logotyp.webp'
import Instruction from '@app/components/instruction/instruction'
import { GuidePages } from '@app/containers/commonEnums'

interface IRespondentLanguageNavbarProps {
  languageText: ITranslationObject
  isInstructed?: boolean
  hideLanguageSelection?: boolean
}

const RespondentLanguageNavbar = (props: IRespondentLanguageNavbarProps): JSX.Element => {
  const { languageText, isInstructed, hideLanguageSelection } = props

  const {
    languages,
    instructionSteps,
    selectedLanguage,
    selectedLanguageData,
    handleChangeDropdownLanguage,
    cancelLanguageChange,
    handleChangeLanguage,
  } = useParticipantRespondentLanguageNavbar()

  // TODO: respondents aren't regular users so we don't have any persistent
  //   guide storage for them in the backend. just keep the state in-memory
  //   for now.
  const [didExitGuide, setDidExitGuide] = React.useState(false)

  return (
    <>
      <div className='d-flex justify-content-between gap-2'>
        <img src={logo} className='idi-logo' />
        <div className='d-flex justify-content-center align-items-center'>
          {!hideLanguageSelection && (
            <div className='d-flex justify-content-center align-items-center'>
              <Dropdown>
                <Dropdown.Toggle
                  variant='default'
                  className='d-flex align-items-center justify-content-start p-2'
                >
                  <div className='d-inline-block fs-6 text-truncate fw-normal'>
                    <i className='bi bi-globe fs-6 '></i> {selectedLanguage}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='overflow-auto'>
                  {languages?.map((language) => (
                    <Dropdown.Item
                      key={language.id}
                      onClick={() => handleChangeDropdownLanguage(language)}
                    >
                      <div className='d-flex align-items-center gap-2 mt-1'>
                        <div
                          className={`${language.icon}`}
                          style={{ backgroundImage: `url(${famfamfam_flags})` }}
                        />
                        <span>{language.displayName}</span>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {isInstructed && !didExitGuide ? (
            <Instruction
              targetElement='instruction4'
              guidePage={GuidePages.Respondent_Form}
              instructionSteps={instructionSteps}
              stepsNotToSkip={[5]}
              isRespondent={true}
              handleExitGuide={() => {
                setDidExitGuide(true)
              }}
            />
          ) : null}
        </div>
      </div>

      {selectedLanguageData.id > 0 && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Confirmation message')}
          bodyText={`${getLanguageValue(
            languageText,
            'Filled form data will be cleared. Do you still want to change language'
          )}?`}
          cancelButtonText={getLanguageValue(languageText, 'No')}
          submitButtonText={getLanguageValue(languageText, 'Yes')}
          handleCancelClick={cancelLanguageChange}
          handleSubmitClick={handleChangeLanguage}
        />
      )}
    </>
  )
}

export default RespondentLanguageNavbar
