import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getLanguageValue,
  ITranslationObject,
} from '../../../../commonUtils/languageFunctionsHelper'
import TextArea from '../../../../components/formComponents/textArea'
import { RootState } from '@app/store/configureStore'
import { createSavedMessage, updateSavedMessage } from '../actions'
import { ISavedMessage } from './invitationMessageListModal'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { UserId } from '@app/containers/reducer'

export interface ICreateUpdateMessageBody {
  userId: UserId
  id: number
  message: string
  emailMessageType: boolean
}

interface IInvitationMessageListModalProps {
  languageText: ITranslationObject
  editMessage: ISavedMessage
  isEmail: boolean
  handleClose: (refreshList: boolean) => void
  handleSelect: (item: ISavedMessage) => void
}

export const AddEditMessage: React.FC<IInvitationMessageListModalProps> = (props) => {
  const { languageText, editMessage, isEmail, handleClose, handleSelect } = props

  const dispatch = useDispatch()
  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (editMessage?.message) setValue(editMessage.message)
  }, [])

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setValue(e.target.value)
  }

  const handleCancelClick = (): void => handleClose(false)

  const handleSaveClick = (): void => {
    const body: ICreateUpdateMessageBody = {
      userId: userId,
      id: editMessage.id,
      message: value,
      emailMessageType: isEmail,
    }

    if (body.id) {
      updateSavedMessage(body, dispatch).then((response) => {
        if (response?.status === 200) {
          handleClose(true)
          handleSelect(body)
        }
      })
    } else {
      createSavedMessage(body, dispatch).then((response) => {
        if (response?.status === 200) {
          handleClose(true)
          handleSelect(body)
        }
      })
    }
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(
          languageText,
          editMessage?.message ? 'Edit Message' : 'Add Message'
        )}
        submitButtonText={getLanguageValue(languageText, 'Save')}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        handleSubmitClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
      >
        <TextArea name='template' value={value} onChange={handleTextChange} />
      </ModalComponent>
    </>
  )
}
