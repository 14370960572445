import React, { useEffect, useRef, useState } from 'react'

import { useDownloadReport } from '../hooks'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../../../commonUtils/languageFunctionsHelper'
import { GroupDownloadHeader } from '../../components/groupDownloadHeader'
import { GroupPreview } from '../../preview/groupPreview/groupPreview'
import RadioButton, {
  IRadioButtonList,
} from '../../../../../../../components/formComponents/radioButton'
import { IEditActivity } from '../../../../interface'
import { ReportType, ReportTypes } from '../../../../../../../types'
import { ActivityId, ProfileId } from '@app/containers/reducer'

export enum ReportsEnum {
  Self = 'Self',
  Others = 'Others',
  Both = 'Both',
}

interface IGroupDownloadProps {
  id: ActivityId
  languageText: ITranslationObject
  profileData: IEditActivity
  name: string
  profileIds: Array<ProfileId>
}

export const GroupDownload: React.FC<IGroupDownloadProps> = (props) => {
  const { languageText, profileData, profileIds, name } = props
  const printRef = useRef(null)

  const [reportTitle, setReportTitle] = useState('')
  const [radioOptions, setRadioOptions] = useState<IRadioButtonList[]>([])
  const [showUsers, setShowUsers] = useState(true)
  const [reportType, setReportType] = useState<ReportType>(ReportTypes.OTHER)

  const { generatePdf } = useDownloadReport()

  const reportName = `IDIProfile`

  useEffect(() => {
    setRadioOptions([
      {
        id: 1,
        label: getLanguageValue(languageText, ReportsEnum.Self),
        name: ReportTypes.SELF,
        value: false,
      },
      {
        id: 2,
        label: getLanguageValue(languageText, ReportsEnum.Others),
        name: ReportTypes.OTHER,
        value: true,
      },
      {
        id: 3,
        label: getLanguageValue(languageText, ReportsEnum.Both),
        name: ReportTypes.BOTH,
        value: false,
      },
    ])
  }, [])

  const handleRadioChange = (updatedOptions: IRadioButtonList[], name: string): void => {
    setRadioOptions(updatedOptions)

    switch (name) {
      case ReportTypes.SELF:
        setReportType(ReportTypes.SELF)
        break
      case ReportTypes.OTHER:
        setReportType(ReportTypes.OTHER)
        break
      case ReportTypes.BOTH:
        setReportType(ReportTypes.BOTH)
        break
      default:
        break
    }
  }

  const handleInputChange = (e) => {
    setReportTitle(e.target.value)
  }

  const zValue = 1030 //this value is higher than the z value that "sticky-top" class has

  return (
    <>
      <div
        style={{
          width: '1400px',
          position: 'absolute',
          top: '-9999px',
          zIndex: zValue,
        }}
      >
        <div ref={printRef} className='p-3'>
          <div className='mx-3 my-3 p-3'>
            <GroupDownloadHeader />
            <div className='mt-10'>
              <div className='mb-4 fs-1 fw-bolder'>
                {reportTitle
                  ? reportTitle
                  : `${getLanguageValue(languageText, 'Group Profile')} - ${
                      reportType === ReportTypes.SELF
                        ? getLanguageValue(languageText, 'Self')
                        : getLanguageValue(languageText, 'Other')
                    } - ${name}`}
              </div>
              <div style={{ height: `${1450}px` }}>
                <GroupPreview
                  id={props.id}
                  reportType={reportType}
                  languageText={languageText}
                  profileData={profileData}
                  showUsers={showUsers}
                  selectedProfileIds={profileIds}
                  messageTranslation={languageText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center flex-wrap my-4 gap-3 mb-5'>
        <RadioButton
          wrapperClass='d-flex justify-content-start gap-4'
          options={radioOptions}
          radioButtonChangeCallback={handleRadioChange}
        />
        <button
          className={`btn ${showUsers ? 'btn-danger' : 'btn-success'}`}
          onClick={() => setShowUsers(!showUsers)}
        >
          {showUsers
            ? getLanguageValue(languageText, 'Hide Users')
            : getLanguageValue(languageText, 'Show Users')}
        </button>
        <input
          type='text'
          className='form-control form-control-solid w-25 ps-3'
          placeholder={getLanguageValue(languageText, 'Report title')}
          value={reportTitle}
          onChange={handleInputChange}
        />
        <button
          onClick={() => generatePdf(`${reportName}`, [printRef])}
          className='btn btn-success'
        >
          {getLanguageValue(languageText, 'Download Pdf')}
        </button>
      </div>
      <div className='p-4'>
        <GroupPreview
          id={props.id}
          languageText={languageText}
          profileData={profileData}
          reportType={reportType}
          showUsers={showUsers}
          selectedProfileIds={profileIds}
          messageTranslation={languageText}
        />
      </div>
    </>
  )
}
