import React from 'react'
import clsx from 'clsx'

import AutocompleteInput from '../../../../components/formComponents/autocompleteInput'
import { AddExistingIcon } from './addExistingIcon'
import FileUpload from '../../../../components/formComponents/fileUpload'
import Input from '../../../../components/formComponents/input'
import TextArea from '../../../../components/formComponents/textArea'
import TextEditor from '../../../../components/textEditor/textEditor'
import { ToggleCheckbox } from '@app/components/formComponents/toggleCheckbox'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { CourseInputs, ICourseFocusInput, ICourses, ILogo } from '../hooks'

interface IAddEditCourseProps {
  languageText: ITranslationObject
  courseInfo: ICourses
  focusInput: ICourseFocusInput
  languages: IDropdownList[]
  categories: IDropdownList[]
  tagNames: string[]
  isSubmitting: boolean
  imgErrorMessage: string
  isCourseSaved: boolean
  existingIconModal: boolean
  baseUrl: string
  uniqueIcons: ILogo[]
  isEditMode: boolean
  editCourseId: number
  onCloseExistingIconModal: () => void
  onOpenExistingIconModal: () => void
  handleFileUpload: (file: File) => void
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleTextEditorChange: (content: string, name: string) => void
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void
  handleTextAreaBlurEvent: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  handleDropdownSelect: (selectedItem: IDropdownSelectedItem) => void
  handleAutoInputChange: (name: string, value: string) => void
  handleCourseSubmit: () => void
  onSelectIcon: (selectedIcon: string, selectedIconPath: string) => void
  removeIcon: () => void
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const AddEditCourse = (props: IAddEditCourseProps): JSX.Element => {
  // why are we unpacking everything? what's the benefit?
  // we're just spamming the exact same garbage everywhere.
  // the app is like 50% unpacking and prop declarations... bruh.
  const {
    languageText,
    courseInfo,
    focusInput,
    languages,
    categories,
    tagNames,
    isSubmitting,
    imgErrorMessage,
    isCourseSaved,
    existingIconModal,
    uniqueIcons,
    isEditMode,
    editCourseId,
    handleFileUpload,
    handleInputChange,
    handleBlurEvent,
    handleTextAreaChange,
    handleTextEditorChange,
    handleTextAreaBlurEvent,
    handleDropdownSelect,
    handleAutoInputChange,
    handleCheckboxChange,
    handleCourseSubmit,
    onSelectIcon,
    onCloseExistingIconModal,
    onOpenExistingIconModal,
    removeIcon,
  } = props

  return (
    <>
      <form>
        <div className='row'>
          {/* Title */}
          <div className={clsx(editCourseId ? 'col-md-5 col-12' : 'col-md-7 col-12')}>
            <Input
              name={CourseInputs.title}
              label={getLanguageValue(languageText, 'Title')}
              placeholder={getLanguageValue(languageText, 'Title')}
              value={courseInfo.title}
              focusInput={focusInput.title}
              handleInputChange={handleInputChange}
              handleBlurEvent={handleBlurEvent}
              required
            />
          </div>

          {editCourseId != 0 && (
            <div className='col-md-2 col-6'>
              <Input
                label={getLanguageValue(languageText, 'Duration in sec')}
                disable={true}
                value={String(courseInfo.duration)}
              />
            </div>
          )}

          {/* Key Ideas */}
          <div className='col-md-2 col-6'>
            <Input
              name={CourseInputs.keyIdeas}
              label={getLanguageValue(languageText, 'Key Ideas')}
              placeholder={getLanguageValue(languageText, 'Key Ideas')}
              value={courseInfo.keyIdeas}
              focusInput={focusInput.keyIdeas}
              handleInputChange={handleInputChange}
              handleBlurEvent={handleBlurEvent}
              tooltipText={getLanguageValue(languageText, 'Choose a number between 1-999')}
              required
            />
          </div>

          {/* Language */}
          <div className='col-md-3 col-12'>
            <DropdownSelect
              name={CourseInputs.idiLanguageId}
              label={getLanguageValue(languageText, 'Language')}
              defaultLabel={getLanguageValue(languageText, 'Select language')}
              list={languages}
              value={courseInfo.idiLanguageId}
              handleDropdownSelect={handleDropdownSelect}
              focusInput={focusInput.idiLanguageId}
              required
            />
          </div>
        </div>

        <div className='row'>
          {/* Category */}
          <div className='col-md-4 col-12'>
            <DropdownSelect
              name={CourseInputs.categoryId}
              label={getLanguageValue(languageText, 'Category')}
              defaultLabel={getLanguageValue(languageText, 'Select category')}
              list={categories}
              value={courseInfo.categoryId}
              handleDropdownSelect={handleDropdownSelect}
              focusInput={focusInput.categoryId}
              required
            />
          </div>

          {/* Tags */}
          <div className='col-md-4 col-12'>
            <AutocompleteInput
              name={CourseInputs.tags}
              label={getLanguageValue(languageText, 'Tags')}
              placeholder={getLanguageValue(languageText, 'Add tags')}
              value={courseInfo.tags ? courseInfo.tags.join(',') : ''}
              list={tagNames}
              handleAutoInputChange={handleAutoInputChange}
            />
          </div>
          <div className='col-md-4 col-12'>
            <ToggleCheckbox
              label={getLanguageValue(languageText, 'OnlyForDeliveredProfile')}
              name={CourseInputs.onlyForDeliveredProfile}
              value={courseInfo.onlyForDeliveredProfile}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
        </div>

        <FileUpload
          label={getLanguageValue(languageText, 'Icon')}
          errorMessage={imgErrorMessage.length > 0 ? imgErrorMessage : ''}
          name={CourseInputs.logoFile}
          handleFileUpload={handleFileUpload}
          imagePath={
            (courseInfo.logo && !courseInfo.logo.match(/\.(png|jpe?g|svg)$/i) && courseInfo.logo) ||
            courseInfo.logoFullUrl
          }
          isEditMode={isEditMode}
          removeIconBtnText={getLanguageValue(languageText, 'Remove icon')}
          selectIconBtnText={getLanguageValue(languageText, 'Select existing icon')}
          removeIcon={removeIcon}
          onOpenExistingIconModal={onOpenExistingIconModal}
        />

        {/* Description */}
        <TextArea
          name={CourseInputs.description}
          label={getLanguageValue(languageText, 'Description')}
          placeholder={getLanguageValue(languageText, 'Description')}
          value={courseInfo.description || ''}
          focusInput={focusInput.description}
          onChange={handleTextAreaChange}
          handleBlurEvent={handleTextAreaBlurEvent}
          checkMarkNotRequired={true}
          required
        />

        <div className='grey-bg-darker mx-n7 h-1px' />

        <div className='fs-2 fw-bold mt-7 mb-5'>{getLanguageValue(languageText, 'Summary')}</div>

        {/* Key Message */}
        <TextEditor
          name={CourseInputs.keyMessage}
          label={`${getLanguageValue(languageText, 'The key message in this course')}:`}
          placeholder={getLanguageValue(languageText, 'Key Message')}
          handleTextEditor={handleTextEditorChange}
          focusInput={focusInput.keyMessage}
          value={courseInfo.keyMessage || ''}
          required
        />

        {/* Actionable Advice */}
        <TextEditor
          name={CourseInputs.actionableAdvice}
          label={`${getLanguageValue(languageText, 'Actionable Advice')}:`}
          placeholder={getLanguageValue(languageText, 'Actionable Advice')}
          handleTextEditor={handleTextEditorChange}
          focusInput={focusInput.actionableAdvice}
          value={courseInfo.actionableAdvice || ''}
          required
        />

        {/* Suggested Further Reading */}
        <TextEditor
          name={CourseInputs.suggestedFurtherReading}
          label={`${getLanguageValue(languageText, 'Suggested Further Reading')}:`}
          placeholder={getLanguageValue(languageText, 'Suggested Further Reading')}
          handleTextEditor={handleTextEditorChange}
          focusInput={focusInput.suggestedFurtherReading}
          value={courseInfo.suggestedFurtherReading || ''}
          required
        />

        {/* References */}
        <TextEditor
          name={CourseInputs.references}
          label={`${getLanguageValue(languageText, 'References')}:`}
          placeholder={getLanguageValue(languageText, 'References')}
          handleTextEditor={handleTextEditorChange}
          focusInput={focusInput.references}
          value={courseInfo.references || ''}
          required
        />
      </form>

      <button
        type='submit'
        className='btn btn-success min-w-125px'
        data-kt-users-modal-action='submit'
        onClick={handleCourseSubmit}
        disabled={isCourseSaved || isSubmitting}
      >
        <span className='indicator-label'>{getLanguageValue(languageText, 'Save')}</span>
      </button>

      {existingIconModal && (
        <AddExistingIcon
          languageText={languageText}
          uniqueIcons={uniqueIcons}
          onCloseExistingIconModal={onCloseExistingIconModal}
          onSelectIcon={onSelectIcon}
        />
      )}
    </>
  )
}

export default AddEditCourse
