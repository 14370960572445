import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

import ReorderModal, { IReorderData } from '@app/components/reorderingModel/reorderModal'
import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { IPresentationSlideList } from '../hooks'
import { addToast, setSpinner } from '@app/containers/actions'
import { getPresentationSlides, updatePresentationTemplateSlide } from '../../actions'
import { ICreateOrUpdateSlide } from '../addEditSlideModal/hooks'

interface ISlideReorderModalProps {
  languageText: ITranslationObject
  templateId: number
  refreshSlideList: () => void
  closeReorderModal: () => void
}

const SlideReorderModal = (props: ISlideReorderModalProps) => {
  const { languageText, templateId, refreshSlideList, closeReorderModal } = props

  const dispatch = useDispatch()

  const [slideList, setSlideList] = React.useState<IPresentationSlideList[]>([])

  useEffect(() => {
    dispatch(setSpinner(true))
    getPresentationSlides('', '', 1000, 0, templateId, dispatch)
      .then((response) => {
        if (response) {
          const slideList: IPresentationSlideList[] = response.items
          const updatedSlideList = slideList.sort(
            (a, b) => a.slideNumber - b.slideNumber // Order by slide number
          )
          setSlideList(updatedSlideList)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }, [])

  const handleSaveReorderedSlides = (reorderedData: IReorderData[]): void => {
    dispatch(setSpinner(true))

    const updatePromises = slideList.map((slide) => {
      const updatedSlide = reorderedData.find((data) => data.id === slide.id)

      const body: ICreateOrUpdateSlide = {
        id: slide.id ?? 0,
        presentationTemplateId: templateId,
        slideNumber: updatedSlide?.orderNumber ?? slide.slideNumber,
        partNumber: slide.partNumber,
        description: slide.description,
        html: slide.html,
        rules: slide.rules,
        isActive: slide.isActive,
        dynamicSlide: slide.dynamicSlide,
        notes: slide.notes,
      }

      return updatePresentationTemplateSlide(body, dispatch)
    })

    Promise.all(updatePromises)
      .then((responses) => {
        const allSuccessful = responses.every((response) => response)
        if (allSuccessful) {
          dispatch(addToast('Slides reordered successfully') as AnyAction)
          refreshSlideList()
          closeReorderModal()
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  const slidesToReorder: IReorderData[] = slideList.map((item) => ({
    id: item.id,
    orderNumber: item.slideNumber,
    title: item.description,
  }))

  return (
    <ReorderModal
      languageText={languageText}
      headerText={getLanguageValue(languageText, 'Reorder Slides')}
      dataToReorder={slidesToReorder}
      closeReorderModal={closeReorderModal}
      handleSaveReorder={handleSaveReorderedSlides}
    />
  )
}

export default SlideReorderModal
