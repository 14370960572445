import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { UploadEmployeeModal } from '../../employeeList/uploadEmployeeModal/uploadEmployeeModal'
import { IndividualGroupBtnsEnum, ProfileRoleStatus } from '../profileEnums'
import { IRoleStepData } from './activityRoleStep'
import { RoleSettingsModal, IRoleData } from './roleSettings/roleSettingsModal'
import { getTypeOfRoleButtonList } from '../../../commonUtils/roleSettingsFunctionsHelper'
import { ActivityId, ProfileId, UserId } from '@app/containers/reducer'

export interface IParticipantData {
  id: UserId
  name: string
  surname: string
}

export interface IParticipantRoleData {
  id: UserId
  name: string
  surname: string
  roleId: number
  roleText: string
  noOfRespondents: number
}

export interface IFetchParticipantsParams {
  activityId: ActivityId
  clientId: number
  searchText: string
}

interface IActivityParticipantsStepProps {
  languageText: ITranslationObject
  roleSettings: IRoleStepData
  filter: string
  participantsList: IParticipantData[]
  selectedParticipants: IParticipantRoleData[]
  participantError: string
  showParticipantSaveBtn: boolean
  selectedIndividualGroupBtn: string
  handleSearch: (value: string) => void
  handleSelectParticipant: (participant: IParticipantData) => void
  handleRemoveParticipant: (participant: IParticipantRoleData) => void
  saveRoleSettings: (updatedParticipants: IParticipantRoleData[]) => void
  onSaveClick: () => void
}

const ActivityParticipantsStep = (props: IActivityParticipantsStepProps) => {
  const {
    languageText,
    roleSettings,
    filter,
    participantsList,
    selectedParticipants,
    participantError,
    showParticipantSaveBtn,
    selectedIndividualGroupBtn,
    handleSearch,
    handleSelectParticipant,
    handleRemoveParticipant,
    saveRoleSettings,
    onSaveClick,
  } = props

  const initialParticipantRoleData: IParticipantRoleData = {
    id: 0 as UserId,
    name: '',
    surname: '',
    roleId: 0,
    roleText: '',
    noOfRespondents: 0,
  }
  const [hideParticipantsAndTabs, setHideParticipantsAndTabs] = useState<boolean>(false)
  const [addParticipantModal, setAddParticipantModal] = useState<boolean>(false)
  const [selectedParticipantForRole, setSelectedParticipantForRole] =
    useState<IParticipantRoleData>(initialParticipantRoleData)
  const [roleSettingsModal, setRoleSettingsModal] = useState<boolean>(false)

  useEffect(() => {
    if (
      selectedIndividualGroupBtn === IndividualGroupBtnsEnum.individual &&
      selectedParticipants.length === 1
    ) {
      setHideParticipantsAndTabs(true)
      if (filter) handleSearch('')
    } else {
      setHideParticipantsAndTabs(false)
    }
  }, [selectedParticipants])

  const onSelectParticipant = (participant: IParticipantData): void => {
    handleSelectParticipant(participant)
  }

  const onAddParticipantClick = (): void => {
    setAddParticipantModal(true)
  }

  const closeAddParticipantModal = (): void => {
    setAddParticipantModal(false)
  }

  const setNewlyAddedParticipant = (newParticipants: any) => {
    const newlyAddedParticipants: IParticipantData[] = newParticipants.map(
      (participant: IParticipantData) => ({
        id: participant.id,
        name: participant.name,
        surname: participant.surname,
      })
    )
    newlyAddedParticipants.map((participant: IParticipantData) =>
      handleSelectParticipant(participant)
    )
  }

  const onRoleSettingsClick = (participant: IParticipantRoleData): void => {
    setRoleSettingsModal(true)
    setSelectedParticipantForRole(participant)
  }

  const closeRoleSettingsModal = (): void => {
    setRoleSettingsModal(false)
    setSelectedParticipantForRole(initialParticipantRoleData)
  }

  const onSaveRoleSettings = (roleData: IRoleData<UserId>): void => {
    const updatedParticipants: IParticipantRoleData[] = selectedParticipants.map((item) => {
      // AUDIT: these IDs aren't of the same type... lol.
      if (item.id !== roleData.id) {
        return item // If it's not the object to update, return it unchanged
      }

      // If it's the object to update, create a new object with the updated properties
      return {
        id: item.id,
        name: item.name,
        surname: item.surname,
        roleId: roleData.roleId,
        roleText: roleData.roleText,
        noOfRespondents: roleData.noOfRespondents,
      }
    })
    saveRoleSettings(updatedParticipants)
    closeRoleSettingsModal()
  }

  const renderRoleSettings = (participant: IParticipantRoleData) => {
    const { roleId, roleText, noOfRespondents } = participant

    if (roleId || roleText || noOfRespondents) {
      let updatedRoleText: string = roleText
      let rolesFilled: boolean = false
      if (roleId) {
        updatedRoleText =
          getTypeOfRoleButtonList(languageText).find((role) => role.id === roleId)?.title ?? ''
      }
      if (updatedRoleText && noOfRespondents) rolesFilled = true

      return (
        <div className='d-flex justify-content-end gap-2 min-w-175px'>
          <button
            className={clsx(
              'btn btn-sm',
              rolesFilled ? 'bg-success text-white' : 'bg-white border'
            )}
            onClick={() => onRoleSettingsClick(participant)}
          >
            {(updatedRoleText || noOfRespondents) && updatedRoleText}
            {rolesFilled && ', '}
            {noOfRespondents > 0 && noOfRespondents}
          </button>
          <button
            className='btn btn-danger btn-sm px-3'
            onClick={() => handleRemoveParticipant(participant)}
          >
            <i className='bi bi-trash3 ms-2 me-1'></i>
          </button>
        </div>
      )
    } else {
      return (
        <div className='d-flex justify-content-end gap-2 min-w-175px'>
          <button
            className='btn btn-sm bg-white border fw-bold px-5'
            onClick={() => onRoleSettingsClick(participant)}
          >
            {getLanguageValue(languageText, 'Role setting')}
          </button>
          <button
            className='btn btn-danger btn-sm px-3'
            onClick={() => handleRemoveParticipant(participant)}
          >
            <i className='bi bi-trash3 ms-2 me-1'></i>
          </button>
        </div>
      )
    }
  }

  return (
    <>
      <div id='activityParticipantStep' className='rounded shadow-sm bg-white p-4 mb-4'>
        <div className='fs-5 fw-bold mb-3'>{getLanguageValue(languageText, 'Participants')}</div>

        {!hideParticipantsAndTabs && (
          <>
            <div className='d-flex gap-3'>
              <button className='btn btn-secondary text-white px-4'>
                {' '}
                {getLanguageValue(languageText, 'Select from list')}{' '}
              </button>
              <button className='btn btn-success px-4' onClick={onAddParticipantClick}>
                {' '}
                {getLanguageValue(languageText, 'Add new users')}
              </button>
            </div>

            <div className='p-3 mt-4 bg-secondary rounded bg-opacity-10'>
              {/* Search participant */}
              <div className='d-flex position-relative align-items-center mw-200px mb-4'>
                <i className='bi bi-search position-absolute end-0 me-3'></i>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid p-2 w-100 ps-10 bg-white border grey-border'
                  placeholder='Search...'
                  value={filter}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>

              {!filter && !participantsList.length && (
                <div className='fs-4 mt-3 mb-5'>
                  {getLanguageValue(languageText, 'There are no users under this account')}.
                </div>
              )}

              {filter && !participantsList.length && (
                <div className='fs-4 mt-3 mb-10'>
                  {getLanguageValue(languageText, 'No matching records found')}
                </div>
              )}

              {/* Participant List */}
              {participantsList.length > 0 && (
                <>
                  <div className='overflow-auto' style={{ maxHeight: '250px' }}>
                    {participantsList.map((participant) => (
                      <div key={participant.id} className='d-flex border-top py-2'>
                        <div>
                          <button
                            className='btn btn-success py-0 px-2'
                            onClick={() => onSelectParticipant(participant)}
                          >
                            <i className='bi bi-plus-lg fs-4'></i>
                          </button>
                        </div>
                        <span className='ms-4 fs-5 fw-bold'>
                          {participant.name} {participant.surname}
                        </span>
                      </div>
                    ))}
                  </div>
                  {participantsList.length > 3 && (
                    <div className='text-center my-2 mb-n2'>
                      {getLanguageValue(languageText, 'Scroll to show more')}
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

        {/* Selected Participants  */}
        {selectedParticipants.length > 0 && (
          <>
            <div className='fs-5 mt-4 fw-bold mb-3'>
              {getLanguageValue(languageText, 'Selected participants')}
            </div>
            <div className='p-3 py-2 bg-success rounded bg-opacity-10'>
              {selectedParticipants.map((participant: IParticipantRoleData, index: number) => (
                <div
                  key={participant.id}
                  className={clsx(
                    'd-flex justify-content-between align-items-center flex-wrap py-2',
                    index !== 0 && 'border-top border-secondary-subtle'
                  )}
                >
                  <span className='fs-5 ms-2 fw-bold'>
                    {participant.name} {participant.surname}
                  </span>

                  {selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group &&
                  roleSettings.roleSetBy === Number(ProfileRoleStatus.RoleSetByFacilitator) &&
                  !roleSettings.roleSameForAll ? (
                    renderRoleSettings(participant)
                  ) : (
                    <button
                      className='btn btn-danger'
                      onClick={() => handleRemoveParticipant(participant)}
                    >
                      <i className='bi bi-trash3'></i>
                    </button>
                  )}
                </div>
              ))}
              {roleSettingsModal && (
                <RoleSettingsModal
                  languageText={languageText}
                  selectedProfileForRole={selectedParticipantForRole}
                  required={
                    roleSettings.roleSetBy === Number(ProfileRoleStatus.RoleSetByFacilitator) &&
                    !roleSettings.roleSameForAll &&
                    !roleSettings.roleCanBeChangedByParticipant
                  }
                  handleCancelClick={closeRoleSettingsModal}
                  handleSaveClick={onSaveRoleSettings}
                />
              )}
            </div>
          </>
        )}

        {addParticipantModal && (
          <UploadEmployeeModal
            languageText={languageText}
            uploadFromActivityPage={true}
            isIndividualActivity={selectedIndividualGroupBtn === IndividualGroupBtnsEnum.individual} // Only 1 particpant can be added for individual profile type
            setNewlyAddedEmployees={setNewlyAddedParticipant}
            closeUploadEmployeeModal={closeAddParticipantModal}
          />
        )}

        {/* Error Message */}
        {participantError && (
          <div className='invalid-feedback d-inline-block'>{participantError}</div>
        )}

        {/* Save button */}
        {showParticipantSaveBtn && (
          <div className='mt-4'>
            <span
              className='fs-5 fw-bold text-decoration-underline'
              onClick={onSaveClick}
              role='button'
            >
              {getLanguageValue(languageText, 'Save and continue')}
            </span>{' '}
            ({getLanguageValue(languageText, `You can also add participants later`)}.)
          </div>
        )}
      </div>
    </>
  )
}

export default ActivityParticipantsStep
