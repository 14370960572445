import React from 'react'
import PopoverTooltip from '../tooltip/popoverTooltip'

interface IToggleCheckboxProps {
  label: string
  name?: string
  value?: boolean
  disable?: boolean
  tooltipText?: string
  handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const ToggleCheckbox: React.FC<IToggleCheckboxProps> = (props): JSX.Element => {
  const { label, name, value, disable, tooltipText, handleCheckboxChange } = props

  return (
    <div className='form-check form-switch mb-2' title={tooltipText}>
      <input
        className='form-check-input'
        type='checkbox'
        name={name ?? 'name'}
        role='button'
        onChange={handleCheckboxChange}
        disabled={disable ?? false}
        checked={value}
      />
      <span className='form-check-label'>{label}</span>
      {tooltipText && <PopoverTooltip tooltipText={tooltipText} />}
    </div>
  )
}
