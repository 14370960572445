import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  getLanguageValue,
  ITranslationObject,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { previewParticipantInvitation, sendParticipantsInvitation } from '../actions'
import {
  ISelectedProfilesList,
  ISendParticipantsInvitationBody,
} from '../activityInviteParticipantsStep'
import EmailSMSInvitation from './emailSMSInvitation'
import { ISavedMessage } from './invitationMessageListModal'
import TabNavItem, { ITabNavItem } from '../../../../components/multiTabComponent/tabNavItem'
import TabContent from '../../../../components/multiTabComponent/tabContent'
import {
  getInvitationNavTabs,
  InvitationNavTabs,
} from '../../../../commonUtils/invitationFunctionsHelper'
import { InvitationType } from '../../profileEnums'
import { CustomModal as SMSWarningModal } from '../../../../components/modals/customModal'
import { setSpinner } from '../../../actions'
import { ModalComponent } from '../../../../components/modals/modalComponent'

interface IPersonalizedInvitationModalProps {
  languageText: ITranslationObject
  messageTranslation: ITranslationObject
  languages: IDropdownList[]
  cultureValue: string
  profile: ISelectedProfilesList
  phoneNumber?: string
  latestEmailSentLog?: string
  selectedInvitationTypeBtn?: number
  handleCloseClick: () => void
  handleMessageSentSuccess: (
    isEmail: boolean,
    profile: ISelectedProfilesList,
    emailOrSMSStatus?: number
  ) => void
}

const PersonalizedInvitationModal = (props: IPersonalizedInvitationModalProps) => {
  const {
    languageText,
    messageTranslation,
    languages,
    cultureValue,
    profile,
    phoneNumber,
    latestEmailSentLog,
    selectedInvitationTypeBtn,
    handleCloseClick,
    handleMessageSentSuccess,
  } = props

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState<string>(InvitationNavTabs.email)
  const [emailLanguage, setEmailLanguage] = useState<string>('')
  const [emailInvitationText, setEmailInvitationText] = useState<string>('')
  const [emailSelectedMessageId, setEmailSelectedMessageId] = useState<number>(0)
  const [smsLanguage, setSMSLanguage] = useState<string>('')
  const [smsInvitationText, setSMSInvitationText] = useState<string>('')
  const [smsSelectedMessageId, setSMSSelectedMessageId] = useState<number>(0)
  const [previewHTML, setPreviewHTML] = useState<string>('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [updatedTabs, setUpdatedTabs] = useState<ITabNavItem[]>([])
  const [smsWarningModal, setSmsWarningModal] = useState<boolean>(false)

  const invitationNavTabs = useMemo(() => getInvitationNavTabs(languageText), [])

  useEffect(() => {
    setEmailLanguage(cultureValue)
    setSMSLanguage(cultureValue)
    if (selectedInvitationTypeBtn) {
      let updatedTabs: ITabNavItem[] = []
      if (selectedInvitationTypeBtn === InvitationType.email) {
        updatedTabs = invitationNavTabs.slice(0, 1)
      } else {
        updatedTabs = invitationNavTabs.slice(1)
        setActiveTab(InvitationNavTabs.sms)
      }
      setUpdatedTabs(updatedTabs)
    }

    if (profile.emailOwnMessage) {
      setEmailInvitationText(profile.emailOwnMessage)
    }
  }, [])

  const handleEmailLanguageSelect = (selectedItem: IDropdownSelectedItem): void => {
    setEmailLanguage(selectedItem.value)
  }

  const onEmailTextChange = (value: string): void => {
    setEmailInvitationText(value)
  }

  const handleSelectedEmail = (selectedMessage: ISavedMessage): void => {
    setEmailInvitationText(selectedMessage.message)
    setEmailSelectedMessageId(selectedMessage.id)
  }

  const handleSMSLanguageSelect = (selectedItem: IDropdownSelectedItem): void => {
    setSMSLanguage(selectedItem.value)
  }

  const onSMSTextChange = (value: string): void => {
    setSMSInvitationText(value)
  }

  const handleSelectedSMS = (selectedMessage: ISavedMessage): void => {
    setSMSInvitationText(selectedMessage.message)
    setSMSSelectedMessageId(selectedMessage.id)
  }

  const handleSendClick = (isEmail: boolean, participant: ISelectedProfilesList): void => {
    if (!isEmail && !phoneNumber) {
      setSmsWarningModal(true)
      return
    }

    setIsSending(true)
    dispatch(setSpinner(true))
    const languageValue: string = isEmail ? emailLanguage : smsLanguage
    const languageId: number =
      languages.find((language) => language.value === languageValue)?.id ?? 0
    let isReminder = false
    if (isEmail && participant.emailStatus && participant.emailStatus > 1) isReminder = true
    else if (!isEmail && participant.smsStatus && participant.smsStatus > 1) isReminder = true

    const body: ISendParticipantsInvitationBody = {
      id: participant.id,
      message: isEmail ? emailInvitationText : smsInvitationText,
      ownMessage: '',
      savedMessageId: isEmail ? emailSelectedMessageId : smsSelectedMessageId,
      isEmailOrSms: isEmail,
      languageId: languageId,
      isEmailOrSmsReminder: isReminder,
      phoneNumber: phoneNumber ?? '',
    }

    sendParticipantsInvitation([body], languageValue, dispatch)
      .then((response) => {
        if (response?.length > 0) {
          const emailOrSMSStatus = isEmail ? response[0].emailStatus : response[0].smsStatus
          handleMessageSentSuccess(isEmail, participant, emailOrSMSStatus)
        }
      })
      .finally(() => {
        setIsSending(false)
        dispatch(setSpinner(false))
      })
  }

  const handlePreviewClick = (participant: ISelectedProfilesList): void => {
    const languageId: number =
      languages.find((language) => language.value === emailLanguage)?.id ?? 0
    let isReminder = false
    if (participant.emailStatus && participant.emailStatus > 1) isReminder = true

    const body: ISendParticipantsInvitationBody = {
      id: participant.id,
      message: emailInvitationText,
      ownMessage: '',
      savedMessageId: emailSelectedMessageId,
      isEmailOrSms: true,
      languageId: languageId,
      isEmailOrSmsReminder: isReminder,
    }

    previewParticipantInvitation(body, emailLanguage, dispatch).then((response) => {
      if (response) {
        setPreviewHTML(response)
      }
    })
  }

  const closePreviewModal = (): void => setPreviewHTML('')

  const closeWarningModal = (): void => setSmsWarningModal(false)

  return (
    <>
      <ModalComponent
        width='lg'
        headerText={getLanguageValue(
          languageText,
          (profile && (profile.emailStatus ?? 0) > 1) || latestEmailSentLog
            ? 'Reminder'
            : 'Invitation'
        )}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={handleCloseClick}
      >
        <TabNavItem
          navTabs={updatedTabs.length > 0 ? updatedTabs : invitationNavTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        {/* Email Invitation */}
        <TabContent id={InvitationNavTabs.email} activeTab={activeTab}>
          <EmailSMSInvitation
            isEmail={true}
            languageText={languageText}
            messageTranslation={messageTranslation}
            languages={languages}
            languageValue={emailLanguage}
            invitationText={emailInvitationText}
            profile={profile}
            sameInvitationForAll={false}
            invitationSent={profile.emailSent}
            previewHTML={previewHTML}
            sendingInvite={isSending}
            handleLanguageSelect={handleEmailLanguageSelect}
            handleInvitationTextChange={onEmailTextChange}
            handleSelectedMessage={handleSelectedEmail}
            handleSendClick={handleSendClick}
            handlePreviewClick={handlePreviewClick}
            closePreviewModal={closePreviewModal}
          />
        </TabContent>

        {/* SMS Invitation */}
        <TabContent id={InvitationNavTabs.sms} activeTab={activeTab}>
          <EmailSMSInvitation
            isEmail={false}
            languageText={languageText}
            messageTranslation={messageTranslation}
            languages={languages}
            languageValue={smsLanguage}
            invitationText={smsInvitationText}
            profile={profile}
            sameInvitationForAll={false}
            invitationSent={profile.smsSent}
            sendingInvite={isSending}
            handleLanguageSelect={handleSMSLanguageSelect}
            handleInvitationTextChange={onSMSTextChange}
            handleSelectedMessage={handleSelectedSMS}
            handleSendClick={handleSendClick}
          />
        </TabContent>

        {smsWarningModal && (
          <SMSWarningModal
            headerText={getLanguageValue(languageText, 'No Phone Number')}
            bodyText={getLanguageValue(languageText, 'Phone number is required to send the sms')}
            submitButtonText={getLanguageValue(languageText, 'Ok')}
            handleCancelClick={closeWarningModal}
            handleSubmitClick={closeWarningModal}
          />
        )}
      </ModalComponent>
    </>
  )
}

export default PersonalizedInvitationModal
