import React from 'react'

import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { DropdownInputs, useDemographicPage } from './hooks'
import { DropdownSelect } from '@app/components/formComponents/dropdownSelect'
import Input from '../../../../components/formComponents/input'

const DemographicPage: React.FC<unknown> = () => {
  const {
    languageText,
    ageDropdownList,
    genderList,
    sectorList,
    seniorityList,
    educationList,
    demographicInfo,
    handleDropdownSelect,
    handleInputChange,
    goToPrevious,
    onSubmitClick,
  } = useDemographicPage()

  return (
    <>
      <div className='p-2 pt-4 p-md-4'>
        <h4 className='fw-bold'>
          {getLanguageValue(languageText, 'Voluntary demographic questions')}
        </h4>

        <form className='rounded shadow-sm bg-white mx-0 mt-4'>
          <div className='row p-4 pb-3'>
            <div className='col-12 mb-4 pb-1'>
              {getLanguageValue(
                languageText,
                'Please answer the voluntary demographic questions below and contribute to our statistics'
              )}
            </div>
            <div className='col-lg-4 mb-3'>
              <DropdownSelect
                label={getLanguageValue(languageText, 'Age')}
                defaultLabel={getLanguageValue(languageText, 'Select age')}
                name={DropdownInputs.age}
                list={ageDropdownList}
                handleDropdownSelect={handleDropdownSelect}
                value={demographicInfo.age}
              />
            </div>
            <div className='col-lg-4 mb-3'>
              <DropdownSelect
                label={getLanguageValue(languageText, 'Legal Gender')}
                defaultLabel={getLanguageValue(languageText, 'Select gender')}
                name={DropdownInputs.gender}
                list={genderList}
                handleDropdownSelect={handleDropdownSelect}
                value={demographicInfo.gender}
              />
            </div>
            <div className='col-lg-4 mb-3'>
              <DropdownSelect
                name={DropdownInputs.sector}
                label={getLanguageValue(languageText, 'Sector')}
                defaultLabel={getLanguageValue(languageText, 'Select sector')}
                value={demographicInfo.sector}
                list={sectorList}
                handleDropdownSelect={handleDropdownSelect}
              />
            </div>
            <div className='col-lg-4 mb-3'>
              <DropdownSelect
                name={DropdownInputs.seniority}
                label={getLanguageValue(languageText, 'Seniority')}
                defaultLabel={getLanguageValue(languageText, 'Select Seniority')}
                value={demographicInfo.seniority}
                list={seniorityList}
                handleDropdownSelect={handleDropdownSelect}
              />
            </div>
            <div className='col-lg-4 mb-3'>
              <DropdownSelect
                name={DropdownInputs.education}
                label={getLanguageValue(languageText, 'Education')}
                defaultLabel={getLanguageValue(languageText, 'Select Education')}
                value={demographicInfo.education}
                list={educationList}
                handleDropdownSelect={handleDropdownSelect}
              />
            </div>
            <div className='col-lg-4 mb-3'>
              <Input
                name={DropdownInputs.occupation}
                label={getLanguageValue(languageText, 'Occupation')}
                value={demographicInfo.occupation}
                handleInputChange={handleInputChange}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between bg-success-subtle rounded-bottom p-4'>
            <button className='btn' onClick={goToPrevious}>
              <i className='bi bi-arrow-left me-2'></i>
              {getLanguageValue(languageText, 'Go back')}
            </button>
            <button type='submit' className='btn btn-success btn-lg' onClick={onSubmitClick}>
              {getLanguageValue(languageText, 'Continue')}{' '}
              <i className='bi bi-arrow-right ms-2'></i>
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default DemographicPage
