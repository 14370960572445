import React from 'react'

import { useSelfForm } from './hooks'
import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import ParticipantHeaderComponent from '../participantHeaderComponent'
import { SelfForm } from '../../../../components/selfForm/selfForm'
import Instruction from '../../../../components/instruction/instruction'
import { GetTypeOfRoleName } from '../../../profileList/profileEnums'
import { GuidePages } from '@app/containers/commonEnums'

const ParticipantSelfFormPage = (): JSX.Element => {
  const { languageText, instructionSteps, uniqueCode, userLanguage, participantProfileDetails } =
    useSelfForm()

  return (
    <>
      <div className='p-2 pt-4 p-md-4'>
        <div className='d-flex justify-content-between pb-3'>
          <ParticipantHeaderComponent currentHeaderId={2} />
          <Instruction
            targetElement='instruction4'
            guidePage={GuidePages.Participant_SelfForm}
            instructionSteps={instructionSteps}
            stepsNotToSkip={[5]}
          />
        </div>

        <h4 className='fw-bold my-4'>{getLanguageValue(languageText, 'Self assessment')}</h4>
        <p>
          {getLanguageValue(
            languageText,
            'Fill in the form according to how you think your respondents perceive you in your role'
          )}
        </p>

        {participantProfileDetails.noOfRespondents > 0 && (
          <p className='fs-5'>
            <span className='me-2'>{getLanguageValue(languageText, 'Respondents')}:</span>
            <span className='fw-bold text-success'>
              {participantProfileDetails.noOfRespondents}{' '}
              {participantProfileDetails.roleId
                ? getLanguageValue(
                    languageText,
                    GetTypeOfRoleName[participantProfileDetails.roleId]
                  )
                : participantProfileDetails.roleText
                  ? participantProfileDetails.roleText
                  : ''}
            </span>
          </p>
        )}
      </div>
      <div className='shadow-sm rounded bg-white my-0 mx-md-4 mx-2 px-3'>
        {participantProfileDetails.noOfRespondents > 0 && (
          <SelfForm
            languageText={languageText}
            isParticipant={true}
            uniqueCode={uniqueCode}
            userLanguage={userLanguage.userLanguageCode}
            profileId={participantProfileDetails.profileId}
            profileRespondentId={0}
            isFormFilledData={participantProfileDetails.isFormFilledData}
            isActivityRoleEnabled={participantProfileDetails.isActivityRoleEnabled}
          />
        )}
      </div>
    </>
  )
}

export default ParticipantSelfFormPage
