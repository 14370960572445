import React from 'react'

import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import DeviationValue from './deviationValues/lowDeviationValue'

interface IDirectivenessProps {
  languageText: ITranslationObject
  profileMeasure: number
  directivenessMessage: string
  directivenessSpreadText: string
}

const Directiveness = (props: IDirectivenessProps): JSX.Element => {
  const { languageText, profileMeasure, directivenessMessage, directivenessSpreadText } = props

  return (
    <>
      <h5 className='fw-bold mb-2'>{getLanguageValue(languageText, 'Directiveness')}</h5>
      <DeviationValue
        profileMeasure={profileMeasure}
        deviationMessage={directivenessMessage}
        deviationSpreadText={directivenessSpreadText}
      />
    </>
  )
}

export default Directiveness
