import React from 'react'

import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { useLanguageList } from './hooks'
import ReactTable from '../../components/reactTable/reactTable'
import { DeleteModal } from '../../components/modals/deleteModal'
import { AddEditLanguageModal } from './addEditModal/addEditLanguageModal'

const LanguageList = () => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openLanguageModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addLanguageClick,
    closeLanguageModal,
    handleDeleteLanguage,
    closeDeleteModal,
  } = useLanguageList()

  let rowData = {}
  if (editedRowId) {
    rowData = data.find((item) => item.id === editedRowId)
  }

  return (
    <>
      <ReactTable
        wrapperClass='p-2 pt-4 p-md-4'
        headerText={getLanguageValue(languageText, 'Language')}
        searchPlaceholder={getLanguageValue(languageText, 'Search Language')}
        buttonText={getLanguageValue(languageText, 'Add Language')}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={addLanguageClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {openLanguageModal || editedRowId ? (
        <AddEditLanguageModal rowData={rowData} closeLanguageModal={closeLanguageModal} />
      ) : (
        ''
      )}

      {deleteRowId ? (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, 'Language')}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeleteLanguage}
          closeDeleteModal={closeDeleteModal}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default LanguageList
