import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import {
  createColleaguesAsProfileParticipantRespondents,
  createManualProfileParticipantRespondents,
  getRespondentEmailPreview,
  getUserForRespondents,
} from '../../actions'
import {
  ICreateManualRespondentsBody,
  ICreateRespondentsParams,
  IRespondentEmailPreviewBody,
} from './sendRespondentsInvitation'
import { IFocusError } from '../../../../components/formComponents/input'
import { ManualRespondentInputs } from './addManually'
import { ValidationHelper } from '../../../validationHelper'
import { IProfileRespondentsList } from '../../../profileList/editProfile/interface'
import { getParticipantRespondentsByParticipantId } from '../../../profileList/editProfile/actions'
import { addToast, setSpinner } from '../../../actions'
import { inviteRespondentsInstructionSteps } from '@app/components/instruction/instructionSteps'
import useParticipantProfileAuth from '../../useParticipantProfileAuth'
import { ProfileId, UserId } from '@app/containers/reducer'

enum NavTabEnums {
  Select_From_List = 1,
  Add_Manually = 2,
}

export interface IUserRespondentsBody {
  profileId: ProfileId
  searchText: string
}
export interface IRespondentList {
  /** This is a user ID */
  id: UserId
  name: string
}

export interface IManualRespondentInfo {
  /** FIXME: this is an ID of unknown type. */
  id: number
  name: string
  emailAddress: string
  phoneNumber: string
  nameError: IFocusError
  emailError: IFocusError
  phoneNumberError: IFocusError
}

export const useInviteRespondent = () => {
  const dispatch = useDispatch()
  useParticipantProfileAuth() // Required to get data from the URL unique code

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)
  const profileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )
  const currentUserLanguages = useSelector(
    (state: RootState) => state.mainReducer.currentUserLanguages
  )
  const instructionSteps = useMemo(
    () => inviteRespondentsInstructionSteps(languageText),
    [languageText]
  )

  const initialErrorState: IFocusError = { errorMessage: '', touched: false }
  const initialInfoState: IManualRespondentInfo = {
    id: 1,
    name: '',
    emailAddress: '',
    phoneNumber: '',
    nameError: initialErrorState,
    emailError: initialErrorState,
    phoneNumberError: initialErrorState,
  }
  const initialRespondentState: IRespondentList = {
    id: 0 as UserId,
    name: '',
  }
  const [respondentsList, setRespondentsList] = useState<IRespondentList[]>([
    initialRespondentState,
  ])
  const [originalRespondentsList, setOriginalRespondentsList] = useState<IRespondentList[]>([])
  const [selectedTab, setSelectedTab] = useState<number>(NavTabEnums.Select_From_List)
  const [respondentsLimitReached, setRespondentsLimitReached] = useState<boolean>(false) // When required noOfRespondents have filled
  const [respondentsLimitReachedModal, setRespondentsLimitReachedModal] = useState<boolean>(false)

  // Select from list states
  const [searchText, setSearchText] = useState<string>('')
  const [selectedRespondents, setSelectedRespondents] = useState<IRespondentList[]>([])
  const [refetchList, setRefetchList] = useState<boolean>(false)
  const [languages, setLanguages] = useState<IDropdownList[]>([])
  const [showSelectFormListOption, setShowSelectFromListOption] = useState<boolean>(false)
  const [listLanguageValue, setListLanguageValue] = useState<string>('')
  const [listMessageValue, setListMessageValue] = useState<string>('')
  // Add Manual states
  const [respondentInfo, setRespondentInfo] = useState<IManualRespondentInfo[]>([initialInfoState])
  const [showAddManualOption, setShowAddManualOption] = useState<boolean>(false)
  const [addManualLanguageValue, setAddManualLanguageValue] = useState<string>('')
  const [addManualMessageValue, setAddManualMessageValue] = useState<string>('')
  const [previewHTML, setPreviewHTML] = useState<string>('')
  // Respondents status states
  const [invitedRespondentsList, setInvitedRespondentsList] = useState<IProfileRespondentsList>({
    participantLink: '',
    respondents: [],
    noOfRespondents: 0,
    respondentsAnswered: 0,
    respondentsInvited: 0,
  })
  const [refetchInvitedRespondentsList, setRefetchInvitedRespondentsList] = useState<boolean>(false)

  useEffect(() => {
    if (currentUserLanguages.length > 0) {
      const cultures: IDropdownList[] = currentUserLanguages.map((item) => ({
        id: item.id,
        displayName: item.displayName,
        value: item.name,
      }))
      setLanguages(cultures)
    }
  }, [currentUserLanguages])

  // Select from list , Add manually useEffect
  useEffect(() => {
    if (profileDetails.participantInviteExternal) {
      setShowAddManualOption(true)
      if (profileDetails.noOfRespondents) {
        const updatedArr: IManualRespondentInfo[] = Array.from(
          { length: profileDetails.noOfRespondents },
          (_, index) => ({ ...initialInfoState, id: index + 1 })
        )
        setRespondentInfo(updatedArr)
      }
      if (userLanguage.userLanguageCode) {
        setAddManualLanguageValue(userLanguage.userLanguageCode)
      }
    }
  }, [profileDetails, userLanguage.userLanguageCode])

  // Select from list useEffect
  useEffect(() => {
    if (profileDetails.profileId && !profileDetails.isLocked) {
      const body: IUserRespondentsBody = {
        profileId: profileDetails.profileId,
        searchText,
      }
      getUserForRespondents(body, dispatch).then((response) => {
        if (response) {
          setOriginalRespondentsList(response)
          // Removing already selected participants as respondents from list
          if (selectedRespondents.length > 0) {
            const updatedRespondentsList = response.filter(
              (respondent) => !selectedRespondents.some((res) => res.id === respondent.id)
            )
            setRespondentsList(updatedRespondentsList)
          } else {
            setRespondentsList(response)
          }
        }
      })
    }
  }, [searchText, refetchList, profileDetails])

  // Respondents status useEffect
  useEffect(() => {
    if (profileDetails.profileId && profileDetails.noOfRespondents) {
      getParticipantRespondentsByParticipantId(profileDetails.profileId, dispatch).then(
        (response) => {
          if (response) {
            setInvitedRespondentsList(() => ({
              ...response,
              respondentsInvited: response.respondentsInvited ?? 0,
              respondentsAnswered: response.respondentsAnswered ?? 0,
              participantRespondents: response.respondents ?? [],
            }))
          }
        }
      )
    }
  }, [refetchInvitedRespondentsList, profileDetails])

  // Setting no of respondents remaining
  useEffect(() => {
    if (profileDetails.participantInviteExternal) {
      const noOfRespondentsSelected =
        selectedRespondents.length + invitedRespondentsList.respondents.length
      const remainingNoOfRespondents = profileDetails.noOfRespondents - noOfRespondentsSelected
      const updateNumber = remainingNoOfRespondents > 1 ? remainingNoOfRespondents : 1
      if (updateNumber > respondentInfo.length) {
        const reqExtraNumber = updateNumber - respondentInfo.length
        const updatedArr: IManualRespondentInfo[] = Array.from({ length: reqExtraNumber }, () => ({
          ...initialInfoState,

          // what the fuck is going on here?
          id: respondentInfo.length + 1,
        }))
        setRespondentInfo((prevInfo) => [...prevInfo, ...updatedArr])
      } else if (updateNumber < respondentInfo.length) {
        const extraNumber = respondentInfo.length - updateNumber
        const updatedArr = [...respondentInfo].slice(0, -extraNumber)
        setRespondentInfo(updatedArr)
      }
    }
  }, [selectedRespondents, profileDetails])

  useEffect(() => {
    if (profileDetails.participantInviteExternal) {
      const remainingNoOfRespondents =
        profileDetails.noOfRespondents - invitedRespondentsList.respondents.length
      const updateNumber = remainingNoOfRespondents > 1 ? remainingNoOfRespondents : 1
      const updatedArr: IManualRespondentInfo[] = Array.from(
        { length: updateNumber },
        (_, index) => ({
          ...initialInfoState,

          // what the fuck is going on here?
          id: index + 1,
        })
      )
      setRespondentInfo(updatedArr)
    }
    if (invitedRespondentsList.respondents.length >= profileDetails.noOfRespondents) {
      setRespondentsLimitReached(true)
      setSelectedTab(0)
    }
    if (
      profileDetails.noOfRespondents &&
      (profileDetails.participantInviteColleagues ||
        profileDetails.participantInviteOtherParticipant)
    ) {
      setShowSelectFromListOption(true)
      if (userLanguage.userLanguageCode) {
        setListLanguageValue(userLanguage.userLanguageCode)
      }
      if (invitedRespondentsList.respondents.length < profileDetails.noOfRespondents) {
        setSelectedTab(NavTabEnums.Select_From_List)
        setRespondentsLimitReached(false)
      }
    } else if (invitedRespondentsList.respondents.length < profileDetails.noOfRespondents) {
      setSelectedTab(NavTabEnums.Add_Manually)
      setRespondentsLimitReached(false)
    }
  }, [invitedRespondentsList, profileDetails, userLanguage.userLanguageCode])

  const handleTabSelection = (tabStatus: number): void => {
    setSelectedTab(tabStatus)
  }

  // Select from list fn's
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value)
  }

  const handleSelectRespondent = (respondent: IRespondentList): void => {
    const updatedSelectedRespondents = [...selectedRespondents]
    updatedSelectedRespondents.push(respondent)
    setSelectedRespondents(updatedSelectedRespondents)

    const updatedRespondentsList = originalRespondentsList.filter(
      (respondent) => !updatedSelectedRespondents.some((res) => res.id === respondent.id)
    )
    setRespondentsList(updatedRespondentsList)
  }

  const handleRemoveRespondent = (respondent: IRespondentList): void => {
    const updatedSelectedRespondents = [...selectedRespondents].filter(
      (res) => res.id !== respondent.id
    )
    setSelectedRespondents(updatedSelectedRespondents)

    const updatedRespondentsList = [...originalRespondentsList].filter(
      (respondent) => !updatedSelectedRespondents.some((res) => res.id === respondent.id)
    )
    setRespondentsList(updatedRespondentsList)
  }

  const handleSaveListLanguage = (selectedValue: string): void => {
    setListLanguageValue(selectedValue)
  }

  const handleSaveListMessage = (value: string): void => setListMessageValue(value)

  const handlePreviewModal = (languageCode: string, ownMsg: string): void => {
    const body: IRespondentEmailPreviewBody = {
      profileId: profileDetails.profileId,
      languageCode: languageCode,
      ownMsg: ownMsg,
    }
    getRespondentEmailPreview(body, dispatch).then((response) => {
      if (response) {
        setPreviewHTML(response)
      }
    })
  }

  const handleListPreviewClick = (): void => handlePreviewModal(listLanguageValue, listMessageValue)

  const isRespondentsLimitReached = (selectedLength: number): boolean => {
    // If selected more than limit, show limit message
    if (
      selectedLength + invitedRespondentsList.respondents.length >
      profileDetails.noOfRespondents
    ) {
      setRespondentsLimitReachedModal(true)
      return true
    } else return false
  }

  const handleListSendInvitationClick = (): void => {
    if (selectedRespondents.length > 0) {
      if (isRespondentsLimitReached(selectedRespondents.length)) return

      dispatch(setSpinner(true))
      const params: ICreateRespondentsParams = {
        profileId: profileDetails.profileId,
        languageCode: listLanguageValue,
        ownMessage: listMessageValue,
      }
      const selectedRespondentIds = selectedRespondents.map((respondent) => respondent.id)
      createColleaguesAsProfileParticipantRespondents(selectedRespondentIds, params, dispatch)
        .then((response) => {
          if (response?.success) {
            dispatch(addToast('Invitation sent successfully') as AnyAction)
            setRefetchList(!refetchList)
            setRefetchInvitedRespondentsList(!refetchInvitedRespondentsList)
            setSelectedRespondents([])
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  // Add manually fn's
  const handleFormErrors = (
    id: number | string,
    name: string,
    value: string,
    onBlur = false
  ): void => {
    let errorMessage: string = ''
    let errorInputName: string = ''

    switch (name) {
      case ManualRespondentInputs.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Name is required')
        }
        errorInputName = ManualRespondentInputs.nameError
        break
      case ManualRespondentInputs.emailAddress:
        if (value) {
          if (!ValidationHelper.isEmailValid(value)) {
            errorMessage = getLanguageValue(languageText, 'Invalid email')
          } else {
            // check if email already exists in invited respondents
            const isEmailExistsInAddManually = respondentInfo
              .filter((respondent) => `${name}${respondent.id}` !== id)
              .some((respondent) => respondent.emailAddress === value)

            // check if email already added manually
            const isEmailExistsInInvitedRespondents = invitedRespondentsList.respondents.some(
              (respondent) => respondent.email === value
            )
            if (isEmailExistsInAddManually || isEmailExistsInInvitedRespondents) {
              errorMessage = getLanguageValue(languageText, 'Email already exists')
            }
            if (value === profileDetails.emailAddress) {
              errorMessage = getLanguageValue(
                languageText,
                'You cannot invite yourself as respondent'
              )
            }
          }
        }
        errorInputName = ManualRespondentInputs.emailError
        break
      default:
        break
    }

    setRespondentInfo((prevInfo) =>
      prevInfo.map((item) => {
        if (id === `${name}${item.id}` && ((!onBlur && item[errorInputName].touched) || onBlur)) {
          return { ...item, [errorInputName]: { errorMessage: errorMessage, touched: true } }
        }
        return item
      })
    )
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = e.target.id
    const value = e.target.value
    const name = e.target.name

    if (name === ManualRespondentInputs.phoneNumber && value) {
      const isValid = ValidationHelper.isPhoneNumberValid(value)
      if (!isValid) return
    }

    setRespondentInfo((prevInfo) =>
      prevInfo.map((item) => {
        if (id === `${name}${item.id}`) {
          handleFormErrors(`${name}${item.id}`, name, value)
          return { ...item, [name]: value }
        }
        return item
      })
    )
  }

  const handlePhoneInputChange = (value: string, id: number): void => {
    setRespondentInfo((prevInfo) =>
      prevInfo.map((item) => {
        if (id === item.id) {
          return { ...item, phoneNumber: value }
        }
        return item
      })
    )
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const id = e.target.id
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(id, name, value, true)
  }

  const onDeleteRowClick = (id: number): void => {
    const filteredRespondentInfo = [...respondentInfo].filter((item) => item.id !== id)
    const updatedRespondentInfo = [...filteredRespondentInfo].map((respondent, index) => {
      respondent.id = index + 1
      return respondent
    })
    setRespondentInfo(updatedRespondentInfo)
  }

  const onAddRowClick = (): void => {
    setRespondentInfo((prevInfo) => [
      ...prevInfo,
      { ...initialInfoState, id: respondentInfo.length + 1 },
    ])
  }

  const handleSaveAddManuallyLanguage = (selectedValue: string): void => {
    setAddManualLanguageValue(selectedValue)
  }

  const handleSaveAddManuallyMessage = (value: string): void => setAddManualMessageValue(value)

  const handleAddManualPreviewClick = (): void => {
    handlePreviewModal(addManualLanguageValue, addManualMessageValue)
  }

  const closePreviewModal = (): void => {
    setPreviewHTML('')
  }

  const handleAddManualSendInvitationClick = (): void => {
    const body: ICreateManualRespondentsBody[] = respondentInfo
      .map((info) => {
        const { name, emailAddress, phoneNumber, nameError, emailError, phoneNumberError } = info
        if (
          !name ||
          (!emailAddress && !phoneNumber) ||
          nameError.errorMessage ||
          emailError.errorMessage ||
          phoneNumberError.errorMessage
        ) {
          return {
            profileId: 0 as ProfileId,
            name: '',
            email: '',
            telephone: '',
          }
        } else
          return {
            profileId: profileDetails.profileId,
            name: info.name,
            email: info.emailAddress,
            telephone: info.phoneNumber,
          }
      })
      .filter((info) => info.name)

    if (body.length > 0) {
      if (isRespondentsLimitReached(body.length)) return

      dispatch(setSpinner(true))
      const params: ICreateRespondentsParams = {
        profileId: profileDetails.profileId,
        languageCode: addManualLanguageValue,
        ownMessage: addManualMessageValue,
      }
      createManualProfileParticipantRespondents(body, params, dispatch)
        .then((response) => {
          if (response?.success) {
            dispatch(addToast('Invitation sent successfully') as AnyAction)
            setRespondentInfo([initialInfoState])
            setRefetchInvitedRespondentsList(!refetchInvitedRespondentsList)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  const handleRefetch = (selectList: boolean, invitedRespondents: boolean): void => {
    if (selectList) setRefetchList(!refetchList)
    if (invitedRespondents) setRefetchInvitedRespondentsList(!refetchInvitedRespondentsList)
  }

  const closeRespondentsLimitReachedModal = (): void => {
    setRespondentsLimitReachedModal(false)
  }

  return {
    languageText,
    NavTabEnums,
    instructionSteps,
    profileDetails,
    selectedTab,
    showSelectFormListOption,
    showAddManualOption,
    respondentsList,
    searchText,
    selectedRespondents,
    languages,
    respondentInfo,
    listLanguageValue,
    listMessageValue,
    addManualLanguageValue,
    addManualMessageValue,
    previewHTML,
    invitedRespondentsList,
    respondentsLimitReached,
    respondentsLimitReachedModal,
    handleTabSelection,
    setSelectedTab,
    handleSearch,
    handleSelectRespondent,
    handleRemoveRespondent,
    handleSaveListLanguage,
    handleSaveListMessage,
    handleListPreviewClick,
    handleListSendInvitationClick,
    handleInputChange,
    handlePhoneInputChange,
    handleBlurEvent,
    onDeleteRowClick,
    onAddRowClick,
    handleSaveAddManuallyLanguage,
    handleSaveAddManuallyMessage,
    handleAddManualPreviewClick,
    handleAddManualSendInvitationClick,
    closePreviewModal,
    handleRefetch,
    closeRespondentsLimitReachedModal,
  }
}
