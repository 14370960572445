import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import { EditProfileDescriptionModal } from './editProfileDescriptionModal'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { getWindowDimensions } from '../../../../../commonUtils/screenWidthHelper'
import { ActivityStatus, activityStatusColors } from '../../../profileEnums'
import { getAllCountries } from '../../../../clientList/addEditModal/action'
import { RootState } from '@app/store/configureStore'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { IEditActivity } from '../../interface'
import { ClientSelectModal } from '../../modals/clientSelectModal/clientSelectModal'
import ReactTooltip from '../../../../../components/tooltip/reactTooltip'
import {
  CustomModal as CancelModal,
  CustomModal,
} from '../../../../../components/modals/customModal'
import { cancelProfile, getClientFacilitators } from '../../actions'
import { DeleteModal } from '@app/components/modals/deleteModal'
import { deleteProfile } from '@app/containers/profileList/actions'
import { routePath } from '@app/containers/routePaths'
import { ICountry } from '@app/containers/commonInterfaces'
import { addToast, setSpinner } from '@app/containers/actions'
import { AnyAction } from 'redux'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import Instruction from '@app/components/instruction/instruction'
import { GuidePages, Roles } from '@app/containers/commonEnums'
import { editProfileInstructionSteps } from '@app/components/instruction/instructionSteps'
import { ActivityId } from '@app/containers/reducer'

export enum EditModalEnum {
  status = 'status',
  completionDate = 'completionDate',
  name = 'name',
  notes = 'notes',
  language = 'language',
  country = 'country',
  facilitator = 'facilitator',
  sourceType = 'sourceType',
}

interface IFacilitatorInfo {
  title: string
  value: string
}

export interface IClientFacilitator {
  id: number
  name: string
  email: string
  phoneNumber: string
}

interface IEditProfileDescriptionStepProps {
  id: ActivityId
  languageText: ITranslationObject
  clientIdFromAccount: number
  profileData: IEditActivity
  cultures: IDropdownList[]
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
  handleProfileCancel: () => void
  onBillingButtonClick: () => void
  handleInstructionHelpClick: (guidePage: GuidePages) => void
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void
  handleClientSelect: (clientId: number, clientName: string, isFacilitatorRemoved: boolean) => void
  handleLanguageSelect: (languageId: number) => void
  refetchFullProfile: () => void
}

const EditProfileDescriptionStep = (props: IEditProfileDescriptionStepProps): JSX.Element => {
  const {
    id,
    languageText,
    clientIdFromAccount,
    profileData,
    cultures,
    isActivityCancelled,
    isActivityInvoiced,
    handleProfileCancel,
    onBillingButtonClick,
    handleInstructionHelpClick,
    handleExitGuide,
    handleClientSelect,
    handleLanguageSelect,
    refetchFullProfile,
  } = props

  const isMobileView = getWindowDimensions().width < 768
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const userDetails = useSelector((state: RootState) => state.loginReducer.userDetails)
  const instructionSteps = useMemo(() => editProfileInstructionSteps(languageText), [languageText])

  const [name, setName] = useState<string>('') // Profile name
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [countries, setCountries] = useState<IDropdownList[]>([])
  const [facilitators, setFacilitators] = useState<IClientFacilitator[]>([])
  const [status, setStatus] = useState<ActivityStatus>(ActivityStatus.Created)
  const [completionDate, setCompletionDate] = useState<string>('')
  const [notes, setNotes] = useState<string>('')
  const [languageName, setLanguageName] = useState<string | JSX.Element>('')
  const [countryName, setCountryName] = useState<string | JSX.Element>('')
  const [facilitatorName, setFacilitatorName] = useState<string>('')
  const [facilitatorId, setFacilitatorId] = useState<number>(0)
  const [sourceType, setSourceType] = useState<string>(profileData.sourceType)
  const [editModalName, setEditModalName] = useState<EditModalEnum | undefined>(undefined)
  const [clientEditMode, setClientEditMode] = useState<boolean>(false)
  const [profileCancelModal, setProfileCancelModal] = useState<boolean>(false)
  const [facilitatorInfoModal, setFacilitatorInfoModal] = useState<boolean>(false)
  const canEditFacilitator =
    loggedInUserRole === Roles.Admin ||
    (loggedInUserRole === Roles.Facilitator && userDetails.isManager)

  const getCountries = async (): Promise<void> => {
    await getAllCountries(dispatch).then((response: ICountry[]) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: item.name,
        }))
        setCountries(countries)
      }
    })
  }

  useEffect(() => {
    getCountries()
  }, [])

  useEffect(() => {
    if (canEditFacilitator && profileData.clientId) {
      getClientFacilitators(profileData.clientId, dispatch).then((response) => {
        if (response?.length > 0) {
          setFacilitators(response)
        }
      })
    }
  }, [profileData.clientId, canEditFacilitator])

  useEffect(() => {
    const languageName =
      cultures.find((culture) => culture.id === profileData.idiLanguageId)?.displayName ?? ''
    const countryName =
      countries.find((culture) => culture.id === profileData.countryId)?.displayName ?? ''

    setStatus(profileData.status)
    setCompletionDate(profileData.completionDate.substring(0, 10))
    setNotes(profileData.notes)
    setName(profileData.name)
    setLanguageName(languageName)
    setCountryName(countryName)
    setSourceType(profileData.sourceType)
  }, [profileData])

  useEffect(() => {
    setFacilitatorName(profileData.facilitator)
    setFacilitatorId(profileData.facilitatorId)
  }, [profileData.facilitatorId])

  const handleFacilitatorSelect = (facilitatorId: number): void => {
    setFacilitatorId(facilitatorId)
    const updatedName =
      facilitators.find((facilitator) => facilitator.id === facilitatorId)?.name.toString() ?? ''
    setFacilitatorName(updatedName)
  }

  const handleFacilitatorInfo = (): void => {
    setFacilitatorInfoModal(true)
  }

  const closeFacilitatorInfoModal = (): void => {
    setFacilitatorInfoModal(false)
  }

  const renderFacilitatorInfo = (): JSX.Element => {
    const selectedFacilitator =
      facilitators.find((facilitator) => facilitator.id === facilitatorId) ?? facilitators[0]

    const facilitatorInfo: IFacilitatorInfo[] = [
      {
        title: getLanguageValue(languageText, 'Name'),
        value: facilitatorName,
      },
      {
        title: getLanguageValue(languageText, 'Email'),
        value: selectedFacilitator?.email,
      },
      {
        title: getLanguageValue(languageText, 'Phone'),
        value: selectedFacilitator?.phoneNumber,
      },
    ]

    return (
      <div className=''>
        {facilitatorInfo.map((item, index) => (
          <div key={index} className='d-flex mb-3'>
            <div className='col-3'>{item.title}:</div>
            <div>{item.value ?? '-'}</div>
          </div>
        ))}
      </div>
    )
  }

  const handleDeleteBtnClick = (): void => {
    setShowDeleteModal(true)
  }

  const closeDeleteModal = (): void => {
    setShowDeleteModal(false)
  }

  const handleDeleteProfile = (): void => {
    dispatch(setSpinner(true))
    deleteProfile(id, dispatch)
      .then((response) => {
        if (response) {
          if (isLoggedInRoleAdmin(loggedInUserRole) && clientIdFromAccount) {
            navigate(routePath.allaccounts)
          } else if (clientIdFromAccount) {
            navigate(routePath.account)
          } else if (isLoggedInRoleAdmin(loggedInUserRole)) {
            navigate(routePath.activities)
          } else {
            navigate(routePath.activities)
          }
          dispatch(addToast('Profile deleted successfully') as AnyAction)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const closeClientSelectModal = (): void => {
    setClientEditMode(false)
  }

  const openSelectClientEditMode = (): void => {
    setClientEditMode(true)
  }

  const handleToggle = () => setIsExpanded(!isExpanded)

  const openEditModal = (name: EditModalEnum): void => {
    setEditModalName(name)
  }

  const closeEditModal = (): void => {
    setEditModalName(undefined)
  }

  const openCancelModal = (): void => {
    setProfileCancelModal(true)
  }

  const closeCancelModal = (): void => {
    setProfileCancelModal(false)
  }

  const handleCancelProfile = (): void => {
    cancelProfile(profileData.id, dispatch).then((response) => {
      if (response) {
        setStatus(ActivityStatus.Cancelled)
        closeCancelModal()
        !isLoggedInRoleAdmin(loggedInUserRole) && handleProfileCancel()
      }
    })
  }

  return (
    <>
      <div className='row mb-3'>
        <div className='col-md-6'>
          <h4 id='selector1' className='fw-bold mb-4 mb-md-2 mb-xl-2'>
            {getLanguageValue(languageText, 'Profile')} / {name}
            {!isActivityCancelled && !isActivityInvoiced && (
              <span
                className='border btn btn-sm rounded mb-1 ms-3'
                onClick={() => openEditModal(EditModalEnum.name)}
                role='button'
              >
                <i className='bi bi-pencil fs-6'></i>
              </span>
            )}
          </h4>
        </div>
        <div className='col-md-6'>
          <div className='align-items-center d-flex justify-content-md-end'>
            {isLoggedInRoleAdmin(loggedInUserRole) && (
              <div
                role='button'
                className='d-flex align-items-center me-3'
                onClick={() => openEditModal(EditModalEnum.sourceType)}
              >
                {/* N/A because we need something to click when the thing is empty. */}
                <i className='bi bi-geo-alt-fill me-1'></i> {sourceType || 'N/A'}
              </div>
            )}
            {profileData.showBilling && isLoggedInRoleAdmin(loggedInUserRole) && (
              <div
                role='button'
                className='d-flex align-items-center me-3'
                onClick={onBillingButtonClick}
              >
                <i className='bi bi-receipt me-1'></i> {getLanguageValue(languageText, 'Billing')}
              </div>
            )}

            {(isLoggedInRoleAdmin(loggedInUserRole) || status === ActivityStatus.Created) && (
              <div
                role='button'
                onClick={handleDeleteBtnClick}
                className='d-flex align-items-center me-3 text-danger'
              >
                <i className='bi bi-trash3 me-1' />
                {getLanguageValue(languageText, 'Delete')}
              </div>
            )}
            {!isActivityCancelled && profileData.id > 0 && (
              <Instruction
                targetElement={
                  profileData.deliveredProfileCount > 0 &&
                  profileData.profileCount !== profileData.deliveredProfileCount
                    ? 'editProfileAddParticipants'
                    : 'respondentSettings'
                }
                guidePage={GuidePages.Edit_Profile}
                instructionSteps={instructionSteps}
                stepsToSkip={
                  profileData.deliveredProfileCount > 0 &&
                  profileData.profileCount === profileData.deliveredProfileCount
                    ? [6]
                    : []
                }
                skipPostGuideRequest={true}
                handleInstructionHelpClick={handleInstructionHelpClick}
                handleExitGuide={handleExitGuide}
              />
            )}
          </div>
        </div>
      </div>

      <div id='status' className='p-3 rounded bg-white'>
        <div>
          <div className='row'>
            <div className='col-lg-2 col-md-4 col-6 border-end mb-3'>
              <div className='text-secondary'>{getLanguageValue(languageText, 'Status')}</div>
              <div className='d-flex align-items-center flex-wrap'>
                <span
                  className={clsx(
                    'fw-bold',
                    isLoggedInRoleAdmin(loggedInUserRole) && 'cursor-pointer'
                  )}
                  onClick={() =>
                    isLoggedInRoleAdmin(loggedInUserRole) && openEditModal(EditModalEnum.status)
                  }
                >
                  <span className={clsx('badge fs-6 pt-2', activityStatusColors[status])}>
                    {status ? getLanguageValue(languageText, ActivityStatus[status]) : ''}
                  </span>
                  {(isLoggedInRoleAdmin(loggedInUserRole) ||
                    (!isLoggedInRoleAdmin(loggedInUserRole) &&
                      (status === ActivityStatus.Created || status === ActivityStatus.Active))) &&
                    status !== ActivityStatus.Cancelled && (
                      <span className='text-decoration-underline ms-2' onClick={openCancelModal}>
                        {getLanguageValue(languageText, 'Cancel')}
                      </span>
                    )}
                </span>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-6 border-end mb-3'>
              <div className='text-secondary'>
                {getLanguageValue(languageText, 'Completion date')}
              </div>
              <div
                className={clsx(
                  'fs-5 fw-bold text-dark opacity-75',
                  !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                )}
                role='button'
                onClick={() =>
                  !isActivityCancelled &&
                  !isActivityInvoiced &&
                  openEditModal(EditModalEnum.completionDate)
                }
              >
                {completionDate}
              </div>
            </div>

            {isMobileView && !isExpanded && (
              <div className='d-flex justify-content-center'>
                <span className='text-primary me-3' onClick={handleToggle}>
                  <i className='bi bi-chevron-down' />
                </span>
              </div>
            )}

            {((isMobileView && isExpanded) || !isMobileView) && (
              <>
                <div className='col-lg-2 col-md-4 col-6 border-end mb-3'>
                  <div className='text-secondary'>{getLanguageValue(languageText, 'Norm')}</div>
                  <div
                    className={clsx(
                      'fs-5 fw-bold text-dark opacity-75',
                      !isActivityCancelled && 'cursor-pointer'
                    )}
                    role='button'
                    onClick={() => !isActivityCancelled && openEditModal(EditModalEnum.language)}
                  >
                    {languageName}
                  </div>
                </div>
                <div className='col-lg-2 col-md-4 col-6 border-end mb-3'>
                  <div className='text-secondary'>{getLanguageValue(languageText, 'Country')}</div>
                  <div className='d-flex justify-content-between fs-5 fw-bold text-dark opacity-75'>
                    <span
                      className={clsx(
                        !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                      )}
                      role='button'
                      onClick={() =>
                        !isActivityCancelled &&
                        !isActivityInvoiced &&
                        openEditModal(EditModalEnum.country)
                      }
                    >
                      {countryName}
                    </span>
                  </div>
                </div>
                <div className='col-lg-2 col-md-4 col-6 border-end mb-3'>
                  <div className='text-secondary'>
                    {getLanguageValue(languageText, 'Facilitator')}
                    {canEditFacilitator && facilitatorName && (
                      <i
                        className='bi bi-info-circle-fill text-success ms-2'
                        onClick={handleFacilitatorInfo}
                        role={canEditFacilitator ? 'button' : undefined}
                      />
                    )}
                  </div>
                  <div
                    title={facilitatorName}
                    className={clsx(
                      'fs-5 fw-bold text-dark opacity-75',
                      canEditFacilitator && 'cursor-pointer'
                    )}
                    role={canEditFacilitator ? 'button' : undefined}
                    onClick={(event) => {
                      event.preventDefault()

                      if (!canEditFacilitator) {
                        return
                      }

                      openEditModal(EditModalEnum.facilitator)
                    }}
                  >
                    {facilitatorName ?? '--'}
                  </div>
                </div>

                {isLoggedInRoleAdmin(loggedInUserRole) && (
                  <div className='col-lg-2 col-md-8 col-6'>
                    <div className='text-secondary'>{getLanguageValue(languageText, 'Client')}</div>
                    <div
                      className='fs-5 text-dark opacity-75 fw-bold cursor-pointer'
                      role='button'
                      onClick={openSelectClientEditMode}
                    >
                      <ReactTooltip
                        id={profileData.clientId.toString()}
                        tooltipText={profileData.clientName}
                      >
                        {profileData.clientName}
                      </ReactTooltip>
                    </div>
                  </div>
                )}

                <div className='col-12 col-md-8'>
                  <div className='text-secondary'>{getLanguageValue(languageText, 'Notes')}</div>
                  <div
                    className={clsx(
                      'fs-5 fw-bold text-dark opacity-75',
                      !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                    )}
                    role='button'
                    onClick={() =>
                      !isActivityCancelled &&
                      !isActivityInvoiced &&
                      openEditModal(EditModalEnum.notes)
                    }
                    title={notes}
                  >
                    {notes ? notes : '________________'}
                  </div>
                </div>
                {isMobileView && isExpanded && (
                  <div className='d-flex justify-content-center'>
                    <span className='text-primary me-3' onClick={handleToggle}>
                      <i className='bi bi-chevron-up' />
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, 'Profile')}
          bodyText={name}
          handleDeleteClick={handleDeleteProfile}
          closeDeleteModal={closeDeleteModal}
        />
      )}

      {editModalName && (
        <EditProfileDescriptionModal
          id={id}
          languageText={languageText}
          editModalName={editModalName}
          statusValue={status}
          completionDateValue={completionDate}
          nameValue={name}
          notesValue={notes}
          languages={cultures}
          countries={countries}
          facilitators={facilitators}
          languageName={languageName}
          countryName={countryName}
          facilitatorId={facilitatorId}
          sourceType={sourceType}
          profileData={profileData}
          setStatusValue={setStatus}
          setCompletionDateValue={setCompletionDate}
          setNameValue={setName}
          setNotesValue={setNotes}
          setLanguageName={setLanguageName}
          setCountryName={setCountryName}
          setSourceType={setSourceType}
          handleLanguageSelect={handleLanguageSelect}
          handleFacilitatorSelect={handleFacilitatorSelect}
          refetchFullProfile={refetchFullProfile}
          closeModal={closeEditModal}
        />
      )}

      {clientEditMode && (
        <ClientSelectModal
          languageText={languageText}
          clientId={profileData.clientId}
          activityId={profileData.id}
          facilitatorId={profileData.facilitatorId}
          handleClientSelect={handleClientSelect}
          closeClientSelectModal={closeClientSelectModal}
        />
      )}

      {profileCancelModal && (
        <CancelModal
          headerText={getLanguageValue(languageText, 'Cancel Profile')}
          bodyText={`${getLanguageValue(languageText, 'Do you really want to cancel profile')} - "${
            profileData.name
          }" ?`}
          cancelButtonText={getLanguageValue(languageText, 'No')}
          submitButtonText={getLanguageValue(languageText, 'Yes')}
          isSubmitDangerButton={true}
          handleCancelClick={closeCancelModal}
          handleSubmitClick={handleCancelProfile}
        />
      )}

      {facilitatorInfoModal && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Facilitator Info')}
          bodyText={renderFacilitatorInfo()}
          cancelButtonText={getLanguageValue(languageText, 'Close')}
          handleCancelClick={closeFacilitatorInfoModal}
        />
      )}
    </>
  )
}

export default EditProfileDescriptionStep
