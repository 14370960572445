import React from 'react'
import clsx from 'clsx'

import { useInviteRespondent } from './hooks'
import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import AddManually from './addManually'
import SelectFromList from './selectFromList'
import { PreviewPopup } from '../../../profileList/addProfile/emailSMSInvitation/previewPopup'
import RespondentStatus from './respondentStatus'
import ParticipantHeaderComponent from '../participantHeaderComponent'
import { GetTypeOfRoleName } from '../../../profileList/profileEnums'
import { CustomModal as RespondentsLimitReachedModal } from '../../../../components/modals/customModal'
import Instruction from '@app/components/instruction/instruction'
import { GuidePages } from '@app/containers/commonEnums'

const InviteRespondents = (): JSX.Element => {
  const {
    languageText,
    NavTabEnums,
    instructionSteps,
    profileDetails,
    selectedTab,
    showSelectFormListOption,
    showAddManualOption,
    respondentsList,
    searchText,
    selectedRespondents,
    languages,
    respondentInfo,
    listLanguageValue,
    listMessageValue,
    addManualLanguageValue,
    addManualMessageValue,
    previewHTML,
    invitedRespondentsList,
    respondentsLimitReached,
    respondentsLimitReachedModal,
    handleTabSelection,
    handleSearch,
    handleSelectRespondent,
    handleRemoveRespondent,
    handleSaveListLanguage,
    handleSaveListMessage,
    handleListPreviewClick,
    handleListSendInvitationClick,
    handleInputChange,
    handlePhoneInputChange,
    handleBlurEvent,
    onDeleteRowClick,
    onAddRowClick,
    handleSaveAddManuallyLanguage,
    handleSaveAddManuallyMessage,
    handleAddManualPreviewClick,
    handleAddManualSendInvitationClick,
    closePreviewModal,
    handleRefetch,
    closeRespondentsLimitReachedModal,
  } = useInviteRespondent()

  return (
    <>
      <div className='p-2 pt-4 p-md-4'>
        <div className='d-flex justify-content-between pb-3'>
          <ParticipantHeaderComponent currentHeaderId={3} />
          <Instruction
            targetElement='inviteRespondentInstruction6'
            guidePage={GuidePages.Participant_Invite_Respondents}
            instructionSteps={instructionSteps}
          />
        </div>

        <h4 className='fw-bold my-4'>
          {getLanguageValue(languageText, 'Invite respondents')}:{' '}
          <span className='fw-bold text-success'>
            {profileDetails.noOfRespondents}{' '}
            {profileDetails.roleId
              ? getLanguageValue(languageText, GetTypeOfRoleName[profileDetails.roleId])
              : profileDetails.roleText
                ? profileDetails.roleText
                : ''}
          </span>
        </h4>

        <p>
          {getLanguageValue(
            languageText,
            'Invite the respondents you want to engage to create your IDI profile'
          )}
          .
        </p>
        {profileDetails.noOfRespondents > 0 && (
          <p className='fs-5 d-none'>
            <span className='me-2'>{getLanguageValue(languageText, 'Respondents')}:</span>
            <span className='fw-bold text-success'>
              {profileDetails.noOfRespondents}{' '}
              {profileDetails.roleId
                ? getLanguageValue(languageText, GetTypeOfRoleName[profileDetails.roleId])
                : profileDetails.roleText
                  ? profileDetails.roleText
                  : ''}
            </span>
          </p>
        )}

        {!respondentsLimitReached ? (
          <>
            <div className='invite-respondents-tab fs-5 fw-bold mb-3'>
              {showSelectFormListOption && showAddManualOption && (
                <div
                  id='inviteRespondentInstruction2'
                  className={clsx(
                    `me-2 btn`,
                    selectedTab === NavTabEnums.Select_From_List ? 'btn-dark' : 'btn-light'
                  )}
                  onClick={() =>
                    !respondentsLimitReached && handleTabSelection(NavTabEnums.Select_From_List)
                  }
                >
                  <i className='bi bi-list-task me-2'></i>
                  {getLanguageValue(languageText, 'Select from list')}
                </div>
              )}
              {showAddManualOption && showSelectFormListOption && (
                <div
                  id='inviteRespondentInstruction3'
                  className={clsx(
                    `btn`,
                    selectedTab === NavTabEnums.Add_Manually ? 'btn-dark' : 'btn-light'
                  )}
                  onClick={() =>
                    !respondentsLimitReached && handleTabSelection(NavTabEnums.Add_Manually)
                  }
                >
                  <i className='bi bi-pencil-fill me-2'></i>
                  {getLanguageValue(languageText, 'Add manually')}
                </div>
              )}
            </div>

            <div className='mt-3 p-2 p-md-4 shadow-sm rounded bg-white mb-4'>
              {/* Select from list */}
              {showSelectFormListOption && selectedTab === NavTabEnums.Select_From_List && (
                <SelectFromList
                  languageText={languageText}
                  respondentsList={respondentsList}
                  searchText={searchText}
                  selectedRespondents={selectedRespondents}
                  languages={languages}
                  languageValue={listLanguageValue}
                  messageValue={listMessageValue}
                  handleSearch={handleSearch}
                  handleSelectRespondent={handleSelectRespondent}
                  handleRemoveRespondent={handleRemoveRespondent}
                  handleSaveLanguage={handleSaveListLanguage}
                  handleSaveMessage={handleSaveListMessage}
                  onPreviewClick={handleListPreviewClick}
                  onSendInvitationClick={handleListSendInvitationClick}
                />
              )}

              {/* Add Manually */}
              {showAddManualOption && selectedTab === NavTabEnums.Add_Manually && (
                <AddManually
                  languageText={languageText}
                  respondentInfo={respondentInfo}
                  languages={languages}
                  languageValue={addManualLanguageValue}
                  messageValue={addManualMessageValue}
                  handleInputChange={handleInputChange}
                  handlePhoneInputChange={handlePhoneInputChange}
                  handleBlurEvent={handleBlurEvent}
                  onDeleteRowClick={onDeleteRowClick}
                  onAddRowClick={onAddRowClick}
                  handleSaveLanguage={handleSaveAddManuallyLanguage}
                  handleSaveMessage={handleSaveAddManuallyMessage}
                  onPreviewClick={handleAddManualPreviewClick}
                  onSendInvitationClick={handleAddManualSendInvitationClick}
                />
              )}
            </div>
          </>
        ) : (
          <div className='text-danger d-none'>
            {
              getLanguageValue(
                languageText,
                'Respondents limit reached. Cannot add further respondents'
              )
              //AM: not sure if we will keep this
            }
            .
          </div>
        )}

        {/* Respondents status */}
        <div className='shadow-sm rounded bg-white mt-3 p-2 p-md-4'>
          <RespondentStatus
            languageText={languageText}
            languages={languages}
            profileId={profileDetails.profileId}
            participantDetails={invitedRespondentsList}
            handleRefetch={handleRefetch}
          />
        </div>
      </div>

      {previewHTML && (
        <PreviewPopup
          languageText={languageText}
          previewHTML={previewHTML}
          onCloseClick={closePreviewModal}
        />
      )}

      {respondentsLimitReachedModal && (
        <RespondentsLimitReachedModal
          headerText={getLanguageValue(languageText, 'Limit Reached')}
          bodyText={`${getLanguageValue(languageText, 'Maximum')} ${
            profileDetails.noOfRespondents
          } ${getLanguageValue(languageText, 'respondents can be invited')}.`}
          cancelButtonText={getLanguageValue(languageText, 'Ok')}
          handleCancelClick={closeRespondentsLimitReachedModal}
        />
      )}
    </>
  )
}

export default InviteRespondents
