import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

import Input from '../../../../components/formComponents/input'
import TextArea from '../../../../components/formComponents/textArea'
import { PreviewPopup } from '../../../profileList/addProfile/emailSMSInvitation/previewPopup'
import { PhoneNumberInput } from '../../../employeeList/addEmployeeModal/phoneNumberInput'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { E164Number } from 'libphonenumber-js/types'
import { CustomModal } from '../../../../components/modals/customModal'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { getRespondentEmailPreview, sendRespondentsInvitation } from '../../actions'
import { EmailStatus, SmsStatus } from '../../../profileList/editProfile/interface'
import { IRespondentEmailPreviewBody } from './sendRespondentsInvitation'
import { SelectLanguageDropdownModal } from '@app/components/languagePageComponent/selectLanguageDropdownModal'
import { addToast, getLanguageTextByName, setSpinner } from '../../../actions'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { LanguageCode } from '@app/containers/commonEnums'
import { ProfileId } from '@app/containers/reducer'

export interface IRespondentsInvitationBody {
  id: number
  message: string
  ownMessage: string
  isEmailOrSms: boolean
  languageId: number
  isEmailOrSmsReminder: boolean
  telephone: string
}

interface IResentRespondentInviteProps {
  languageText: ITranslationObject
  profileId: ProfileId
  respondentId: number
  name: string
  email: string
  emailOwnMessage: string
  smsOwnMessage: string
  phoneNumber: string
  languageId: number
  languages: IDropdownList[]
  emailStatus: number
  smsStatus: number
  closeModal: () => void
  handleSendClick: () => void
}

export const ResentRespondentInvite: React.FC<IResentRespondentInviteProps> = (props) => {
  const {
    languageText,
    profileId,
    respondentId,
    name,
    email,
    phoneNumber,
    languageId,
    languages,
    emailOwnMessage,
    smsOwnMessage,
    emailStatus,
    smsStatus,
    closeModal,
    handleSendClick,
  } = props

  const dispatch = useDispatch()

  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownList>({
    id: 0,
    displayName: '',
    value: '',
  })
  const [emailMessage, setEmailMessage] = useState<string>(emailOwnMessage ?? '')
  const [smsMessage, setSmsMessage] = useState<string>(smsOwnMessage ?? '')
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('')
  const [smsWarningModal, setSmsWarningModal] = useState<boolean>(false)
  const [previewHTML, setPreviewHTML] = useState<string>('')
  const [defaultSmsMessage, setDefaultSmsMessage] = useState<string>('')
  const [defaultEmailMessage, setDefaultEmailMessage] = useState<string>('')
  const [isSmsInvite, setIsSmsInvite] = useState<boolean>(false)
  const [isLargeText, setIsLargeText] = useState<boolean>(false)
  const [changeLanguageModal, setChangeLanguageModal] = useState<boolean>(false)

  useEffect(() => {
    let selectedLang = languages.find((language) => language.id === languageId)

    // TODO: this is a workaround for invitees that have their language set
    //   to something _not_ marked with 'ShowParticipant'. it should never hapen
    //   for fresh data, but it may happen for stale data that we haven't updated
    //   in a while (also migrated customers).
    //   -johan, 2024-09-05
    if (!selectedLang) {
      selectedLang = languages.find((lang) => lang.value === LanguageCode.English)
    }

    setSelectedLanguage(selectedLang!)
  }, [])

  useEffect(() => {
    if (selectedLanguage.value) {
      getLanguageTextByName(selectedLanguage.value, dispatch).then((lang) => {
        setDefaultSmsMessage(getLanguageValue(lang, 'AP-DE-ST-RespStatusSMSInviteMsg'))
        setDefaultEmailMessage(getLanguageValue(lang, 'AP-DE-ST-RespStatusSMSInviteMsg'))
      })
    }
  }, [selectedLanguage])

  useEffect(() => {
    setPhoneNumberValue(phoneNumber)
  }, [phoneNumber])

  const handleSaveLanguage = (selectedValue: string): void => {
    const selectedLang = languages.find((language) => language.value === selectedValue)
    setSelectedLanguage(selectedLang!)
  }

  // Email fn's
  const handleEmailTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setEmailMessage(e.target.value)
  }

  const onLanguageClick = (): void => {
    setChangeLanguageModal(true)
  }

  const closeLanguageModal = (): void => {
    setChangeLanguageModal(false)
  }

  const onResendInvite = (isEmail = false): void => {
    dispatch(setSpinner(true))

    let isEmailOrSmsReminder: boolean = false
    if (isEmail && emailStatus !== EmailStatus.NotSent) {
      isEmailOrSmsReminder = true
    } else if (!isEmail && smsStatus !== SmsStatus.NotSent) {
      isEmailOrSmsReminder = true
    }

    const body: IRespondentsInvitationBody = {
      id: respondentId,
      isEmailOrSms: isEmail,
      message: isEmail ? '' : defaultSmsMessage,
      ownMessage: isEmail ? emailMessage : smsMessage,
      languageId: selectedLanguage.id,
      isEmailOrSmsReminder: isEmailOrSmsReminder,
      telephone: isEmail ? '' : phoneNumberValue,
    }
    sendRespondentsInvitation([body], selectedLanguage.value, dispatch)
      .then((response) => {
        if (response?.success) {
          dispatch(addToast('Resent invitation successfully') as AnyAction)
        }
      })
      .finally(() => {
        handleSendClick()
        dispatch(setSpinner(false))
      })
  }
  const onSendEmailClick = (): void => onResendInvite(true)

  const onPreviewClick = (): void => {
    const body: IRespondentEmailPreviewBody = {
      profileId: profileId,
      languageCode: selectedLanguage.value,
      ownMsg: emailMessage,
    }
    getRespondentEmailPreview(body, dispatch).then((response) => {
      if (response) {
        setPreviewHTML(response)
      }
    })
  }

  const closePreviewModal = (): void => setPreviewHTML('')

  // SMS fn's
  const onPhoneNumberChange = (value: E164Number): void => {
    setPhoneNumberValue(value)
  }

  const handleSmsTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSmsMessage(e.target.value.slice(0, 124))
    setIsSmsInvite(true)
  }

  const onSendSmsClick = (): void => {
    if (phoneNumberValue && phoneNumberValue.length > 7) {
      onResendInvite(false)
    } else {
      setSmsWarningModal(true)
    }
  }

  const handleCancelClick = (): void => setSmsWarningModal(false)
  return (
    <>
      <ModalComponent
        headerText={`${getLanguageValue(languageText, 'Send reminder to')} ${name}`}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={closeModal}
        width='lg'
      >
        <div className='d-flex flex-column mb-4 mb-md-n6'>
          <div className='fs-6'>
            {getLanguageValue(languageText, 'Select the instruction language')}:
          </div>
          <div className='fs-6 fw-bold cursor-pointer' onClick={onLanguageClick}>
            {selectedLanguage.displayName}
          </div>
        </div>

        <div className='border-bottom my-4'></div>

        {/* Email Reminder */}
        <div className='mb-4 fw-bold fs-5'>{getLanguageValue(languageText, 'Email reminder')}</div>
        <Input value={email} disable={true} />
        {defaultEmailMessage.length > 120 ? (
          isLargeText ? (
            <div className='fs-6 my-4'>
              <p>{defaultEmailMessage}</p>
              <button
                className='border-0 bg-white d-flex justify-content-end'
                onClick={() => setIsLargeText(false)}
              >
                {getLanguageValue(languageText, 'Show less')}
                <i className='bi bi-chevron-up text-gray-400 ms-2 my-1' />
              </button>
            </div>
          ) : (
            <div className='fs-6 my-4'>
              <p>{defaultEmailMessage.slice(0, 50)} ...</p>
              <button className='d-flex justify-content-end' onClick={() => setIsLargeText(true)}>
                {getLanguageValue(languageText, 'Show More')}
                <i className='bi bi-chevron-down text-gray-400 ms-2 my-1' />
              </button>
            </div>
          )
        ) : (
          <div className='fs-6 my-4'>{defaultSmsMessage}</div>
        )}
        <TextArea
          name='message'
          label={getLanguageValue(languageText, 'Own Message')}
          placeholder={getLanguageValue(languageText, 'Add own message')}
          value={emailMessage}
          onChange={handleEmailTextAreaChange}
        />
        <div className='d-flex align-items-center gap-3 mt-4'>
          <button className='btn btn-success' onClick={onSendEmailClick}>
            {getLanguageValue(languageText, 'Send')}
          </button>
          <span onClick={onPreviewClick} className='fs-6 text-decoration-underline cursor-pointer'>
            {getLanguageValue(languageText, 'Preview')}
          </span>
        </div>

        <div className='border-bottom my-4'></div>

        {/* SMS Reminder */}
        <p className=' fw-bold fs-5 mb-4'>{getLanguageValue(languageText, 'SMS reminder')}</p>
        <PhoneNumberInput
          value={(phoneNumber as E164Number) || ''}
          onChange={onPhoneNumberChange}
          placeholder='+123 45 67 89'
          languageText={languageText}
        />
        <div className='my-4'>
          <TextArea
            name='message'
            placeholder={getLanguageValue(languageText, 'Add own message')}
            value={smsMessage || defaultSmsMessage}
            onChange={handleSmsTextAreaChange}
            disable
          />
        </div>
        <button className='btn btn-success' onClick={onSendSmsClick}>
          {getLanguageValue(languageText, 'Send')}
        </button>
      </ModalComponent>

      {smsWarningModal && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'No Phone Number')}
          bodyText={getLanguageValue(languageText, 'Phone number is required to send the sms')}
          submitButtonText={getLanguageValue(languageText, 'Ok')}
          handleCancelClick={handleCancelClick}
          handleSubmitClick={handleCancelClick}
        />
      )}

      {changeLanguageModal && (
        <SelectLanguageDropdownModal
          languageText={languageText}
          languages={languages}
          languageValue={selectedLanguage.value}
          closeModal={closeLanguageModal}
          handleSaveLanguage={handleSaveLanguage}
        />
      )}

      {previewHTML && (
        <PreviewPopup
          languageText={languageText}
          previewHTML={previewHTML}
          onCloseClick={closePreviewModal}
        />
      )}
    </>
  )
}
