import React from 'react'

import { useAddProfileModal } from './hooks'
import { IndividualGroupBtnsEnum } from '../profileEnums'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import ActivityGroupSelectionStep from './activityGroupSelectionStep'
import ActivityDescriptionStep from './activityDescriptionStep'
import ActivityRoleStep from './activityRoleStep'
import ActivityRespondentsStep from './activityRespondentsStep'
import ActivityParticipantsStep from './activityParticipantsStep'
import ActivityInviteParticipantsStep from './activityInviteParticipantsStep'
import ActivityClientSelectionStep from './activityClientSelectionStep'
import ActivityConsultantClientSelectionStep from './activityConsultantClientSelectionStep'

export const AddProfileForm: React.FC = (props) => {
  const {
    scrollRef,
    languageText,
    isConsultant,
    selectedClientBtn,
    showEditModeConsultantClient,
    selectedIndividualGroupBtn,
    stepsCompleted,
    languages,
    createdActivityId,
    selectedCulture,
    showDescriptionSaveBtn,
    selectedSetRoleBtn,
    selectedSameRoleBtn,
    selectedTypeOfRoleBtn,
    typeOfRoleInput,
    customTypeOfRole,
    roleInputSelected,
    selectedNoOfRespondentsBtn,
    noOfRespondentsInput,
    customNoOfRespondents,
    respondentsInputSelected,
    respondentsConfirmModal,
    selectedParticipantChangeBtn,
    roleStepError,
    showRoleSaveBtn,
    roleSettings,
    inviteRespondents,
    showRespondentsSaveBtn,
    typeOfRoleButtonList,
    filter,
    participantsList,
    selectedParticipants,
    participantError,
    showParticipantSaveBtn,
    selectedParticipantsResponse,
    respondentErrorMessage,
    showEditModeRoleButtonList,
    showEditModeNoOfRespondent,
    showEditModeRoleType,
    showEditModeSelectedSameRoleBtn,
    showEditModeGroupSelect,
    showEditModeSelectedParticipantBtnClick,
    clientData,
    clientId,
    clientEditMode,
    handleClientSelect,
    refreshClientList,
    handleConsultantClientBtn,
    handleConsultantClientSelect,
    handleSaveConsultantClient,
    handleIndividualGroupBtns,
    handleFirstStepSave,
    setShowDescriptionSaveBtn,
    handleSecondStepSave,
    closeClientSelectModal,
    onSetRoleBtnClick,
    onSameRoleBtnClick,
    onTypeOfRoleChange,
    onTypeOfRoleBtnClick,
    handleTypeOfRoleFocus,
    onNoOfRespondentsChange,
    onNoOfRespondentsClick,
    openSelectClientEditMode,
    handleNoOfRespondentsFocus,
    cancelRespondentsConfirmModal,
    SubmitRespondentsConfirmModal,
    handleParticipantChangeRoleClick,
    handleThirdStepSave,
    handleRespondentsCheckboxChange,
    handleFourthStepSave,
    handleSearch,
    handleSelectParticipant,
    handleRemoveParticipant,
    saveRoleSettings,
    handleFifthStepSave,
    handleSixthStepSave,
    setShowEditModeConsultantClient,
    setShowEditModeNoOfRespondent,
    setShowEditModeRoleType,
    setShowEditModeRoleButtonList,
    setShowEditModeSelectedSameRoleBtn,
    setShowEditModeGroupSelect,
    setshowEditModeSelectedParticipantBtnClick,
  } = useAddProfileModal()

  return (
    <div ref={scrollRef}>
      <div className='p-2 pt-4 p-md-4'>
        <h1 className='mb-4 fs-4 fw-bold'>{getLanguageValue(languageText, 'Add Profile')}</h1>

        {/* Client List Select */}
        {isConsultant ? (
          <ActivityConsultantClientSelectionStep
            languageText={languageText}
            selectedBtn={selectedClientBtn}
            clientData={clientData}
            clientId={clientId}
            showEditModeConsultantClient={showEditModeConsultantClient}
            stepsCompleted={stepsCompleted}
            handleConsultantClientBtn={handleConsultantClientBtn}
            handleConsultantClientSelect={handleConsultantClientSelect}
            setShowEditModeConsultantClient={setShowEditModeConsultantClient}
            handleSaveClick={handleSaveConsultantClient}
            refreshClientList={refreshClientList}
            handleClientSelect={handleClientSelect}
            closeClientSelectModal={closeClientSelectModal}
            openSelectClientEditMode={openSelectClientEditMode}
          />
        ) : (
          <ActivityClientSelectionStep
            languageText={languageText}
            clientData={clientData}
            clientId={clientId}
            clientEditMode={clientEditMode}
            refreshClientList={refreshClientList}
            handleClientSelect={handleClientSelect}
            closeClientSelectModal={closeClientSelectModal}
            openSelectClientEditMode={openSelectClientEditMode}
          />
        )}

        {/* Group or Individual selection */}
        {((isConsultant && stepsCompleted.length !== 0) || !isConsultant) && (
          <ActivityGroupSelectionStep
            languageText={languageText}
            selectedBtn={selectedIndividualGroupBtn}
            createdActivityId={createdActivityId}
            stepsCompleted={stepsCompleted}
            handleButtonChange={handleIndividualGroupBtns}
            onSaveClick={handleFirstStepSave}
            showEditModeGroupSelect={showEditModeGroupSelect}
            setShowEditModeGroupSelect={setShowEditModeGroupSelect}
          />
        )}

        {/* Description Step */}
        {stepsCompleted.includes(1) && (
          <>
            <ActivityDescriptionStep
              languageText={languageText}
              createdActivityId={createdActivityId}
              clientId={clientId}
              selectedIndividualGroupBtn={selectedIndividualGroupBtn}
              showDescriptionSaveBtn={showDescriptionSaveBtn}
              setShowDescriptionSaveBtn={setShowDescriptionSaveBtn}
              onSaveClick={handleSecondStepSave}
            />
          </>
        )}

        {/* Role Selection Step */}
        {stepsCompleted.includes(2) && (
          <div className='p-4 mb-4 shadow-sm rounded bg-white'>
            <ActivityRoleStep
              languageText={languageText}
              selectedIndividualGroupBtn={selectedIndividualGroupBtn}
              typeOfRoleButtonList={typeOfRoleButtonList}
              selectedSetRoleBtn={selectedSetRoleBtn}
              selectedSameRoleBtn={selectedSameRoleBtn}
              selectedTypeOfRoleBtn={selectedTypeOfRoleBtn}
              typeOfRoleInput={typeOfRoleInput}
              customTypeOfRole={customTypeOfRole}
              roleInputSelected={roleInputSelected}
              selectedNoOfRespondentsBtn={selectedNoOfRespondentsBtn}
              noOfRespondentsInput={noOfRespondentsInput}
              customNoOfRespondents={customNoOfRespondents}
              respondentsInputSelected={respondentsInputSelected}
              respondentsConfirmModal={respondentsConfirmModal}
              selectedParticipantChangeBtn={selectedParticipantChangeBtn}
              roleStepError={roleStepError}
              showRoleSaveBtn={showRoleSaveBtn}
              onSetRoleBtnClick={onSetRoleBtnClick}
              onSameRoleBtnClick={onSameRoleBtnClick}
              onTypeOfRoleChange={onTypeOfRoleChange}
              onTypeOfRoleBtnClick={onTypeOfRoleBtnClick}
              handleTypeOfRoleFocus={handleTypeOfRoleFocus}
              onNoOfRespondentsChange={onNoOfRespondentsChange}
              onNoOfRespondentsClick={onNoOfRespondentsClick}
              handleNoOfRespondentsFocus={handleNoOfRespondentsFocus}
              cancelRespondentsConfirmModal={cancelRespondentsConfirmModal}
              SubmitRespondentsConfirmModal={SubmitRespondentsConfirmModal}
              handleParticipantChangeRoleClick={handleParticipantChangeRoleClick}
              onSaveClick={handleThirdStepSave}
              setShowEditModeNoOfRespondent={setShowEditModeNoOfRespondent}
              showEditModeNoOfRespondent={showEditModeNoOfRespondent}
              showEditModeRoleType={showEditModeRoleType}
              setShowEditModeRoleType={setShowEditModeRoleType}
              setShowEditModeRoleButtonList={setShowEditModeRoleButtonList}
              showEditModeRoleButtonList={showEditModeRoleButtonList}
              showEditModeSelectedSameRoleBtn={showEditModeSelectedSameRoleBtn}
              setShowEditModeSelectedSameRoleBtn={setShowEditModeSelectedSameRoleBtn}
              setshowEditModeSelectedParticipantBtnClick={
                setshowEditModeSelectedParticipantBtnClick
              }
              showEditModeSelectedParticipantBtnClick={showEditModeSelectedParticipantBtnClick}
            />
          </div>
        )}

        {/* Invite respondents step */}
        {stepsCompleted.includes(3) && (
          <div className='rounded shadow-sm bg-white p-4 mb-4'>
            <ActivityRespondentsStep
              languageText={languageText}
              isGroup={selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group}
              inviteRespondents={inviteRespondents}
              showRespondentsSaveBtn={showRespondentsSaveBtn}
              handleRespondentsCheckboxChange={handleRespondentsCheckboxChange}
              onSaveClick={handleFourthStepSave}
              respondentErrorMessage={respondentErrorMessage}
            />
          </div>
        )}

        {/* Participants step */}
        {stepsCompleted.includes(4) && (
          <>
            <ActivityParticipantsStep
              languageText={languageText}
              roleSettings={roleSettings}
              filter={filter}
              participantsList={participantsList}
              selectedParticipants={selectedParticipants}
              participantError={participantError}
              showParticipantSaveBtn={showParticipantSaveBtn}
              selectedIndividualGroupBtn={selectedIndividualGroupBtn}
              handleSearch={handleSearch}
              handleSelectParticipant={handleSelectParticipant}
              handleRemoveParticipant={handleRemoveParticipant}
              saveRoleSettings={saveRoleSettings}
              onSaveClick={handleFifthStepSave}
            />
          </>
        )}

        {/* Invite Participants step */}
        {stepsCompleted.includes(5) && (
          <>
            <ActivityInviteParticipantsStep
              languageText={languageText}
              createdActivityId={createdActivityId}
              languages={languages}
              cultureValue={selectedCulture}
              selectedIndividualGroupBtn={selectedIndividualGroupBtn}
              selectedParticipants={selectedParticipantsResponse}
              onSaveClick={handleSixthStepSave}
            />
          </>
        )}
      </div>
    </div>
  )
}
