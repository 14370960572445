import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import Input from '@app/components/formComponents/input'
import { ModalComponent } from '@app/components/modals/modalComponent'
import * as React from 'react'
import { LanguageTextKeyPair } from './actions'
import { ILanguageText } from '@app/containers/reducer'
import Checkbox from '@app/components/formComponents/checkbox'
import { ILanguage } from '@app/containers/commonInterfaces'

type Props = {
  kind: 'add' | 'edit'
  languages: ReadonlyArray<ILanguage>
  languageText: ILanguageText
  onChange: (next: LanguageTextKeyPair) => void
  onClose: () => void
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void
  value: LanguageTextKeyPair
}

function isRelevantLanguage(text: LanguageTextKeyPair, lang: ILanguage): boolean {
  return (
    lang.name === 'en-GB' ||
    (text.allowFacilitatorRole && lang.showFacilitator) ||
    (text.allowParticipantRole && lang.showParticipant)
  )
}

export const AddEditLanguageTextModal: React.FC<Props> = (props) => {
  const [showAllLanguages, setShowAllLanguages] = React.useState(false)

  return (
    <ModalComponent
      width='xl'
      headerText={
        props.kind === 'edit'
          ? getLanguageValue(props.languageText, 'Edit key')
          : getLanguageValue(props.languageText, 'Add key')
      }
      submitButtonText={getLanguageValue(props.languageText, 'Save')}
      submitButtonDisabled={!props.value.key || !props.value.values['en-GB']}
      cancelButtonText={getLanguageValue(props.languageText, 'Cancel')}
      handleSubmitClick={props.onSubmit}
      handleCancelClick={props.onClose}
    >
      <Input
        label={getLanguageValue(props.languageText, 'Name')}
        handleInputChange={(event) => {
          props.onChange({
            ...props.value,
            key: event.target.value,
          })
        }}
        value={props.value.key}
      />

      <div className='row'>
        <div className='col-3'>
          <Checkbox
            name='allowAdminRole'
            handleCheckboxChange={(event) => {
              props.onChange({
                ...props.value,
                allowAdminRole: event.target.checked,
              })
            }}
            label={getLanguageValue(props.languageText, 'Allow admins')}
            value={props.value.allowAdminRole}
          />
        </div>

        <div className='col-3'>
          <Checkbox
            name='allowFacilitatorRole'
            handleCheckboxChange={(event) => {
              props.onChange({
                ...props.value,
                allowFacilitatorRole: event.target.checked,
              })
            }}
            label={getLanguageValue(props.languageText, 'Allow facilitators')}
            value={props.value.allowFacilitatorRole}
          />
        </div>

        <div className='col-3'>
          <Checkbox
            name='allowParticipantRole'
            handleCheckboxChange={(event) => {
              props.onChange({
                ...props.value,
                allowParticipantRole: event.target.checked,
              })
            }}
            label={getLanguageValue(props.languageText, 'Allow participants')}
            value={props.value.allowParticipantRole}
          />
        </div>

        <div className='col-3'>
          <Checkbox
            name='allowRespondentRole'
            handleCheckboxChange={(event) => {
              props.onChange({
                ...props.value,
                allowRespondentRole: event.target.checked,
              })
            }}
            label={getLanguageValue(props.languageText, 'Allow respondents')}
            value={props.value.allowRespondentRole}
          />
        </div>
      </div>
      <Input
        label={getLanguageValue(props.languageText, 'Notes')}
        handleInputChange={(event) => {
          props.onChange({
            ...props.value,
            notes: event.target.value,
          })
        }}
        value={props.value.notes}
      />
      <hr />

      <div className='mb-3'>
        <Checkbox
          handleCheckboxChange={(event) => {
            setShowAllLanguages(event.target.checked)
          }}
          label={getLanguageValue(props.languageText, 'Show all languages')}
          value={showAllLanguages}
        />
      </div>

      {props.languages.map((lang, idx) => {
        const languageCode = lang.name

        if (!showAllLanguages && !isRelevantLanguage(props.value, lang)) {
          return <React.Fragment key={idx} />
        }

        return (
          <Input
            key={idx}
            label={lang.displayName}
            value={props.value.values[languageCode]}
            required={languageCode === 'en-GB'}
            handleInputChange={(event) => {
              props.onChange({
                ...props.value,
                values: {
                  ...props.value.values,
                  [languageCode]: event.target.value,
                },
              })
            }}
          />
        )
      })}
    </ModalComponent>
  )
}
