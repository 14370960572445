import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { EditModalEnum, IClientFacilitator } from './editProfileDescriptionStep'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import { ActivityStatus } from '../../../profileEnums'
import ReactDatePicker from '../../../../../components/datePicker/ReactDatePicker'
import Input from '../../../../../components/formComponents/input'
import { recalculateProfiles, saveProfileEditFieldValue, updateCompletionDate } from '../../actions'
import { RootState } from '@app/store/configureStore'
import { getFormattedDateOnly } from '../../../../../commonUtils/dateFunctionsHelper'
import EditProfileConfirmationModal from './editProfileConfirmationModal'
import { IEditActivity } from '../../interface'
import { addToast, setSpinner } from '../../../../actions'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { AnyAction } from 'redux'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { ActivityId } from '@app/containers/reducer'

export interface ISaveProfileEditFieldValueBody {
  id: ActivityId
  fieldName: string
  fieldValue: string
}

interface IEditProfileDescriptionModalProps {
  id: ActivityId
  languageText: ITranslationObject
  editModalName: string
  statusValue: number
  profileData: IEditActivity
  completionDateValue: string
  nameValue: string
  notesValue: string
  languages: IDropdownList[]
  countries: IDropdownList[]
  facilitators: IClientFacilitator[]
  languageName: string | JSX.Element
  countryName: string | JSX.Element
  facilitatorId: number
  sourceType: string
  setStatusValue: React.Dispatch<React.SetStateAction<number>>
  setCompletionDateValue: React.Dispatch<React.SetStateAction<string>>
  setNameValue: React.Dispatch<React.SetStateAction<string>>
  setNotesValue: React.Dispatch<React.SetStateAction<string>>
  setLanguageName: React.Dispatch<React.SetStateAction<string | JSX.Element>>
  setCountryName: React.Dispatch<React.SetStateAction<string | JSX.Element>>
  setSourceType: React.Dispatch<React.SetStateAction<string>>
  handleLanguageSelect: (languageId: number) => void
  handleFacilitatorSelect: (facilitatorId: number) => void
  refetchFullProfile: () => void
  closeModal: () => void
}

export const EditProfileDescriptionModal: React.FC<IEditProfileDescriptionModalProps> = (props) => {
  const {
    id,
    languageText,
    editModalName,
    profileData,
    statusValue,
    completionDateValue,
    nameValue,
    notesValue,
    languages,
    countries,
    facilitators,
    languageName,
    countryName,
    facilitatorId,
    sourceType,
    setStatusValue,
    setCompletionDateValue,
    setNameValue,
    setNotesValue,
    setLanguageName,
    setCountryName,
    setSourceType,
    handleLanguageSelect,
    handleFacilitatorSelect,
    refetchFullProfile,
    closeModal,
  } = props

  const dispatch = useDispatch()
  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)

  const statusDropdown = useMemo(
    () =>
      [
        {
          id: ActivityStatus.Created,
          displayName: getLanguageValue(languageText, ActivityStatus[ActivityStatus.Created]),
          value: String(ActivityStatus.Created),
        },
        {
          id: ActivityStatus.Active,
          displayName: getLanguageValue(languageText, ActivityStatus[ActivityStatus.Active]),
          value: String(ActivityStatus.Active),
        },
        {
          id: ActivityStatus.Completed,
          displayName: getLanguageValue(languageText, ActivityStatus[ActivityStatus.Completed]),
          value: String(ActivityStatus.Completed),
        },
        {
          id: ActivityStatus.Invoiced,
          displayName: getLanguageValue(languageText, ActivityStatus[ActivityStatus.Invoiced]),
          value: String(ActivityStatus.Invoiced),
        },
        {
          id: ActivityStatus.Cancelled,
          displayName: getLanguageValue(languageText, ActivityStatus[ActivityStatus.Cancelled]),
          value: String(ActivityStatus.Cancelled),
        },
        {
          id: ActivityStatus.ProBono,
          displayName: getLanguageValue(languageText, ActivityStatus[ActivityStatus.ProBono]),
          value: String(ActivityStatus.ProBono),
        },
        {
          id: ActivityStatus.Delivered,
          displayName: getLanguageValue(languageText, ActivityStatus[ActivityStatus.Delivered]),
          value: String(ActivityStatus.Delivered),
        },
      ] as IDropdownList[],
    [languageText]
  )

  // BIG FIXME:
  //   all these copied properties are incredibly confusing. they are suffixed with 'value'
  //   so they won't conflict with the input property with the exact same name. but why do
  //   we repeat ourselves and have a prop, a change handler, a stateful value etc. for
  //   every single property? why not just make it generic over the key?
  //
  //   -johan, 2024-05-20
  const [status, setStatus] = useState<string>('')
  const [completionDate, setCompletionDate] = useState<Date | null>(null)
  const [name, setName] = useState<string>('') // Description
  const [notes, setNotes] = useState<string>('')
  const [languageValue, setLanguageValue] = useState<string>('')
  const [countryValue, setCountryValue] = useState<string>('')
  const [selectedFacilitatorId, setSelectedFacilitatorId] = useState<number>(0)
  const [sourceTypeValue, setSourceTypeValue] = useState<string>(sourceType)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const completedAndDeliveredProfileCount =
    profileData.completedProfileCount + profileData.deliveredProfileCount
  const [isWarningMsg, setIsWarningMsg] = useState<boolean>(false)

  useEffect(() => {
    const languageValue =
      languages.find((language) => language.displayName === languageName)?.value ?? ''
    const countryValue =
      countries.find((country) => country.displayName === countryName)?.value ?? ''

    switch (editModalName) {
      case EditModalEnum.status:
        setStatus(String(statusValue))
        break
      case EditModalEnum.completionDate:
        setCompletionDate(new Date(completionDateValue))
        break
      case EditModalEnum.name:
        setName(nameValue)
        break
      case EditModalEnum.notes:
        setNotes(notesValue)
        break
      case EditModalEnum.language:
        setLanguageValue(languageValue)
        break
      case EditModalEnum.country:
        setCountryValue(countryValue)
        break
      case EditModalEnum.facilitator:
        setSelectedFacilitatorId(facilitatorId)
        break
      default:
        break
    }
  }, [])

  const renderStatusElement = (): JSX.Element => {
    const handleStatusSelect = (selectedItem: IDropdownSelectedItem): void => {
      setStatus(selectedItem.value)
    }
    return (
      <DropdownSelect
        name={EditModalEnum.status}
        label={getLanguageValue(languageText, 'Status')}
        dropdownHeight='mh-325px'
        list={statusDropdown}
        value={status}
        handleDropdownSelect={handleStatusSelect}
      />
    )
  }

  const renderDateElement = (): JSX.Element => {
    const handleDateSelect = (name: string, date: Date): void => {
      setCompletionDate(date)
      // If admin selects previous date, show warning message
      if (isLoggedInRoleAdmin(loggedInUserRole) && date < new Date()) {
        setIsWarningMsg(true)
      } else setIsWarningMsg(false)
    }

    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    return (
      <>
        <ReactDatePicker
          label={getLanguageValue(languageText, 'Completion date')}
          name={editModalName}
          placeholder={getLanguageValue(languageText, 'Select Completion Date')}
          date={completionDate}
          handleDateSelect={handleDateSelect}
          minDate={!isLoggedInRoleAdmin(loggedInUserRole) ? tomorrow : undefined}
          required
        />
        {isWarningMsg && (
          <div className='text-danger'>
            {getLanguageValue(
              languageText,
              `Don't forget to update status after changing the date`
            )}
          </div>
        )}
      </>
    )
  }

  const renderDescriptionElement = (): JSX.Element => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value)
    }
    return (
      <Input
        name={editModalName}
        label={getLanguageValue(languageText, 'Description')}
        placeholder={getLanguageValue(languageText, 'Description')}
        value={name}
        handleInputChange={handleInputChange}
        required
      />
    )
  }

  const renderNotesElement = (): JSX.Element => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setNotes(e.target.value)
    }
    return (
      <Input
        name={editModalName}
        label={getLanguageValue(languageText, 'Notes')}
        placeholder={getLanguageValue(languageText, 'Notes')}
        value={notes}
        handleInputChange={handleInputChange}
      />
    )
  }

  const renderLanguageElement = (): JSX.Element => {
    const handleLanguageSelect = (selectedItem: IDropdownSelectedItem): void => {
      setLanguageValue(selectedItem.value)
    }
    return (
      <DropdownSelect
        name={EditModalEnum.language}
        label={getLanguageValue(languageText, 'Norm')}
        list={languages}
        value={languageValue}
        handleDropdownSelect={handleLanguageSelect}
        searchOption
      />
    )
  }

  const renderCountryElement = (): JSX.Element => {
    const handleCountrySelect = (selectedItem: IDropdownSelectedItem): void => {
      setCountryValue(selectedItem.value)
    }
    return (
      <DropdownSelect
        name={EditModalEnum.country}
        label={getLanguageValue(languageText, 'Country')}
        list={countries}
        value={countryValue}
        handleDropdownSelect={handleCountrySelect}
        searchOption
      />
    )
  }

  const renderFacilitatorElement = (): JSX.Element => {
    const handleFacilitatorSelect = (selectedItem: IDropdownSelectedItem): void => {
      setSelectedFacilitatorId(selectedItem.id)
    }
    const facilitatorsList: IDropdownList[] = facilitators.map((item) => ({
      id: item.id,
      displayName: item.name,
      value: item.id.toString(),
    }))

    return (
      <DropdownSelect
        name={EditModalEnum.facilitator}
        label={getLanguageValue(languageText, 'Facilitator')}
        list={facilitatorsList}
        value={selectedFacilitatorId.toString()}
        handleDropdownSelect={handleFacilitatorSelect}
      />
    )
  }

  const renderSourceTypeElement = (): JSX.Element => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSourceTypeValue(e.target.value)
    }
    return (
      <Input
        name={editModalName}
        label={getLanguageValue(languageText, 'Source type')}
        placeholder={getLanguageValue(languageText, 'Source type')}
        value={sourceTypeValue}
        handleInputChange={handleInputChange}
      />
    )
  }

  const renderModalBody = (): JSX.Element => {
    let element: JSX.Element = <></>

    switch (editModalName) {
      case EditModalEnum.status:
        element = renderStatusElement()
        break
      case EditModalEnum.completionDate:
        element = renderDateElement()
        break
      case EditModalEnum.name:
        element = renderDescriptionElement()
        break
      case EditModalEnum.notes:
        element = renderNotesElement()
        break
      case EditModalEnum.language:
        element = renderLanguageElement()
        break
      case EditModalEnum.country:
        element = renderCountryElement()
        break
      case EditModalEnum.facilitator:
        element = renderFacilitatorElement()
        break
      case EditModalEnum.sourceType:
        element = renderSourceTypeElement()
        break
      default:
        break
    }

    return element
  }

  const openShowConfirmationModal = (): void => {
    setShowConfirmationModal(true)
  }

  const closeShowConfirmationModal = (): void => {
    setShowConfirmationModal(false)
  }

  const updateCompletionDateByAdmin = (): void => {
    const formattedDate = getFormattedDateOnly(completionDate!)
    updateCompletionDate(id, formattedDate, dispatch).then((response) => {
      if (response) {
        dispatch(addToast('Changes saved successfully') as AnyAction)
        setCompletionDateValue(formattedDate)
        closeModal()
      }
    })
  }

  const handleSaveClick = async (): Promise<void> => {
    if (isLoggedInRoleAdmin(loggedInUserRole) && editModalName === EditModalEnum.completionDate) {
      updateCompletionDateByAdmin()
    } else {
      const body: ISaveProfileEditFieldValueBody = {
        id,
        fieldName: '',
        fieldValue: '',
      }

      const formattedDate = getFormattedDateOnly(completionDate!)
      const languageId = String(
        languages.find((language) => language.value === languageValue)?.id ?? ''
      )
      const countryId = String(
        countries.find((country) => country.value === countryValue)?.id ?? ''
      )
      const userId = String(
        facilitators.find((facilitator) => facilitator.id === selectedFacilitatorId)?.id ?? ''
      )

      switch (editModalName) {
        case EditModalEnum.status:
          body.fieldName = 'Status'
          body.fieldValue = status
          break
        case EditModalEnum.completionDate:
          body.fieldName = 'CompletionDate'
          body.fieldValue = formattedDate
          break
        case EditModalEnum.name:
          body.fieldName = 'Name'
          body.fieldValue = name
          break
        case EditModalEnum.notes:
          body.fieldName = 'Notes'
          body.fieldValue = notes
          break
        case EditModalEnum.language:
          body.fieldName = 'IDILanguageId'
          body.fieldValue = languageId
          break
        case EditModalEnum.country:
          body.fieldName = 'CountryId'
          body.fieldValue = countryId
          break
        case EditModalEnum.facilitator:
          body.fieldName = 'FacilitatorId'
          body.fieldValue = userId
          break
        case EditModalEnum.sourceType:
          body.fieldName = 'SourceType'
          body.fieldValue = sourceTypeValue
          break
        default:
          break
      }

      dispatch(setSpinner(true))
      await saveProfileEditFieldValue(body, dispatch)
        .then(async (response) => {
          if (response) {
            const formattedDate = getFormattedDateOnly(completionDate!)
            const languageName =
              languages.find((language) => language.value === languageValue)?.displayName ?? ''
            const languageId =
              languages.find((language) => language.value === languageValue)?.id ?? 0
            const countryName =
              countries.find((country) => country.value === countryValue)?.displayName ?? ''

            switch (editModalName) {
              case EditModalEnum.status:
                setStatusValue(Number(status))
                if (status === String(ActivityStatus.Delivered)) refetchFullProfile()
                break
              case EditModalEnum.completionDate:
                setCompletionDateValue(formattedDate)
                break
              case EditModalEnum.name:
                setNameValue(name)
                break
              case EditModalEnum.notes:
                setNotesValue(notes)
                break
              case EditModalEnum.language:
                setLanguageName(languageName)
                handleLanguageSelect(languageId)
                closeModal()
                await recalculateProfiles(profileData.id, dispatch)
                break
              case EditModalEnum.country:
                setCountryName(countryName)
                break
              case EditModalEnum.facilitator:
                handleFacilitatorSelect(selectedFacilitatorId)
                break
              case EditModalEnum.sourceType:
                setSourceType(sourceTypeValue)
                break
              default:
                break
            }
            dispatch(addToast('Changes saved successfully') as AnyAction)
            closeModal()
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Edit Profile')}
        submitButtonText={getLanguageValue(languageText, 'Save')}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        handleSubmitClick={
          editModalName === EditModalEnum.language ? openShowConfirmationModal : handleSaveClick
        }
        handleCancelClick={closeModal}
      >
        {renderModalBody()}
      </ModalComponent>

      {showConfirmationModal && (
        <EditProfileConfirmationModal
          languageText={languageText}
          completedAndDeliveredProfileCount={completedAndDeliveredProfileCount}
          closeShowConfirmationModal={closeShowConfirmationModal}
          handleSaveClick={handleSaveClick}
        />
      )}
    </>
  )
}
