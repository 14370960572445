import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../api'
import { setNotification } from '../../actions'
import { ICreateProfileInfo, IUpdateProfileInfo } from './activityDescriptionStep'
import { ISendParticipantsInvitationBody } from './activityInviteParticipantsStep'
import { IFetchParticipantsParams } from './activityParticipantsStep'
import { ICreateUpdateMessageBody } from './emailSMSInvitation/addEditMessage'
import { ISavedMessagesBody } from './emailSMSInvitation/invitationMessageListModal'
import {
  ICreateProfileBody,
  IRoleStepBody,
  IUpdateProfileClientBody,
  IUpdateProfileRespondentsBody,
  IUpdateProfileTypeBody,
} from './hooks'
import { ActivityId } from '@app/containers/reducer'
import { ApiResponse, ApiResult } from '@app/types'
import { IEmployee } from '@app/containers/employeeList/hooks'
import { IEmployeeTableProps } from '@app/containers/employeeList/uploadEmployeeModal/hooks'

export const updateProfileType = async (
  body: IUpdateProfileTypeBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(apiEndPoints.updateProfileType, JSON.stringify(body), {
      params: body,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createProfileCulture = async (
  body: ICreateProfileInfo,
  dispatch: Dispatch
): Promise<ActivityId> => {
  try {
    const response = await api.post(apiEndPoints.createProfileCulture, JSON.stringify(body), {})
    if (!response.data.success) {
      return Promise.reject()
    }
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateProfileCulture = async (
  body: IUpdateProfileInfo,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(apiEndPoints.updateProfileCulture, JSON.stringify(body), {})
    if (!response.data.success) {
      return Promise.reject()
    }
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateProfileRole = async (
  body: IRoleStepBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(apiEndPoints.updateProfileRole, JSON.stringify(body), {})
    if (!response.data.success) {
      return Promise.reject()
    }
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateProfileRespondents = async (
  body: IUpdateProfileRespondentsBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.put(apiEndPoints.updateProfileRespondents, JSON.stringify(body), {})
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getProfileEmployeesByClientId = async (
  body: IFetchParticipantsParams,
  dispatch: Dispatch
): Promise<ApiResult<IEmployee>> => {
  try {
    const response = await api.get<ApiResponse<ApiResult<IEmployee>>>(
      apiEndPoints.getProfileEmployeesByClientId,
      {
        params: body,
      }
    )
    if (!response.data.success) {
      return Promise.reject(response.data)
    }
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createProfileParticipants = async (
  body: ICreateProfileBody[],
  activityId: ActivityId,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createProfileParticipants, JSON.stringify(body), {
      params: { activityId },
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createParticipantsFromEditProfile = async (
  body: ICreateProfileBody[],
  activityId: ActivityId,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.createParticipantsFromEditProfile,
      JSON.stringify(body),
      {
        params: { activityId },
      }
    )
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getAllSavedMessages = async (
  body: ISavedMessagesBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getAllSavedMessages, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createSavedMessage = async (
  body: ICreateUpdateMessageBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createSavedMessage, JSON.stringify(body), {})
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateSavedMessage = async (
  body: ICreateUpdateMessageBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.put(apiEndPoints.updateSavedMessage, JSON.stringify(body), {})
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteSavedMessage = async (
  savedMessageId: number,
  dispatch: Dispatch
): Promise<any> => {
  const paramQuery = {
    id: savedMessageId,
  }
  try {
    const response = await api.delete(apiEndPoints.deleteSavedMessage, {
      params: paramQuery,
    })
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const sendParticipantsInvitation = async (
  body: ISendParticipantsInvitationBody[],
  languageCode: string,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.sendParticipantsInvitation, JSON.stringify(body), {
      params: { languageCode },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const previewParticipantInvitation = async (
  body: ISendParticipantsInvitationBody,
  languageCode: string,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.previewParticipantInvitation,
      JSON.stringify(body),
      {
        params: { languageCode },
      }
    )
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateProfileClient = async (
  body: IUpdateProfileClientBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(apiEndPoints.updateProfileClient, JSON.stringify(body), {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
