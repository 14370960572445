import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { getFacilitatorParticipantsProfilesReport } from '../actions'
import DimensionsChart from '../../../components/dimensions/dimensionsChart'
import { ToggleCheckbox } from '@app/components/formComponents/toggleCheckbox'
import { IProfileGroupReport } from '../../profileList/editProfile/interface'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import {
  IFacilitatorDashboardOrganizationView,
  IFacilitatorProfilesReportParams,
} from '../interface'
import { GroupAffDirGraph } from '../../profileList/editProfile/modals/reports/components/groupAffDirGraph'
import { GroupAdaptability } from '../../profileList/editProfile/modals/reports/components/groupAdaptability'
import '../../../css/components/idiMatris.scss'
import { ReportType, ReportTypes } from '../../../types'
import { RootState } from '@app/store/configureStore'

interface IFacilitatorDashboardOrganisationViewProps {
  languageText: ITranslationObject
  organizationView: IFacilitatorDashboardOrganizationView
}

const FacilitatorDashboardOrganisationView = (
  props: IFacilitatorDashboardOrganisationViewProps
): JSX.Element => {
  const { languageText, organizationView } = props

  const dispatch = useDispatch()

  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)
  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  const [isReports, setIsReports] = useState<boolean>(false)
  const [profileGroupReport, setProfileGroupReport] = useState<IProfileGroupReport | undefined>()
  const [reportType, setReportType] = useState<ReportType>(ReportTypes.OTHER)

  useEffect(() => {
    if (userId && defaultClient.defaultClientId) {
      const params: IFacilitatorProfilesReportParams = {
        facilitatorId: userId,
        clientId: defaultClient.defaultClientId,
        isSelf: false,
        isBoth: true, // right now, we want both all the time
      }
      getFacilitatorParticipantsProfilesReport(params, dispatch).then((response) => {
        if (response) {
          setProfileGroupReport(response)
        }
      })
    }
  }, [userId, defaultClient])

  const handleCheckboxChange = (): void => setIsReports(!isReports)

  const selectReportType = (reportType: ReportType): void => {
    setReportType(reportType)
  }

  return (
    <div className='col-12 col-md-8'>
      <div className='card h-100 shadow-sm bg-white rounded border-0'>
        <div className='card-header border-0 p-3 fs-5'>
          {getLanguageValue(languageText, 'Organization view')}
          <div className='float-end fs-6 mt-1'>
            <ToggleCheckbox
              label={getLanguageValue(languageText, 'Show in graph')}
              value={isReports}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
        </div>

        {isReports ? (
          <div className='p-5 pt-3'>
            <div className='d-flex mb-3 fw-bold gap-4'>
              <div
                className={clsx(
                  'cursor-pointer',
                  reportType === ReportTypes.SELF && 'text-dark text-decoration-underline'
                )}
                onClick={() => selectReportType(ReportTypes.SELF)}
                role='button'
              >
                {getLanguageValue(languageText, 'Self')}
              </div>
              <div
                className={clsx(
                  'cursor-pointer',
                  reportType === ReportTypes.OTHER && 'text-dark text-decoration-underline'
                )}
                onClick={() => selectReportType(ReportTypes.OTHER)}
                role='button'
              >
                {getLanguageValue(languageText, 'Others')}
              </div>
              <div
                className={clsx(
                  'cursor-pointer',
                  reportType === ReportTypes.BOTH && 'text-dark text-decoration-underline'
                )}
                onClick={() => selectReportType(ReportTypes.BOTH)}
                role='button'
              >
                {getLanguageValue(languageText, 'Both')}
              </div>
            </div>

            <GroupAffDirGraph
              languageText={languageText}
              reportType={reportType}
              profileGroupReport={profileGroupReport}
              enableMarkerDimming={false}
            />
            <GroupAdaptability
              languageText={languageText}
              reportType={reportType}
              profileGroupReport={profileGroupReport}
              enableMarkerDimming={false}
            />
          </div>
        ) : (
          <div className='p-4 pt-0'>
            <DimensionsChart
              languageText={languageText}
              directivenessSelfValue={organizationView.directivenessSelfValue}
              directivenessOtherValue={organizationView.directivenessOtherValue}
              affiliationSelfValue={organizationView.affiliationSelfValue}
              affiliationOtherValue={organizationView.affiliationOtherValue}
              adaptabilitySelfValue={organizationView.adaptabilitySelfValue}
              adaptabilityOtherValue={organizationView.adaptabilityOtherValue}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FacilitatorDashboardOrganisationView
