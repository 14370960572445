import React, { useEffect, useRef } from 'react'
import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { useActivityFilters } from './hooks'
import Input from '@app/components/formComponents/input'
import ReactDatePicker from '@app/components/datePicker/ReactDatePicker'
import { IFilterObject } from './hooks'
import Dropdown from '@app/components/formComponents/dropdown'
import { InvoicedFilter, ProfileStatus } from '@app/containers/profileList/profileEnums'
import { SubscriptionType } from '@app/containers/clientList/addEditModal/clientEnums'

export interface IFilterProps {
  languageText: ITranslationObject
  initialFilterObject: IFilterObject
  clearFilterOptions: boolean
  handleUpdatedFilterOptions?: (options: IFilterObject) => void
}

const ActivityFilters = (props: IFilterProps): JSX.Element => {
  const { languageText, initialFilterObject, clearFilterOptions, handleUpdatedFilterOptions } =
    props

  const isMounted = useRef<boolean>(false)

  const { filterOptions, handleInputChange, handleDateSelect, handleDropdownChange } =
    useActivityFilters(initialFilterObject, clearFilterOptions)

  useEffect(() => {
    if (isMounted.current && handleUpdatedFilterOptions) {
      handleUpdatedFilterOptions(filterOptions)
    }
    if (!isMounted.current) isMounted.current = true
  }, [filterOptions])

  const profileStatusList = Object.keys(ProfileStatus)
    .filter((key) => isNaN(Number(key)))
    .map((key, idx) => ({
      id: idx + 1,
      value: ProfileStatus[key],
      displayName: getLanguageValue(languageText, key),
    }))
  profileStatusList.unshift({
    id: 0,
    value: '',
    displayName: getLanguageValue(languageText, 'All'),
  })

  const subscriptionTypeList = Object.keys(SubscriptionType)
    .filter((key) => isNaN(Number(key)))
    .map((key, idx) => ({
      id: idx + 1,
      value: SubscriptionType[key],
      displayName: getLanguageValue(languageText, key),
    }))
  subscriptionTypeList.unshift({
    id: 0,
    value: '',
    displayName: getLanguageValue(languageText, 'All'),
  })

  return (
    <>
      <div>
        <form id='multi_filter_form' className='row gy-2'>
          <div className='col-md-2 col-6'>
            <Dropdown
              name='status'
              defaultLabel={getLanguageValue(languageText, 'Profile Status')}
              value={filterOptions?.status?.toString() ?? ''}
              handleDropdownSelect={handleDropdownChange}
              list={profileStatusList}
            />
          </div>
          <div className='col-md-2 col-6'>
            <Dropdown
              name='subscriptionType'
              defaultLabel={getLanguageValue(languageText, 'Subscription Type')}
              value={filterOptions?.subscriptionType?.toString() ?? ''}
              handleDropdownSelect={handleDropdownChange}
              list={subscriptionTypeList}
            />
          </div>
          <div className='col-md-2 col-6'>
            <Dropdown
              name='invoiced'
              defaultLabel={getLanguageValue(languageText, 'Invoiced status')}
              value={filterOptions?.invoiced?.toString() ?? ''}
              handleDropdownSelect={handleDropdownChange}
              list={Object.keys(InvoicedFilter)
                .filter((key) => isNaN(Number(key)))
                .map((key, idx) => ({
                  id: idx + 1,
                  value: InvoicedFilter[key],
                  displayName: getLanguageValue(languageText, key),
                }))}
            />
          </div>
          <div className='col-md-2 col-6'>
            <Input
              name='clientName'
              placeholder={getLanguageValue(languageText, 'Client')}
              value={filterOptions && filterOptions.clientName}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className='col-md-2 col-6'>
            <Input
              name='facilitatorName'
              placeholder={getLanguageValue(languageText, 'Facilitator')}
              value={filterOptions && filterOptions.facilitatorName}
              handleInputChange={handleInputChange}
            />
          </div>

          <div className='col-md-2 col-6'>
            <Input
              name='source'
              placeholder={getLanguageValue(languageText, 'Source')}
              value={filterOptions && filterOptions.source}
              handleInputChange={handleInputChange}
            />
          </div>

          <div className='col-md-2 col-6'>
            <Input
              name='addedProfilesMin'
              placeholder={getLanguageValue(languageText, 'Added Participants Min')}
              value={filterOptions && (filterOptions.addedProfilesMin ?? '')}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className='col-md-2 col-6'>
            <Input
              name='addedProfilesMax'
              placeholder={getLanguageValue(languageText, 'Added Participants Max')}
              value={filterOptions && (filterOptions.addedProfilesMax ?? '')}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className='col-md-2 col-6'>
            <Input
              name='completedProfilesMin'
              placeholder={getLanguageValue(languageText, 'Completed Participants Min')}
              value={filterOptions && (filterOptions.completedProfilesMin ?? '')}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className='col-md-2 col-6'>
            <Input
              name='completedProfilesMax'
              placeholder={getLanguageValue(languageText, 'Completed Participants Max')}
              value={filterOptions && (filterOptions.completedProfilesMax ?? '')}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className='col-md-2 col-6'>
            <ReactDatePicker
              className='d-block'
              placeholder={getLanguageValue(languageText, 'Completed From')}
              name='dateFrom'
              date={filterOptions.dateFrom}
              handleDateSelect={handleDateSelect}
            />
          </div>
          <div className='col-md-2 col-6'>
            <ReactDatePicker
              placeholder={getLanguageValue(languageText, 'Completed To')}
              name='dateTo'
              date={filterOptions.dateTo}
              handleDateSelect={handleDateSelect}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ActivityFilters
