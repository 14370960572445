import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import TermsFooter from '../../../components/termsFooter/termsFooter'
import { AlertWindow } from '../../../components/alert/alert'
import { ParticipantProfileReport } from '../../participantPages/myIDIProfilesPage/participantProfileReport/participantProfileReport'
import NotFoundPage from '../../notFoundPage'
import { useParticipantRoute } from './hooks'
import NavigationMenu from '@app/containers/navigation/navigationMenu'

import DemographicPage from '../../participantPages/profileParticipantPages/demographicPage.tsx/demographicPage'
import RolePage from '../../participantPages/profileParticipantPages/rolePage/rolePage'
import ParticipantSelfFormPage from '../../participantPages/profileParticipantPages/selfFormPage/participantSelfFormPage'
import InviteRespondents from '../../participantPages/profileParticipantPages/inviteRespondents/inviteRespondents'
import { ParticipantProfilePage } from '../../participantPages/profileParticipantPages/profilePage/participantProfilePage'
import { routePath } from '@app/containers/routePaths'
import { isLoggedIn } from '@app/containers/rootState'
import { resetState } from '@app/reducers/actions'
import { CreateProfileV2 } from '@app/containers/participantPages/createProfile'
import ParticipantWelcomePage from '../../participantPages/participantWelcomePage/index'
import ParticipantDashboard from '../../participantPages/participantDashboard/index'
import ParticipantMyIDIProfiles from '../../participantPages/myIDIProfilesPage/index'
import ParticipantProfile from '../../usersProfile'
import ParticipantAccount from '../../usersProfile/account/account'

import ParticipantAcademy from '../../academy'
import CategoryCourses from '../../academy/categoryCourses'
import CourseLessons from '../../academy/categoryCourses/courseLessons'
import CoursesList from '../../academy/coursesList'
import Course from '../../academy/course'
import Quiz from '../../academy/quiz'
import CourseSummary from '../../academy/courseSummary'

export const ParticipantRoutes = (): JSX.Element => {
  const { loggedInUserRole } = useParticipantRoute()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isAuth, setIsAuth] = useState<boolean>(false)
  const [isNotFound, setIsNotFound] = useState<boolean>(false)

  useEffect(() => {
    if (isLoggedIn()) {
      setIsAuth(true)
    } else {
      navigate(routePath.signIn)
      dispatch(resetState())
    }
  }, [loggedInUserRole])

  if (isAuth) {
    return (
      <>
        {!isNotFound && <NavigationMenu />}
        <div className='container'>
          <Routes>
            <Route path={'/create'} element={<DemographicPage />} />
            <Route path={'/role'} element={<RolePage />} />
            <Route path={'/selfForm'} element={<ParticipantSelfFormPage />} />
            <Route path={'/inviteRespondents'} element={<InviteRespondents />} />
            <Route path={'/profilePresentation'} element={<ParticipantProfilePage />} />
            <Route path={'/welcome'} element={<ParticipantWelcomePage />} />
            <Route path={'/dashboard'} element={<ParticipantDashboard />} />
            <Route path={'/myProfiles'} element={<ParticipantMyIDIProfiles />} />
            <Route path='/create-profile-v2' element={<CreateProfileV2 />} />
            <Route
              path={'/myprofiles/profileReport/:profileId'}
              element={<ParticipantProfileReport />}
            />
            <Route path={'/user-profile'} element={<ParticipantProfile />} />
            <Route path={'/account'} element={<ParticipantAccount />} />
            <Route path={'/academy'} element={<ParticipantAcademy />} />
            <Route path={'/academy/categoryCourses/:parentId'} element={<CategoryCourses />} />
            <Route
              path={'/academy/categoryCourses/lessons/:categoryId'}
              element={<CourseLessons />}
            />
            <Route path={'/lessons'} element={<CoursesList />} />
            <Route path={'/lessons/:courseId/quiz/:quizId'} element={<Quiz />} />
            <Route path={'/lessons/:courseId/summary'} element={<CourseSummary />} />
            <Route path={'/lessons/:courseId/part/:partId'} element={<Course />} />
            <Route path='*' element={<NotFoundPage setIsNotFound={setIsNotFound} />} />
          </Routes>

          <TermsFooter footerContainerClassName='px-2 px-md-4 mb-3' />
        </div>
      </>
    )
  } else {
    return <AlertWindow />
  }
}
