import React from 'react'

import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import Input, { KeyCode } from '../../components/formComponents/input'

interface ITwoFactorAuthProps {
  languageText: ITranslationObject
  handleSubmit: () => unknown
  handleChange: (value: string) => unknown
  handleBackClick: () => unknown

  /** Describes how many digits this input requires. */
  length: number

  /** This value should _not_ be a translation placeholder: it should be translated before passed into this prop. */
  title: string
  value: string
}

const TwoFactorAuth = (props: ITwoFactorAuthProps): JSX.Element => {
  const { languageText, handleBackClick, handleSubmit, handleChange } = props
  const isValid = typeof props.value === 'string' && props.value.length === props.length
  const digitRefs = new Array(props.length).fill(0).map(() => {
    return React.useRef<HTMLInputElement>(null)
  })

  const enableDigitAtIndex = Math.min(props.length, props.value.length)

  React.useEffect(() => {
    if (enableDigitAtIndex === props.length) {
      props.handleSubmit()
      return
    }
    digitRefs[enableDigitAtIndex].current?.focus()
  }, [enableDigitAtIndex])

  return (
    <React.Fragment>
      <div className='px-10 login-subpages-container'>
        <div className='text-center my-10'>
          {/* the title should be translated outside of this component. */}
          <p className='text-dark mb-3 fs-4'>{props.title}</p>
        </div>
        <form onSubmit={handleSubmit} className='form w-100'>
          <div className='d-flex gap-2'>
            {digitRefs.map((ref, index) => {
              return (
                <Input
                  key={index}
                  type='number'
                  handleInputChange={(event) => {
                    const value = event.target.value.trim()

                    // this looks like a paste event.
                    if (index === 0 && value.length === props.length) {
                      handleChange(value)
                      return
                    }

                    const next = props.value.slice(0, index) + value.charAt(0)
                    handleChange(next)
                  }}
                  handleKeyDown={(event) => {
                    if (event.keyCode === KeyCode.Backspace) {
                      const next = props.value.slice(0, Math.max(0, index - 1))
                      handleChange(next)
                    }
                  }}
                  value={props.value.charAt(index)}
                  inputRef={ref}
                  placeholder=''
                  inputClass='text-center'
                  disable={index !== enableDigitAtIndex}
                  requireLeadingZero={true}
                />
              )
            })}
          </div>

          <div className='d-flex justify-content-between'>
            <button
              type='button'
              onClick={handleBackClick}
              className='btn btn-light justify-content-center'
            >
              {getLanguageValue(languageText, 'Back')}
            </button>
            <button
              type='submit'
              className='btn btn-success justify-content-center'
              disabled={!isValid}
            >
              {getLanguageValue(languageText, 'Validate')}
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default TwoFactorAuth
