import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import {
  ICombinedGroupReportParticipants,
  IEditActivity,
  IEditActivityDeliveredProfile,
  IParticipantProfileGroupReport,
  IProfileGroupReport,
} from '../../../../interface'
import { GroupAffDirGraph } from '../../components/groupAffDirGraph'
import { GroupAdaptability } from '../../components/groupAdaptability'
import GroupProfileParticipants from './groupProfileParticipants'
import { getGroupReport } from '../../../../actions'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { ITranslationObject } from '../../../../../../../commonUtils/languageFunctionsHelper'
import { DeliveredProfileParticipants } from './deliveredProfileParticipants'
import { ReportType, ReportTypes } from '../../../../../../../types'
import { emptyParticipantProfileGroupReport } from '../../../../../../utils'
import { GuidePages } from '@app/containers/commonEnums'
import { ActivityId, ProfileId } from '@app/containers/reducer'

interface IGroupPreviewProps {
  languageText: ITranslationObject
  id: ActivityId
  profileData: IEditActivity
  isInstructionHelpClicked?: boolean
  completedStatusEditProfileGuideDone?: boolean
  reportType: ReportType
  showUsers?: boolean
  selectedProfileIds: Array<ProfileId>
  deliveredProfileParticipants?: IEditActivityDeliveredProfile[]
  deliveredProfileParticipantsCount?: number
  isActivityCancelled?: boolean
  roleDisabled?: boolean
  languages?: IDropdownList[]
  cultureValue?: string
  messageTranslation: ITranslationObject
  allProfilesDelivered?: boolean
  handleExitGuide?: (guidePage: GuidePages, isSkipped: boolean) => void
  refetchFullProfile?: () => void
  refetchDeliveredParticipants?: () => void
  handleUnPublishProfile?: () => void
  handleTransferProfile?: () => void
}

export const GroupPreview = (props: IGroupPreviewProps): JSX.Element => {
  const {
    id,
    languageText,
    profileData,
    isInstructionHelpClicked,
    completedStatusEditProfileGuideDone,
    selectedProfileIds,
    isActivityCancelled,
    roleDisabled,
    languages = [],
    cultureValue = '',
    messageTranslation,
    allProfilesDelivered = false,
    handleExitGuide,
    refetchFullProfile = () => {},
    refetchDeliveredParticipants = () => {},
    handleUnPublishProfile = () => {},
    showUsers,
    handleTransferProfile = () => {},
  } = props

  const dispatch = useDispatch()

  const initialReportState: IParticipantProfileGroupReport = emptyParticipantProfileGroupReport()

  const initialGroupReportState: IProfileGroupReport = {
    selfGroupReport: initialReportState,
    otherGroupReport: initialReportState,
  }
  const [profileGroupReport, setProfileGroupReport] =
    useState<IProfileGroupReport>(initialGroupReportState)
  const [hoveredProfileId, setHoveredProfileId] = useState<ProfileId>(0 as ProfileId)

  const [copyPastaSelectedProfileIds, setCopyPastaSelectedProfileIds] = useState<Array<ProfileId>>(
    []
  ) // Checkbox selected participant

  useEffect(() => {
    getGroupReport(selectedProfileIds, ReportTypes.BOTH, dispatch).then((response) => {
      if (response) {
        setProfileGroupReport(response)
      }
    })
  }, [])

  // Checkbox fn's
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      // Select all participants
      const allParticipants = profileParticipants.map((p) => p.profileId)
      setCopyPastaSelectedProfileIds(allParticipants)
    } else {
      // Unselect all participants
      setCopyPastaSelectedProfileIds([])
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: ProfileId): void => {
    setCopyPastaSelectedProfileIds((prev) => {
      if (e.target.checked) {
        return [...prev, id]
      } else {
        return prev.filter((item) => item !== id)
      }
    })
  }

  const handleHover = (profileId: ProfileId): void => {
    setHoveredProfileId(profileId)
  }

  const handleMouseLeave = (): void => {
    setHoveredProfileId(0 as ProfileId)
  }

  const combineParticipants = (
    profileGroupReport: IProfileGroupReport
  ): ICombinedGroupReportParticipants[] => {
    const combinedParticipants: ICombinedGroupReportParticipants[] = []
    profileGroupReport.selfGroupReport.groupReportProfiles.forEach((selfProfile) => {
      const otherParticipant = profileGroupReport.otherGroupReport.groupReportProfiles.find(
        (otherProfile) => otherProfile.profileId === selfProfile.profileId
      )
      if (otherParticipant) {
        combinedParticipants.push({
          selfPointId: selfProfile.pointId,
          otherPointId: otherParticipant.pointId,
          selfAdaptabilityIndex: selfProfile.adaptabilityIndex,
          otherAdaptabilityIndex: otherParticipant.adaptabilityIndex,
          profileId: selfProfile.profileId,
          name: selfProfile.name,
        })
      }
    })
    return combinedParticipants
  }

  //This is used to combine the results from self and other so that we can show values in the participant list
  const profileParticipants = combineParticipants(profileGroupReport)

  return (
    <div className='row'>
      {/* Joakim - The class does not make sense now, it's basically the same if delivered or not */}
      {/* The col-xl-6 needs to be there to work if the menu is expanded */}
      <div className={clsx(allProfilesDelivered ? 'col-12 col-xl-6' : 'col-12 col-xl-6')}>
        <div className='align-items-start'>
          <GroupAffDirGraph
            languageText={languageText}
            reportType={props.reportType}
            profileGroupReport={profileGroupReport}
            hoveredProfileId={hoveredProfileId}
            selectedProfileIds={copyPastaSelectedProfileIds}
          />

          <GroupAdaptability
            languageText={languageText}
            reportType={props.reportType}
            profileGroupReport={profileGroupReport}
            hoveredProfileId={hoveredProfileId}
            selectedProfileIds={copyPastaSelectedProfileIds}
          />
        </div>
      </div>
      <div className={clsx(allProfilesDelivered ? 'col-12 col-xl-6' : 'col-12 col-xl-6')}>
        {!allProfilesDelivered ? (
          <GroupProfileParticipants
            languageText={languageText}
            profileParticipants={profileParticipants}
            hoveredProfileId={hoveredProfileId}
            handleHover={handleHover}
            showUsers={showUsers}
            handleMouseLeave={handleMouseLeave}
            reportType={props.reportType}
          />
        ) : (
          <DeliveredProfileParticipants
            id={id}
            languageText={languageText}
            profileData={profileData}
            isInstructionHelpClicked={isInstructionHelpClicked!}
            completedStatusEditProfileGuideDone={completedStatusEditProfileGuideDone!}
            profiles={profileParticipants}
            roleDisabled={roleDisabled ? roleDisabled : false}
            languages={languages}
            cultureValue={cultureValue}
            messageTranslation={messageTranslation}
            isActivityCancelled={isActivityCancelled ? isActivityCancelled : false}
            handleExitGuide={handleExitGuide!}
            refetchFullProfile={refetchFullProfile}
            refetchDeliveredParticipants={refetchDeliveredParticipants}
            handleUnPublishProfile={handleUnPublishProfile}
            handleHover={handleHover}
            handleMouseLeave={handleMouseLeave}
            handleSelectAll={handleSelectAll}
            selectedProfileIds={copyPastaSelectedProfileIds}
            setSelectedProfiles={setCopyPastaSelectedProfileIds}
            handleCheckboxChange={handleCheckboxChange}
            reportType={props.reportType}
            handleTransferProfile={handleTransferProfile}
          />
        )}
      </div>
    </div>
  )
}
