import React from 'react'

import ReactTable from '@app/components/reactTable/reactTable'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { useClientUsersList } from './hooks'
import { AddEditUserModal } from '@app/containers/userList/addEditUserModal/addEditUserModal'
import ConnectUserModal from './clientUserModals/connectUserModal'
import { CustomModal as DisconnectUserModal } from '@app/components/modals/customModal'
import { RoleEnum } from '@app/containers/commonEnums'

export interface IClientUsersListProps {
  clientId: number
  refreshClientList: () => void
}

const ClientUsersList = (props: IClientUsersListProps): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openUserModal,
    editUserId,
    openUserConnectModal,
    disconnectUserData,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    handleAddUser,
    handleConnectClick,
    closeUserModal,
    handleConnectUser,
    closeConnectUserModal,
    handleDisconnectUser,
    closeDisconnectUserModal,
  } = useClientUsersList(props)

  return (
    <>
      <ReactTable
        minHeight='min-h-0'
        headerText={getLanguageValue(languageText, 'Users')}
        buttonText={getLanguageValue(languageText, 'Add User')}
        buttonSecondOptionalText={getLanguageValue(languageText, 'Connect User')}
        searchPlaceholder={getLanguageValue(languageText, `Search`)}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={handleAddUser}
        handleSecondButtonClick={handleConnectClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {/* Add/Edit User Modal */}
      {(openUserModal || editUserId > 0) && (
        <AddEditUserModal
          languageText={languageText}
          editUserId={editUserId}
          roleId={RoleEnum.Admin}
          clientIdFromAccount={props.clientId}
          closeUserModal={closeUserModal}
        />
      )}

      {/* Connect User Modal */}
      {openUserConnectModal && (
        <ConnectUserModal
          languageText={languageText}
          clientId={props.clientId}
          handleConnectUser={handleConnectUser}
          closeConnectUserModal={closeConnectUserModal}
        />
      )}

      {/* Disconnect User Modal */}
      {disconnectUserData.id > 0 && (
        <DisconnectUserModal
          headerText={getLanguageValue(languageText, 'Disconnect User')}
          bodyText={
            disconnectUserData.isDefaultClient
              ? `${getLanguageValue(
                  languageText,
                  'This is the default client for the User. User cannot be disconnected'
                )}.`
              : `${getLanguageValue(
                  languageText,
                  'Do you really want to disconnect user from this client'
                )}?`
          }
          cancelButtonText={getLanguageValue(
            languageText,
            disconnectUserData.isDefaultClient ? 'OK' : 'No'
          )}
          submitButtonText={
            !disconnectUserData.isDefaultClient ? getLanguageValue(languageText, 'Yes') : ''
          }
          handleSubmitClick={handleDisconnectUser}
          handleCancelClick={closeDisconnectUserModal}
        />
      )}
    </>
  )
}

export default ClientUsersList
