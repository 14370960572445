import React from 'react'

import Input from '../../../components/formComponents/input'
import { PhoneNumberInput } from '../addEmployeeModal/phoneNumberInput'
import Status from '../../participantPages/participantDashboard/dashboardComponents/status'
import ProgressTimeline from '../../participantPages/participantDashboard/dashboardComponents/progressTimeline'
import ParticipantProfile from '../../participantPages/participantDashboard/dashboardComponents/participantProfile'
import AcademyStudies from '../../participantPages/participantDashboard/dashboardComponents/academyStudies'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { EmployeeInputs, useUpdateEmployee } from './hooks'
import { E164Number } from 'libphonenumber-js/types'
import { DeleteModal } from '@app/components/modals/deleteModal'

const EditEmployee = (): JSX.Element => {
  const {
    languageText,
    dashboardDetails,
    employeeInfo,
    focusInput,
    phoneNumberError,
    submitBtnEnabled,
    showDeleteModal,
    onSubmitClick,
    handleBlurEvent,
    handleInputChange,
    handlePhoneInputChange,
    validatePhonenumber,
    handleDeleteBtnClick,
    handleDeleteEmployee,
    closeDeleteModal,
  } = useUpdateEmployee()

  return (
    <>
      <div className='p-2 pt-4 p-md-4'>
        <div className='card h-100 shadow-sm bg-white rounded border-0'>
          <h3 className='card-header fw-bold fs-5 border-0 px-4 py-3'>
            {getLanguageValue(languageText, 'Edit Employee')}
          </h3>

          <div className='p-4 pb-0'>
            <div className='d-flex justify-content-end text-danger'>
              <span className='d-inline' role='button' onClick={handleDeleteBtnClick}>
                <i className='bi bi-trash3 me-1' />
                {getLanguageValue(languageText, 'Delete')}
              </span>
            </div>
            <form>
              <Input
                name={EmployeeInputs.name}
                label={getLanguageValue(languageText, 'Firstname')}
                placeholder={getLanguageValue(languageText, 'Firstname')}
                value={employeeInfo.name}
                focusInput={focusInput.name}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
                required
              />

              <Input
                name={EmployeeInputs.surname}
                label={getLanguageValue(languageText, 'Lastname')}
                placeholder={getLanguageValue(languageText, 'Lastname')}
                value={employeeInfo.surname}
                focusInput={focusInput.surname}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
                required
              />

              <Input
                name={EmployeeInputs.emailAddress}
                label={getLanguageValue(languageText, 'Email')}
                placeholder={getLanguageValue(languageText, 'Email')}
                value={employeeInfo.emailAddress}
                focusInput={focusInput.emailAddress}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
                required
              />

              <PhoneNumberInput
                languageText={languageText}
                label={getLanguageValue(languageText, 'Phonenumber')}
                value={(employeeInfo.phoneNumber as E164Number) || ''}
                onChange={(value) => handlePhoneInputChange(value)}
                onBlur={(e) => validatePhonenumber(e.target.value as E164Number)}
                placeholder='+123 45 67 89'
                phoneNumberError={phoneNumberError}
              />
            </form>

            <div className='bg-secondary rounded-bottom bg-opacity-50 p-4 mx-n4'>
              <button
                type='submit'
                className='btn btn-success'
                data-kt-employee-modal-action='submit'
                disabled={!submitBtnEnabled}
                onClick={onSubmitClick}
              >
                <span className='indicator-label'>{getLanguageValue(languageText, 'Update')}</span>
              </button>
            </div>
          </div>
        </div>

        <div className='row gx-5 gy-4 mt-2'>
          <div className='col-12 col-md-6'>
            <Status
              languageText={languageText}
              profileStatus={dashboardDetails.profileStatus}
              readOnly
            />
          </div>
          <div className='col-12 col-md-6'>
            <ProgressTimeline
              languageText={languageText}
              profileTimelines={dashboardDetails.profileTimelines}
              readOnly
            />
          </div>

          <div className='col-12 col-md-6'>
            <ParticipantProfile
              languageText={languageText}
              profilesDeliveredReport={dashboardDetails.profileDeliveredReports}
            />
          </div>
          <div className='col-12 col-md-6'>
            <AcademyStudies
              languageText={languageText}
              profileCourseStudies={dashboardDetails.profileCourseStudies}
              readOnly
            />
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, 'Employees')}
          bodyText={`${employeeInfo.name} ${employeeInfo.surname}`}
          handleDeleteClick={handleDeleteEmployee}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </>
  )
}

export default EditEmployee
