// This is the input field select component where list of options is dynamically fetched from an API based on user input

import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import debounce from 'lodash/debounce'

import { IDropdownList } from './dropdownSelect'
import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { classNames } from '@app/containers/utils'

interface IAutocompleteInputProps {
  languageText: ITranslationObject
  id?: string
  name?: string
  label?: string
  placeholder: string
  list: IDropdownList[]
  selectedValue: IDropdownList
  disable?: boolean
  required?: boolean
  errorMessage?: string
  fetchList: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  handleSelect: (selectedValue: IDropdownList) => void
  handleUnSelect?: () => void
}

const DynamicSearchInputField = (props: IAutocompleteInputProps): JSX.Element => {
  const {
    languageText,
    id,
    name,
    label,
    placeholder,
    list,
    selectedValue,
    disable,
    required,
    errorMessage,
    fetchList,
    handleSelect,
    handleUnSelect,
  } = props

  const inputRef = useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState('')
  const [listData, setListData] = useState<IDropdownList[]>([])
  const [showList, setShowList] = useState<boolean>(false)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setListData(list)
  }, [list])

  const handleClickOutside = (e: MouseEvent): void => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setShowList(false)
    }
  }

  const debouncedFetchOptions = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
    await fetchList(e)
  }, 300)

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setInputValue(value)

    if (value.length >= 3) {
      debouncedFetchOptions(e)
      setShowList(true)
    } else {
      setShowList(false)
      setListData([])
    }
  }

  const onSelectClick = (selectedValue: IDropdownList): void => {
    setShowList(false)
    setInputValue('')
    handleSelect(selectedValue)
  }

  return (
    <div className='mb-3'>
      {label && (
        <label
          className={clsx('fs-6 mb-2', {
            required: required,
          })}
        >
          {label}
        </label>
      )}

      <div ref={inputRef} className='position-relative'>
        <div className='d-flex justify-content-end align-items-center'>
          {selectedValue.value && (
            <i
              className='bi bi-x-lg position-absolute pe-4 cursor-pointer'
              role='button'
              onClick={handleUnSelect}
            />
          )}
          <input
            className={clsx(
              'form-control form-control-solid',
              selectedValue.value && 'dynamic-search-input-field'
            )}
            id={id}
            type='text'
            name={name}
            placeholder={selectedValue.value ? selectedValue.displayName.toString() : placeholder}
            value={inputValue}
            disabled={disable}
            autoComplete='off'
            onChange={onInputChange}
          />
        </div>

        <div
          className={classNames({
            'border-start border-end border-bottom position-absolute w-100 shadow z-1': true,
            'd-none': !showList,
            'd-block': showList,
          })}
        >
          <ul
            className='list-group list-group-flush'
            style={{ maxHeight: 300, overflowY: 'scroll' }}
          >
            {listData.length > 10 ? (
              list.map((item) => {
                const clazz = classNames({
                  'list-group-item list-group-item-action': true,
                })
                return (
                  <li
                    key={item.id}
                    className={clazz}
                    onClick={() => onSelectClick(item)}
                    role='button'
                  >
                    {item.displayName}
                  </li>
                )
              })
            ) : (
              <li className='list-group-item'>
                {getLanguageValue(languageText, 'No Items to display')}
              </li>
            )}
          </ul>
        </div>

        {errorMessage && <div className='invalid-feedback d-inline-block'>{errorMessage}</div>}
      </div>
    </div>
  )
}
{
  /*  */
}
export default DynamicSearchInputField
