import React from 'react'
import { E164Number } from 'libphonenumber-js/types'

import ProductDetails from './invoiceComponents/productDetails'
import InvoiceAddress from './invoiceComponents/invoiceAddressForm'
import CreateInvoiceDraft from './invoiceComponents/createInvoiceDraft'
import ToggleCardCheckbox from '../formComponents/toggleCardCheckbox'
import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { IPaymentTypes } from '../../commonUtils/planSuscriptionHelper'
import {
  IInvoiceExistingAddress,
  IInvoiceFocusInput,
  IInvoiceFormData,
  IProductData,
} from './invoiceInterface'
import { CustomModal } from '../modals/customModal'

export interface IInvoiceBillingProps {
  languageText: ITranslationObject
  isActivity: boolean
  countries: IDropdownList[]
  paymentTypes: IPaymentTypes[]
  existingAddresses?: IInvoiceExistingAddress[]
  selectAddressModal?: boolean
  invoiceFormData: IInvoiceFormData
  focusInput: IInvoiceFocusInput
  fortnoxClientList: IDropdownList[]
  fortnoxInvoiceList: IDropdownList[]
  selectedFortnoxCustomer: IDropdownSelectedItem
  showFortnoxInvoiceInputs: boolean
  productFormData: IProductData
  isEditMode: boolean
  productsList: IDropdownList[]
  handleSelectPaymentMethod: (paymentId: number) => void
  openSelectAddressModal?: () => void
  closeSelectAddressModal?: () => void
  onSelectAddressClick?: (data: IInvoiceExistingAddress) => void
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlePhoneInputChange: (value: E164Number) => void
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleCountrySelect: (selectedItem: IDropdownSelectedItem) => void
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  handleTextAreaBlurEvent: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void
  handleFortnoxClientSelect: (selectedItem: IDropdownSelectedItem) => void
  handleInvoiceSelect: (selectedItem: IDropdownSelectedItem) => void
  handleCloseCustomerDefaultPopup: () => void
  handleSaveCustomerAsDefault: () => void
  handleProductSelect: (selectedItem: IDropdownSelectedItem) => void
  handleProductInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleAddProduct: () => void
  handleSaveProduct: () => void
  handleCancelProduct: () => void
  handleEditProduct: (id: number) => void
  handleDeleteProduct: (id: number) => void
  createOrUpdateInvoiceDraft: () => void
}

const InvoiceBilling = (props: IInvoiceBillingProps) => {
  const {
    languageText,
    isActivity,
    countries,
    paymentTypes,
    existingAddresses,
    selectAddressModal,
    invoiceFormData,
    focusInput,
    fortnoxClientList,
    fortnoxInvoiceList,
    selectedFortnoxCustomer,
    showFortnoxInvoiceInputs,
    productFormData,
    isEditMode,
    productsList,
    handleSelectPaymentMethod,
    openSelectAddressModal,
    closeSelectAddressModal,
    onSelectAddressClick,
    handleInputChange,
    handlePhoneInputChange,
    handleTextAreaChange,
    handleCountrySelect,
    handleBlurEvent,
    handleTextAreaBlurEvent,
    handleFortnoxClientSelect,
    handleInvoiceSelect,
    handleCloseCustomerDefaultPopup,
    handleSaveCustomerAsDefault,
    handleProductSelect,
    handleProductInputChange,
    handleAddProduct,
    handleSaveProduct,
    handleCancelProduct,
    handleEditProduct,
    handleDeleteProduct,
    createOrUpdateInvoiceDraft,
  } = props

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='row gap-5 fs-6'>
          {paymentTypes.map((paymentType, index) => (
            <ToggleCardCheckbox
              key={index}
              keys={paymentType.id}
              header={paymentType.name}
              description={paymentType.description}
              containerClass='col-lg-4 col-12'
              icon={paymentType.icon}
              checked={paymentType.id === invoiceFormData.paymentId}
              handleClick={() => handleSelectPaymentMethod(paymentType.id)}
            />
          ))}
        </div>

        <InvoiceAddress
          languageText={languageText}
          isActivity={isActivity}
          countries={countries}
          existingAddresses={existingAddresses!}
          selectAddressModal={selectAddressModal!}
          invoiceAddressFormData={invoiceFormData}
          focusInput={focusInput}
          openSelectAddressModal={openSelectAddressModal!}
          closeSelectAddressModal={closeSelectAddressModal!}
          onSelectAddressClick={onSelectAddressClick!}
          handleInputChange={handleInputChange}
          handlePhoneInputChange={handlePhoneInputChange}
          handleTextAreaChange={handleTextAreaChange}
          handleCountrySelect={handleCountrySelect}
          handleBlurEvent={handleBlurEvent}
          handleTextAreaBlurEvent={handleTextAreaBlurEvent}
        />

        <CreateInvoiceDraft
          languageText={languageText}
          fortnoxClientList={fortnoxClientList}
          fortnoxInvoiceList={fortnoxInvoiceList}
          invoiceFormData={invoiceFormData}
          productFormData={productFormData}
          isEditMode={isEditMode}
          productsList={productsList}
          focusInput={focusInput}
          showFortnoxInvoiceInputs={showFortnoxInvoiceInputs}
          handleFortnoxClientSelect={handleFortnoxClientSelect}
          handleInvoiceSelect={handleInvoiceSelect}
          handleBlurEvent={handleBlurEvent}
          handleProductSelect={handleProductSelect}
          handleProductInputChange={handleProductInputChange}
          handleAddProduct={handleAddProduct}
          handleSaveProduct={handleSaveProduct}
          handleCancelProduct={handleCancelProduct}
        />

        <ProductDetails
          languageText={languageText}
          invoiceId={invoiceFormData.invoiceId}
          profileInvoiceRows={invoiceFormData.profileInvoiceRows}
          handleEditProduct={handleEditProduct}
          handleDeleteProduct={handleDeleteProduct}
        />

        {/* Create / Update Invoice draft */}
        <div className='d-flex flex-end'>
          <button className='btn btn-success' onClick={createOrUpdateInvoiceDraft}>
            {getLanguageValue(
              languageText,
              invoiceFormData.invoiceId ? 'Update invoice draft' : 'Create invoice draft'
            )}
          </button>
        </div>
      </div>

      {selectedFortnoxCustomer.id > 0 && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Select as default')}
          bodyText={getLanguageValue(
            languageText,
            'Do you want to set selected fortnox customer as default'
          )}
          cancelButtonText={getLanguageValue(languageText, 'No')}
          submitButtonText={getLanguageValue(languageText, 'Yes')}
          handleCancelClick={handleCloseCustomerDefaultPopup}
          handleSubmitClick={handleSaveCustomerAsDefault}
        />
      )}
    </>
  )
}

export default InvoiceBilling
