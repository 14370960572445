import React from 'react'

import { SelfForm } from '../../../components/selfForm/selfForm'
import TermsFooter from '../../../components/termsFooter/termsFooter'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import RespondentLanguageNavbar from '../respondentLanguageNavbar/respondentLanguageNavbar'
import { useRespondentSelfForm } from './hooks'

const RespondentSelfForm = () => {
  const { languageText, respondentData, respondentToken } = useRespondentSelfForm()

  return (
    <div className='container my-md-5'>
      <div className='row p-3'>
        <div className='col-lg-10 rounded shadow mx-auto p-md-5 p-4 bg-white'>
          <RespondentLanguageNavbar languageText={languageText} isInstructed={true} />

          <div className='text-center'>
            <div className='mt-5 mb-3 fs-4 fw-bold'>
              {getLanguageValue(languageText, 'You are describing')}{' '}
              <h1 className='fs-2 text-success fw-bolder'>{respondentData.participantName}</h1>
            </div>
            <p className='mt-8 mb-1 fs-5'>
              {getLanguageValue(languageText, 'Fill in the form according to how you perceive')}{' '}
              <span className='text-decoration-underline'>{respondentData.participantName}</span>{' '}
            </p>
            <p className='fs-5'>
              {getLanguageValue(languageText, 'in his role in the context you work together')}.
            </p>
          </div>
          <SelfForm
            languageText={languageText}
            isParticipant={false}
            userLanguage={respondentData.languageName}
            profileId={respondentData.profileId}
            profileRespondentId={respondentData.profileRespondentId}
            isFormFilledData={respondentData.isFormFilledData}
            respondentToken={respondentToken}
          />
        </div>
      </div>
      <TermsFooter languageText={languageText} />
    </div>
  )
}

export default RespondentSelfForm
