import React from 'react'

import { ParticipantProfileGraph } from './participantProfileGraph'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IProfilesDeliveredReport } from '../interface'

interface IParticipantProfileProps {
  languageText: ITranslationObject
  profilesDeliveredReport: IProfilesDeliveredReport[]
}

const ParticipantProfile = (props: IParticipantProfileProps): JSX.Element => {
  const { languageText, profilesDeliveredReport } = props

  return (
    <div className='card h-100 shadow-sm bg-white rounded border-0'>
      <div className='card-header border-0 p-3 fs-5 text-body-tertiary'>
        {getLanguageValue(languageText, 'Profiles')}
      </div>
      <div className='card-body p-4'>
        <div className='mx-4'>
          <ParticipantProfileGraph languageText={languageText} profiles={profilesDeliveredReport} />
        </div>
      </div>
    </div>
  )
}

export default ParticipantProfile
