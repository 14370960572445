import React, { useEffect, useState } from 'react'

import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import DropdownMultiSelect, {
  IMultiDropdownList,
} from '../../../components/formComponents/dropdownMultiSelect'
import { EditRoleMode, isDefaultClientForEditMode, UserInfoRole, UserInputs } from './hooks'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { RoleEnum, Roles } from '../../commonEnums'
import Checkbox from '../../../components/formComponents/checkbox'
import { ModalComponent } from '../../../components/modals/modalComponent'
import { IClients, IRoles } from '@app/containers/commonInterfaces'
import { uniqueBy } from '@app/containers/utils'

interface IAddEditUserClientModalProps {
  languageText: ITranslationObject
  clients: Array<IClients>
  initialClientId: number | undefined

  /** All roles that have been assigned to the user, regardless of 'initialClientId' and 'editMode'. */
  assignedRoles: Array<UserInfoRole>
  editMode: EditRoleMode
  closeUserClientModal: () => void
  handleEditUserRoles: (
    clientId: number,
    roleIds: Array<RoleEnum>,
    isDefaultClient: boolean,
    editMode: EditRoleMode
  ) => void
}

const CAN_ASSIGN_ROLES_FOR_MODE: {
  [K in EditRoleMode]: ReadonlyArray<RoleEnum>
} = {
  non_participant: [RoleEnum.Facilitator, RoleEnum.Agent, RoleEnum.Manager],
  participant: [RoleEnum.Participant],
}

function getInitialSelectedRoleIdsState(
  initialClientId: number | undefined,
  roles: ReadonlyArray<UserInfoRole>,
  editMode: EditRoleMode
): Array<RoleEnum> {
  const rolesForClient = roles.filter(
    (r) =>
      r.clientId === initialClientId &&
      (editMode === 'participant'
        ? r.roleId === RoleEnum.Participant
        : r.roleId !== RoleEnum.Participant)
  )
  const roleIdsForClient = rolesForClient.map((r) => r.roleId)

  if (!initialClientId && roleIdsForClient.length === 0) {
    switch (editMode) {
      case 'non_participant':
        roleIdsForClient.push(RoleEnum.Facilitator)
        break
      case 'participant':
        roleIdsForClient.push(RoleEnum.Participant)
        break
    }
  }

  return roleIdsForClient
}

function getInitialIsDefaultClientState(
  initialClientId: number | undefined,
  roles: ReadonlyArray<UserInfoRole>,
  editMode: EditRoleMode
): boolean {
  return (
    roles.length === 0 ||
    (!!initialClientId && isDefaultClientForEditMode(initialClientId, roles, editMode))
  )
}

export const AddEditUserClientModal = (props: IAddEditUserClientModalProps): JSX.Element => {
  const { languageText, clients, editMode, closeUserClientModal, handleEditUserRoles } = props

  const [selectedClientId, setSelectedClientId] = React.useState<number | undefined>(
    props.initialClientId
  )
  const [selectedRoleIds, setSelectedRoleIds] = React.useState<Array<RoleEnum>>(
    getInitialSelectedRoleIdsState(props.initialClientId, props.assignedRoles, props.editMode)
  )
  const [isDefaultClient, setIsDefaultClient] = React.useState<boolean>(
    getInitialIsDefaultClientState(props.initialClientId, props.assignedRoles, props.editMode)
  )

  const [error, setError] = useState<boolean>(false)

  const handleClientSelect = (item: IDropdownSelectedItem): void => {
    if (!item.value) {
      return
    }
    setSelectedClientId(Number(item.value))
    setError(false)
  }

  const handleRoleSelect = (_: string, value: Array<string>): void => {
    setSelectedRoleIds(value.map(Number))
    setError(false)
  }

  const handleIsDefaultClientChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsDefaultClient(e.target.checked)
  }

  const onSaveClick = (): void => {
    if (!selectedClientId || selectedRoleIds.length === 0) {
      setError(true)
      return
    }
    handleEditUserRoles(selectedClientId, selectedRoleIds, isDefaultClient, editMode)
  }

  // Set first added client as default client automatically
  const isFirstClient = uniqueBy(props.assignedRoles, (r) => r.clientId).length < 2
  const clientDropdownItems: Array<IDropdownList> = clients.map((c) => {
    return {
      id: c.id,
      displayName: c.name,
      value: String(c.id),
    }
  })
  const roleDropdownItems: Array<IMultiDropdownList> = Object.values(Roles)
    .filter((roleName) => CAN_ASSIGN_ROLES_FOR_MODE[editMode].includes(RoleEnum[roleName]))
    .map((roleName) => {
      const id = RoleEnum[roleName]
      return {
        id: id,
        label: roleName,
        value: String(id),
      }
    })

  return (
    <>
      <ModalComponent
        width='lg'
        headerText={getLanguageValue(
          languageText,
          typeof props.initialClientId !== 'undefined' ? 'Edit Client' : 'Add Client'
        )}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        submitButtonText={getLanguageValue(languageText, 'Save')}
        submitButtonDisabled={!selectedClientId || selectedRoleIds.length === 0}
        handleCancelClick={closeUserClientModal}
        handleSubmitClick={onSaveClick}
      >
        <div className='row'>
          <div className='col-6'>
            <DropdownSelect
              name={UserInputs.clientId}
              label={getLanguageValue(languageText, isFirstClient ? 'Default Client' : 'Client')}
              defaultLabel={getLanguageValue(languageText, 'Select Client')}
              list={clientDropdownItems}
              value={selectedClientId ? String(selectedClientId) : undefined}
              handleDropdownSelect={handleClientSelect}
              disabled={
                typeof props.initialClientId !== 'undefined' &&
                typeof selectedClientId !== 'undefined' &&
                isDefaultClient
              }
              searchOption
              required
            />
          </div>
          <div className='col-6'>
            <DropdownMultiSelect
              name={UserInputs.roleIds}
              label={getLanguageValue(languageText, 'Client Roles')}
              placeholder={getLanguageValue(languageText, 'Select Roles')}
              list={roleDropdownItems}
              value={selectedRoleIds.map((r) => String(r))}
              handleMultiDropdownSelect={handleRoleSelect}
              disabled={editMode === 'participant'}
              required
            />
          </div>
        </div>

        <Checkbox
          label={getLanguageValue(languageText, 'Set as default client')}
          value={isDefaultClient}
          handleCheckboxChange={handleIsDefaultClientChange}
          disable={isFirstClient}
        />

        {error && (
          <div className='invalid-feedback d-inline-block'>
            {getLanguageValue(languageText, 'Both fields should be selected')}
          </div>
        )}
      </ModalComponent>
    </>
  )
}
