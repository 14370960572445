import React from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IFacilitatorDashboardLatestProfile } from '../interface'
import { routePath } from '../../routePaths'

interface IFacilitatorDashboardProfileSectionProps {
  languageText: ITranslationObject
  latestProfile: IFacilitatorDashboardLatestProfile
}

const FacilitatorDashboardProfileSection = (
  props: IFacilitatorDashboardProfileSectionProps
): JSX.Element => {
  const { languageText, latestProfile } = props
  const { id, completionDate, addedProfiles, invitedProfiles, deliveredProfiles } = latestProfile

  const navigate = useNavigate()

  let progressBarWidth: number = 0
  if (addedProfiles) progressBarWidth = 30
  if (invitedProfiles) progressBarWidth += 30
  if (deliveredProfiles) progressBarWidth += 30

  const handleInvitedParticipantsClick = (): void => {
    if (id && addedProfiles && !invitedProfiles)
      navigate(routePath.editActivity.replace(':id', id.toString()))
  }

  return (
    <div className='col-12 col-md-4 mb-7 mb-md-0'>
      <div className='card h-100 shadow-sm bg-white rounded border-0'>
        <div className='card-header border-0 p-3 fs-5 text-body-tertiary'>
          {getLanguageValue(languageText, 'Ongoing activity')}
        </div>
        <div className='card-body p-4'>
          <div className='progress'>
            <div
              className='progress-bar bg-success'
              style={{ width: `${progressBarWidth}%` }}
            ></div>
          </div>

          {/* Added Participants & Completion date */}
          <div
            className={clsx(
              'd-flex mt-3 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm',
              addedProfiles ? 'grey-bg' : 'grey-bg'
            )}
          >
            <div className='small ms-1'>
              {getLanguageValue(languageText, 'Completion')}{' '}
              {id ? completionDate.substring(0, 10) : ''}
            </div>
            <span className='border px-2 rounded'>{addedProfiles}</span>
          </div>

          {/* Invited Participants */}
          <div
            className={clsx(
              'd-flex mt-1 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm',
              !addedProfiles ? 'bg-white' : !invitedProfiles ? 'grey-bg' : 'grey-bg',
              id && addedProfiles && !invitedProfiles && 'cursor-pointer'
            )}
            onClick={handleInvitedParticipantsClick}
          >
            <div className='small ms-1'>
              {getLanguageValue(languageText, 'Invite participants')}
            </div>
            {id && !invitedProfiles ? (
              <i className='bi bi-arrow-right fs-4 me-2 text-success' />
            ) : (
              <span className='border px-2 rounded'>{invitedProfiles}</span>
            )}
          </div>

          {/* Delivered Participants / Profiles */}
          <div
            className={clsx(
              'd-flex mt-1 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm',
              !invitedProfiles ? 'bg-white' : !deliveredProfiles ? 'grey-bg' : 'grey-bg'
            )}
          >
            <div className='small ms-1'>{getLanguageValue(languageText, 'Completed profiles')}</div>
            <span
              className={clsx(
                'border px-2 rounded',
                deliveredProfiles ? 'bg-success text-white' : 'bg-white'
              )}
            >
              {deliveredProfiles}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacilitatorDashboardProfileSection
