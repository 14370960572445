import React from 'react'

import InvoiceBilling from '../../../../../components/invoice/invoiceBilling'
import { useSubscriptioInvoice } from './hooks'
import { ModalComponent } from '../../../../../components/modals/modalComponent'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'

export interface ISubscriptionAddInvoiceProps {
  languageText: ITranslationObject
  clientSubscriptionId: number
  invoiceId: number
  handleClientSubscription: (clientSubscriptionId: number, invoiceId?: number) => void
  handleCloseClick: () => void
}

const SubscriptionAddInvoice = (props: ISubscriptionAddInvoiceProps) => {
  const { languageText, handleCloseClick } = props

  const {
    countries,
    paymentTypes,
    existingInvoiceDetails,
    invoiceFormData,
    focusInput,
    fortnoxClientList,
    fortnoxInvoiceList,
    selectedFortnoxCustomer,
    showFortnoxInvoiceInputs,
    productFormData,
    isEditMode,
    productsList,
    handleSelectPaymentMethod,
    handleInputChange,
    handlePhoneInputChange,
    handleTextAreaChange,
    handleCountrySelect,
    handleBlurEvent,
    handleTextAreaBlurEvent,
    handleFortnoxClientSelect,
    handleInvoiceSelect,
    handleCloseCustomerDefaultPopup,
    handleSaveCustomerAsDefault,
    handleProductSelect,
    handleProductInputChange,
    handleAddProduct,
    handleSaveProduct,
    handleCancelProduct,
    handleEditProduct,
    handleDeleteProduct,
    createOrUpdateInvoiceDraft,
    handleSendToFortnox,
  } = useSubscriptioInvoice(props)

  return (
    <>
      <ModalComponent
        width='xl'
        headerText={getLanguageValue(languageText, 'Billing details')}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        submitButtonText={
          existingInvoiceDetails.idiInvoice.fortnoxInvoiceNumber
            ? getLanguageValue(languageText, 'Update To Fortnox')
            : existingInvoiceDetails.idiInvoice.id
              ? getLanguageValue(languageText, 'Send To Fortnox')
              : ''
        }
        handleCancelClick={handleCloseClick}
        handleSubmitClick={handleSendToFortnox}
      >
        <InvoiceBilling
          languageText={languageText}
          isActivity={false}
          countries={countries}
          paymentTypes={paymentTypes}
          invoiceFormData={invoiceFormData}
          focusInput={focusInput}
          fortnoxClientList={fortnoxClientList}
          fortnoxInvoiceList={fortnoxInvoiceList}
          selectedFortnoxCustomer={selectedFortnoxCustomer}
          showFortnoxInvoiceInputs={showFortnoxInvoiceInputs}
          productFormData={productFormData}
          isEditMode={isEditMode}
          productsList={productsList}
          handleSelectPaymentMethod={handleSelectPaymentMethod}
          handleInputChange={handleInputChange}
          handlePhoneInputChange={handlePhoneInputChange}
          handleTextAreaChange={handleTextAreaChange}
          handleCountrySelect={handleCountrySelect}
          handleBlurEvent={handleBlurEvent}
          handleTextAreaBlurEvent={handleTextAreaBlurEvent}
          handleFortnoxClientSelect={handleFortnoxClientSelect}
          handleInvoiceSelect={handleInvoiceSelect}
          handleCloseCustomerDefaultPopup={handleCloseCustomerDefaultPopup}
          handleSaveCustomerAsDefault={handleSaveCustomerAsDefault}
          handleProductSelect={handleProductSelect}
          handleProductInputChange={handleProductInputChange}
          handleAddProduct={handleAddProduct}
          handleSaveProduct={handleSaveProduct}
          handleCancelProduct={handleCancelProduct}
          handleEditProduct={handleEditProduct}
          handleDeleteProduct={handleDeleteProduct}
          createOrUpdateInvoiceDraft={createOrUpdateInvoiceDraft}
        />
      </ModalComponent>
    </>
  )
}

export default SubscriptionAddInvoice
