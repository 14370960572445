import * as React from 'react'
import * as Redux from 'redux'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import ReactTable from '@app/components/reactTable/reactTable'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { RootState } from '@app/store/configureStore'
import { ColumnDef } from '@tanstack/react-table'
import { ActivityStatus, activityStatusColors } from '../profileList/profileEnums'
import { getFullDateTimeFormat } from '@app/commonUtils/dateFunctionsHelper'
import { useNavigate } from 'react-router-dom'
import { routePath } from '../routePaths'
import { api, apiEndPoints } from '@app/api'
import { setNotification, setSpinner } from '../actions'
import { ApiResult } from '@app/types'
import { isLoggedInRoleAdmin, isLoggedInRoleFacilitator } from '@app/commonUtils/roleHelper'

// /api/services/app/Profile/ProfilesWithParticipants
type IProfileWithParticipant = {
  clientName: string
  creationTime: string
  completionDate: string
  facilitatorName: string
  id: number
  participantName: string
  profileName: string
  status: ActivityStatus
}

function getProfilesWithParticipants(
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  isFacilitator: boolean,
  clientId: number,
  dispatch: Redux.Dispatch
): Promise<ApiResult<IProfileWithParticipant>> {
  const params = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
    isFacilitator,
    clientId,
  }

  return api
    .get(apiEndPoints.getAllProfilesWithParticipants, {
      params: params,
    })
    .then(
      (res) => res.data.result,
      (err) => {
        dispatch(setNotification(err))
        return Promise.reject(err)
      }
    )
}

export const ProfileWithParticipantList: React.FC<unknown> = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMounted = React.useRef<boolean>(false)

  const [data, setData] = React.useState<Array<IProfileWithParticipant>>([])
  const [filter, setFilter] = React.useState<string>('')
  const [sorting, setSorting] = React.useState<string>('')
  const [totalCount, setTotalCount] = React.useState<number>(0)
  const [pageLimit, setPageLimit] = React.useState<number>(10)
  const [pageCount, setPageCount] = React.useState<number>(0)
  const [pageSelected, setPageSelected] = React.useState<number>(0)

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)

  const handleEditClick = (id: number): void => {
    navigate(routePath.editActivity.replace(':id', id.toString()))
  }

  const fetchProfilesWithParticipants = (skipCount: number): void => {
    dispatch(setSpinner(true))
    const isFacilitator = isLoggedInRoleFacilitator(loggedInUserRole)
    getProfilesWithParticipants(
      filter,
      sorting,
      pageLimit,
      skipCount,
      isFacilitator,
      isLoggedInRoleAdmin(loggedInUserRole) ? 0 : defaultClient.defaultClientId,
      dispatch
    )
      .then((response) => {
        const pageSize = Math.ceil(response.totalCount / pageLimit)
        setData(response.items.slice())
        setTotalCount(response.totalCount)
        setPageCount(pageSize)
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  React.useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected
      fetchProfilesWithParticipants(skipCount)
    }
  }, [pageSelected, sorting])

  React.useEffect(() => {
    if (
      isLoggedInRoleAdmin(loggedInUserRole) ||
      (isLoggedInRoleFacilitator(loggedInUserRole) && defaultClient.defaultClientId)
    ) {
      fetchProfilesWithParticipants(0)
      if (!isMounted.current) isMounted.current = true
    }
  }, [filter, pageLimit, defaultClient, loggedInUserRole])

  const tableHeader = React.useMemo<ColumnDef<IProfileWithParticipant, any>[]>(() => {
    const headerArray: Array<ColumnDef<IProfileWithParticipant, any>> = [
      {
        header: '',
        accessorKey: 'actions',
        enableSorting: false,
        cell: ({ ...props }) => (
          <div
            className='btn btn-lg border-end rounded-0 my-n3 ms-n3'
            onClick={() => handleEditClick(props.row.original.id)}
          >
            <i className='bi bi-pencil-fill text-secondary' />
          </div>
        ),
      },
      {
        header: getLanguageValue(languageText, 'Activity ID'),
        accessorKey: 'id',
      },
      {
        header: getLanguageValue(languageText, 'Status'),
        accessorKey: 'status',
        cell: ({ ...props }) => (
          <span
            className={clsx('badge fw-bold fs-6', activityStatusColors[props.row.original.status])}
          >
            {getLanguageValue(languageText, ActivityStatus[props.row.original.status])}
          </span>
        ),
      },
      {
        header: getLanguageValue(languageText, 'Completion date'),
        accessorKey: 'completionDate',
        cell: ({ ...props }) => new Date(props.row.original.completionDate).toLocaleDateString(),
      },
      {
        header: getLanguageValue(languageText, 'Profile'),
        accessorKey: 'profileName',
      },
      {
        header: getLanguageValue(languageText, 'Participant'),
        accessorKey: 'participantName',
      },
      {
        header: getLanguageValue(languageText, 'Facilitator'),
        accessorKey: 'facilitatorName',
      },
      {
        header: getLanguageValue(languageText, 'Created'),
        accessorKey: 'creationTime',
        cell: ({ ...props }) => getFullDateTimeFormat(props.row.original.creationTime),
      },
    ]

    return headerArray
  }, [languageText])

  return (
    <>
      <ReactTable
        wrapperClass='p-2 pt-4 p-md-4'
        headerText={getLanguageValue(languageText, 'Profiles')}
        searchPlaceholder={getLanguageValue(languageText, 'Search')}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          navigate(routePath.activities)
        }}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />
    </>
  )
}
