import React, { useEffect, useState } from 'react'

import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { SelectLanguageDropdownModal } from '@app/components/languagePageComponent/selectLanguageDropdownModal'
import { TextAreaModal } from '@app/components/modals/textAreaModal'
import { ProfileId } from '@app/containers/reducer'

export interface IRespondentEmailPreviewBody {
  profileId: ProfileId
  languageCode: string
  ownMsg: string
}

export interface ICreateRespondentsParams {
  profileId: ProfileId
  languageCode: string
  ownMessage: string
}

export interface ICreateManualRespondentsBody {
  profileId: ProfileId
  name: string
  email: string
  telephone: string
}

interface ISendRespondentsInvitationProps {
  languageText: ITranslationObject
  languages: IDropdownList[]
  languageValue: string
  messageValue: string
  handleSaveLanguage: (selectedValue: string) => void
  handleSaveMessage: (value: string) => void
  onPreviewClick: () => void
  onSendInvitationClick: () => void
}

const SendRespondentsInvitation = (props: ISendRespondentsInvitationProps) => {
  const {
    languageText,
    languages,
    languageValue,
    messageValue,
    handleSaveLanguage,
    handleSaveMessage,
    onPreviewClick,
    onSendInvitationClick,
  } = props

  const [languageName, setLanguageName] = useState<string>('')
  const [changeLanguageModal, setChangeLanguageModal] = useState<boolean>(false)
  const [addMessageModal, setAddMessageModal] = useState<boolean>(false)

  useEffect(() => {
    const updatedLanguageName = String(
      languages.find((language) => language.value === languageValue)?.displayName ?? ''
    )
    setLanguageName(updatedLanguageName)
  }, [languageValue, languages])

  const onLanguageClick = (): void => {
    setChangeLanguageModal(true)
  }

  const closeLanguageModal = (): void => {
    setChangeLanguageModal(false)
  }

  const onAddMessageClick = (): void => {
    setAddMessageModal(true)
  }

  const closeAddMessageModal = (): void => {
    setAddMessageModal(false)
  }

  return (
    <>
      <div>
        <div className='mb-3 mt-1' onClick={onLanguageClick} role='button'>
          <span className='fs-6 me-1'>
            <i className='bi bi-globe me-2 text-dark'></i>{' '}
            {getLanguageValue(languageText, 'Select the instruction language')}:
          </span>
          <span className='fs-6 fw-bold'>{languageName}</span>
        </div>
        <div className='mb-3'>
          {messageValue ? (
            <span onClick={onAddMessageClick} role='button'>
              <span className='fs-6 me-1'>
                <i className='bi bi-chat-left-text me-2'></i>{' '}
                {getLanguageValue(languageText, 'Your message')}:
              </span>
              <span className='fs-6  fw-bold'>{messageValue}</span>
            </span>
          ) : (
            <span onClick={onAddMessageClick} role='button'>
              <span className='fs-6 me-1'>
                <i className='bi bi-chat-left-text me-2 text-dark'></i>{' '}
                {getLanguageValue(languageText, 'Add a personal message')}
              </span>
              <span className='fs-6 fw-bold'>{getLanguageValue(languageText, '(optional)')}</span>
            </span>
          )}
        </div>
      </div>

      {changeLanguageModal && (
        <SelectLanguageDropdownModal
          languageText={languageText}
          languages={languages}
          languageValue={languageValue}
          closeModal={closeLanguageModal}
          handleSaveLanguage={handleSaveLanguage}
        />
      )}

      {addMessageModal && (
        <TextAreaModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, messageValue ? 'Edit message' : 'Add message')}
          value={messageValue}
          handleCloseModal={closeAddMessageModal}
          handleSaveClick={handleSaveMessage}
        />
      )}

      <div className='mb-3 pb-1'></div>

      <div className='d-flex align-items-center flex-wrap'>
        <button
          id='inviteRespondentInstruction4'
          className='btn btn-success me-2'
          onClick={onSendInvitationClick}
        >
          <i className='bi bi-send me-2'></i> {getLanguageValue(languageText, 'Send invitation')}
        </button>
        <button
          id='inviteRespondentInstruction5'
          className='btn btn-light'
          onClick={onPreviewClick}
        >
          <i className='bi bi-eye me-2'></i> {getLanguageValue(languageText, 'Preview')}
        </button>
      </div>
    </>
  )
}

export default SendRespondentsInvitation
