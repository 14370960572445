import React from 'react'

import Input from '../../../components/formComponents/input'
import TextArea from '../../../components/formComponents/textArea'
import Switchbox from '../../../components/formComponents/switchbox'
import Checkbox from '../../../components/formComponents/checkbox'
import { DropdownSelect } from '@app/components/formComponents/dropdownSelect'
import SubscriptionCurrencies from '../subscriptionCurrencies/subscriptionCurrencies'
import { SubscriptionType } from '../../clientList/addEditModal/clientEnums'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { SubscriptionInputs, useAddEditSubscription } from './hooks'
import { ModalComponent } from '../../../components/modals/modalComponent'

export interface IAddEditSubscriptionProps {
  subscriptionId: number
  closeModal: () => void
  handleRefetchSubscriptions: () => void
}

const AddEditSubscription = (props: IAddEditSubscriptionProps) => {
  const { subscriptionId, closeModal } = props

  const {
    languageText,
    subscriptionInfo,
    focusInput,
    subscriptionTypes,
    currencies,
    currencyInfo,
    handleInputChange,
    handleSubscriptionTypeChange,
    handleTextAreaChange,
    handleBlurEvent,
    handleCheckboxChange,
    handleCurrencyInputChange,
    handleCurrencyDropdownChange,
    handleAddCurrency,
    handleDeleteCurrency,
    handleSubmit,
  } = useAddEditSubscription(props)

  return (
    <>
      <ModalComponent
        width='lg'
        headerText={getLanguageValue(
          languageText,
          subscriptionId ? 'Edit Plan/Subscription' : 'Add Plan/Subscription'
        )}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        submitButtonText={getLanguageValue(languageText, 'Submit')}
        handleCancelClick={closeModal}
        handleSubmitClick={handleSubmit}
      >
        <div className='d-flex flex-column'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <Input
                name={SubscriptionInputs.name}
                label={getLanguageValue(languageText, 'Name')}
                placeholder={getLanguageValue(languageText, 'Name')}
                value={subscriptionInfo.name}
                focusInput={focusInput.name}
                handleInputChange={handleInputChange}
                handleBlurEvent={handleBlurEvent}
                required
              />
            </div>
            <div className='col-12 col-md-3'>
              <Input
                name={SubscriptionInputs.trialDays}
                label={getLanguageValue(languageText, 'Trial days')}
                type='number'
                value={subscriptionInfo.trialDays}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className='col-12 col-md-3'>
              <DropdownSelect
                name={SubscriptionInputs.type}
                label={getLanguageValue(languageText, 'Type')}
                list={subscriptionTypes}
                value={subscriptionInfo.type}
                handleDropdownSelect={handleSubscriptionTypeChange}
              />
            </div>
          </div>
          <TextArea
            name={SubscriptionInputs.description}
            label={getLanguageValue(languageText, 'Description')}
            placeholder={getLanguageValue(languageText, 'Description')}
            value={subscriptionInfo.description}
            areaClass='form-control form-control-solid min-h-100px'
            onChange={handleTextAreaChange}
            checkMarkNotRequired={true}
          />
          <div className='row'>
            <div className='w-125px'>
              <Switchbox
                name={SubscriptionInputs.enabled}
                labelFirstText={getLanguageValue(languageText, 'Enabled')}
                value={subscriptionInfo.enabled}
                handleSwitchboxChange={handleCheckboxChange}
              />
            </div>
            <div className='w-175px'>
              <Switchbox
                name={SubscriptionInputs.hiddenForFacilitators}
                labelFirstText={getLanguageValue(languageText, 'Hidden for facilitators')}
                value={subscriptionInfo.hiddenForFacilitators}
                handleSwitchboxChange={handleCheckboxChange}
              />
            </div>
            <div className='w-175px'>
              <Switchbox
                name={SubscriptionInputs.hiddenForParticipants}
                labelFirstText={getLanguageValue(languageText, 'Hidden for participants')}
                value={subscriptionInfo.hiddenForParticipants}
                handleSwitchboxChange={handleCheckboxChange}
              />
            </div>
            {SubscriptionType.Subscription === Number(subscriptionInfo.type) && (
              <>
                <div className='w-150px'>
                  <Switchbox
                    name={SubscriptionInputs.enableAnnual}
                    labelFirstText={getLanguageValue(languageText, 'Enable Annual')}
                    value={subscriptionInfo.enableAnnual}
                    handleSwitchboxChange={handleCheckboxChange}
                  />
                </div>

                <div className='w-150px'>
                  <Switchbox
                    name={SubscriptionInputs.enableMonthly}
                    labelFirstText={getLanguageValue(languageText, 'Enable Monthly')}
                    value={subscriptionInfo.enableMonthly}
                    handleSwitchboxChange={handleCheckboxChange}
                  />
                </div>
                <div className='w-125px'>
                  <Switchbox
                    name={SubscriptionInputs.individual}
                    labelFirstText={getLanguageValue(languageText, 'Individual')}
                    value={subscriptionInfo.individual}
                    handleSwitchboxChange={handleCheckboxChange}
                  />
                </div>
              </>
            )}
          </div>
          <div className='fw-bold mb-5 fs-5'>
            {getLanguageValue(languageText, 'Included features per user')}
          </div>
          <div className='d-flex flex-column gap-5 mb-5'>
            <Checkbox
              name={SubscriptionInputs.profile}
              value={subscriptionInfo.profile}
              handleCheckboxChange={handleCheckboxChange}
              label={getLanguageValue(languageText, 'Profile')}
            />
            {subscriptionInfo.profile && (
              <div className='row'>
                {SubscriptionType.Plan === Number(subscriptionInfo.type) ? (
                  <div className='col-md-3 col-12'>
                    <Input
                      name={SubscriptionInputs.profileIncluded}
                      label={getLanguageValue(languageText, 'Included')}
                      type='number'
                      value={subscriptionInfo.profileIncluded}
                      handleInputChange={handleInputChange}
                    />
                  </div>
                ) : (
                  <>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.profileIncludedAnnual}
                        label={getLanguageValue(languageText, 'Included Annual')}
                        value={subscriptionInfo.profileIncludedAnnual}
                        focusInput={focusInput.profileIncludedAnnual}
                        handleInputChange={handleInputChange}
                        handleBlurEvent={handleBlurEvent}
                      />
                    </div>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.profileIncludedMonthly}
                        label={getLanguageValue(languageText, 'Included Monthly')}
                        type='number'
                        value={subscriptionInfo.profileIncludedMonthly}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.profileIncludedInTrial}
                        label={getLanguageValue(languageText, 'Included in trial')}
                        type='number'
                        value={subscriptionInfo.profileIncludedInTrial}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className='d-flex flex-column gap-5 mb-5'>
            <Checkbox
              name={SubscriptionInputs.academyCourses}
              value={subscriptionInfo.academyCourses}
              handleCheckboxChange={handleCheckboxChange}
              label={getLanguageValue(languageText, 'Academy courses')}
            />
            {subscriptionInfo.academyCourses && (
              <div className='row'>
                {SubscriptionType.Plan === Number(subscriptionInfo.type) ? (
                  <div className='col-md-3 col-12'>
                    <Input
                      name={SubscriptionInputs.academyCoursesIncluded}
                      label={getLanguageValue(languageText, 'Included')}
                      type='number'
                      value={subscriptionInfo.academyCoursesIncluded}
                      handleInputChange={handleInputChange}
                    />
                  </div>
                ) : (
                  <>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.academyCoursesIncludedAnnual}
                        label={getLanguageValue(languageText, 'Included Annual')}
                        value={subscriptionInfo.academyCoursesIncludedAnnual}
                        focusInput={focusInput.academyCoursesIncludedAnnual}
                        handleInputChange={handleInputChange}
                        handleBlurEvent={handleBlurEvent}
                      />
                    </div>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.academyCoursesIncludedMonthly}
                        label={getLanguageValue(languageText, 'Included Monthly')}
                        type='number'
                        value={subscriptionInfo.academyCoursesIncludedMonthly}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.academyCoursesIncludedInTrial}
                        label={getLanguageValue(languageText, 'Included in trial')}
                        type='number'
                        value={subscriptionInfo.academyCoursesIncludedInTrial}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className='d-flex flex-column gap-5 mb-5'>
            <Checkbox
              name={SubscriptionInputs.facilitatorConsultationHours}
              value={subscriptionInfo.facilitatorConsultationHours}
              handleCheckboxChange={handleCheckboxChange}
              label={getLanguageValue(languageText, 'Facilitator consultation hours')}
            />
            {subscriptionInfo.facilitatorConsultationHours && (
              <div className='row'>
                {SubscriptionType.Plan === Number(subscriptionInfo.type) ? (
                  <div className='col-md-3 col-12'>
                    <Input
                      name={SubscriptionInputs.facilitatorConsultationHoursIncluded}
                      label={getLanguageValue(languageText, 'Included')}
                      type='number'
                      value={subscriptionInfo.facilitatorConsultationHoursIncluded}
                      handleInputChange={handleInputChange}
                    />
                  </div>
                ) : (
                  <>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.facilitatorConsultationHoursIncludedAnnual}
                        label={getLanguageValue(languageText, 'Included Annual')}
                        value={subscriptionInfo.facilitatorConsultationHoursIncludedAnnual}
                        focusInput={focusInput.facilitatorConsultationHoursIncludedAnnual}
                        handleInputChange={handleInputChange}
                        handleBlurEvent={handleBlurEvent}
                      />
                    </div>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.facilitatorConsultationHoursIncludedMonthly}
                        label={getLanguageValue(languageText, 'Included Monthly')}
                        type='number'
                        value={subscriptionInfo.facilitatorConsultationHoursIncludedMonthly}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                    <div className='col-md-3 col-12'>
                      <Input
                        name={SubscriptionInputs.facilitatorConsultationHoursIncludedInTrial}
                        label={getLanguageValue(languageText, 'Included in trial')}
                        type='number'
                        value={subscriptionInfo.facilitatorConsultationHoursIncludedInTrial}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className='row mb-5'>
            {SubscriptionType.Plan === Number(subscriptionInfo.type) ? (
              <>
                <div className='col-md-3 col-12'>
                  <Input
                    name={SubscriptionInputs.priceSEK}
                    label={getLanguageValue(languageText, 'Price SEK')}
                    type='number'
                    value={subscriptionInfo.priceSEK}
                    focusInput={focusInput.priceSEK}
                    handleInputChange={handleInputChange}
                    handleBlurEvent={handleBlurEvent}
                    required
                  />
                </div>
                <div className='col-md-3 col-12'>
                  <Input
                    name={SubscriptionInputs.discount}
                    label={getLanguageValue(languageText, 'Discount Amount')}
                    type='number'
                    value={subscriptionInfo.discount}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </>
            ) : (
              <>
                <div className='col-md-3 col-12'>
                  <Input
                    name={SubscriptionInputs.priceAnnualSEK}
                    label={getLanguageValue(languageText, 'Price Annual SEK')}
                    type='number'
                    value={subscriptionInfo.priceAnnualSEK}
                    focusInput={focusInput.priceAnnualSEK}
                    handleInputChange={handleInputChange}
                    handleBlurEvent={handleBlurEvent}
                    required
                  />
                </div>
                <div className='col-md-3 col-12'>
                  <Input
                    name={SubscriptionInputs.priceMonthlySEK}
                    label={getLanguageValue(languageText, 'Price Monthly SEK')}
                    type='number'
                    value={subscriptionInfo.priceMonthlySEK}
                    focusInput={focusInput.priceMonthlySEK}
                    handleInputChange={handleInputChange}
                    handleBlurEvent={handleBlurEvent}
                    required
                  />
                </div>
                <div className='col-md-3 col-12'>
                  <Input
                    name={SubscriptionInputs.discountAnnual}
                    label={getLanguageValue(languageText, 'Discount Amount Annual')}
                    type='number'
                    value={subscriptionInfo.discountAnnual}
                    handleInputChange={handleInputChange}
                  />
                </div>
                <div className='col-md-3 col-12'>
                  <Input
                    name={SubscriptionInputs.discountMonthly}
                    label={getLanguageValue(languageText, 'Discount Amount Monthly')}
                    type='number'
                    value={subscriptionInfo.discountMonthly}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </>
            )}
          </div>
          <div className='mb-5'>
            <SubscriptionCurrencies
              languageText={languageText}
              subscriptionType={subscriptionInfo.type}
              currencies={currencies}
              currencyInfo={currencyInfo}
              handleInputChange={handleCurrencyInputChange}
              handleDropdownChange={handleCurrencyDropdownChange}
              handleDeleteCurrency={handleDeleteCurrency}
              handleAddCurrency={handleAddCurrency}
            />
          </div>
          <div className='d-flex flex-column gap-5'>
            <span className='fs-6'>{getLanguageValue(languageText, 'Allow type of payment')}</span>
            <Checkbox
              name={SubscriptionInputs.invoice}
              value={subscriptionInfo.invoice}
              handleCheckboxChange={handleCheckboxChange}
              label={getLanguageValue(languageText, 'Invoice')}
            />
            <Checkbox
              name={SubscriptionInputs.creditCard}
              value={subscriptionInfo.creditCard}
              handleCheckboxChange={handleCheckboxChange}
              label={getLanguageValue(languageText, 'Credit Card')}
            />
            {focusInput.invoice.errorMessage && (
              <div className='invalid-feedback d-inline-block'>
                {focusInput.invoice.errorMessage}
              </div>
            )}
          </div>
        </div>
      </ModalComponent>
    </>
  )
}
export default AddEditSubscription
