import * as React from 'react'
import ReactTable from '@app/components/reactTable/reactTable'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { RootState } from '@app/store/configureStore'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnDef, Row } from '@tanstack/react-table'
import { setNotification, setSpinner } from '../actions'
import { ApiResult } from '@app/types'
import { api, apiEndPoints } from '@app/api'
import { classNames } from '../utils'
import Input from '@app/components/formComponents/input'
import Dropdown from '@app/components/formComponents/dropdown'

type ILogEntry = {
  id: number
  logger: string
  level: string
  message: string
  date: string
}

function getLogs(query: unknown): Promise<ApiResult<ILogEntry>> {
  return api
    .get(apiEndPoints.getLogs, {
      params: query,
    })
    .then((res) => res.data.result)
}

const LEVELS = ['', 'Fatal', 'Error', 'Warn', 'Info', 'Debug', 'Trace'] as const
type Level = (typeof LEVELS)[number]

export const LogEntryList: React.FC<unknown> = (props) => {
  const dispatch = useDispatch()
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const [data, setData] = React.useState<Array<ILogEntry>>([])
  const [level, setLevel] = React.useState<Level | undefined>('Error')
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [filter, setFilter] = React.useState<string>('')

  function fetchLogs(): Promise<unknown> {
    dispatch(setSpinner(true))

    const query: { [key: string]: unknown } = {
      filter: filter,
      level: level,
      startDate: startDate,
      endDate: endDate,
      maxResultCount: 25,
      skipCount: 0,
    }

    return getLogs(query)
      .then((response) => {
        setData(response.items.slice())
      })
      .catch((e) => {
        dispatch(setNotification(e))
        return Promise.reject(e)
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  React.useEffect(() => {
    fetchLogs()
  }, [filter, level, startDate, endDate])

  const headerText = getLanguageValue(languageText, 'Log')
  const [expanded, setExpanded] = React.useState<{ [id: number]: boolean }>({})
  const tableHeader: Array<ColumnDef<ILogEntry>> = [
    {
      accessorKey: '__some_bogus_accessor_key_1',
      header: '',
      cell: (props) => {
        const entry = props.row.original
        const isExpanded = expanded[entry.id] === true
        const iconClazz = classNames({
          'bi text-dark': true,
          'bi-caret-right-fill': !isExpanded,
          'bi-caret-down-fill': isExpanded,
        })

        return (
          <div>
            <i className={iconClazz} />
          </div>
        )
      },
    },
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'level',
      header: 'Level',
      enableSorting: false,
    },
    {
      accessorKey: '__some_bogus_accessor_key_2',
      header: 'Message',
      enableSorting: false,
      cell: (props) => {
        const entry = props.row.original
        const isExpanded = expanded[entry.id] === true
        let message = entry.message

        if (!isExpanded) {
          const match = /^([^\n]+)/.exec(message)
          if (match !== null) {
            message = match[1]
          }
        }

        const codeStyle: React.CSSProperties = {
          marginBottom: 0,
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
        }

        return <pre style={codeStyle}>{message}</pre>
      },
    },
    {
      accessorKey: 'date',
      header: 'Date',
      enableSorting: false,
    },
  ]

  function handleRowClick(event: React.MouseEvent<HTMLTableRowElement>, row: Row<ILogEntry>) {
    event.preventDefault()
    event.stopPropagation()
    const entry = row.original
    const isExpanded = expanded[entry.id] === true
    setExpanded({
      ...expanded,
      [entry.id]: !isExpanded,
    })
  }

  return (
    <ReactTable
      wrapperClass='mt-3 p-2 p-md-4'
      data={data}
      headerText={headerText}
      responsive={false}
      handleRowClick={handleRowClick}
      customFilters={
        <div className='row'>
          <div className='col-4'>
            <Input
              label={getLanguageValue(languageText, 'Search')}
              handleInputChange={(e) => {
                setFilter(e.target.value)
              }}
              placeholder={getLanguageValue(languageText, 'Search')}
              value={filter}
            />
          </div>
          <div className='col'>
            <Input
              label={getLanguageValue(languageText, 'Date start')}
              handleInputChange={(e) => {
                setStartDate(e.target.value)
              }}
              placeholder={getLanguageValue(languageText, 'Date start')}
              value={startDate}
              type='date'
            />
          </div>
          <div className='col'>
            <Input
              label={getLanguageValue(languageText, 'Date end')}
              handleInputChange={(e) => {
                setEndDate(e.target.value)
              }}
              placeholder={getLanguageValue(languageText, 'Date end')}
              value={endDate}
              type='date'
            />
          </div>
          <div className='col'>
            <Dropdown
              label={getLanguageValue(languageText, 'Level')}
              handleDropdownSelect={(e) => {
                setLevel(e.target.value as Level)
              }}
              list={LEVELS.map((l, idx) => {
                return {
                  id: idx + 1,
                  displayName: l,
                  value: l,
                }
              })}
              value={level || ''}
            />
          </div>
        </div>
      }
      tableHeader={tableHeader}
    />
  )
}
