import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { getOrderedCourses, updateCourseOrder } from '../actions'
import ReorderModal, { IReorderData } from '../../../components/reorderingModel/reorderModal'
import { addToast, setSpinner } from '../../actions'
import { DropdownSelect, IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { getFacilitatorLanguages } from '@app/containers/languageList/languageTexts/actions'
import { RootState } from '@app/store/configureStore'
import { ILanguage } from '@app/containers/commonInterfaces'

export interface IUpdateCourseOrderBody {
  id: number
  orderNumber: number
}

export interface IOrderedCourses {
  id: number
  title: string
  orderNumber: number
  languageId: number
  languageName: string
}

interface IReorderCoursesModalProps {
  languageText: ITranslationObject
  refetchCourseList: () => void
  closeReorderCoursesModal: () => void
}

export const ReorderCoursesModal: React.FC<IReorderCoursesModalProps> = (props): JSX.Element => {
  const { languageText, refetchCourseList, closeReorderCoursesModal } = props

  const dispatch = useDispatch()

  const [orderedCourses, setOrderedCourses] = useState<IOrderedCourses[]>([])
  const [languages, setLanguages] = React.useState<Array<ILanguage>>([])
  const [selectedLanguage, setSelectLanguage] = React.useState<number | undefined>(
    useSelector((state: RootState) => state.loginReducer.userLanguage.userLanguageId)
  )

  useEffect(() => {
    dispatch(setSpinner(true))
    getOrderedCourses(dispatch)
      .then((response) => {
        if (response) {
          setOrderedCourses(response)
        }
      })
      .finally(() => dispatch(setSpinner(false)))

    getFacilitatorLanguages(dispatch).then((langs) => {
      setLanguages(langs)
    })
  }, [])

  const handleSaveReorderCourses = (updatedList: IReorderData[]): void => {
    // the 'updatedList' parameter is a subset of the data in 'orderedCourses'.
    // this still works because the backend will only operate on the data we send
    // to it.

    const body: IUpdateCourseOrderBody[] = updatedList.map((item) => {
      return {
        id: item.id,
        orderNumber: item.orderNumber,
      }
    })

    updateCourseOrder(body, dispatch).then((response) => {
      dispatch(addToast('Courses reordered') as AnyAction)
      refetchCourseList()
      closeReorderCoursesModal()
    })
  }

  const languageOptions: Array<IDropdownList> = languages.map((x) => {
    return {
      id: x.id,
      displayName: x.displayName,
      value: String(x.id),
    }
  })

  const filteredCourses = orderedCourses.filter((x) => {
    return typeof selectedLanguage === 'undefined' || selectedLanguage === x.languageId
  })

  return (
    <>
      <ReorderModal
        languageText={languageText}
        headerText={getLanguageValue(languageText, 'Reorder Courses')}
        dataToReorder={filteredCourses}
        closeReorderModal={closeReorderCoursesModal}
        handleSaveReorder={handleSaveReorderCourses}
      >
        <DropdownSelect
          handleDropdownSelect={(option) => {
            setSelectLanguage(option.id)
          }}
          label={getLanguageValue(languageText, 'Course language')}
          list={languageOptions}
          name=''
          searchOption={true}
          value={selectedLanguage?.toString()}
        />
      </ReorderModal>
    </>
  )
}
