import React from 'react'

import CourseCard from './courseCard'
import { useCoursesList } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ICourse } from '../types'

const CoursesList = (): JSX.Element => {
  const { courses, languageText } = useCoursesList()

  return (
    <div className='p-2 pt-4 p-md-4'>
      <div className='d-flex flex-column justify-items-center mb-10'>
        <h1 className='mb-7'>{getLanguageValue(languageText, 'IDI Academy')}</h1>
        <p className='fw-normal fs-5'>{`${getLanguageValue(
          languageText,
          'Welcome to IDI academy'
        )}`}</p>
      </div>

      {courses &&
        courses.map((course: ICourse, i: number) => (
          <CourseCard index={i} key={course.id} languageText={languageText} course={course} />
        ))}
    </div>
  )
}

export default CoursesList
