import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IEditActivity, IEditActivityCompletedProfile } from '../interface'
import { GetTypeOfRoleName, ProfileStatus, ProfileRoleStatus } from '../../profileEnums'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { IRoleData } from '../../addProfile/roleSettings/roleSettingsModal'
import { addToast, setNotification, setSpinner } from '../../../actions'
import Checkbox from '../../../../components/formComponents/checkbox'
import { ParticipantProfileDropdown } from '../modals/participantProfileDropdown'
import EditProfileParticipantModal from '../modals/editProfileParticipantModal/editProfileParticipantModal'
import { ParticipantAccountDetailsModal } from '../modals/participantAccountDetailsModal/participantAccountDetailsModal'
import { RoleSettingsModalWithEditOption } from '../../addProfile/roleSettings/roleSettingsModalWithEditOption'
import ReactTooltip from '../../../../components/tooltip/reactTooltip'
import IndividualPreviewModal from '../modals/reports/preview/individualPreview/individualPreviewModal'
import GroupPreviewModal from '../modals/reports/preview/groupPreview/groupPreviewModal'
import { PresentationsModal } from '../modals/presentationsModal/presentationsModal'
import IndividualDownloadModal from '../modals/reports/download/individualDownload/individualDownloadModal'
import GroupDownloadModal from '../modals/reports/download/groupDownload/groupDownloadModal'
import MultipleParticipantDownloadModal from '../modals/reports/download/multipleDownload/multipleParticipantDownloadModal'
import { DisconnectParticipantModal } from '../modals/disconnectParticipantModal'
import { disconnectParticipantFromPresentation, deliverProfilesManually } from '../actions'
import { getDateAndShortMonthFormat } from '../../../../commonUtils/dateFunctionsHelper'
import EpNoOfRespondents from '../modals/epNoOfRespondents'
import { CustomModal } from '../../../../components/modals/customModal'
import { AnyAction } from 'redux'
import { GuidePages } from '@app/containers/commonEnums'
import { editProfileCompletedStatusInstructionSteps } from '@app/components/instruction/instructionSteps'
import Instruction from '@app/components/instruction/instruction'
import { ActivityId, ProfileId } from '@app/containers/reducer'

interface IManualProfileDeliveryResponse {
  name: string
  isActivityDeliver: boolean
}

export interface IDisconnectParticipantData {
  profileId: ProfileId
  presentationId: number
  presentationDate: string
}

interface IEditProfileCompletedParticipantProps {
  id: ActivityId
  languageText: ITranslationObject
  profileData: IEditActivity
  isInstructionHelpClicked: boolean
  onGoingStatusEditProfileGuideDone: boolean
  languages: IDropdownList[]
  cultureValue: string
  messageTranslation: ITranslationObject
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void
  refetchFullProfile: () => void
  refetchCompletedParticipants: () => void
  refetchPlannedPresentations: () => void
  refetchOngoingParticipants: () => void
  refetchDeliveredParticipants: () => void
  handlePublishProfile: (profileId: ProfileId) => void
  sendEmailForDeliveredProfiles: (profileIds: Array<ProfileId>) => void
  handleTransferProfile?: () => void
}

const EditProfileCompletedParticipant = (
  props: IEditProfileCompletedParticipantProps
): JSX.Element => {
  const {
    id,
    languageText,
    profileData,
    isInstructionHelpClicked,
    onGoingStatusEditProfileGuideDone,
    languages,
    cultureValue,
    messageTranslation,
    isActivityCancelled,
    isActivityInvoiced,
    handleExitGuide,
    refetchFullProfile,
    refetchCompletedParticipants,
    refetchOngoingParticipants,
    refetchPlannedPresentations,
    refetchDeliveredParticipants,
    handlePublishProfile,
    sendEmailForDeliveredProfiles,
    handleTransferProfile,
  } = props

  const dispatch = useDispatch()
  const instructionSteps = useMemo(
    () => editProfileCompletedStatusInstructionSteps(languageText),
    [languageText]
  )

  const initialRoleState: IRoleData<ProfileId> = {
    id: 0 as ProfileId,
    roleId: 0,
    roleText: '',
    noOfRespondents: 0,
  }
  const initialDisconnectParticipantDataState: IDisconnectParticipantData = {
    profileId: 0 as ProfileId,
    presentationId: 0,
    presentationDate: '',
  }
  const [editProfileId, setEditProfileId] = useState<ProfileId>(0 as ProfileId)
  const [selectedProfileIds, setSelectedProfileIds] = useState<Array<ProfileId>>([]) // Checkbox selected participant
  const [selectedParticipantRoleSettings, setSelectedParticipantRoleSettings] =
    useState<IRoleData<ProfileId>>(initialRoleState)
  const [profileAccountDetailsId, setProfileAccountDetailsId] = useState<ProfileId>(0 as ProfileId)
  // Preview states
  const [previewProfileId, setPreviewProfileId] = useState<ProfileId>(0 as ProfileId)
  const [showGroupPreviewModal, setShowGroupPreviewModal] = useState<boolean>(false)
  const [isSelf, setIsSelf] = useState<boolean>(false)
  const [downloadProfileId, setDownloadProfileId] = useState<ProfileId>(0 as ProfileId)
  const [isGroupDownloadOpen, setIsGroupDownloadOpen] = useState<boolean>(false)
  const [isMultipleDownloadOpen, setIsMultipleDownloadOpen] = useState<boolean>(false)
  const [profilesToUpdateDownloadStatus, setProfilesToUpdateDownloadStatus] = useState<
    Array<ProfileId>
  >([]) // List of profiles to update download status while downloading multiple profiles
  const [openPresentationsModal, setOpenPresentationsModal] = useState<boolean>(false)
  const [errorParticipantIds, setErrorParticipantIds] = useState<number[]>([])
  const [selectedParticipantsWithoutPresentation, setSelectedParticipantsWithoutPresentation] =
    useState<IEditActivityCompletedProfile[]>([])
  const [disconnectParticipantData, setDisconnectParticipantData] =
    useState<IDisconnectParticipantData>(initialDisconnectParticipantDataState)
  const [deliveredParticipants, setDeliveredParticipants] = useState<
    IManualProfileDeliveryResponse[]
  >([]) // Participants after manual delivery

  // Add edit presentation fn's
  const onOpenPresentationModal = (): void => {
    if (!selectedProfileIds.length) return
    const selectedParticipantsWithPresentation = profileData.completedProfiles.filter(
      (profile) => selectedProfileIds.includes(profile.id) && profile.presentationId !== null
    )
    const selectedParticipantsWithoutPresentation = profileData.completedProfiles.filter(
      (profile) => selectedProfileIds.includes(profile.id) && profile.presentationId === null
    )
    if (selectedParticipantsWithPresentation.length > 0) {
      const errorIds = selectedParticipantsWithPresentation.map((participant) => participant.id)
      setErrorParticipantIds(errorIds)
      dispatch(
        setNotification(
          getLanguageValue(
            languageText,
            'At least one selected participant already has a presentation assigned'
          )
        )
      )
    } else {
      setSelectedParticipantsWithoutPresentation(selectedParticipantsWithoutPresentation)
      setOpenPresentationsModal(true)
    }
  }

  const closePresentationsModal = (): void => {
    setOpenPresentationsModal(false)
  }

  // Edit Settings fn's
  const onSettingsClick = (id: ProfileId): void => {
    setEditProfileId(id)
  }

  const closeSettingsModal = (): void => {
    setEditProfileId(0 as ProfileId)
  }

  // Role settings modal fn's
  const onRoleClick = (participant: IEditActivityCompletedProfile): void => {
    setSelectedParticipantRoleSettings({
      id: participant.id,
      roleId: participant.roleId,
      roleText: participant.roleText,
      noOfRespondents: participant.noOfRespondents,
    })
  }

  const closeRoleSettingsModal = (): void => {
    setSelectedParticipantRoleSettings(initialRoleState)
  }

  // Participant account details fn's
  const onProfileClick = (id: ProfileId): void => {
    setProfileAccountDetailsId(id)
  }

  const closeAccountDetailsModal = (): void => {
    setProfileAccountDetailsId(0 as ProfileId)
  }

  // Checkbox fn's
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      // Select all participants
      const allParticipants = profileData.completedProfiles.map((p) => p.id)
      setSelectedProfileIds(allParticipants)
    } else {
      // Unselect all participants
      setSelectedProfileIds([])
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: ProfileId): void => {
    const isSelected = e.target.checked

    setSelectedProfileIds((prevSelectedParticipants) => {
      if (isSelected) {
        return [...prevSelectedParticipants, id]
      } else {
        return prevSelectedParticipants.filter((item) => item !== id)
      }
    })

    setErrorParticipantIds((prevErrorParticipantIds) => {
      if (isSelected && prevErrorParticipantIds.includes(id)) {
        return prevErrorParticipantIds.filter((errorId) => errorId !== id)
      } else {
        return prevErrorParticipantIds
      }
    })
  }

  // Preview Group Participants
  const onGroupPreviewClick = (_: number, isSelf: boolean): void => {
    if (selectedProfileIds.length > 0) {
      setShowGroupPreviewModal(true)
      setIsSelf(isSelf)
    }
  }

  const closeGroupPreview = (): void => {
    setShowGroupPreviewModal(false)
  }

  // Preview Individual Participant
  const onIndividualPreviewClick = (profileId: ProfileId): void => {
    setPreviewProfileId(profileId)
  }

  const closeIndividualPreview = (): void => {
    setPreviewProfileId(0 as ProfileId)
  }

  // Download Individual Participant
  const onIndividualDownloadClick = (profileId: ProfileId): void => {
    setDownloadProfileId(profileId)
  }

  const closeIndividualDownload = (): void => {
    setDownloadProfileId(0 as ProfileId)
  }

  // Download Group Participants
  const onGroupDownloadClick = (): void => {
    if (selectedProfileIds.length === 0) return
    setIsGroupDownloadOpen(true)
  }

  const closeGroupDownload = (): void => {
    setIsGroupDownloadOpen(false)
  }

  // Download Multiple Participants
  const onMultipleDownloadClick = (): void => {
    if (selectedProfileIds.length === 0) return
    setIsMultipleDownloadOpen(true)

    // Get profileIds for whom profiles are not yet downloaded to update the download status
    const requiredProfiles = profileData.completedProfiles.filter(
      (participant) => !participant.pdfProfileDownloaded
    )
    let requiredProfileIds: Array<ProfileId> = []
    if (requiredProfiles.length > 0) {
      requiredProfileIds = requiredProfiles.map((profiole) => profiole.id)
    }
    if (requiredProfileIds) {
      setProfilesToUpdateDownloadStatus(requiredProfileIds)
    }
  }

  const closeMultipleDownload = (): void => {
    setIsMultipleDownloadOpen(false)
    setProfilesToUpdateDownloadStatus([])
  }

  // Disconnect participants from presentation fn's
  const handleDisconnectClick = (participant: IEditActivityCompletedProfile): void => {
    setDisconnectParticipantData({
      profileId: participant.id,
      presentationId: participant.presentationId,
      presentationDate: participant.presentationDate,
    })
  }

  const closeDisconnectModal = (): void => {
    setDisconnectParticipantData(initialDisconnectParticipantDataState)
  }

  const handleDisconnectParticipant = (): void => {
    dispatch(setSpinner(true))
    disconnectParticipantFromPresentation(disconnectParticipantData.profileId, dispatch)
      .then((response) => {
        if (response?.success) {
          refetchCompletedParticipants()
          refetchPlannedPresentations()
          dispatch(addToast('Participant disconnected') as AnyAction)
          closeDisconnectModal()
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  // Deliver participants
  const handleDeliverProfile = (profileIds: Array<ProfileId>, isGroupDelivery = false): void => {
    deliverProfilesManually(id, profileIds, dispatch).then(
      (response: IManualProfileDeliveryResponse[]) => {
        if (response) {
          if (isGroupDelivery) {
            const allProfilesDeliveryFailed = response.every(
              (profile) => !profile.isActivityDeliver
            )
            if (!allProfilesDeliveryFailed) {
              setDeliveredParticipants(response)
            } else {
              let errorMsg = ''
              if (selectedProfileIds.length === 1) {
                errorMsg = 'Profile delivery failed'
              } else {
                errorMsg = 'Profiles delivery failed'
              }
              dispatch(setNotification(errorMsg))
            }
          } else {
            const isActivityDelivered = response[0].isActivityDeliver
            if (isActivityDelivered) {
              sendEmailForDeliveredProfiles(profileIds)
              dispatch(addToast('Deliver successful') as AnyAction)
              if (
                profileData.newProfileCount === 0 &&
                profileData.onGoingProfileCount === 0 &&
                profileData.completedProfileCount === 1
              ) {
                refetchFullProfile()
              } else {
                refetchCompletedParticipants()
                refetchDeliveredParticipants()
              }
            } else {
              dispatch(setNotification('Profile delivery failed'))
            }
          }
        }
      }
    )
  }

  const handleDeliverClick = (profileId: ProfileId): void => {
    if (profileId) {
      handleDeliverProfile([profileId])
    } else {
      if (selectedProfileIds.length === 0) return
      handleDeliverProfile(selectedProfileIds, true)
    }
  }

  const handleCloseDeliveredParticipants = (): void => {
    const allProfilesDelivered = deliveredParticipants.every(
      (participant) => participant.isActivityDeliver
    )
    if (
      profileData.newProfileCount === 0 &&
      profileData.onGoingProfileCount === 0 &&
      profileData.completedProfileCount === selectedProfileIds.length &&
      allProfilesDelivered
    ) {
      refetchFullProfile()
    } else {
      refetchCompletedParticipants()
      refetchDeliveredParticipants()
    }
    setDeliveredParticipants([])
    setSelectedProfileIds([])
    sendEmailForDeliveredProfiles(selectedProfileIds)
  }

  const getDeliveredParticipantsProfileInfo = () => {
    return (
      <>
        {deliveredParticipants.map((participant, index) => (
          <div className='fs-5' key={index}>
            {index + 1}. {participant.name} -
            <span
              className={clsx(
                'badge fw-bold fs-6 ms-2',
                participant.isActivityDeliver ? 'text-bg-primary' : 'badge-light-danger'
              )}
            >
              {getLanguageValue(
                languageText,
                participant.isActivityDeliver ? 'Delivered' : 'Not-Delivered'
              )}
            </span>
          </div>
        ))}
      </>
    )
  }

  let allParticipantsSelected = false
  if (profileData.completedProfileCount > 0) {
    allParticipantsSelected = selectedProfileIds.length === profileData.completedProfiles.length
  }

  return (
    <div className='rounded border mb-3 bg-white'>
      <div
        id='editProfileCompletedStatusHeader'
        className='p-3 pe-4 d-flex justify-content-between align-items-center flex-wrap bg-secondary-subtle'
      >
        <span className='fs-5 fw-bold text-secondary'>
          <span className='badge fs-5 bg-dark pt-2 me-2 bg-opacity-25'>
            {profileData.completedProfileCount}
          </span>
          {getLanguageValue(languageText, 'Completed')}
        </span>
        {profileData.completedProfileCount > 0 && (
          <>
            <div id='completedMassAction' className='d-flex align-items-center'>
              <button
                id='presentationIcon'
                className={clsx(
                  'btn border me-4',
                  selectedProfileIds.length > 0 && !isActivityCancelled && 'btn-success'
                )}
                disabled={isActivityCancelled || isActivityInvoiced}
                onClick={onOpenPresentationModal}
              >
                <i className='bi bi-easel-fill fs-5'></i>
              </button>
              <ParticipantProfileDropdown
                languageText={languageText}
                isGroup={true}
                isDeliver={true}
                handlePreviewClick={onGroupPreviewClick}
                handleDeliverOrUnDeliverClick={handleDeliverClick}
                handleDownloadClick={onMultipleDownloadClick}
                handleDownloadGroupClick={onGroupDownloadClick}
              />
              <Checkbox
                className='fs-3 ms-4'
                inputClass={clsx(
                  'border border-secondary-subtle',
                  !allParticipantsSelected && 'bg-white border grey-border'
                )}
                value={allParticipantsSelected}
                handleCheckboxChange={handleSelectAll}
              />
            </div>

            {/* Instruction steps */}
            {onGoingStatusEditProfileGuideDone ? (
              <Instruction
                showHelpButton={false}
                targetElement='completedIndividualCheckbox'
                guidePage={GuidePages.Edit_Profile_Completed_Status}
                instructionSteps={instructionSteps}
                skipPostGuideRequest={true}
                manualTrigger={isInstructionHelpClicked}
                handleExitGuide={handleExitGuide}
              />
            ) : null}
          </>
        )}
      </div>

      {profileData.completedProfileCount > 0 && (
        <div>
          {profileData.completedProfiles.map((profile) => {
            const isSelected = selectedProfileIds.includes(profile.id)
            const hasError = errorParticipantIds.includes(profile.id)
            return (
              <div
                key={profile.id}
                className={clsx(
                  'd-flex flex-wrap border-top p-4',
                  hasError && isSelected ? 'border-danger bg-light-danger' : 'border-gray-400'
                )}
              >
                <div id='completedParticipantDetails' className='col-lg-6 d-flex col-12 p-0'>
                  {/* Participant name */}
                  <div
                    className={clsx(
                      'w-100 m-auto fs-5 fw-bold',
                      !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                    )}
                    role='button'
                    onClick={() =>
                      !isActivityCancelled && !isActivityInvoiced && onSettingsClick(profile.id)
                    }
                  >
                    <ReactTooltip id={profile.id.toString()} tooltipText={profile.name}>
                      {profile.name}
                    </ReactTooltip>
                    {(profile.noOfRespondents || profile.roleId || profile.roleText) && (
                      <div className='fs-6 fw-normal'>
                        {profile.noOfRespondents ? profile.noOfRespondents : ''}{' '}
                        {profile.roleId
                          ? getLanguageValue(languageText, GetTypeOfRoleName[profile.roleId])
                          : profile.roleText
                            ? profile.roleText
                            : getLanguageValue(languageText, 'Respondents')}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 d-flex col-12 p-0 d-flex justify-content-between'>
                  <div className='d-flex py-1'>
                    {/* No of respondents */}
                    <EpNoOfRespondents
                      id='completedNoOfRespondents'
                      profileId={profile.id}
                      noOfRespondents={profile.noOfRespondents}
                      respondentsInvited={profile.respondentsInvited}
                      respondentsAnswered={profile.respondentsAnswered}
                      isActivityCancelled={isActivityCancelled}
                      isActivityInvoiced={isActivityInvoiced}
                      onNoOfRespondentsClick={onProfileClick}
                    />
                  </div>

                  <div className='d-flex py-1 justify-content-end align-items-center'>
                    {/* Presentation icon */}
                    {profile.presentationId && (
                      <button
                        className={clsx(
                          'btn bg-success-subtle me-4',
                          !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                        )}
                        onClick={() =>
                          !isActivityCancelled &&
                          !isActivityInvoiced &&
                          handleDisconnectClick(profile)
                        }
                      >
                        <span className='d-none d-md-inline text-truncate'>
                          {getDateAndShortMonthFormat(profile.presentationDate)}
                        </span>{' '}
                        <i className='bi bi-easel-fill fs-5 ms-2'></i>
                      </button>
                    )}

                    {/* Profile dropdown */}
                    <ParticipantProfileDropdown
                      languageText={languageText}
                      id='completedIndividualDropdown'
                      isDeliver={true}
                      profileId={profile.id}
                      handlePreviewClick={onIndividualPreviewClick}
                      handleDownloadClick={onIndividualDownloadClick}
                      handleDeliverOrUnDeliverClick={handleDeliverClick}
                    />

                    {/* Checkbox icon */}
                    <div className='mt-1'>
                      <Checkbox
                        id='completedIndividualCheckbox'
                        className='ms-4 fs-3'
                        inputClass={clsx(
                          'border border-secondary-subtle',
                          !isSelected && 'bg-white border grey-border'
                        )}
                        value={isSelected}
                        handleCheckboxChange={(e) => handleCheckboxChange(e, profile.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

          {/* Add Edit Presentation Modal */}
          {openPresentationsModal && (
            <PresentationsModal
              isEdit={false}
              selectedProfiles={selectedParticipantsWithoutPresentation}
              activityId={profileData.id}
              handleCloseClick={closePresentationsModal}
              refetchPlannedPresentations={refetchPlannedPresentations}
              refetchParticipants={refetchCompletedParticipants}
            />
          )}

          {/* Edit Settings Modal */}
          {editProfileId > 0 && (
            <EditProfileParticipantModal
              languageText={languageText}
              profileId={editProfileId}
              roleDisabled={profileData.roleSetBy === Number(ProfileRoleStatus.DisableRole)}
              languageCode={cultureValue}
              languages={languages}
              messageTranslation={messageTranslation}
              status={ProfileStatus.Completed}
              handleCloseClick={closeSettingsModal}
              refetchParticipants={refetchCompletedParticipants}
              refetchOngoingParticipants={refetchOngoingParticipants}
              handlePublishProfile={handlePublishProfile}
              handleTransferProfile={handleTransferProfile}
            />
          )}

          {/* Role Settings Modal */}
          {selectedParticipantRoleSettings.id > 0 && (
            <RoleSettingsModalWithEditOption
              languageText={languageText}
              profileId={selectedParticipantRoleSettings.id}
              status={ProfileStatus.Completed}
              roleId={selectedParticipantRoleSettings.roleId}
              roleText={selectedParticipantRoleSettings.roleText}
              noOfRespondents={selectedParticipantRoleSettings.noOfRespondents}
              refetchParticipants={refetchCompletedParticipants}
              refetchOngoingParticipants={refetchOngoingParticipants}
              handleCloseClick={closeRoleSettingsModal}
            />
          )}

          {/* Participant account details Modal */}
          {profileAccountDetailsId > 0 && (
            <ParticipantAccountDetailsModal
              languageText={languageText}
              languages={languages}
              handleCloseClick={closeAccountDetailsModal}
              profileId={profileAccountDetailsId}
            />
          )}

          {/* Preview Group Participants */}
          {showGroupPreviewModal && (
            <GroupPreviewModal
              id={id}
              languageText={languageText}
              profileData={profileData}
              isSelf={isSelf}
              selectedProfileIds={selectedProfileIds}
              handleCloseClick={closeGroupPreview}
            />
          )}

          {/* Preview Individual Participant */}
          {previewProfileId > 0 && (
            <IndividualPreviewModal
              languageText={languageText}
              profileId={previewProfileId}
              handleCloseClick={closeIndividualPreview}
            />
          )}

          {/* Download Participant Profile */}
          {downloadProfileId > 0 && (
            <IndividualDownloadModal
              languageText={languageText}
              profileId={downloadProfileId}
              status={ProfileStatus.Completed}
              pdfProfileDownloaded={
                profileData.completedProfiles.find((par) => par.id === downloadProfileId)
                  ?.pdfProfileDownloaded ?? false
              }
              handleCloseClick={closeIndividualDownload}
              refetchCompletedParticipants={refetchCompletedParticipants}
              profileParticipantName={
                profileData.completedProfiles.find((par) => par.id === downloadProfileId)?.name ??
                ''
              }
            />
          )}

          {/* Download Group Participant */}
          {isGroupDownloadOpen && (
            <GroupDownloadModal
              id={id}
              languageText={languageText}
              name={profileData.name}
              profileIds={selectedProfileIds}
              handleCloseClick={closeGroupDownload}
              profileData={profileData}
            />
          )}
          {isMultipleDownloadOpen && (
            <MultipleParticipantDownloadModal
              languageText={languageText}
              profileIds={selectedProfileIds}
              status={ProfileStatus.Completed}
              profilesToUpdateDownloadStatus={profilesToUpdateDownloadStatus}
              handleCloseClick={closeMultipleDownload}
              refetchCompletedParticipants={refetchCompletedParticipants}
            />
          )}

          {/* Disconnect Presentation Modal */}
          {disconnectParticipantData.presentationId > 0 && (
            <DisconnectParticipantModal
              languageText={languageText}
              presentationData={disconnectParticipantData}
              closeDisconnectModal={closeDisconnectModal}
              handleDisconnectClick={handleDisconnectParticipant}
            />
          )}
        </div>
      )}

      {/* DeliveredParticipants Modal */}
      {deliveredParticipants.length > 0 && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Delivery Info')}
          bodyText={getDeliveredParticipantsProfileInfo()}
          submitButtonText={getLanguageValue(languageText, 'Ok')}
          handleCancelClick={handleCloseDeliveredParticipants}
          handleSubmitClick={handleCloseDeliveredParticipants}
        />
      )}
    </div>
  )
}

export default EditProfileCompletedParticipant
