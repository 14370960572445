import React from 'react'

import EditProfileCompletedParticipant from './editProfileCompletedParticipant'
import { EditProfilePresentation } from './editProfilePresentation'
import { IEditActivity } from '../interface'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { ITranslationObject } from '../../../../commonUtils/languageFunctionsHelper'
import { GuidePages } from '@app/containers/commonEnums'
import { ActivityId, ProfileId } from '@app/containers/reducer'

interface IEditProfileCompletedOrPlannedParticipantProps {
  id: ActivityId
  languageText: ITranslationObject
  profileData: IEditActivity
  isInstructionHelpClicked: boolean
  onGoingStatusEditProfileGuideDone: boolean
  cultures: IDropdownList[]
  languages: IDropdownList[]
  cultureValue: string
  messageTranslation: ITranslationObject
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void
  refetchFullProfile: () => void
  refetchCompletedParticipants: () => void
  refetchOngoingParticipants: () => void
  refetchPlannedPresentations: () => void
  refetchDeliveredParticipants: () => void
  handlePublishProfile: (profileId: ProfileId) => void
  sendEmailForDeliveredProfiles: (profileIds: Array<ProfileId>) => void
  handleTransferProfile?: () => void
}

export const EditProfileCompletedOrPlannedParticipant: React.FC<
  IEditProfileCompletedOrPlannedParticipantProps
> = (props) => {
  const {
    id,
    languageText,
    profileData,
    isInstructionHelpClicked,
    onGoingStatusEditProfileGuideDone,
    cultures,
    languages,
    cultureValue,
    messageTranslation,
    isActivityCancelled,
    isActivityInvoiced,
    handleExitGuide,
    refetchFullProfile,
    refetchCompletedParticipants,
    refetchOngoingParticipants,
    refetchPlannedPresentations,
    refetchDeliveredParticipants,
    handlePublishProfile,
    sendEmailForDeliveredProfiles,
    handleTransferProfile,
  } = props

  return (
    <>
      <EditProfileCompletedParticipant
        id={id}
        languageText={languageText}
        profileData={profileData}
        isInstructionHelpClicked={isInstructionHelpClicked}
        onGoingStatusEditProfileGuideDone={onGoingStatusEditProfileGuideDone}
        languages={languages}
        cultureValue={cultureValue}
        messageTranslation={messageTranslation}
        isActivityCancelled={isActivityCancelled}
        isActivityInvoiced={isActivityInvoiced}
        handleExitGuide={handleExitGuide}
        refetchFullProfile={refetchFullProfile}
        refetchCompletedParticipants={refetchCompletedParticipants}
        refetchOngoingParticipants={refetchOngoingParticipants}
        refetchPlannedPresentations={refetchPlannedPresentations}
        refetchDeliveredParticipants={refetchDeliveredParticipants}
        handlePublishProfile={handlePublishProfile}
        sendEmailForDeliveredProfiles={sendEmailForDeliveredProfiles}
        handleTransferProfile={handleTransferProfile}
      />
      <EditProfilePresentation
        languageText={languageText}
        profileData={profileData}
        cultures={cultures}
        isActivityCancelled={isActivityCancelled}
        isActivityInvoiced={isActivityInvoiced}
        refetchCompletedParticipants={refetchCompletedParticipants}
        refetchPlannedPresentations={refetchPlannedPresentations}
        refetchDeliveredParticipants={refetchDeliveredParticipants}
      />
    </>
  )
}
