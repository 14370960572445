import React from 'react'

import { DeleteModal } from '../../components/modals/deleteModal'
import ReactTable from '../../components/reactTable/reactTable'
import { AddEditPresentationModal } from './addEditPresentationModal/addEditPresentationModal'
import { usePresentationList } from './hooks'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { ModelPermissionModal } from '../auth/modelPermissionModal'
import { ModelType } from '../commonInterfaces'

const PresentationsList = () => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openPresentationModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    createTemplateClick,
    refreshPresentationList,
    closePresentationModal,
    handleDeletePresentation,
    closeDeleteModal,
    openPermissionModalForPresentation,
    closePermissionModal,
  } = usePresentationList()

  return (
    <>
      <ReactTable
        wrapperClass='p-2 pt-4 p-md-4'
        headerText={getLanguageValue(languageText, 'Presentations')}
        searchPlaceholder={getLanguageValue(languageText, 'Search Presentation')}
        buttonText={getLanguageValue(languageText, `Create Template`)}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={createTemplateClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {openPresentationModal && (
        <AddEditPresentationModal
          editId={editedRowId}
          refreshPresentationList={refreshPresentationList}
          closePresentationModal={closePresentationModal}
        />
      )}

      {deleteRowId > 0 && (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, 'Presentation')}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeletePresentation}
          closeDeleteModal={closeDeleteModal}
        />
      )}

      {!!openPermissionModalForPresentation && (
        <ModelPermissionModal
          modelType={ModelType.PresentationTemplate}
          modelId={openPermissionModalForPresentation.id}
          headerText={`${getLanguageValue(languageText, 'Permissions')}: ${
            openPermissionModalForPresentation.description
          }`}
          handleClose={closePermissionModal}
        />
      )}
    </>
  )
}

export default PresentationsList
