import React from 'react'

interface IMultiLabelToggleCheckboxProps {
  flabel: string
  rlabel: string
  name?: string
  value?: boolean
  className?: string
  disable?: boolean
  handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const MultiLabelToggleCheckbox = (props: IMultiLabelToggleCheckboxProps) => {
  const { flabel, rlabel, name, value, disable, handleCheckboxChange } = props

  return (
    <div className='d-flex align-items-center mb-3'>
      <span className='form-check-label text-dark fs-5 me-2'>{flabel}</span>
      <div className='form-check form-switch fs-5'>
        <input
          className='form-check-input bg-success'
          type='checkbox'
          name={name ?? 'name'}
          onChange={handleCheckboxChange}
          disabled={disable ?? false}
          checked={value}
        />
      </div>
      <span className='form-check-label text-dark fs-5'>{rlabel}</span>
    </div>
  )
}

export default MultiLabelToggleCheckbox
