import React from 'react'

import SendRespondentsInvitation from './sendRespondentsInvitation'
import { PhoneNumberInput } from '../../../employeeList/addEmployeeModal/phoneNumberInput'
import Input from '../../../../components/formComponents/input'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { IManualRespondentInfo } from './hooks'
import { E164Number } from 'libphonenumber-js/types'

export enum ManualRespondentInputs {
  name = 'name',
  emailAddress = 'emailAddress',
  phoneNumber = 'phoneNumber',
  nameError = 'nameError',
  emailError = 'emailError',
  phoneNumberError = 'phoneNumberError',
}

interface IAddManuallyProps {
  languageText: ITranslationObject
  languages: IDropdownList[]
  respondentInfo: IManualRespondentInfo[]
  languageValue: string
  messageValue: string
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlePhoneInputChange: (value: string, id: number) => void
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void
  onDeleteRowClick: (id: number) => void
  onAddRowClick: () => void
  handleSaveLanguage: (selectedValue: string) => void
  handleSaveMessage: (value: string) => void
  onPreviewClick: () => void
  onSendInvitationClick: () => void
}

const AddManually = (props: IAddManuallyProps): JSX.Element => {
  const {
    languageText,
    respondentInfo,
    languages,
    languageValue,
    messageValue,
    handleInputChange,
    handlePhoneInputChange,
    handleBlurEvent,
    onDeleteRowClick,
    onAddRowClick,
    handleSaveLanguage,
    handleSaveMessage,
    onPreviewClick,
    onSendInvitationClick,
  } = props

  return (
    <div>
      {respondentInfo.map((respondent, index) => (
        <div key={index} className='add-manually-row'>
          <div className='row justify-content-center'>
            <div className='col-md-4'>
              <Input
                id={`${ManualRespondentInputs.name}${respondent.id}`}
                name={ManualRespondentInputs.name}
                placeholder={getLanguageValue(languageText, 'Name')}
                value={respondent.name}
                focusInput={respondent.nameError}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
                required
              />
            </div>
            <div className='col-md-4'>
              <Input
                id={`${ManualRespondentInputs.emailAddress}${respondent.id}`}
                name={ManualRespondentInputs.emailAddress}
                placeholder={getLanguageValue(languageText, 'Email')}
                value={respondent.emailAddress}
                focusInput={respondent.emailError}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
                required
              />
            </div>
            <div className='col-md-4'>
              <div
                className='btn btn-light float-end border-secondary-subtle'
                onClick={() => onDeleteRowClick(respondent.id)}
              >
                <i className='bi bi-trash3'></i>
              </div>
              <div className='w-75'>
                <PhoneNumberInput
                  value={(respondent.phoneNumber as E164Number) || ''}
                  onChange={(value) => handlePhoneInputChange(value, respondent.id)}
                  languageText={languageText}
                  placeholder={getLanguageValue(languageText, 'Mobile number')}
                />
              </div>
            </div>
            <div className='my-4 mt-md-2 mb-md-3 d-md-none border-top'></div>
            <div className='my-2 d-md-block d-none'></div>
          </div>
        </div>
      ))}
      <div className='d-flex justify-content-center border-bottom mb-4 pb-4 pb-md-3 d-none'>
        <div
          className='d-flex gap-2 justify-content-center align-items-center'
          onClick={onAddRowClick}
          role='button'
        >
          <i className='bi bi-plus-lg text-dark fs-3'></i>
          <p className='my-auto'>{getLanguageValue(languageText, 'Add Field')}</p>
        </div>
      </div>

      <SendRespondentsInvitation
        languageText={languageText}
        languages={languages}
        languageValue={languageValue}
        messageValue={messageValue}
        handleSaveLanguage={handleSaveLanguage}
        handleSaveMessage={handleSaveMessage}
        onPreviewClick={onPreviewClick}
        onSendInvitationClick={onSendInvitationClick}
      />
    </div>
  )
}

export default AddManually
