import React from 'react'

import useSwitchRoles from './hooks'
import LanguageNavbar from '../../components/languageNavbarComponent/languageNavbar'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { Roles } from '../commonEnums'

const SwitchRolesPage = (): JSX.Element => {
  const { languageText, handleSubmit } = useSwitchRoles()

  return (
    <>
      <div className='container my-md-5'>
        <div className='row p-3'>
          <div className='col-12 col-md-5 shadow p-4 p-md-5 bg-white rounded mx-auto'>
            <LanguageNavbar />

            <div className='mt-4'>
              <div className='mb-1 fs-4 fw-bolder'>
                {getLanguageValue(languageText, 'Select account to sign in to')}
              </div>
              <p className='py-4'>
                {getLanguageValue(
                  languageText,
                  'Your login is linked to several accounts. Select the type of account you want to log in to'
                )}
                .
              </p>
              <div className='row'>
                <div className='col-6'>
                  <button
                    onClick={() => handleSubmit(Roles.Facilitator)}
                    className='btn btn-outline-success w-100 fw-bold'
                  >
                    {getLanguageValue(languageText, 'Facilitator')}
                  </button>
                </div>
                <div className='col-6'>
                  <button
                    onClick={() => handleSubmit(Roles.Participant)}
                    className='btn btn-success w-100 fw-bold'
                  >
                    {getLanguageValue(languageText, 'Participant')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SwitchRolesPage
