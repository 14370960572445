import React from 'react'

import AffiliationDirectivenessGraph from '../../components/affiliationDirectivenessGraph'
import Adaptability from '../../components/adaptability'
import Directiveness from '../../components/directiveness'
import Affiliation from '../../components/affiliation'
import CreatedDate from '../../components/createdDate'
import CultureNorm from '../../components/cultureNorm'
import Respondents from '../../components/respondents'
import { IParticipantProfileReport } from '../../../../interface'
import { ITranslationObject } from '@app/commonUtils/languageFunctionsHelper'

interface IIndividualPreviewProps {
  languageText: ITranslationObject
  participantReport: IParticipantProfileReport
}

const IndividualPreview = (props: IIndividualPreviewProps): JSX.Element => {
  const { languageText, participantReport } = props

  return (
    <div className='d-flex justify-content-between flex-wrap gap-3 ms-4'>
      <div className='individual-matris'>
        <div className='individual-matris-affDir border p-2 ps-4 pb-4'>
          <div className='fs-5 fw-bold p-2'>{participantReport.title}</div>
          <AffiliationDirectivenessGraph
            languageText={languageText}
            oAffPos={participantReport.oAffPos}
            oDirPos={participantReport.oDirPos}
            sAffPos={participantReport.sAffPos}
            sDirPos={participantReport.sDirPos}
            outsideStdDevA={participantReport.outsideStdDevA}
            outsideStdDevD={participantReport.outsideStdDevD}
          />
        </div>

        <div className='individual-matris-dir border border-top-0 p-2'>
          <Directiveness
            languageText={languageText}
            profileMeasure={participantReport.directivenessMeasure!}
            directivenessMessage={participantReport.directivenessMessage!}
            directivenessSpreadText={participantReport.directivenessSpreadText ?? ''}
          />
        </div>

        <div className='individual-matris-aff border border-top-0 p-2'>
          <Affiliation
            languageText={languageText}
            profileMeasure={participantReport.affiliationMeasure!}
            affiliationMessage={participantReport.affiliationMessage!}
            affiliationSpreadText={participantReport.affiliationSpreadText ?? ''}
          />
        </div>

        <div className='individual-matris-adap border border-top-0 p-2'>
          <Adaptability
            languageText={languageText}
            oFlex={participantReport.oFlex}
            sFlex={participantReport.sFlex}
            outsideStdDevF={participantReport.outsideStdDevF}
            profileMeasure={participantReport.flexMeasure!}
            flexMessage={participantReport.flexMessage!}
            adaptabilitySpreadText={participantReport.adaptabilitySpreadText ?? ''}
          />
        </div>
      </div>

      <div>
        <CreatedDate languageText={languageText} createdDate={participantReport.date} />

        <div className='my-3'>
          <CultureNorm languageText={languageText} language={participantReport.culture} />
        </div>

        <Respondents
          languageText={languageText}
          roleId={participantReport.roleId}
          roleText={participantReport.roleText}
          noOfRespondents={participantReport.noOfRespondents}
          respondents={participantReport.respondents}
        />
      </div>
    </div>
  )
}

export default IndividualPreview
