import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'

import EditProfileParticipantModal from '../modals/editProfileParticipantModal/editProfileParticipantModal'
import { ParticipantAccountDetailsModal } from '../modals/participantAccountDetailsModal/participantAccountDetailsModal'
import { RoleSettingsModalWithEditOption } from '../../addProfile/roleSettings/roleSettingsModalWithEditOption'
import Checkbox from '../../../../components/formComponents/checkbox'
import PersonalizedInvitationModal from '../../addProfile/emailSMSInvitation/personalizedInvitationModal'
import ReactTooltip from '../../../../components/tooltip/reactTooltip'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { ISelectedProfilesList } from '../../addProfile/activityInviteParticipantsStep'
import { GetTypeOfRoleName, ProfileStatus, ProfileRoleStatus } from '../../profileEnums'
import {
  EmailStatus,
  IEditActivity,
  IEditActivityOngoingProfile,
  getEmailStatus,
} from '../interface'
import { IRoleData } from '../../addProfile/roleSettings/roleSettingsModal'
import EpNoOfRespondents from '../modals/epNoOfRespondents'
import GroupInvitation from '../../addProfile/emailSMSInvitation/groupInvitation'
import { GuidePages } from '@app/containers/commonEnums'
import { editProfileOnGoingStatusInstructionSteps } from '@app/components/instruction/instructionSteps'
import Instruction from '@app/components/instruction/instruction'
import { ProfileId } from '@app/containers/reducer'

interface IEditProfileOngoingParticipantProps {
  id: number
  languageText: ITranslationObject
  profileData: IEditActivity
  isInstructionHelpClicked: boolean
  newStatusEditProfileGuideDone: boolean
  languages: IDropdownList[]
  cultureValue: string
  messageTranslation: ITranslationObject
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void
  refetchOngoingParticipants: () => void
  refetchCompletedParticipants: () => void
  handleTransferProfile: () => void
}

const EditProfileOngoingParticipant = (props: IEditProfileOngoingParticipantProps): JSX.Element => {
  const {
    languageText,
    profileData,
    isInstructionHelpClicked,
    newStatusEditProfileGuideDone,
    languages,
    cultureValue,
    messageTranslation,
    isActivityCancelled,
    isActivityInvoiced,
    handleExitGuide,
    refetchOngoingParticipants,
    refetchCompletedParticipants,
    handleTransferProfile,
  } = props

  const instructionSteps = useMemo(
    () => editProfileOnGoingStatusInstructionSteps(languageText),
    [languageText]
  )

  const initialRoleState: IRoleData<ProfileId> = {
    id: 0 as ProfileId,
    roleId: 0,
    roleText: '',
    noOfRespondents: 0,
  }
  const initialInviteState: ISelectedProfilesList[] = [
    {
      id: 0 as ProfileId,
      name: '',
      email: '',
      phoneNumber: '',
      emailSent: false,
      smsSent: false,
    },
  ]
  const [editProfileId, setEditProfileId] = useState<ProfileId>(0 as ProfileId)
  const [selectedParticipantRoleSettings, setSelectedParticipantRoleSettings] =
    useState<IRoleData<ProfileId>>(initialRoleState)
  const [profileAccountDetailsId, setProfileAccountDetailsId] = useState<ProfileId>(0 as ProfileId)
  // Email feature States
  const [selectedProfileIds, setSelectedProfileIds] = useState<Array<ProfileId>>([]) // Checkbox selected participant
  const [invitedProfileIds, setInvitedProfileIds] = useState<Array<ProfileId>>([]) // Email sent once more for these participants
  const [participantsList, setParticipantsList] = useState<ISelectedProfilesList[]>([]) // List of participants that can be invited
  const [selectedProfilesForInvite, setSelectedProfilesForInvite] =
    useState<ISelectedProfilesList[]>(initialInviteState) // To send invite once more
  const [emailInvitationSent, setEmailInvitationSent] = useState<boolean>(false)
  const [smsInvitationSent, setSMSInvitationSent] = useState<boolean>(false)

  useEffect(() => {
    if (profileData.onGoingProfileCount > 0) {
      const participantsList: ISelectedProfilesList[] = profileData.onGoingProfiles.map(
        (participant) => ({
          id: participant.id,
          name: participant.name,
          email: participant.emailAddress,
          phoneNumber: participant.phoneNumber ?? '',
          emailSent: false,
          smsSent: false,
          emailOwnMessage: participant.emailOwnMessage,
          smsOwnMessage: participant.smsOwnMessage,
          emailStatus: participant.emailStatus,
          smsStatus: participant.smsStatus,
        })
      )
      setParticipantsList(participantsList)
    }
  }, [profileData.onGoingProfiles])

  // Edit Settings fn's
  const onSettingsClick = (id: ProfileId): void => {
    setEditProfileId(id)
  }

  const closeSettingsModal = (): void => {
    setEditProfileId(0 as ProfileId)
  }

  // Role settings modal fn's
  const onRoleClick = (participant: IEditActivityOngoingProfile): void => {
    setSelectedParticipantRoleSettings({
      id: participant.id,
      roleId: participant.roleId,
      roleText: participant.roleText,
      noOfRespondents: participant.noOfRespondents,
      respondentsAnswered: participant.respondentsAnswered,
    })
  }

  const closeRoleSettingsModal = (): void => {
    setSelectedParticipantRoleSettings(initialRoleState)
  }

  // Participant account details fn's
  const onProfileClick = (id: ProfileId): void => {
    setProfileAccountDetailsId(id)
  }

  const closeAccountDetailsModal = (): void => {
    setProfileAccountDetailsId(0 as ProfileId)
  }

  // Checkbox fn's
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      // Select all participants
      const filteredParticipants = profileData.onGoingProfiles.filter(
        (participant) =>
          participant.emailStatus !== EmailStatus.InvitationSentWaitingForResult &&
          participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult
      )
      if (filteredParticipants.length > 0) {
        const allParticipants = filteredParticipants.map((p) => p.id)
        setSelectedProfileIds(allParticipants)
      } else return
    } else {
      // Unselect all participants
      setSelectedProfileIds([])
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: ProfileId): void => {
    setSelectedProfileIds((prevSelectedParticipant) => {
      if (e.target.checked) {
        return [...prevSelectedParticipant, id]
      } else {
        return prevSelectedParticipant.filter((item) => item !== id)
      }
    })
  }

  const closeInvitationModal = (): void => {
    setSelectedProfilesForInvite(initialInviteState)
    setSelectedProfileIds([])
  }

  // Individual Invitation fn
  const onInvitationClick = (id: number): void => {
    const selectedParticipant = participantsList.find((participant) => participant.id === id)
    initialInviteState
    setSelectedProfilesForInvite(selectedParticipant ? [selectedParticipant] : initialInviteState)
  }

  const handleIndividualMessageSentSuccess = (
    isEmail: boolean,
    profile: ISelectedProfilesList
  ): void => {
    const updatedList: ISelectedProfilesList[] = participantsList.map((item) => {
      if (item.id === profile.id) {
        return {
          ...item,
          emailSent: isEmail ? true : item.emailSent,
          smsSent: !isEmail ? true : item.smsSent,
        }
      } else return item
    })

    setParticipantsList(updatedList)

    const updatedParticipant = updatedList.find((item) => item.id === profile.id)
    setSelectedProfilesForInvite(updatedParticipant ? [updatedParticipant] : initialInviteState)

    // Email sent once more for these participants
    setInvitedProfileIds((prev) => [...prev, profile.id])

    refetchOngoingParticipants()
  }

  // Group Invitation fn
  const inviteSelectedParticipants = (): void => {
    if (!selectedProfileIds.length) return
    const selectedParticipantsToInvite = participantsList.filter((item) =>
      selectedProfileIds.includes(item.id)
    )
    setSelectedProfilesForInvite(selectedParticipantsToInvite)
  }

  const handleSameMessageSentSuccess = (isEmail: boolean): void => {
    const updatedList: ISelectedProfilesList[] = participantsList.map((item) => ({
      ...item,
      emailSent: isEmail ? true : item.emailSent,
      smsSent: !isEmail ? true : item.smsSent,
    }))
    setParticipantsList(updatedList)

    const updatedParticipant =
      updatedList.filter((item) =>
        selectedProfilesForInvite.filter((participant) => participant.id === item.id)
      ) ?? initialInviteState
    setSelectedProfilesForInvite(updatedParticipant)

    // Email sent once more for these participants
    selectedProfilesForInvite.map((participant) =>
      setInvitedProfileIds((prev) => [...prev, participant.id])
    )

    if (isEmail) {
      setEmailInvitationSent(true)
    } else setSMSInvitationSent(true)

    refetchOngoingParticipants()
  }

  let disableCheckbox = false
  if (profileData.onGoingProfileCount > 0) {
    disableCheckbox = profileData.onGoingProfiles.every(
      (participant) =>
        participant.emailStatus === EmailStatus.InvitationSentWaitingForResult ||
        participant.emailStatus === EmailStatus.ReminderSentWaitingForResult
    )
  }

  let allParticipantsSelected = false
  if (profileData.onGoingProfileCount > 0 && !disableCheckbox) {
    allParticipantsSelected =
      selectedProfileIds.length ===
      profileData.onGoingProfiles.filter(
        // Shouldn't send email for participants waiting for email result
        (participant) =>
          participant.emailStatus !== EmailStatus.InvitationSentWaitingForResult &&
          participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult
      ).length
  }

  return (
    <div className='rounded border mb-3 bg-white'>
      <div
        id='editProfileOnGoingStatusHeader'
        className='p-3 pe-4 d-flex justify-content-between align-items-center flex-wrap bg-secondary-subtle'
      >
        <span className='fs-5 fw-bold text-secondary'>
          <span className='badge fs-5 bg-dark pt-2 me-2 bg-opacity-25'>
            {profileData.onGoingProfileCount}
          </span>
          {getLanguageValue(languageText, 'Ongoing')}
        </span>
        {profileData.onGoingProfileCount > 0 && (
          <>
            <div id='onGoingMultiEmailSMS' className='d-flex align-items-center'>
              <button
                className={clsx('btn border me-4', selectedProfileIds.length > 0 && 'btn-success')}
                disabled={isActivityCancelled || isActivityInvoiced || disableCheckbox}
                onClick={inviteSelectedParticipants}
              >
                <i className='bi bi-send-fill fs-5'></i>
              </button>
              <Checkbox
                className='fs-3'
                inputClass={clsx('border border-secondary-subtle')}
                value={allParticipantsSelected}
                disable={isActivityCancelled || isActivityInvoiced}
                handleCheckboxChange={handleSelectAll}
              />
            </div>

            {/* Instruction steps */}
            {newStatusEditProfileGuideDone && (
              <Instruction
                showHelpButton={false}
                targetElement='onGoingIndividualCheckbox'
                guidePage={GuidePages.Edit_Profile_OnGoing_Status}
                instructionSteps={instructionSteps}
                skipPostGuideRequest={true}
                manualTrigger={isInstructionHelpClicked}
                handleExitGuide={handleExitGuide}
              />
            )}
          </>
        )}
      </div>

      {profileData.onGoingProfileCount > 0 && (
        <div>
          {profileData.onGoingProfiles.map((participant) => {
            const isSelected = selectedProfileIds.includes(participant.id)
            return (
              <div key={participant.id} className='d-flex flex-wrap border-top p-4'>
                <div id='onGoingParticipantDetails' className='col-lg-6 d-flex col-12 p-0'>
                  {/* Participant name */}
                  <div
                    className={clsx(
                      'w-100 m-auto fs-4 fw-bold',
                      !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                    )}
                    role='button'
                    onClick={() =>
                      !isActivityCancelled && !isActivityInvoiced && onSettingsClick(participant.id)
                    }
                  >
                    <ReactTooltip id={participant.id.toString()} tooltipText={participant.name}>
                      {participant.name}
                    </ReactTooltip>
                    {(participant.noOfRespondents ||
                      participant.roleId ||
                      participant.roleText) && (
                      <div className='fs-6 fw-normal'>
                        {participant.noOfRespondents ? participant.noOfRespondents : ''}{' '}
                        {participant.roleId
                          ? getLanguageValue(languageText, GetTypeOfRoleName[participant.roleId])
                          : participant.roleText
                            ? participant.roleText
                            : getLanguageValue(languageText, 'Respondents')}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-6 d-flex col-12 p-0 d-flex justify-content-between'>
                  <div className='d-flex py-1'>
                    {/* No of respondents */}
                    <EpNoOfRespondents
                      id='onGoingNoOfRespondents'
                      profileId={participant.id}
                      noOfRespondents={participant.noOfRespondents}
                      respondentsInvited={participant.respondentsInvited}
                      respondentsAnswered={participant.respondentsAnswered}
                      isActivityCancelled={isActivityCancelled}
                      isActivityInvoiced={isActivityInvoiced}
                      onNoOfRespondentsClick={onProfileClick}
                    />
                  </div>

                  <div className='d-flex py-1 justify-content-end align-items-center'>
                    {/* Invitation icon */}
                    <div
                      id='onGoingInvitation'
                      className={clsx(
                        'btn pt-md-2',
                        !isActivityCancelled &&
                          participant.emailStatus !== EmailStatus.InvitationSentWaitingForResult &&
                          participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult &&
                          ' cursor-pointer',
                        getEmailStatus(participant.emailStatus)
                      )}
                      onClick={() =>
                        !isActivityCancelled &&
                        participant.emailStatus !== EmailStatus.InvitationSentWaitingForResult &&
                        participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult &&
                        onInvitationClick(participant.id)
                      }
                    >
                      <i className='bi bi-send-fill fs-5'></i>
                    </div>

                    {/* Checkbox icon */}
                    <div className='mt-1'>
                      <Checkbox
                        id='onGoingIndividualCheckbox'
                        className='ms-4 fs-3'
                        inputClass={clsx(!isSelected && 'border border-secondary-subtle')}
                        value={isSelected}
                        disable={
                          isActivityCancelled ||
                          participant.emailStatus === EmailStatus.InvitationSentWaitingForResult ||
                          participant.emailStatus === EmailStatus.ReminderSentWaitingForResult
                        }
                        handleCheckboxChange={(e) => handleCheckboxChange(e, participant.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}

      {/* Edit Settings Modal */}
      {editProfileId > 0 && (
        <EditProfileParticipantModal
          languageText={languageText}
          profileId={editProfileId}
          roleDisabled={profileData.roleSetBy === Number(ProfileRoleStatus.DisableRole)}
          languageCode={cultureValue}
          languages={languages}
          messageTranslation={messageTranslation}
          status={ProfileStatus.Active}
          handleCloseClick={closeSettingsModal}
          refetchParticipants={refetchOngoingParticipants}
          refetchCompletedParticipants={refetchCompletedParticipants}
          handleTransferProfile={handleTransferProfile}
        />
      )}

      {/* Role Settings Modal */}
      {selectedParticipantRoleSettings.id > 0 && (
        <RoleSettingsModalWithEditOption
          languageText={languageText}
          profileId={selectedParticipantRoleSettings.id}
          status={ProfileStatus.Active}
          roleId={selectedParticipantRoleSettings.roleId}
          roleText={selectedParticipantRoleSettings.roleText}
          noOfRespondents={selectedParticipantRoleSettings.noOfRespondents}
          respondentsAnswered={selectedParticipantRoleSettings.respondentsAnswered}
          refetchParticipants={refetchOngoingParticipants}
          refetchCompletedParticipants={refetchCompletedParticipants}
          handleCloseClick={closeRoleSettingsModal}
        />
      )}

      {/* Participant account details Modal */}
      {profileAccountDetailsId > 0 && (
        <ParticipantAccountDetailsModal
          languageText={languageText}
          languages={languages}
          handleCloseClick={closeAccountDetailsModal}
          profileId={profileAccountDetailsId}
        />
      )}

      {/* Individual Invitation Modal */}
      {selectedProfilesForInvite.length === 1 && selectedProfilesForInvite[0].id > 0 && (
        <PersonalizedInvitationModal
          languageText={languageText}
          messageTranslation={messageTranslation}
          languages={languages}
          cultureValue={cultureValue}
          profile={selectedProfilesForInvite[0]}
          phoneNumber={selectedProfilesForInvite[0].phoneNumber}
          handleCloseClick={closeInvitationModal}
          handleMessageSentSuccess={handleIndividualMessageSentSuccess}
        />
      )}

      {/* Group Invitation Modal */}
      {selectedProfilesForInvite.length > 1 && (
        <GroupInvitation
          languageText={languageText}
          messageTranslation={messageTranslation}
          languages={languages}
          cultureValue={cultureValue}
          participants={selectedProfilesForInvite}
          emailInvitationSent={emailInvitationSent}
          smsInvitationSent={smsInvitationSent}
          handleMessageSentSuccess={handleSameMessageSentSuccess}
          handleCloseClick={closeInvitationModal}
        />
      )}
    </div>
  )
}

export default EditProfileOngoingParticipant
