import React, { useEffect } from 'react'
import clsx from 'clsx'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IProfilesDeliveredReport } from '../interface'
import '../../../../css/components/idiMatris.scss'

interface IParticipantProfileGraphProps {
  languageText: ITranslationObject
  profiles: IProfilesDeliveredReport[]
}

export const ParticipantProfileGraph = (props: IParticipantProfileGraphProps): JSX.Element => {
  const adjustLine = (
    from: HTMLElement | null,
    to: HTMLElement | null,
    line: HTMLElement | null
  ) => {
    if (!from || !to || !line) {
      return
    }
    const fT = from.offsetTop + from.offsetHeight / 2
    const tT = to.offsetTop + to.offsetHeight / 2
    const fL = from.offsetLeft + from.offsetWidth / 2
    const tL = to.offsetLeft + to.offsetWidth / 2

    const CA = Math.abs(tT - fT)
    const CO = Math.abs(tL - fL)
    const H = Math.sqrt(CA * CA + CO * CO)
    let ANG = (180 / Math.PI) * Math.acos(CA / H)
    let top = 0
    let left = 0

    if (tT > fT) {
      top = (tT - fT) / 2 + fT
    } else {
      top = (fT - tT) / 2 + tT
    }
    if (tL > fL) {
      left = (tL - fL) / 2 + fL
    } else {
      left = (fL - tL) / 2 + tL
    }
    if (
      (fT < tT && fL < tL) ||
      (tT < fT && tL < fL) ||
      (fT > tT && fL > tL) ||
      (tT > fT && tL > fL)
    ) {
      ANG *= -1
    }
    top -= H / 2
    line.style['-webkit-transform'] = `rotate(${ANG}deg)`
    line.style['-moz-transform'] = `rotate(${ANG}deg)`
    line.style['-ms-transform'] = `rotate(${ANG}deg)`
    line.style['-o-transform'] = `rotate(${ANG}deg)`
    line.style['-transform'] = `rotate(${ANG}deg)`
    line.style.top = `${top}px`
    line.style.left = `${left}px`
    line.style.height = `${H}px`
  }

  useEffect(() => {
    if (props.profiles.length > 1) {
      for (let xfrom = 1; xfrom < props.profiles.length; xfrom++) {
        adjustLine(
          document.getElementById(`profile${xfrom}`),
          document.getElementById(`profile${xfrom + 1}`),
          document.getElementById(`line${xfrom}`)
        )
      }
    }
  }, [props.profiles])

  function graphPositionAsString(profile: IProfilesDeliveredReport): string {
    return JSON.stringify([profile.oDirPos, profile.oAffPos])
  }

  /** Saving profileId as well as index since it might be used */
  type ProfileIdAndIndex = [number, number]
  /** the key needs to be a stringified array for the hash comparison to work */
  const graphPositionHash: Map<string, ProfileIdAndIndex[]> = new Map()
  const sortedProfiles = props.profiles
    //Build the hashmap and just return the item so that we don't change the array
    .map((item, index) => {
      /** Let's add 1 to index to start visual profile number from 1 */
      const profileIdAndIndex: ProfileIdAndIndex = [item.profileId, index + 1]
      const profilePosition = graphPositionAsString(item)
      let positionArray: ProfileIdAndIndex[] = []
      if (graphPositionHash.has(profilePosition)) {
        positionArray = graphPositionHash.get(profilePosition) ?? []
      }
      positionArray.push(profileIdAndIndex)
      graphPositionHash.set(profilePosition, positionArray)
      //let's return unchanged
      return item
    })
    /** Since the first one should be the oldest, we order by date ascending. */
    .sort((a, b) => new Date(a.profileDate).getTime() - new Date(b.profileDate).getTime())

  return (
    <div className='position-relative'>
      <div className='ratio ratio-1x1 border position-relative'>
        {sortedProfiles.length > 0 &&
          sortedProfiles.map((profile, index) => {
            const sLine = index + 1
            const profilePosition = graphPositionAsString(profile)

            /** Should be at least one, but fallback on empty array to make compiler happy */
            const profilesAtPostition = (graphPositionHash.get(profilePosition) || []).map(
              (item) => item[1]
            )
            return (
              <div key={index} id={profile.profileId.toString()}>
                <div
                  id={`profile${sLine}`}
                  className={clsx(`idi-position-parent y${profile.oAffPos} x${profile.oDirPos}`)}
                  title={
                    profilesAtPostition.length > 1
                      ? getLanguageValue(
                          props.languageText,
                          'This data point contains two or more profiles'
                        )
                      : ''
                  }
                >
                  <div className='idi-position-child bg-success'>
                    {profilesAtPostition.join(',')}
                  </div>
                </div>
                {/** Draw the edge between the nodes */}
                {sLine > 1 && <div id={`line${sLine - 1}`} className='lineC' />}
              </div>
            )
          })}
        <div className='d-flex justify-content-between rotate90 ms-n4'>
          <div>{getLanguageValue(props.languageText, 'Task focus')}</div>
          <div className='fw-bold'>{getLanguageValue(props.languageText, 'Affiliation')}</div>
          <div>{getLanguageValue(props.languageText, 'People focus')}</div>
        </div>
        <div className='d-flex flex-wrap'>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom'></div>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom'></div>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom border-end'></div>
          <div className='h-25 w-25 border-bottom'></div>
          <div className='h-25 w-25 border-end'></div>
          <div className='h-25 w-25 border-end'></div>
          <div className='h-25 w-25 border-end'></div>
          <div className='h-25 w-25'></div>
        </div>
      </div>
      <div className='d-flex justify-content-between mt-1'>
        <div>{getLanguageValue(props.languageText, 'Inquery')}</div>
        <div className='fw-bold'>{getLanguageValue(props.languageText, 'Directiveness')}</div>
        <div>{getLanguageValue(props.languageText, 'Advocacy')}</div>
      </div>
    </div>
  )
}
